import Modal from 'react-bootstrap/Modal';
import "./style.css";
import { Button, CloseButton, Form
    , Spinner , Col 
  } from "react-bootstrap";
import { useState , useEffect } from 'react';
import { Multiselect } from 'multiselect-react-dropdown';
import {ProductService} from '../../../business/ProductService';
import {UserService} from '../../../business/UserService';
import {PromoService} from '../../../business/PromoService';
import * as Yup from 'yup';
import { yupToFormErrors } from "formik";

type ModalAgregarPromocionProps = {
    handleClose: any
    handleOpen: any
    show?: boolean

}

const validSquema = Yup.object().shape({
   tipo: Yup.number().required("*Campo obligatorio")
   ,codigo: Yup.string().required("*Campo obligatorio")
 });

const initialValues: any = {
    tipo: 1,
    codigo: null,
    fecha_inicio: null,
    fecha_vencimiento: null,
    promocion_producto:[],
    monto: null
};

function ModalAgregarPromocion(props:ModalAgregarPromocionProps) {
    const [data, setData] = useState(initialValues);
    const [products, setProducts] = useState([]);
    const [errors, setErrors] = useState<any>();
    const [validated, setValidated] = useState<any>();
    const [loading, setLoadingButton] = useState(false);

    useEffect(() => {      
        if(errors == null)
        {
            setValidated(false);
        }
        else{
            setValidated(true);
        }
        
      }, [errors]);

    const getProducts = async (search:string) => { 
        const getInstance = ProductService.getInstance();

        await getInstance.getProductsList({search: search})
            .then((response:any) => {
                
                    if(response.success === true)
                    {
                        setProducts(response.products);

                    }
                    
                })
            .catch((err:any) => {
            });

    }
    
    const insertPromocion = async () => { 
        setLoadingButton(true);
        const getInstance = PromoService.getInstance();
            
        await getInstance.insertPromo(data)
            .then((response:any) => {
                setLoadingButton(false);
                if(response.success === true)
                {
                    handleClose();
                }
                
                    
            })
            .catch((err:any) => {
                setLoadingButton(false);
                console.log(err);
               
                setErrors({
                    tipo: err.result.errors.tipo !== undefined ? err.result.errors.tipo[0] : null,
                    codigo: err.result.errors.codigo !== undefined ? err.result.errors.codigo[0] : null,
            
                });
                
            });
    
    }


    const handleSearchProduct = (value:string) => {
        if(value.length > 3){
            getProducts(value);
        }
    }
    
    const onSelectProduct = (selectedList:any, selectedItem:any) => {
        setData({
            ...data,
            ['products']: selectedList
        });
    }

    const onRemoveProduct = (selectedList:any, removedItem:any) => {
        setData({
            ...data,
            ['promocion_producto']: selectedList
        });
    }

    const handleChangeData= (event: any) => {
        const { name, value} = event.target;
        let copy = {...data};
        let val = value;
        
        if(name==='tipo_promocion')
        {
            val = parseInt(value);
        }

    
        setData({
            ...data,
            [name]: event.target.type==='checkbox' ? event.target.checked : val
        });
        
      };

      const handleSave= (event: any) => {
        validSquema.validate(data, { abortEarly: false }).then(function() {
            // Success
            insertPromocion();

            setErrors(null);
        }).catch(function (err) {
            let errorArray = {};
            const errors = yupToFormErrors(err);
            console.log(errors);
            setErrors(errors);
            setValidated(true);
           
        });
        
      }

      const handleClose = () => {
        setData(initialValues);
        props.handleClose();
      }

  return (
    <Modal id="modal_agregar_promocion" className='modal_cupon' show={props.show} onHide={props.handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header  className="close-modal">   
            <h3 className='title_modal_cupon'>Agregar promoción</h3>             
            <CloseButton variant="white" onClick={handleClose}/>
        </Modal.Header>        
        <Modal.Body>                
            <div className='cont_modal_cupon'>
                <Form className='form_agregar_cupon' validated={validated}>
                    <Form.Group className='row'>
                        <Col md={3}>
                            <Form.Label>Tipo de promoción*</Form.Label>
                        </Col>
                        <Col md={9}>
                            <Form.Control as="select" name="tipo" required value={data.tipo} onChange={e => {handleChangeData(e)}}>
                                <option value="1">2x1</option>
                                <option value="2">3x2</option>
                                <option value="3">Delivery gratis</option>
                                <option value="4">Producto gratis</option>
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    <Form.Group className='row'>
                        <Col md={3}>
                            <Form.Label>Código*</Form.Label>
                        </Col>
                        <Col md={9}>
                            <Form.Control as="input" type="text" name="codigo" required value={data.codigo} onChange={e => {handleChangeData(e)}}/>
                            {errors?.codigo != "" && (
                                <Form.Control.Feedback type="invalid">
                                    {errors?.codigo}
                                </Form.Control.Feedback>
                            )}
                        </Col>
                    </Form.Group>
                    <Form.Group className='row'>
                        <Col md={3}>
                            <Form.Label>Fecha inicio</Form.Label>
                        </Col>
                        <Col md={9}>
                            <Form.Control as="input" type="date" name="fecha_inicio" required value={data.fecha_inicio} onChange={e => {handleChangeData(e)}}/>
                            {errors?.fecha_inicio != "" && (
                                <Form.Control.Feedback type="invalid">
                                    {errors?.fecha_inicio}
                                </Form.Control.Feedback>
                            )}
                        </Col>
                    </Form.Group>
                    <Form.Group className='row'>
                        <Col md={3}>
                            <Form.Label>Fecha vencimiento</Form.Label>
                        </Col>
                        <Col md={9}>
                            <Form.Control as="input" type="date" name="fecha_vencimiento" value={data.fecha_vencimiento} onChange={e => {handleChangeData(e)}}/>
                        </Col>
                    </Form.Group>
                    <Form.Group className='row'>
                        <Col md={3}>                               
                            <Form.Label>Monto S/</Form.Label>
                        </Col>
                        <Col md={9}>
                            <input type="number" name='monto' className='form-control' value={data.monto} onChange={e => {handleChangeData(e)}}/>                                                    
                                                     
                        </Col>
                    </Form.Group>
                    <Form.Group className='row'>
                        <Col md={3}>
                            <Form.Label>Producto(s)</Form.Label>
                        </Col>
                        <Col md={9}>
                            <Multiselect
                                options={products} // Options to display in the dropdown
                                onSelect={onSelectProduct} // Function will trigger on select event
                                onRemove={onRemoveProduct} // Function will trigger on remove event
                                displayValue="name" // Property name to display in the dropdown options
                                onSearch={handleSearchProduct}
                                placeholder='Elija uno o varios productos'
                                />
                        </Col>
                    </Form.Group>
                    <div className='cont_modal_confirm'>
                        <Col md={12} className='dis_fle'>           
                            <Col md={6} className="con_btn_confirm">
                                <Button className='btn_confirm' type="button" onClick={(e)=>handleSave(e)} disabled={loading}>
                                    {loading ? (
                                    <Spinner animation="border" role="status" style={{width: '1rem', height: '1rem'}}>
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                    ) : (
                                    'Guardar'
                                    )}
                                </Button>  
                            </Col>
                            <Col md={6} className="con_btn_confirm">
                                <button className='btn_cancel' type="button" onClick={handleClose}>Cancelar</button> 
                            </Col>                
                        </Col>    
                    </div>
                </Form>
            </div>
        </Modal.Body>
    </Modal>
  );
}

export default ModalAgregarPromocion;