import Modal from 'react-bootstrap/Modal';
import "./style.css";
import { Button, CloseButton, Form
    , Row , Col 
  } from "react-bootstrap"

type TermsProps = {
    handleClose?: any
    show?: boolean
}

function Terms(props:TermsProps) {
    

  return (
    <Modal
      show={props.show} onHide={props.handleClose}
      fullscreen={true}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
        <Modal.Header  className="close-modal">
            <CloseButton variant="white" onClick={props.handleClose}/>
        </Modal.Header>
        <Modal.Body className="container-iframe">
       
            <iframe className="responsive-iframe" id="iframesrc" src="https://origo.pet/pdf/Terminos_condiciones.pdf"></iframe>
           
        </Modal.Body>
        <Modal.Footer style={{justifyContent: "center"}}>
            
        </Modal.Footer>
    </Modal>
  );
}

export default Terms