import { api } from "../api";
import ActionType from "../utils/customTypes";

export class SalesService extends api {

    private static classInstance?: SalesService;
        
    constructor(token: string, type: ActionType) {
        super( process.env.REACT_APP_BASE_URL,token);    
    } 
  
    public static getInstance() {
        let token = localStorage.getItem("token");
        if (!this.classInstance) {
            this.classInstance = new SalesService(token!==undefined && token !== null ? token:'', 'GET');
        }
        
        return this.classInstance;
    }   

    public getSales= (data:any) => this.instance.post('/get-sales', data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getSuscriptions= (data:any) => this.instance.post('/get-suscriptions', data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getSuscription= (data:any) => this.instance.post('/get-suscription', data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getDonations= (data:any) => this.instance.post('/get-donations', data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getDonation= (data:any) => this.instance.post('/get-donation', data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getPurchase= (data:any) => this.instance.post('/get-purchase', data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getTopProducts= () => this.instance.post('/get-top-products').then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getSalesByCategory= () => this.instance.post('/get-sales-by-category').then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getSuscriptionsLastMonths= () => this.instance.post('/get-suscriptions-lastmonths').then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getUsersLastMonths= () => this.instance.post('/get-users-lastmonths').then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public export= (data:any) => this.instance.post('/export', data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public updatePaymentPurchase= (data:any) => this.instance.post('/update-payment-purchase', data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public updateSuscription= (data:any) => this.instance.post('/update-suscription', data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public updateDonation= (data:any) => this.instance.post('/update-donation', data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

   
    
}