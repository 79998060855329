import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders, InternalAxiosRequestConfig } from "axios";
import { ToastContainer, toast } from 'react-toastify';
import ActionType from "../../src/utils/customTypes";
import { MESSAGE_CODES, NETWORK_ERROR } from "../utils/constans"; 
import Cookies from 'universal-cookie';
const cookies = new Cookies();

interface RefreshToken {
    status: number;
    data: {
        hashToken: string;
    };
}



export abstract class api {
    protected instance: AxiosInstance;
    protected token: string ;
    protected readonly baseURL: string | undefined;
    protected type : ActionType;

    public constructor(baseURL: string | undefined, token: string) {
        this.baseURL = baseURL;
        this.instance = axios.create({
            baseURL
        });
        this.token = token;
        this.type = 'GET';
     

        this.initializeRequestInterceptor();
        this.initializeResponseInterceptor();
    }

    private initializeRequestInterceptor = () => {
        
        this.instance.interceptors.request.use(this.handleRequest);
        
    };

    private initializeResponseInterceptor = () => {
       
        this.instance.interceptors.response.use(response => {
            if (response.headers && response.headers.authorization) {
                const responseToken = (response.headers.authorization as string).split(' ')[1];
                this.token = responseToken;

                localStorage.setItem('hashToken', this.token);
            }
           
            
            const message = response.data.messages[0];
            const result = response.data.result;
            const idMessage = message?.code;


            switch (idMessage) {
                // Success cases
                case '':
                case MESSAGE_CODES.OK:
                if (
                    message &&
                    (this.type === 'TOAST')
                ) 
                {
                    toast.success(`${message?.message || 'ERROR'}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: 0,
                        });  
                    
                }
                return Promise.resolve(result);
                
                // Error cases
                case MESSAGE_CODES.SESSION_EXPIRED:
                    localStorage.removeItem('token');
                    localStorage.removeItem('rol');
                    localStorage.removeItem('name');
                    localStorage.removeItem('te');
                  
                    toast.error(`${message?.message || 'Sesión expirada'}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: 0,
                        });  
                        setTimeout(() => {
                            window.location.href = '/'
                          }, 1000);
                    return Promise.reject(response.data);
                default:
                        
                    if(this.type !== 'GET' ){
                        toast.error(`${message?.message || 'ERROR'}`,{
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: 0,
                            });  
                    }
                     return Promise.reject({ ...response.data });
            }
            
        }, this.handleError);
    }


    public handleRequest = (config: InternalAxiosRequestConfig) => {
        if(config.url?.includes('/get-') || config.url?.includes('/validate-')){
            this.type = 'GET';
        }
        else{
            if(config.url?.includes('/insert-') || config.url?.includes('/update-') || config.url?.includes('/delete-')){
                this.type = 'TOAST';
            }
        }
        const sessiontoken = "Bearer " + this.token;
        const newConfig = config;
        if (sessiontoken) {
            if(newConfig!==undefined){
                newConfig.headers!.Authorization = sessiontoken ;
                
            }
           
        }
        return newConfig;
    };

    private handleError = async (error: any) => {
        
        const originalRequest = error.config;
        if (error.response?.status === 401) {
            /*const refreshToken = await this.refreshToken();
            if (refreshToken.status === 200) {
                this.token = refreshToken.data.hashToken;
                localStorage.setItem('hashToken', this.token);
                return this.instance(originalRequest);
            }*/
            localStorage.removeItem('token');
            localStorage.removeItem('rol');
            localStorage.removeItem('name');
            localStorage.removeItem('te');

            toast.error('Sesión expirada', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: 0,
                });  
                
            setTimeout(() => {
                window.location.href = '/login'
              }, 1000);
        }
        if (axios.isCancel(error)) {
            return Promise.reject(error);
          }
          if (error.response) {
            if (error.response.status === 500) {
              return Promise.reject();
            }
            if (error.response.data) {
              return Promise.reject(error.response.data);
            }
            console.error('Status:', error.response.status);
            console.error('Data:', error.response.data);
            console.error('Headers:', error.response.headers);
          }
          if (error.message === NETWORK_ERROR) {
            console.debug('No Internet Connection');
          } else if (
            typeof error.message !== 'undefined' &&
            error.message.includes('timeout')
          ) {
            console.error('Timeout!');
          }
          return Promise.reject(error.response || error.message || error);
    }

    private async refreshToken(): Promise<RefreshToken> {
        const refreshTokenRequest = {
            hashToken: this.token,
            clientKey: process.env.CLIENT_KEY
        };
        
        const options: AxiosRequestConfig = {
            headers: {
                sessionToken: this.token
            }
        };

        return axios.post(`${this.baseURL}/auth/RenewToken`, refreshTokenRequest, options);
    }
    
    
}