import  Navbar  from "../../Navbar/Navbar"

const MainLayout = ({children}:any) => {

    return (
        <><Navbar />{children}</>
    );
};

export default MainLayout;

