import { Button, Stack } from "react-bootstrap"
import { useShoppingCart } from "../context/ShoppingCartContext"
import storeItems from "./data/items.json"
import { formatMoney } from "../utils/formatCurrency"
import { Row, Col, Form } from 'react-bootstrap';
import PurchaseOption from '../components/producto/purchaseOption';
import BoxNumberS from '../components/producto/boxNumberS';
import {useState, useEffect} from 'react';

type CartItemProps = {
  id: number
  name: string
  category: string
  idLine:number
  line: string
  price: number
  quantity: number
  percentageDiscount: number
  normalPrice:number
  image:string
  presentation:string
  type: number
  promo: number
  total: number
  periodicity:number
  canEdit:boolean
  onceBuy: boolean
}

export function CartItem(props: CartItemProps ) {
 
  const { removeFromCart 
    ,increaseCartQuantity
    ,decreaseCartQuantity
    ,changeBuyType
    ,changePeriodicity
  } = useShoppingCart()
 

  const increaseQuantity = () =>{
    increaseCartQuantity(props.id);
  }

  const decreaseQuantity = () =>{
    decreaseCartQuantity(props.id);
  }

  const changetype = (buyType:number) =>{
    changeBuyType(props.id, buyType);
   
  }

  const changedays = (event:any) =>{
    changePeriodicity(props.id, parseInt(event.target.value));
  }



  return (
        
    <div className="product_res">
        <div className="margin_product_res">
            <Row style={{position: "relative"}}>
                <Col md={4}>
                {props.promo > 0 &&
                        <div style={{position: "absolute", width: "6rem"}} className="dscto_cont_cart d-flex justify-content-center align-items-center">{props.promo == 1 ? '2x1': '3x2'}</div>    
                    }
                    {props.percentageDiscount > 0 &&
                        <div style={{position: "absolute", width: "6rem"}} className="dscto_cont_cart d-flex justify-content-center align-items-center">{Math.round(props.percentageDiscount)}% Dscto</div>    
                    }
                    <div className="cont_img_res">
                   
                        <img src={props.image} alt="" />
                    </div>   
                                                            
                </Col>
                <Col md={8} className="">
                    <div className="sp_betw">
                        <div className="cont_tit_cant">
                       
                            <div className="title_product_res">{props.name}</div>
                            <div className="cont-box-promo">
                                <div className="d-flex align-items-center">
                                {props.canEdit == true && <span className="text-muted">
                                {formatMoney(props.price)}&nbsp;x&nbsp;
                                </span>
                                
                                }
                                {props.canEdit == true && 
                                    <BoxNumberS
                                    quantity={props.quantity}
                                    increaseQuantity={increaseQuantity}
                                    decreaseQuantity={decreaseQuantity}
                                    />
                                }                            
                                </div>
                                {props.canEdit == false && 
                                    <div className="dis_fle">
                                        {props.quantity == 1 && 
                                            <span className="label_tipo">{props.quantity} unidad</span>
                                        }
                                        {props.quantity > 1 && 
                                            <span className="label_tipo">{props.quantity} unidades</span>
                                        }
                                    </div>
                                }
                               
                            </div>
                        </div>
                        {props.canEdit == true && 
                            <div className="cont_img_trush">
                                <img src={process.env.REACT_APP_PUBLIC_URL + "images/common/trush.webp"} alt="Eliminar" onClick={() => removeFromCart(props.id)} />
                            </div>
                        }
                    </div>
                    <div>
                    
                        <div className="cont_price_res sp_betw">
                            
                            <div className="price_product_res">{props.total > 0 ? formatMoney(props.total) : 'Gratis'}</div>
                            <div className="editable_res">  
                                {props.canEdit == true && props.onceBuy == false &&     (                                                                                                      
                                    <PurchaseOption
                                        type ={props.type}
                                        changetype = {changetype}
                                    ></PurchaseOption>
                                )}
                            {props.canEdit == true && props.onceBuy == true &&
                                <div>Compra única</div>
                            }  
                            {props.canEdit == false && props.type == 2 &&
                                <div>Recurrente</div>
                                
                               
                            }
                            {props.canEdit == false && props.type == 1 && props.price > 0 &&
                                <div>Compra única</div>
                            }
                            {props.canEdit == false && props.type == 1 && props.price == 0 &&
                                <div>Promoción*</div>
                            }
                            </div>
                           
                        </div>
                        <div>
                            {props.canEdit == true &&  props.type ==2 &&   (  
                                
                                
                                <Form.Group className='dis_fle' style={{textAlign: 'left', marginTop: "0.5rem"}}>
                                    <Form.Label style={{marginBottom: '0px', width: "90%", fontSize: "1.2rem"}}>Se envía cada: &nbsp;</Form.Label>
                                    <Form.Control as="select" name="dayPeriodicity" className="form-control" value={props.periodicity}
                                        style={{fontSize: "1.2rem", padding:"0px"}}
                                        onChange={(e) => changedays(e)}>
                                           {Array.from({ length: 24 }).map((it, index) => (
                                                <option value={7 + index}>{7 + index} {'días'}</option>
                                            ))}
                                        
                                    </Form.Control>                                   
                                    <span className="help-block"></span>
                                </Form.Group>
                                    
                                )
                            }
                            </div>
                    </div>
                </Col>
            </Row>
        </div>
    </div>
);

}
