import "./style.css";
import StarRating from "../../components/starRating/StarRating"

type TestimonioProps = {
    description: string
    name: string
    value: number
}  

function Testimonio(props: TestimonioProps){

    const onChange = (index:number) => {
        
    };

    return (

        <div className='card_testimonio'>
            <div className='text_testimonio'>“{props.description}”
            <p className="font_name_testimonio">{props.name}</p>
            </div>
            <div className='cont_star'>
                <StarRating value={props.value} onChange={onchange} readonly={true}/>
            </div>
        </div>
    );
}
export default Testimonio;