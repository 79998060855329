import React from 'react';
import "./style.css";
import BannerParallax from '../../components/bannerParallax/bannerParallax';
import { Container, Row, Col, Form, CloseButton, Offcanvas } from 'react-bootstrap';
import {CartItem} from 'components/CartItem';
import { useShoppingCart } from "../../context/ShoppingCartContext";
import Product from '../../components/producto/product';
import {useState, useEffect} from 'react';
import Accordion from 'react-bootstrap/Accordion';
import AccordionEventKey from 'react-bootstrap/AccordionContext'
import {StoreService} from '../../business/StoreService';
import PreviewProduct from 'components/producto/previewProduct';
import { useSearchParams } from "react-router-dom";
import MainLayout from "../../components/layout/MainLayout/MainLayout";
import Modal from 'react-bootstrap/Modal';
import { useMediaQuery } from "../../hooks/useMediaQuery";


type Presentation = {
  idPresentation: number
  ,name: string
  ,presentation: string
  ,price: number
  ,percentageDiscount: number
  ,normalPrice: number
  ,image: string
  ,onceBuy: boolean
}

type ProductProds = {
  idProduct: number
  ,idPresentation: number
  ,selected: number
  ,url: string
  ,image: string
  ,idLine: number
  ,line: string
  ,name: string
  ,category: string
  ,presentaciones: Presentation[]
}

let initalValueProduct = {
  idProduct: 0
  ,idPresentation: 0
  ,selected: -1
  ,url: ""
  ,image: ""
  ,idLine: 0
  ,line: ""
  ,name: ""
  ,category: ""
  ,presentaciones: []
}

let categorias = [
  {
    slug: 'alimentocompleto',
    lineas: '1,3',
    nombre: 'Alimento completo'
  },
  {
    slug: 'treats',
    lineas: '2',
    nombre: 'Treats'
  },
  {
    slug: 'masticables',
    lineas: '8',
    nombre: 'Masticables'
  },
  {
    slug: 'suplementoscbd',
    lineas: '4',
    nombre: 'Suplementos y CBD'
  },
  {
    slug: 'promociones',
    lineas: '0',
    nombre: 'Promociones'
  },
]

let proteinas = [
  { id: 1, nombre: 'Pollo' },
  { id: 2, nombre: 'Pato' },
  { id: 3, nombre: 'Pavo' },
  { id: 4, nombre: 'Pescado' },
  { id: 7, nombre: 'Res' },
  { id: 8, nombre: 'Codorníz' }
]

let especies = [
  { id: 1, nombre: 'Perros' },
  { id: 2, nombre: 'Gatos' },
  { id: 3, nombre: 'Perros & Gatos' }
]

    /**
 * Login Screen Component
 * @packageDocumentation
 * @module Login
 */
const Tienda: React.FC<{}> = () => {


  const params = new URLSearchParams(window.location.search);
  const lineaParam = params.get('categ');

  const [show, setShow] = useState(false);
  const [product, setProduct] = useState<ProductProds>(initalValueProduct);
  const [products, setProducts] = useState([]);
  const [allProducts, setAllProducts] = useState<any>([]);
  const [fetchingProducts, setFetchingProducts] = useState(true);
  const [search, setSearch] = useState("");
  const [ingrediente, setIngrediente] = useState(0);
  const [categoria, setCategoria] = useState(0);
  const [linea, setLinea] = useState("");
  const [activeKeys, setActiveKeys] = React.useState<string[]>([]);
  // const [priceRange, setPriceRange] = useState<number>(0);
  const [minPrice, setMinPrice] = useState<number>(0);
  const [maxPrice, setMaxPrice] = useState<number>(500);
  const [priceRange, setPriceRange] = useState([0, 500]);

  const handleMinPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (value <= maxPrice) {
      setMinPrice(value);
    }
  };

  const handleMaxPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (value >= minPrice) {
      setMaxPrice(value);
    }
  };

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (e.target.name === "min") {
      setPriceRange([value, priceRange[1]]);
    } else {
      setPriceRange([priceRange[0], value]);
    }
  };

  const handleSelect = (AccordionEventKey:any) => {
    setActiveKeys(AccordionEventKey);
  };
  const [showFilter, setShowFilter] = useState(false);
  const handleCloseFilter = () => setShowFilter(false);

  const handleShowFilter = () => setShowFilter(true);

  const getProducts = async () => { 
    const getInstance = StoreService.getInstance();
    

    await getInstance.getProducts()
        .then((response:any) => {
              setFetchingProducts(false);
          
              if(response.success === true)
              {
                
                setAllProducts(response.products);

                if(lineaParam!==undefined && lineaParam!="" && lineaParam !=null)
                {
                  setProducts(response.products.filter((item:any) => item.lineslug === lineaParam));
                }
                else{
                  setProducts(response.products);
                }

                

              }
              else{
                
              }
            }   
          )
        .catch((err:any) => {
          setFetchingProducts(false);
        });

    }

   
  
  useEffect(() => {      
    getProducts()  
  }, []);

  useEffect(() => {      
    if(allProducts.length > 0)
    {
      if(lineaParam!==undefined && lineaParam!="" && lineaParam !=null)
      {
        let item = categorias.find((item:any) => item.slug === lineaParam);
        if(item!==undefined)
        {
          setActiveKeys(["0"]);
          setLinea(item.lineas);
        }
        
      }    
    }
     
  }, [allProducts]);

  useEffect(() => {      
    setShow(true);
  }, [product.selected]);

  useEffect(() => {
    let productsFilter = allProducts;
    
    if(search!=""){
      productsFilter = allProducts.filter((item:any) => item.name.toLowerCase().includes(search));
    
    }
    if(ingrediente>0){
      productsFilter = productsFilter?.filter((item:any) => item.ingrediente != null ? item.ingrediente.split(",").includes(ingrediente.toString()) : false);
    }
    if(categoria > 0){
      productsFilter = productsFilter?.filter((item:any) => item.idCategory == categoria);
    }
    if(linea=='0')
    {
      productsFilter = productsFilter?.filter((item:any) => {
          const filteredPresentations = item.presentaciones.filter((presentation: any) => {
              const percentageDiscount = parseFloat(presentation.percentageDiscount);
              console.log('percentageDiscount:', percentageDiscount);
              console.log('producto:', item);
              
              return !isNaN(percentageDiscount) && percentageDiscount > 0;
          });
      
          return filteredPresentations.length > 0;
      });
    }
    else
    {
      if(linea!='')
      {
        let arrayLineas = linea.split(',').map(Number);   
        let arrayLineasString = linea.split(',');        
        productsFilter = productsFilter?.filter((item:any) => arrayLineas.includes(item.idLine) || arrayLineasString.includes(item.idLine));
      
      }
    }

    setProducts(productsFilter);
  },[linea]);

 
  

  const handleSearch = (event:any) => {
    let productsFilter = allProducts.filter((item:any) => item.name.toLowerCase().includes(event.target.value.toLowerCase()));
    setSearch(event.target.value.toLowerCase());

    if(ingrediente>0){
      productsFilter = productsFilter?.filter((item:any) => item.ingrediente != null ? item.ingrediente.split(",").includes(ingrediente.toString()) : false);
    }
    if(categoria > 0){
      productsFilter = productsFilter?.filter((item:any) => item.idCategory == categoria);
    }
    if(linea != ''){
      if(linea == '0')
      {
        productsFilter = productsFilter?.filter((item:any) => {
          const filteredPresentations = item.presentaciones.filter((presentation: any) => {
              const percentageDiscount = parseFloat(presentation.percentageDiscount);
              console.log('percentageDiscount:', percentageDiscount);
              console.log('producto:', item);
              
              return !isNaN(percentageDiscount) && percentageDiscount > 0;
          });
      
          return filteredPresentations.length > 0;
      });
      }
      else
      {
        let arrayLineas = linea.split(',').map(Number);   
        let arrayLineasString = linea.split(',');        
        productsFilter = productsFilter?.filter((item:any) => arrayLineas.includes(item.idLine) || arrayLineasString.includes(item.idLine));
      }
      
    }

    setProducts(productsFilter);
  }

  const handleChangeIngrediente = (event: any, type:number) => {
    let productsFilter = allProducts;
    
    if(search!=""){
      productsFilter = allProducts.filter((item:any) => item.name.toLowerCase().includes(search));
    
    }
    if(categoria > 0){
      productsFilter = productsFilter?.filter((item:any) => item.idCategory == categoria);
    }
    if(linea != ''){
      if(linea == '0')
      {
        productsFilter = productsFilter?.filter((item:any) => {
          const filteredPresentations = item.presentaciones.filter((presentation: any) => {
              const percentageDiscount = parseFloat(presentation.percentageDiscount);
              console.log('percentageDiscount:', percentageDiscount);
              console.log('producto:', item);
              
              return !isNaN(percentageDiscount) && percentageDiscount > 0;
          });
      
          return filteredPresentations.length > 0;
      });
      }
      else
      {
        let arrayLineas = linea.split(',').map(Number);   
        let arrayLineasString = linea.split(',');        
        productsFilter = productsFilter?.filter((item:any) => arrayLineas.includes(item.idLine) || arrayLineasString.includes(item.idLine));
      }
      
    }
    if(event.target.checked == true){
      productsFilter = productsFilter?.filter((item:any) => item.ingrediente != null ? item.ingrediente.split(",").includes(type.toString()) : false);
      setIngrediente(type);
    }
    else{
      setIngrediente(0);
    }
    
    setProducts(productsFilter);
    
  }

  const handleChangeCategoria = (event: any, type:number) => {
    let productsFilter = allProducts;
    
    if(search!=""){
      productsFilter = allProducts.filter((item:any) => item.name.toLowerCase().includes(search));
    
    }
    if(ingrediente>0){
      productsFilter = productsFilter?.filter((item:any) => item.ingrediente != null ? item.ingrediente.split(",").includes(ingrediente.toString()) : false);
    }
    if(linea != ''){
      if(linea == '0')
      {
        productsFilter = productsFilter?.filter((item:any) => {
          const filteredPresentations = item.presentaciones.filter((presentation: any) => {
              const percentageDiscount = parseFloat(presentation.percentageDiscount);
              console.log('percentageDiscount:', percentageDiscount);
              console.log('producto:', item);
              
              return !isNaN(percentageDiscount) && percentageDiscount > 0;
          });
      
          return filteredPresentations.length > 0;
      });
      }
      else
      {
        let arrayLineas = linea.split(',').map(Number);   
        let arrayLineasString = linea.split(',');        
        productsFilter = productsFilter?.filter((item:any) => arrayLineas.includes(item.idLine) || arrayLineasString.includes(item.idLine));
      }
      
    }
    
    if(event.target.checked == true){
      productsFilter = productsFilter?.filter((item:any) => item.idCategory == type);
      setCategoria(type);
    }
    else{
      setCategoria(0);
    }
    setProducts(productsFilter);
    
  }

  const handleChangeLinea = (event: any, type:string) => {
    if(event.target.checked == true){
     
      setLinea(type);
    }
    else{
      setLinea('');
    }
    
    
  }
  
  const handleClose = (event:any) => {
    event.preventDefault();
    let copy = {...product};    
    copy.idPresentation = 0;
    copy.selected = -1;
    setProduct(copy);
    setShow(false);
  }
  const handleShow = () => setShow(true);

  const onOpenPreview = (event:any,idProduct:number, idPresentation:number) => {
    event.preventDefault();
  
    let prod = allProducts.find((item:any) => item.idPresentation === idPresentation);
    
    let indexPresentation = prod.presentaciones.findIndex((object:any) => {
      return object.idPresentation === idPresentation;
    });
    var obj = {...product};
      obj.idProduct = prod.idProduct;
      obj.idPresentation= prod.idPresentation;
      obj.selected= indexPresentation;
      obj.url=process.env.REACT_APP_PUBLIC_URL + "producto/" +prod.slug;
      obj.image=process.env.REACT_APP_BASE_URL_BACK + prod.img;
      obj.idLine= prod.idLine;
      obj.line= prod.line;
      obj.name= prod.name;
      obj.category= prod.category;
      obj.presentaciones= prod.presentaciones;
     
        setProduct(obj);
        
  }

  let screen = useMediaQuery("sm");

    useEffect(() => {      
        console.log(screen,'sreen');
    }, [screen]);

 
    return (
      <MainLayout>
      <div className='pad_initial'>
      {/* <BannerParallax 
                img={process.env.REACT_APP_PUBLIC_URL + "images/banners/parallax1.webp"}
                clase="cover"
                text= "TIENDA"
      ></BannerParallax> */}
      <div className="tienda-page">
      <section className="pad_var">
        <Container>
          <Row className="pad_top_30">   
            <div className="cont_filt_text">                      
              <Row>
                <Col md={9} className='cont_seacrh_form'>
                  <input type="search" className="form-control inp_search" name="search" placeholder='Buscar por palabra...' onChange={(e)=>handleSearch(e)}/>
                  <div className='search_form'><img src={process.env.REACT_APP_PUBLIC_URL + 'images/common/lupa-w.webp'} alt="Buscar producto" /></div>
                </Col>
                <Col md={2} className='offset-1 pos_relative'>                  
                  <a className="btn-filters" onClick={handleShowFilter}><img className='filter-img' src={process.env.REACT_APP_PUBLIC_URL + 'images/common/filtros-b.png'} alt="filters" />Mostrar Filtros</a>                   
                </Col>                
              </Row>
            </div>
            {screen == true ? (
                  
                
              fetchingProducts == true ? (
                 <Col md={12} className="results mrg_0" style={{padding:0}}>
                  <Row>
                    <div className="product_item col-md-3 p-0" style={{height: '26rem'}}><div className="bg-alternative content_product"></div></div>
                    <div className="product_item col-md-3 p-0" style={{height: '26rem'}}><div className="bg-alternative content_product"></div></div>
                    <div className="product_item col-md-3 p-0" style={{height: '26rem'}}><div className="bg-alternative content_product"></div></div>
                    <div className="product_item col-md-3 p-0" style={{height: '26rem'}}><div className="bg-alternative content_product"></div></div>
                    <div className="product_item col-md-3 p-0" style={{height: '26rem'}}><div className="bg-alternative content_product"></div></div>
                    <div className="product_item col-md-3 p-0" style={{height: '26rem'}}><div className="bg-alternative content_product"></div></div>
                    <div className="product_item col-md-3 p-0" style={{height: '26rem'}}><div className="bg-alternative content_product"></div></div>
                    <div className="product_item col-md-3 p-0" style={{height: '26rem'}}><div className="bg-alternative content_product"></div></div>
                    <div className="product_item col-md-3 p-0" style={{height: '26rem'}}><div className="bg-alternative content_product"></div></div>
                  </Row>
                </Col>
              ) : (
                <Col md={12 } className="results mrg_0" style={{padding:0}}>
                  <Row>
                  {products.filter((item:any) => item.presentaciones.length > 0).map((item:any, index:any) => (
                    <Product key={'prod'+item.idProduct+'-'+index}
                    
                    idProduct={item.idProduct}
                    idPresentation={item.presentaciones[0]?.idPresentation}
                    url={process.env.REACT_APP_PUBLIC_URL + "producto/" +item.slug}
                    image={process.env.REACT_APP_BASE_URL_BACK + item.img}
                    idLine={item.idLine}
                    category={item.category}
                    line={item.line}
                    name={item.name}
                    minPrice={item.precio_minimo}
                    maxPrice={item.precio_maximo}
                    maxPriceDescuento = {item.precio_maximo_descuento}
                    minPriceDescuento = {item.precio_minimo_descuento}
                    quantity={item.quantity}
                    promo={item.tipo_promo}
                    col= {3}
                    percentageDiscount={Math.round(item.presentaciones[0]?.percentageDiscount)}
                    slug={item.presentaciones[0]?.slug}
                    presentation={item.presentaciones.length == 1 ? item.presentaciones[0]?.presentation : ''}
                    onOpenPreview={onOpenPreview}/>
                ))}
                </Row>
                </Col>
                ))
              :
              (
                fetchingProducts == true ? (
                  <Col xs={12} className="results mrg_0" style={{padding:0}}>
                   <Row>
                     <div className="product_item col-md-3 p-0" style={{height: '26rem'}}><div className="bg-alternative content_product"></div></div>
                     <div className="product_item col-md-3 p-0" style={{height: '26rem'}}><div className="bg-alternative content_product"></div></div>
                     <div className="product_item col-md-3 p-0" style={{height: '26rem'}}><div className="bg-alternative content_product"></div></div>
                     <div className="product_item col-md-3 p-0" style={{height: '26rem'}}><div className="bg-alternative content_product"></div></div>
                     <div className="product_item col-md-3 p-0" style={{height: '26rem'}}><div className="bg-alternative content_product"></div></div>
                     <div className="product_item col-md-3 p-0" style={{height: '26rem'}}><div className="bg-alternative content_product"></div></div>
                     <div className="product_item col-md-3 p-0" style={{height: '26rem'}}><div className="bg-alternative content_product"></div></div>
                     <div className="product_item col-md-3 p-0" style={{height: '26rem'}}><div className="bg-alternative content_product"></div></div>
                     <div className="product_item col-md-3 p-0" style={{height: '26rem'}}><div className="bg-alternative content_product"></div></div>
                   </Row>
                 </Col>
               ) : (
                 <Col xs={12 } className="results mrg_0" style={{padding:0}}>
                   <Row>
                   {products.filter((item:any) => item.presentaciones.length > 0).map((item:any, index:any) => (
                     <Product key={'prod'+item.idProduct+'-'+index}
                     
                     idProduct={item.idProduct}
                     idPresentation={item.presentaciones[0]?.idPresentation}
                     url={process.env.REACT_APP_PUBLIC_URL + "producto/" +item.slug}
                     image={process.env.REACT_APP_BASE_URL_BACK + item.img}
                     idLine={item.idLine}
                     category={item.category}
                     line={item.line}
                     name={item.name}
                     minPrice={item.precio_minimo}
                     maxPrice={item.precio_maximo}
                     maxPriceDescuento = {item.precio_maximo_descuento}
                     minPriceDescuento = {item.precio_minimo_descuento}
                     quantity={item.quantity}
                     promo={item.tipo_promo}
                     col= {6}
                     percentageDiscount={Math.round(item.presentaciones[0]?.percentageDiscount)}
                     slug={item.presentaciones[0]?.slug}
                     presentation={item.presentaciones.length ==1 ? item.presentaciones[0]?.presentation  : ''}
                     onOpenPreview={onOpenPreview} />
                 ))}
                 </Row>
                 </Col>
                 )
              )              
              }
                
              
          </Row>
        </Container>
      </section>
    </div> 
    
     <Offcanvas className='tienda-filtros' show={showFilter} onHide={handleCloseFilter} placement="start">
      <Offcanvas.Header className='headerCart' closeButton>
        <Offcanvas.Title className='pad_l_1_2'>FILTROS:</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
      <Col md={12} className="cont_acoor" style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}>
      <Accordion defaultActiveKey={['0', '1', '2', '3']} alwaysOpen>
        <Accordion.Item eventKey="0">
          <Accordion.Header><span className="filter_tip">Categoría</span></Accordion.Header>
          <Accordion.Body className='back_acord_bod'>
            <div className="tags">
              {categorias.map((item) => (
                <Form.Check type='checkbox' className='' key={item.lineas}>
                  <Form.Check.Input value={item.lineas} checked={linea === item.lineas} name='linea' type="checkbox" onChange={(e) => { handleChangeLinea(e, item.lineas) }} />
                  <Form.Check.Label className="no_bold c3">{item.nombre}</Form.Check.Label>
                </Form.Check>
              ))}
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header><span className="filter_tip">Proteína</span></Accordion.Header>
          <Accordion.Body className='back_acord_bod'>
            <div className="tags">
              {proteinas.map((item) => (
                <Form.Check type='checkbox' className='' key={item.id}>
                  <Form.Check.Input value={item.id} checked={ingrediente === item.id} name='ingrediente' type="checkbox" onChange={(e) => { handleChangeIngrediente(e, item.id) }} />
                  <Form.Check.Label className="no_bold c3">{item.nombre}</Form.Check.Label>
                </Form.Check>
              ))}
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header><span className="filter_tip">Especie</span></Accordion.Header>
          <Accordion.Body className='back_acord_bod'>
            <div className="tags">
              {especies.map((item) => (
                <Form.Check type='checkbox' className='' key={item.id}>
                  <Form.Check.Input value={item.id} checked={categoria === item.id} name='categoria' type="checkbox" onChange={(e) => { handleChangeCategoria(e, item.id) }} />
                  <Form.Check.Label className="no_bold c3">{item.nombre}</Form.Check.Label>
                </Form.Check>
              ))}
            </div>
          </Accordion.Body>
        </Accordion.Item>
            {/* <Accordion.Item eventKey="3">
              <Accordion.Header><span className="filter_tip">Precio</span></Accordion.Header>
              <Accordion.Body className='back_acord_bod'>
                <Form.Group>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '45%' }}>
                      <Form.Range
                        min={0}
                        max={500}
                        value={minPrice}
                        onChange={handleMinPriceChange}
                      />
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ marginRight: '5px' }}>S/</span>
                        <Form.Control
                          type="number"
                          value={minPrice}
                          onChange={handleMinPriceChange}
                          style={{ marginTop: '5px', width: 'calc(100% - 20px)' }}
                        />
                      </div>
                    </div>
                    <div style={{ width: '45%' }}>
                      <Form.Range
                        min={0}
                        max={500}
                        value={maxPrice}
                        onChange={handleMaxPriceChange}
                      />
                      <Form.Control
                        type="number"
                        value={maxPrice}
                        onChange={handleMaxPriceChange}
                        style={{ marginTop: '5px' }}
                      />
                    </div>
                  </div>
                </Form.Group>
              </Accordion.Body>
            </Accordion.Item> */}
          </Accordion>
        </Col>
      </Offcanvas.Body>
    </Offcanvas>
    
    {product.selected >= 0 && (<PreviewProduct 
        selected = {product.selected}
        url= {product.url}
        image= {product.image}
        idLine= {product.idLine}
        line= {product.line}
        name= {product.name}
        category= {product.category}
        presentations= {product.presentaciones}
        show={show} 
        handleClose={handleClose} 
    />)}
  </div>  
  </MainLayout>
    
)};
export default Tienda;
