import React from 'react';
import "../reporteVentas/style.css";
import BannerParallax from '../../../components/bannerParallax/bannerParallax';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-js-pagination';
import { useState, useCallback, useEffect } from 'react';
import BackLayout from "../../../components/layout/BackLayout/BackLayout";
import LoadingTable from "../../../components/skeletons/LoadingTable";
import ModalConfirm from "../../../components/confirm/ModalConfirm";
import {SalesService} from '../../../business/SalesService';
import { formatNumber } from "../../../utils/formatCurrency";

let filtersDefault = {
    from: '',
    to: '',
    idStatus: '0',
    suscriptor: ''

}
let confirmDefault = {
    idSuscription:0,
    suscriptor: '',
    show: false
}

function Suscripciones (){
    const [data, setData] = useState<any>({data: []});
    const [filters, setFilters] = useState(filtersDefault);
    const [loading, setLoading] = useState(false);
    const [confirmData, setConfirmData] = useState(confirmDefault);

    useEffect(() => {      
        getSuscriptions(1);     
      }, []);

    const getSuscriptions = async (pageNumber:number) => { 
        const getInstance = SalesService.getInstance();
        setLoading(true);

        await getInstance.getSuscriptions({page: pageNumber, from: filters.from, to: filters.to, idStatus: filters.idStatus, suscriptor: filters.suscriptor})
            .then((response:any) => {
                
                    if(response.success === true)
                    {
                        setData(response.suscriptions);

                    }
                    setLoading(false);
                    
                })
            .catch((err:any) => {
                setLoading(false);
            });

    }

    const updateSuscription = async (id:number) => { 
        const getInstance = SalesService.getInstance();
       
        await getInstance.updateSuscription({id: id})
            .then((response:any) => {
                setConfirmData({
                    ...confirmData,
                    show: false,
                    idSuscription: 0,
                    suscriptor: ''
                });
                getSuscriptions(data?.current_page > 0 ? data?.current_page : 1);
            })
            .catch((err:any) => {
                
            });

    }

    const handleChangeFilter= (event: any) => {
    
        const { name, value} = event.target;
        setFilters({
            ...filters,
            [name]: value
          });      
        
      };

    const handlePageChange = (pageNumber:any) => {
        getSuscriptions(pageNumber);
    }

    const handleSearch = (e:any) => {
        getSuscriptions(1);
    }

    const handleUnsuscribe = (e:any,id:number, suscriptor: string) => {
        setConfirmData({show:true, idSuscription: id, suscriptor: suscriptor})
    }

    const handleClose= (event: any) => {
    
        setConfirmData({
            ...confirmData,
            show: false
        })    
        
    };

    const unsuscribe = (event:any, id:number) => {
        setConfirmData({
            ...confirmData,
            show: false
        });
        updateSuscription(id);
    }

    return (
        <BackLayout>
        <div className='fond_backoffice'>
            <Container>                
            <div className='cont_title'>
                <h1>Suscripciones</h1>
            </div>
            <div className='cont_data'>
                <Container className='cont_filters'>
                    <Row className='row_filter'>
                        <Col md={3} className="align-items-center txt_ali dis_fle">
                            <Col md={3}>
                                <label htmlFor="buscar">Buscar:</label>
                            </Col>
                            <Col md={9}>   
                            <input name="suscriptor" type="search" className='form-control' placeholder='Suscriptor' value={filters.suscriptor} onChange={(e)=>handleChangeFilter(e)}/>
                            </Col>
                        </Col>
                        <Col md={3} className="align-items-center txt_ali dis_fle">
                            <Col md={4}>
                            <label htmlFor="from">F. Desde:</label>
                            </Col>
                            <Col md={8}>
                                <input name="from" type="date" className='form-control' value={filters.from} onChange={(e)=>handleChangeFilter(e)}/>
                            </Col>
                        </Col>
                        <Col md={3} className="align-items-center txt_ali dis_fle">
                            <Col md={4}>
                                <label htmlFor="to">F. Hasta:</label>
                            </Col>
                            <Col md={8}>
                                <input name="to" type="date" className='form-control' value={filters.to} onChange={(e)=>handleChangeFilter(e)}/>
                            </Col>
                        </Col>
                        <Col md={2} className="align-items-center txt_ali dis_fle">
                            <Col md={4}>
                            <label htmlFor="estado_suscripcion">Estado:</label>
                            </Col>
                            <Col md={8}>
                                <Form.Control required as="select" name="idStatus" value={filters.idStatus} onChange={(e)=>handleChangeFilter(e)}>                              
                                    <option value="">Seleccione</option>
                                    <option value="1">Activo</option>
                                    <option value="2">Cargo no realizado</option>
                                    <option value="3">Anulado</option>
                                    <option value="4">Pendiente de pago</option>
                                </Form.Control>
                            </Col>
                        </Col>
                        
                        <Col md={1} className="align-items-center txt_ali dis_fle">
                            <Button className='btn_filter_search' type="button" onClick={(e)=>handleSearch(e)}>Buscar</Button>
                        </Col>
                    </Row>
                </Container>
                <Table responsive className='table_backoffice'>
                <thead>
                    <tr>
                        <th>Ver detalle</th>
                        <th>Suscriptor</th>
                        <th>F.Suscripción</th>
                        <th>T.Entrega</th>  
                        <th>Próx.Entrega</th>
                        <th>Monto</th>
                        <th>Nro Tarjeta</th>                  
                        <th>Estado</th>
                        <th>Anular</th>
                    </tr>
                </thead>
                {loading==true ? (
                        <LoadingTable rows={10} cols={9}/>
                    )
                    
                    :
                    (
                <tbody>
                    {data.data.map((item:any) => {
                        return (    
                    <tr key={item.id_suscri}>
                        <td className='text_icon'>
                            <a href={`/admin/suscripciones/detalle/${item.id_suscri}`}>
                                <div className='cont_ver_detalle'>                                
                                    <img src={process.env.REACT_APP_PUBLIC_URL + 'images/common/lupa.webp'} alt="Ver detalle" />
                                </div>
                            </a>
                        </td>
                        <td>{item.suscriptor}</td>
                        <td>{item.fecha}</td>
                        <td>Cada {item.periodo}</td>
                        <td style={{background: item.soon==1? '#dcaef3': '#fff'}}>{item.prox_fecha}</td>
                        <td>{formatNumber(item.monto_total)}</td>
                        <td>{item.tarjeta}</td>
                        <td><span className="label label-lg label-light-primary label-inline">{item.estado}</span></td>
                        
                        <td className='text_icon'>
                            <a className="desuscribirse cur_point" onClick={(e) => handleUnsuscribe(e, item.id_suscri, item.suscriptor)}>

                                <div className='cont_ver_detalle'>                                
                                    <img src={process.env.REACT_APP_PUBLIC_URL + 'images/common/cancel.webp'} alt="Cancelar" />
                                </div>
                            </a> 
                        </td>
                    </tr>
                    )
                })}
                </tbody>)}
                </Table>
                <div className='pagination_back'>                    
                {data.total > 0 && (<Pagination
                    activePage={data.current_page}
                    itemsCountPerPage={data.per_page}
                    totalItemsCount={data.total}
                    pageRangeDisplayed={5}
                    onChange={(e) => handlePageChange(e)}
                    innerClass={"pagination"}
                    itemClass={"page-item"}
                    linkClass={"page-link"}
                    />)}

                </div>
            </div>
            </Container>
        </div>
        <ModalConfirm text="" handleClose={handleClose} show={confirmData?.show} handleSave={unsuscribe} action={"¿Estás seguro de anular la suscripción de " + confirmData?.suscriptor + "?"} id={confirmData?.idSuscription}/>
        
        </BackLayout>
)};
export default Suscripciones;
