
import './styles.css';

function LoadingTable(props:any) {
    let rows = props.rows;
    let cols = props.cols;

    return(
        <tbody className='loadingTable'>
          {Array.from({ length: rows }).map((it, index) => (
                <tr key={index}>
                     {Array.from({ length: cols }).map((it, indexcol) => (
                        <td key={indexcol + index}><div className="bar"></div></td>
                    ))}
                </tr>
            ))}
       
        </tbody>
    )
}

export default LoadingTable;