import React from 'react';
import "../reporteVentas/style.css";
import { Container, Row, Col, Form, Button, CloseButton } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-js-pagination';
import { useState, useEffect } from 'react';
import BackLayout from "../../../components/layout/BackLayout/BackLayout";
import {CouponService} from '../../../business/CouponService';
import ModalEditarCupon from './modalEditarCupon';
import ModalAgregarCupon from './modalAgregarCupon';
import ModalConfirm from "../../../components/confirm/ModalConfirm";
import LoadingTable from "../../../components/skeletons/LoadingTable";
import { formatDate } from "../../../utils/parsers"

let filtersDefault = {
    from: '',
    to: '',
    search: '',

}
let confirmDefault = {
    idCoupon:0,
    code: '',
    show: false
}

function Cupones (){
    const [data, setData] = useState<any>({data: []});
    const [filters, setFilters] = useState(filtersDefault);
    const [loading, setLoading] = useState(false);
    const [confirmData, setConfirmData] = useState(confirmDefault);
    const [idCouponSelected, setIdCouponSelected] = useState(0);

    const [showAgregarCupon, setShow] = useState(false);
    const handleShowAgregarCupon = () => setShow(true);
    const handleCloseAgregarCupon = () => {
        setShow(false);
    
    }
    const handleReopenAgregarCupon = () => {
        setShow(false);
        setShow(true);
    }

    const [showEditarCupon, setshowEditarCupon] = useState(false);
    const handleShowEditarCupon = (e:any, idCoupon:number) => {
        setIdCouponSelected(idCoupon);
        setshowEditarCupon(true);
    }
    const handleCloseEditarCupon = () => {
        setshowEditarCupon(false);
        setIdCouponSelected(0);
    }

    useEffect(() => {      
        getCoupons(1);    
      }, []);

    const getCoupons = async (pageNumber:number) => { 
        const getInstance = CouponService.getInstance();
        setLoading(true);

        await getInstance.getCoupons({page: pageNumber, from: filters.from, to: filters.to, search: filters.search})
            .then((response:any) => {
                
                    if(response.success === true)
                    {
                        setData(response.coupons);

                    }
                    setLoading(false);
                    
                })
            .catch((err:any) => {
                setLoading(false);
            });

    }

    const deleteCoupon = async (id:number) => { 
        const getInstance = CouponService.getInstance();
        setLoading(true);

        await getInstance.deleteCoupon({id:id})
            .then((response:any) => {
                
                    if(response.success === true)
                    {
                        getCoupons(data.current_page); 

                    }
                    setLoading(false);
                    
                })
            .catch((err:any) => {
                setLoading(false);
            });

    }

    const handleConfirmDelete = (e:any,id:number, code: string) => {
        setConfirmData({show:true, idCoupon: id, code: code})
    }

    const handleDeleteCoupon = (event:any, id:number) => {
        setConfirmData({
            ...confirmData,
            show: false
        });
        deleteCoupon(id);
    }

    const handleChangeFilter= (event: any) => {
    
        const { name, value} = event.target;
        setFilters({
            ...filters,
            [name]: value
          });      
        
    };

    const updateCoupon = async (id:number) => { 
        const getInstance = CouponService.getInstance();
       
        await getInstance.updateCoupon({id: id})
            .then((response:any) => {
                setConfirmData({
                    ...confirmData,
                    show: false,
                    idCoupon: 0,
                    code: ''
                });
                getCoupons(data?.current_page > 0 ? data?.current_page : 1);
            })
            .catch((err:any) => {
                
            });

    }

    const handlePageChange = (pageNumber:any) => {
        getCoupons(pageNumber);
    }

    const handleSearch = (e:any) => {
        getCoupons(1);
    }

    

    const handleClose= (event: any) => {
    
        setConfirmData({
            ...confirmData,
            show: false
        })    
        
    };

    const handleUpdate = (event:any, id:number) => {
        setConfirmData({
            ...confirmData,
            show: false
        });
        updateCoupon(id);
    }
    return (
        <BackLayout>
        <div className='fond_backoffice'>
            <Container>                
            <div className='cont_title'>
                <h1>Cupones</h1>
            </div>
            <div className='cont_data'>
                <Container className='cont_filters'>
                    <Row className='row_filter'>
                        <Col md={2} className="align-items-center txt_ali dis_fle">
                            <Col md={4}>
                                <label htmlFor="buscar">Buscar:</label>
                            </Col>
                            <Col md={8}>   
                            <input name="search" type="search" className='form-control' placeholder='código'  value={filters.search} onChange={(e)=>handleChangeFilter(e)}/>
                            </Col>
                        </Col>
                        <Col md={3} className="align-items-center txt_ali dis_fle">
                            <Col md={4}>
                            <label htmlFor="from">F. Desde:</label>
                            </Col>
                            <Col md={8}>
                                <input name="from"type="date" className='form-control'  value={filters.from} onChange={(e)=>handleChangeFilter(e)}/>
                            </Col>
                        </Col>
                        <Col md={3} className="align-items-center txt_ali dis_fle">
                            <Col md={4}>
                                <label htmlFor="to">F. Hasta:</label>
                            </Col>
                            <Col md={8}>
                                <input name="to" type="date" className='form-control' value={filters.to} onChange={(e)=>handleChangeFilter(e)}/>
                            </Col>
                        </Col>                        
                        <Col md={2} className="align-items-center txt_ali dis_fle">
                            <Button className='btn_filter_search' type="button" onClick={(e)=>handleSearch(e)}>Buscar</Button>
                        </Col>
                        <Col md={2} className="align-items-center txt_ali dis_fle">
                            <Button className='btn_export_excel' onClick={handleShowAgregarCupon}>Agregar</Button>
                        </Col>
                    </Row>
                </Container>
                <Table responsive className='table_backoffice'>
                <thead>
                    <tr>
                        <th>Fecha de creación</th>
                        <th>Código</th>
                        <th>Tipo descuento</th>
                        <th>Valor</th>
                        <th>Límite usos</th>
                        <th>Periódo válido</th>
                        <th>Productos</th>
                        <th>Cliente</th>
                        <th>Validez</th>
                        <th>Estado</th>
                        <th>Editar</th>
                        <th>Eliminar</th>
                    </tr>
                </thead>
                {loading==true ? (
                        <LoadingTable rows={10} cols={12}/>
                    )
                    
                    :
                    (<tbody>
                    {data.data.map((item:any) => {
                        return (    
                    <tr>
                        <td>{formatDate(item.created_at, 'yyyy-MM-DD HH:mm:ss')}</td>
                        <td>{item.codigo}</td>
                        <td>{item.tipo_descuento}</td>
                        <td>{item.valor}</td>
                        <td>{item.limite}</td>
                        <td>{item.fecha_vencimiento}</td>
                        <td>{item.cuponpresentacion.map((itemcup:any) => {
                            return (   
                                <li>{itemcup.prodpres}</li>
                                    )
                                })}
                        </td>  
                        <td>{item.cliente}</td>
                        <td>{item.primera_compra}</td>
                        <td><span className="label label-lg label-light-primary label-inline">{item.estado}</span></td>
                        <td className='text_icon'>
                            <a className='cur_point' onClick={(e) => handleShowEditarCupon(e, item.id_cup)}>
                                <div className='cont_ver_detalle'>                                
                                    <img src={process.env.REACT_APP_PUBLIC_URL + 'images/common/edit.webp'} alt="Editar" />
                                </div>
                            </a>
                        </td>
                        <td className='text_icon'>
                            <a className="delete-cupon cur_point" onClick={(e) => handleConfirmDelete(e, item.id_cup, item.codigo)}>
                                <div className='cont_ver_detalle'>                                
                                    <img src={process.env.REACT_APP_PUBLIC_URL + 'images/common/trush.webp'} alt="Eliminar" />
                                </div>
                            </a>
                        </td>
                    </tr>
                    )
                })}
                </tbody>)}
                </Table>
                <div className='pagination_back'>                    
                {data.total > 0 && (<Pagination
                    activePage={data.current_page}
                    itemsCountPerPage={data.per_page}
                    totalItemsCount={data.total}
                    pageRangeDisplayed={5}
                    onChange={(e) => handlePageChange(e)}
                    innerClass={"pagination"}
                    itemClass={"page-item"}
                    linkClass={"page-link"}
                    />)}


                </div>
            </div>
            </Container>
            <ModalConfirm text="" handleClose={handleClose} show={confirmData?.show} handleSave={handleDeleteCoupon} action={"¿Estás seguro de eliminar el cupón " + confirmData?.code + "?"} id={confirmData?.idCoupon}/>
        
            <ModalAgregarCupon show={showAgregarCupon} handleClose={handleCloseAgregarCupon} handleOpen={handleReopenAgregarCupon}/>            
            {idCouponSelected > 0 && (<ModalEditarCupon show={showEditarCupon} handleClose={handleCloseEditarCupon} idCoupon={idCouponSelected}/> )}          
        </div>
        </BackLayout>
        
)};
export default Cupones;
