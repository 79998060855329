import { api } from "../api";
import ActionType from "../utils/customTypes";

export class StoreService extends api {

    private static classInstance?: StoreService;
        
    constructor(token: string, type: ActionType) {
        super( process.env.REACT_APP_BASE_URL,token);    
    } 
     
    public static getInstance() {
        let token = localStorage.getItem("token");
        if (!this.classInstance) {
            this.classInstance = new StoreService(token!==undefined && token !== null ? token:'', 'GET');
        }
        
        return this.classInstance;
    }   
     
    public validatePromotionalCode = (data:any) => this.instance.post('/store/validate-promotional-code',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public validateUCode = (data:any) => this.instance.post('/store/validate-ucode',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getEnabledDates = (data:any) => this.instance.post('/store/get-enabled-dates',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getTotalOrder = (data:any) => this.instance.post('/store/get-total-order',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getPurchase = (data:any) => this.instance.post('/store/get-purchase',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getProducts = () => this.instance.post('/store/get-products').then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getProduct = (data:any) => this.instance.post('/store/get-product',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getRecommendedProducts = (data:any) => this.instance.post('/store/get-recommended-products',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public insertPayment = (data:any) => this.instance.post('/store/insert-payment',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    

   
}