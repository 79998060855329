import Modal from 'react-bootstrap/Modal';
import "./style.css";
import { Button, CloseButton, Form
    , Spinner , Col 
  } from "react-bootstrap";
import { useState , useEffect } from 'react';
import { Multiselect } from 'multiselect-react-dropdown';
import {ProductService} from '../../../business/ProductService';
import {UserService} from '../../../business/UserService';
import {CouponService} from '../../../business/CouponService';
import * as Yup from 'yup';
import { yupToFormErrors } from "formik";

type ModalAgregarCuponProps = {
    handleClose: any
    handleOpen: any
    show?: boolean

}

const validSquema = Yup.object().shape({
    codigo: Yup.string().required("*Campo obligatorio")
   ,tipo_descuento: Yup.number().required("*Campo obligatorio")
   , valor: Yup.number().when('tipo_descuento', (val:any, schema:any) => {
        if(val==1) { 
            return Yup.number().min(1, "Valor Mínimo 1").required("*Campo obligatorio")
        } else { 
            return Yup.number().min(1, "Valor Mínimo 1").max(100,"*Porcentaje no válido").required("*Campo obligatorio")
        }
    })
   ,fecha_inicio: Yup.string().required("*Campo obligatorio")
 });

const initialValues: any = {
    codigo: null,
    primera_compra: false,
    tipo_descuento: 0,
    valor: null,
    limite: null,
    fecha_inicio: null,
    fecha_vencimiento: null,
    cliente:null,
    cupon_producto:[],
};

function ModalAgregarCupon(props:ModalAgregarCuponProps) {
    const [data, setData] = useState(initialValues);
    const [clients, setClients] = useState([]);
    const [products, setProducts] = useState([]);
    const [errors, setErrors] = useState<any>();
    const [validated, setValidated] = useState<any>();
    const [loading, setLoadingButton] = useState(false);

    useEffect(() => {      
        if(errors == null)
        {
            setValidated(false);
        }
        else{
            setValidated(true);
        }
        
      }, [errors]);

    const getProducts = async (search:string) => { 
        const getInstance = ProductService.getInstance();

        await getInstance.getProductsList({search: search})
            .then((response:any) => {
                
                    if(response.success === true)
                    {
                        setProducts(response.products);

                    }
                    
                })
            .catch((err:any) => {
            });

    }

    const getClients = async (search:string) => { 
        const getInstance = UserService.getInstance();

        await getInstance.getClientList({search: search})
            .then((response:any) => {
                
                    if(response.success === true)
                    {
                        setClients(response.clients);

                    }
                    
                })
            .catch((err:any) => {
            });

    }
    const insertCoupon = async () => { 
        setLoadingButton(true);
        const getInstance = CouponService.getInstance();
            
        await getInstance.insertCoupon(data)
            .then((response:any) => {
                setLoadingButton(false);
                if(response.success === true)
                {
                    handleClose();
                }
                
                    
            })
            .catch((err:any) => {
                setLoadingButton(false);
                console.log(err);
               
                setErrors({
                    codigo: err.result.errors.codigo !== undefined ? err.result.errors.codigo[0] : null,
                    primera_compra: err.result.errors.primera_compra !== undefined ? err.result.errors.primera_compra[0] : null,
                    tipo_descuento: err.result.errors.tipo_descuento !== undefined ? err.result.errors.tipo_descuento[0] : null,
                    valor: err.result.errors.valor !== undefined ? err.result.errors.valor[0] : null,
                    limite: err.result.errors.limite !== undefined ? err.result.errors.limite[0] : null,
                    fecha_inicio: err.result.errors.fecha_inicio !== undefined ? err.result.errors.fecha_inicio[0] : null,
                    fecha_vencimiento: err.result.errors.fecha_vencimiento !== undefined ? err.result.errors.fecha_vencimiento[0] : null,
                });
                
            });
    
    }


    const handleSearchProduct = (value:string) => {
        if(value.length > 3){
            getProducts(value);
        }
    }
    
    const onSelectProduct = (selectedList:any, selectedItem:any) => {
        setData({
            ...data,
            ['products']: selectedList
        });
    }

    const onRemoveProduct = (selectedList:any, removedItem:any) => {
        setData({
            ...data,
            ['cupon_producto']: selectedList
        });
    }

    const handleSearchClient = (value:string) => {
        if(value.length > 3){
            getClients(value);
        }
    }
    
    const onSelectClient = (selectedList:any, selectedItem:any) => {
        setData({
            ...data,
            ['cliente']: selectedItem.value
        });
    }

    const onRemoveClient = (selectedList:any, removedItem:any) => {
        setData({
            ...data,
            ['cliente']: null
        });
    }

    const handleChangeData= (event: any) => {
        const { name, value} = event.target;
        let copy = {...data};
        let val = value;
        
        if(name==='tipo_descuento')
        {
            val = parseInt(value);
        }

    
        setData({
            ...data,
            [name]: event.target.type==='checkbox' ? event.target.checked : val
        });
        
      };

      const handleSave= (event: any) => {
        validSquema.validate(data, { abortEarly: false }).then(function() {
            // Success
            insertCoupon();

            setErrors(null);
        }).catch(function (err) {
            let errorArray = {};
            const errors = yupToFormErrors(err);
            console.log(errors);
            setErrors(errors);
            setValidated(true);
           
        });
        
      }

      const handleClose = () => {
        setData(initialValues);
        props.handleClose();
      }

  return (
    <Modal id="modal_agregar_cupon" className='modal_cupon' show={props.show} onHide={props.handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header  className="close-modal">   
            <h3 className='title_modal_cupon'>Agregar cupón</h3>             
            <CloseButton variant="white" onClick={handleClose}/>
        </Modal.Header>        
        <Modal.Body>                
            <div className='cont_modal_cupon'>
                <Form className='form_agregar_cupon' validated={validated}>
                    <Form.Group className='row'>                                
                        <Form.Check className=''>
                            <Form.Check.Input name='primera_compra' type="checkbox" value={data.primera_compra} onChange={e => {handleChangeData(e)}}/>
                            <Form.Label>&nbsp;Válido solo para primera compra</Form.Label>
                        </Form.Check>                 
                    </Form.Group>
                    <Form.Group className='row'>
                        <Col md={3}>
                            <Form.Label>Código*</Form.Label>
                        </Col>
                        <Col md={9}>
                            <Form.Control as="input" type="text" name="codigo" required value={data.codigo} onChange={e => {handleChangeData(e)}}/>
                            {errors?.codigo != "" && (
                                <Form.Control.Feedback type="invalid">
                                    {errors?.codigo}
                                </Form.Control.Feedback>
                            )}
                        </Col>
                    </Form.Group>
                    <Form.Group className='row'>
                        <Col md={3}>
                            <Form.Label>Tipo*</Form.Label>
                        </Col>
                        <Col md={9}>
                            <Form.Control as="select" name="tipo_descuento" required value={data.tipo_descuento} onChange={e => {handleChangeData(e)}}>
                                <option value="0">Porcentaje</option>
                                <option value="1">Monto</option>
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    <Form.Group className='row'>
                        <Col md={3}>
                            <Form.Label>Valor*</Form.Label>
                        </Col>
                        <Col md={9}>
                            <Form.Control as="input" type="number" name="valor" required value={data.valor} onChange={e => {handleChangeData(e)}}/>
                            {errors?.valor != "" && (
                                <Form.Control.Feedback type="invalid">
                                    {errors?.valor}
                                </Form.Control.Feedback>
                            )}
                        </Col>
                    </Form.Group>
                    <Form.Group className='row'>
                        <Col md={3}>
                            <Form.Label>Límite de usos</Form.Label>
                        </Col>
                        <Col md={9}>
                            <Form.Control as="input" type="number" name="limite" value={data.limite} onChange={e => {handleChangeData(e)}}/>
                        </Col>
                    </Form.Group>
                    <Form.Group className='row'>
                        <Col md={3}>
                            <Form.Label>Fecha inicio*</Form.Label>
                        </Col>
                        <Col md={9}>
                            <Form.Control as="input" type="date" name="fecha_inicio" required value={data.fecha_inicio} onChange={e => {handleChangeData(e)}}/>
                            {errors?.fecha_inicio != "" && (
                                <Form.Control.Feedback type="invalid">
                                    {errors?.fecha_inicio}
                                </Form.Control.Feedback>
                            )}
                        </Col>
                    </Form.Group>
                    <Form.Group className='row'>
                        <Col md={3}>
                            <Form.Label>Fecha vencimiento</Form.Label>
                        </Col>
                        <Col md={9}>
                            <Form.Control as="input" type="date" name="fecha_vencimiento" value={data.fecha_vencimiento} onChange={e => {handleChangeData(e)}}/>
                        </Col>
                    </Form.Group>
                    <Form.Group className='row'>
                        <Col md={3}>
                            <Form.Label>Producto</Form.Label>
                        </Col>
                        <Col md={9}>
                            <Multiselect
                                options={products} // Options to display in the dropdown
                                onSelect={onSelectProduct} // Function will trigger on select event
                                onRemove={onRemoveProduct} // Function will trigger on remove event
                                displayValue="name" // Property name to display in the dropdown options
                                onSearch={handleSearchProduct}
                                placeholder='Elija uno o varios productos'
                                />
                        </Col>
                    </Form.Group>
                    <Form.Group className='row'>
                        <Col md={3}>
                            <Form.Label>Clientes</Form.Label>
                        </Col>
                        <Col md={9}>
                        <Multiselect
                                options={clients} // Options to display in the dropdown
                                onSelect={onSelectClient} // Function will trigger on select event
                                onRemove={onRemoveClient} // Function will trigger on remove event
                                displayValue="text" // Property name to display in the dropdown options
                                onSearch={handleSearchClient}
                                placeholder='Elija un cliente'
                                selectionLimit={1}
                                />
                        </Col>
                    </Form.Group>
                    <div className='cont_modal_confirm'>
                        <Col md={12} className='dis_fle'>           
                            <Col md={6} className="con_btn_confirm">
                                <Button className='btn_confirm' type="button" onClick={(e)=>handleSave(e)} disabled={loading}>
                                    {loading ? (
                                    <Spinner animation="border" role="status" style={{width: '1rem', height: '1rem'}}>
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                    ) : (
                                    'Guardar'
                                    )}
                                </Button>  
                            </Col>
                            <Col md={6} className="con_btn_confirm">
                                <button className='btn_cancel' type="button" onClick={handleClose}>Cancelar</button> 
                            </Col>                
                        </Col>    
                    </div>
                </Form>
            </div>
        </Modal.Body>
    </Modal>
  );
}

export default ModalAgregarCupon;