import React from 'react';
import "./nosotros.css";
import BannerParallax from '../../components/bannerParallax/bannerParallax';
import { Row, Col } from 'react-bootstrap';
import MainLayout from "../../components/layout/MainLayout/MainLayout";

function Nosotros (){
    document.title = "ORIGO: Nosotros";
    document.getElementsByTagName('meta')[3].content = "ORIGO: Nosotros";
    document.getElementsByTagName('meta')[4].content = "Conoce nuestra historia.";
    
    return (
    <MainLayout>
    <div className='pad_initial'>
        <BannerParallax 
            img={process.env.REACT_APP_PUBLIC_URL + "images/banners/parallax.webp"}
            clase=""
            text= "NUESTRA MISIÓN"
        ></BannerParallax>
       
        <div className='section_cuestionar'>           
            <Row>
                <Col md={2} className='pos_rel'>
                    <img className='img_fondo_cuestionar mz_i' src={process.env.REACT_APP_PUBLIC_URL + "images/nosotros/manzana.webp"} alt="Manzana"/>
                    <img className="img_fondo_cuestionar pe_i" src={process.env.REACT_APP_PUBLIC_URL + "images/nosotros/perejil.webp"} alt="Perejil"/>
                    <img className="img_fondo_cuestionar za_i" src={process.env.REACT_APP_PUBLIC_URL + "images/nosotros/zanahoria.webp"} alt="Zanahoria"/>
                    <img className="img_fondo_cuestionar ap_i" src={process.env.REACT_APP_PUBLIC_URL + "images/nosotros/apio.webp"} alt="Apio"/>
                    <img className="img_fondo_cuestionar pl_i" src={process.env.REACT_APP_PUBLIC_URL + "images/nosotros/platano.webp"} alt="Platano"/>
                </Col>
                <Col md={8}>
                    <div className="title_1parte">Cuestionar lo</div>
                    <div className="title_2parte">tradicional</div>
                    <div className="descr_cuest">Investigamos, probamos y comprobamos alternativas más <br></br>conscientes de alimentación y conexión con tu mascota</div>
                    <div className="descr_cuest2">Te acompañamos en el proceso de conocer y<br></br> conectar con ellos</div>
                </Col>
                <Col md={2} className='pos_rel'>
                    <img className="img_fondo_cuestionar co_d" src={process.env.REACT_APP_PUBLIC_URL + "images/nosotros/col.webp"} alt="Col"/>
                    <img className="img_fondo_cuestionar hu_d" src={process.env.REACT_APP_PUBLIC_URL + "images/nosotros/huevo.webp"} alt="Huevo"/>
                    <img className="img_fondo_cuestionar ca_d" src={process.env.REACT_APP_PUBLIC_URL + "images/nosotros/camote.webp"} alt="Camote"/>
                    <img className="img_fondo_cuestionar mz_d" src={process.env.REACT_APP_PUBLIC_URL + "images/nosotros/manzana.webp"} alt="Manzana"/>
                    <img className="img_fondo_cuestionar es_d" src={process.env.REACT_APP_PUBLIC_URL + "images/nosotros/espinaca.webp"} alt="Espinaca"/>
                    <img className="img_fondo_cuestionar be_d" src={process.env.REACT_APP_PUBLIC_URL + "images/nosotros/betarraga.webp"} alt="Betarraga"/>
                </Col>
            </Row>
        </div>        
        <div className="no_responsive section_linea">
            <div className="origin">
                <div className="titulo_origin">El&nbsp;
                <img className="o_title" src={process.env.REACT_APP_PUBLIC_URL + "images/common/origoo.webp"} alt="Origen"/>
                    <span translate="no">rigen</span>
                    </div>
                <Row>
                    <Col md={4} className="pos_rel">
                        <img className="img_fondo_origin" src={process.env.REACT_APP_PUBLIC_URL + "images/nosotros/croquetas.webp"} alt="Croquetas"/>
                    </Col>
                    <Col md={4}>
                        <img className="img_foto1" src={process.env.REACT_APP_PUBLIC_URL + "images/nosotros/mascotapata.webp"} alt="Argos mascota"/>
                    </Col>
                    <Col md={4} className="cont_dat pos_rel">
                        <div className="ray_r_origin"></div>
                        <div className="tip_date" translate="no">2015</div>
                        <div className="desc_dat_r2">Todo empezó<br></br>con Argos</div>
                    </Col>
                </Row>
                <div className="parr_origin">
                    Nuestro Beagle de 7 años se había alimentado siempre con todo tipo de croquetas.<br></br>
                    Sufrió toda su vida de alergia crónica causándole picazón, enrojecimiento, heridas<br></br>
                    expuestas y mal olor, además de tener sobrepeso y muy poca energía.
                </div>
            </div>
            <Col md={12}>
                <Row className="tex_al">
                    <Col md={3} className="cinta cinta_img_dos">
                        <img className="img_fondo_conc1" src={process.env.REACT_APP_PUBLIC_URL + "images/nosotros/compras.webp"} alt="Compras"/>
                    </Col>
                    <Col md={6} className="cont_conc">
                        <div className="title_conc">UN CAMBIO CONSCIENTE</div>
                        <div className="tex_al parr_conc">
                            Empezamos a investigar sobre las necesidades anatómicas<br></br>
                            y nutricionales del perro, probamos con una alimentación<br></br>
                            natural hecha en casa (sin mucho más que sentido común)<br></br>
                            y la vida de Argos dio un giro de 180.<br></br>
                            Desaparecieron las alergias, recuperó su energía y felicidad,<br></br>
                            fue más joven la segunda parte de su vida. 
                        </div>
                    </Col>
                    <Col md={3} className='pos_rel cont_ifc2'>
                        <img className="img_fondo_conc2" src={process.env.REACT_APP_PUBLIC_URL + "images/nosotros/platoc.webp"} alt="Plato de verduras"/>
                    </Col>
                </Row>   
                <Row className="tex_al">
                    <Col md={3} className='pos_rel'>
                        <div className="card_height">
                            <div className="cont_img_nos cinta_img_cuatro">
                                <img className="img_responsive_card" src={process.env.REACT_APP_PUBLIC_URL + "images/nosotros/preparacion.webp"} alt="Local de produccion"/>
                            </div>
                            <div className="cont_text_card">
                                <div className="tittle_card">2do local de producción</div>
                                <div className="text_card">Teníamos 2 máquinas industriales,<br></br>
                                    3 congeladoras y 20 m2, faltaba espacio pero sobraba pasión.
                                </div>
                            </div>
                        </div>                        
                    </Col>
                    <Col md={6} className="cont_conc">
                        <div className="tip_date2 age1" translate="no">2016</div>
                        <div className="desc_dat_r">Nace WilDog Barf</div>
                        <div className="parr_nace">
                            Teníamos la solución a un problema real y la motivación<br></br>
                            de dos <span className="esp_dog">Dog Lovers</span>, solo había que creérsela.<br></br>
                            Fue así que decidimos emprender y llevar esta<br></br>
                            alternativa a muchas más personas.
                        </div>
                        <div className="card_height2 hidden_web">
                            <div className="cont_img_nos cinta_img_tres">
                                <img className="img_responsive_card" src={process.env.REACT_APP_PUBLIC_URL + "images/nosotros/preparacion1.webp"} alt="Local de porduccion"/>
                            </div>
                            <div className="cont_text_card">
                                <div className="tittle_card">1er local de producción</div>
                                <div className="text_card">
                                    Acondicionamos el patio de la casa para<br></br>
                                    producir los primeros pedidos creyendo<br></br>
                                    que teníamos todo lo necesario.<br></br>
                                    <span className="bold_text_c">Fue un fracaso total y tuvimos que<br></br>
                                    reinventarnos.</span>
                                </div>
                            </div>
                        </div>
                        <div className="title_1pr age2">PRIMERA PRODUCCIÓN</div>
                        <div className="desc_dat_r">(exitosa)</div>
                        <div className="parr_nace">
                            Logramos acondicionar un pequeño local de<br></br>
                            producción en Cieneguilla y comprar nuestras<br></br>
                            primeras máquinas industriales.<br></br>
                            Aquí nos mantuvimos 4 años mientras ya<br></br>
                            planeábamos Origo, un proyecto bastante más<br></br>
                            ambicioso y complejo.
                        </div>
                    </Col>
                    <Col md={3} className="pos_rel hidden_responsive">
                        <div className="card_height2">
                            <div className="cont_img_nos cinta_img_tres">
                                <img className="img_responsive_card" src={process.env.REACT_APP_PUBLIC_URL + "images/nosotros/preparacion1.webp"} alt="Local de produccion"/>
                            </div>
                            <div className="cont_text_card">
                                <div className="tittle_card">1er local de producción</div>
                                <div className="text_card">
                                    Acondicionamos el patio de la casa para<br></br>
                                    producir los primeros pedidos creyendo<br></br>
                                    que teníamos todo lo necesario.<br></br>
                                    <span className="bold_text_c">Fue un fracaso total y tuvimos que<br></br>
                                    reinventarnos.</span>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={3} className='pos_rel'>
                        <div className="img_cocnc_cont cinta_img_cinco">
                            <img className="img_fondo_conc3" src={process.env.REACT_APP_PUBLIC_URL + "images/nosotros/preparacion2.webp"} alt="Preparacion de comida"/>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="tip_date2 age3" translate="no">2020</div>
                        <div className="desc_dat_r">Nace Origo Pet Food</div>
                        <div className="parr_nace">
                            Lo teníamos planeado desde 2017, pero por muchas<br></br>
                            dificultades y mil anécdotas, recién pudimos<br></br>
                            empezar a construir la nueva fábrica en 2020.
                        </div>
                        <div className="card_height3 hidden_web">
                            <div className="cont_img_nos cinta_img_tres">
                                <img className="img_responsive_card" src={process.env.REACT_APP_PUBLIC_URL + "images/nosotros/team.webp"} alt="Local de produccion"/>
                            </div>
                            <div className="cont_text_card">
                                <div className="tittle_card">3er local de producción</div>
                                <div className="text_card">
                                    Hoy nuestras cocinas cuentan con 300 m2,<br></br>
                                    los estándares de calidad y sanidad más<br></br>
                                    exigentes de la industria alimentaria y, detrás de cada alimento entregado, <span className="bold_text_c">una<br></br>
                                    familia de colaboradores apasionados por su trabajo.</span>
                                </div>
                            </div>
                        </div>
                        <div className="tip_date2 age4" translate="no">2021</div>
                        <div className="desc_dat_r">Relanzamos la marca</div>
                        <div className="parr_nace">
                            Han sido años de muchos retos, caídas, aprendizajes,<br></br>
                            pero sobretodo, de mucha felicidad. Que nuestro<br></br>
                            trabajo nos permita estar más cerca de los perros<br></br>
                            para comprenderlos mejor y ayudarlos a elevar su<br></br>
                            calidad de vida, no tiene precio.
                        </div>
                    </Col>
                    <Col md={3} className='pos_rel hidden_responsive'>
                        <div className="card_height3">
                            <div className="cont_img_nos cinta_img_tres">
                                <img className="img_responsive_card" src={process.env.REACT_APP_PUBLIC_URL + "images/nosotros/team.webp"} alt="Empaquetando porductos"/>
                            </div>
                            <div className="cont_text_card">
                                <div className="tittle_card">3er local de producción</div>
                                <div className="text_card">
                                    Hoy nuestras cocinas cuentan con 300 m2,<br></br>
                                    los estándares de calidad y sanidad más<br></br>
                                    exigentes de la industria alimentaria y, detrás de cada alimento entregado, <span className="bold_text_c">una<br></br>
                                    familia de colaboradores apasionados por su trabajo.</span>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="pad_phot">
                        <div className="card_height5">
                            <div className="cont_img_nos2">
                                <img className="img_responsive_card2" src={process.env.REACT_APP_PUBLIC_URL + "images/nosotros/team2.webp"} alt="Rafa y Luciano"/>
                            </div>
                            <div className="cont_text_card2">
                                <div className="text_card">
                                    Con mucho cariño.<br></br>
                                    <span className="bold_text_c">Rafa y Luciano</span>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Col>
        </div>
        
    </div>
    </MainLayout>
)};
export default Nosotros;