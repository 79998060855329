import { api } from "../../src/api";
import ActionType from "../../src/utils/customTypes";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export class IndexService extends api {

    private static classInstance?: IndexService;
        
    constructor(token: string, type: ActionType) {
        super( process.env.REACT_APP_BASE_URL,token);    
    } 
  
    public static getInstance() {
        let token = localStorage.getItem("token");
        if (!this.classInstance) {
            this.classInstance = new IndexService(token!==undefined && token !== null ? token:'', 'GET');
        }
        
        return this.classInstance;
    }   

    public getNotes= () => this.instance.post('/get-notes').then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getAllFaq= () => this.instance.post('/faq/get-all-faq').then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getTestimonials= () => this.instance.post('/get-testimonials').then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getSliderHome= () => this.instance.post('/get-slider-home').then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });
     
    public getCalculator= (data:any) => this.instance.post('/get-calculator',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getPromotionalProducts= () => this.instance.post('/get-promotional-products').then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getDogBreeds= () => this.instance.post('/get-dog-breeds').then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getSuscriptionPlanCalc= (data: any) => this.instance.post('/get-suscription-plan-calc',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });
   
    
}