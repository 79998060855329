import React, { useState, useEffect } from 'react';
import { Button, Container, Row, Col, Form, Spinner} from 'react-bootstrap';
import {CartItem} from 'components/CartItem';
import Tooltip from 'components/producto/tooltip';
import BeforeFooter from 'components/beforeFooter/beforeFooter';
import BackLayout from "../../../components/layout/BackLayout/BackLayout";
import {ProductService} from '../../../business/ProductService';
import uploadFile from '../../../business/FileService';
import * as Yup from 'yup';
import { yupToFormErrors } from "formik";
import { Multiselect } from 'multiselect-react-dropdown';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const validSquema = Yup.object().shape({    
    linea: Yup.number().required("*Campo obligatorio")
    ,categoria: Yup.number().required("*Campo obligatorio")
    ,nombre: Yup.string().required("*Campo obligatorio")
    ,slug: Yup.string().required("*Campo obligatorio")
    ,presentacion: Yup.number().required("*Campo obligatorio")
    ,codigo: Yup.string().required("*Campo obligatorio")
    ,concepto: Yup.string().required("*Campo obligatorio")
    ,precio: Yup.number().required("*Campo obligatorio")
 });


function RegistrarProducto (){
    const [data, setData] = useState<any>({data: []});
    const [loading, setLoading] = useState(false);
    const [linea, setLinea] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [etapas, setEtapas] = useState([]);
    const [actividades, setActividades] = useState([]);
    const [experiencias, setExperiencias] = useState([]);
    const [presentaciones, setPresentaciones] = useState([]);
    const [validated, setValidated] = useState<any>();
    const [errors, setErrors] = useState<any>();
    const [loadingButton, setLoadingButton] = useState(false);
    const [selectedProductFile, setSelectedProductFile] = useState();
    const [selectedDetailFile, setSelectedDetailFile] = useState();
    const [previewProductFile, setPreviewProductFile] = useState("");
    const [previewDetailFile, setPreviewDetailFile] = useState("");

    useEffect(() => {      
        getLinesList(); 
        getCategoryList();  
        getStageList();
        getActivityList(); 
        getExperienceList(); 
        getPresentationList();        
      }, []);

    const getLinesList = async () => { 
        const getInstance = ProductService.getInstance();
        setLoading(true);

        await getInstance.getLinesList()
            .then((response:any) => {
                
                    if(response.success === true)
                    {
                        setLinea(response.data);

                    }
                    setLoading(false);
                    
                })
            .catch((err:any) => {
                setLoading(false);
            });

    }

    const getCategoryList = async () => { 
        const getInstance = ProductService.getInstance();
        setLoading(true);

        await getInstance.getCategoryList()
            .then((response:any) => {
                
                    if(response.success === true)
                    {
                        setCategorias(response.data);

                    }
                    setLoading(false);
                    
                })
            .catch((err:any) => {
                setLoading(false);
            });

    }

    const getStageList = async () => { 
        const getInstance = ProductService.getInstance();
        setLoading(true);

        await getInstance.getStageList()
            .then((response:any) => {
                
                    if(response.success === true)
                    {
                        setEtapas(response.data);

                    }
                    setLoading(false);
                    
                })
            .catch((err:any) => {
                setLoading(false);
            });

    }

    const getActivityList = async () => { 
        const getInstance = ProductService.getInstance();
        setLoading(true);

        await getInstance.getActivityList()
            .then((response:any) => {
                
                    if(response.success === true)
                    {
                        setActividades(response.data);

                    }
                    setLoading(false);
                    
                })
            .catch((err:any) => {
                setLoading(false);
            });

    }

    const getExperienceList = async () => { 
        const getInstance = ProductService.getInstance();
        setLoading(true);

        await getInstance.getExperienceList()
            .then((response:any) => {
                
                    if(response.success === true)
                    {
                        setExperiencias(response.data);

                    }
                    setLoading(false);
                    
                })
            .catch((err:any) => {
                setLoading(false);
            });

    }

    const getPresentationList = async () => { 
        const getInstance = ProductService.getInstance();
        setLoading(true);

        await getInstance.getPresentationList()
            .then((response:any) => {
                
                    if(response.success === true)
                    {
                        setPresentaciones(response.data);

                    }
                    setLoading(false);
                    
                })
            .catch((err:any) => {
                setLoading(false);
            });

    }

    const insertProduct = async (imgProduct:string) => { 
        setLoadingButton(true);
        const getInstance = ProductService.getInstance();
            
        await getInstance.insertProduct({...data, ['imagen_product']: imgProduct})
            .then((response:any) => {
                setLoadingButton(false);
                if(response.success === true)
                {
                    window.location.href = process.env.REACT_APP_PUBLIC_URL + '/admin/productos'
                }
                
                    
            })
            .catch((err:any) => {
                setLoadingButton(false);
                console.log(err);
               
                
            });
    
    }

    const onSelectStage = (selectedList:any, selectedItem:any) => {
        setData({
            ...data,
            ['etapas']: selectedList
        });
    }

    const onRemoveStage = (selectedList:any, removedItem:any) => {
        setData({
            ...data,
            ['etapas']: selectedList
        });
    }

    const onSelectActivity = (selectedList:any, selectedItem:any) => {
        setData({
            ...data,
            ['actividades']: selectedList
        });
    }

    const onRemoveActivity = (selectedList:any, removedItem:any) => {
        setData({
            ...data,
            ['actividades']: selectedList
        });
    }

    const onSelectExperience = (selectedList:any, selectedItem:any) => {
        setData({
            ...data,
            ['experiencias']: selectedList
        });
    }

    const onRemoveExperience = (selectedList:any, removedItem:any) => {
        setData({
            ...data,
            ['experiencias']: selectedList
        });
    }

    const handleChangeResumen = (html: any) => {

        setData({
            ...data,
            ['resumen_nutritivo'] : html
        });
        
      };

    const handleChangeData= (event: any) => {
        const { name, value} = event.target;
        let copy = {...data};
        let val = value;
        
        if((name==='linea' || name==='categoria' || name==='presentacion') && value != '')
        {
            val = parseInt(value);
        }
        if((name==='precio' || name==='descuento') && value != '')
        {
            val = parseFloat(value);
        }

    
        setData({
            ...data,
            [name]: event.target.type==='checkbox' ? event.target.checked : val
        });
        
      };

    const  handleSave = (event: any) => {
        validSquema.validate(data, { abortEarly: false }).then(async function() {
            // Success
           
            var result = await uploadFile(selectedProductFile,'upload/productos').then((response:any) => {
                
                if(response.status == 200)
                {
                    let result = response.data.result;
                    if(result.success == true)
                    {
                       return result.data.imagen_path;
                        
                    }
                }
                return '';
                
            });

            insertProduct(result);

            setErrors(null);
        }).catch(function (err) {
            let errorArray = {};
            const errors = yupToFormErrors(err);
            console.log(errors);
            setErrors(errors);
            setValidated(true);
           
        });
        
    }

    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        if (!selectedProductFile) {
            setPreviewProductFile("")
            return
        }

        const objectUrl = URL.createObjectURL(selectedProductFile)
        setPreviewProductFile(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedProductFile])

    const onSelectProductFile = (e:any) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedProductFile(undefined)
            return
        }

        // I've kept this example simple by using the first image instead of multiple
        setSelectedProductFile(e.target.files[0])
    }

    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        if (!selectedDetailFile) {
            setPreviewDetailFile("")
            return
        }

        const objectUrl = URL.createObjectURL(selectedDetailFile)
        setPreviewDetailFile(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedDetailFile])

    const onSelectDetailFile = (e:any) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedDetailFile(undefined)
            return
        }

        // I've kept this example simple by using the first image instead of multiple
        setSelectedDetailFile(e.target.files[0])
    }

    return (
        <BackLayout>
        <div className='fond_backoffice'>
            <Container>                
                <div className='cont_title'>
                    <h1>Registrar Producto</h1>
                </div>
                <div className='cont_data'>                           
                    <Form id="form-registrar-producto" validated={validated}>
                        <Row className="">
                            <Col md={12}>
                                <div className="section_backoffice_form">
                                    <div className="">
                                        <h3>Datos del Producto</h3>
                                        <Row>
                                            <Col md={6}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Línea*:</Form.Label>
                                                    <Form.Control as="select" name="linea" required value={data.linea} onChange={e => {handleChangeData(e)}}>
                                                     <option value={''}>Seleccione</option>
                                                        {linea.map((item:any) => (
                                                        <option key={item.id} value={item.id}>
                                                            {item.name}   
                                                        </option>
                                                        ))} 
                                                    </Form.Control>                                                    
                                                    {errors?.linea != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.linea}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>  
                                            </Col>
                                            <Col md={6}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Categoría*:</Form.Label>
                                                    <Form.Control as="select" name="categoria" required value={data.categoria} onChange={e => {handleChangeData(e)}}>
                                                        <option value={''}>Seleccione</option>
                                                        {categorias.map((item:any) => (
                                                        <option key={item.id} value={item.id}>
                                                            {item.name}   
                                                        </option>
                                                        ))}  
                                                    </Form.Control>                                                    
                                                    {errors?.categoria != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.categoria}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>  
                                            </Col>
                                            <Col md={6}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Nombre*:</Form.Label>
                                                    <input type="text" name='nombre' required className='form-control' value={data.nombre} onChange={e => {handleChangeData(e)}}/>                                                    
                                                    {errors?.nombre != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.nombre}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>  
                                            </Col>
                                            <Col md={6}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Slug*:</Form.Label>
                                                    <input type="text" name='slug' required className='form-control' value={data.slug} onChange={e => {handleChangeData(e)}}/>                                                    
                                                    {errors?.slug != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.slug}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>  
                                            </Col>
                                           
                                            <Col md={12}>
                                                <Form.Group>
                                                <Form.Label>Descripción</Form.Label>
                                                <textarea name="descripcion" className="form-control" rows={2} placeholder="" value={data.descripcion} onChange={e => {handleChangeData(e)}}></textarea>
                                                </Form.Group>
                                            </Col>  
                                           
                                            <Col md={6}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Imagen:</Form.Label>

                                                    <Form.Control type="file" as='input' name='imagen_suscripcion_producto' className='form-control' onChange={onSelectProductFile}/>
                                                    {selectedProductFile &&  <img className="edit-img-propre" style={{width: '200px'}} src={previewProductFile} /> }
                                                </Form.Group>  
                                            </Col>
                                        </Row>
                                    </div>
                                
                                    <div className="section_backoffice">
                                        <h3>Detalle del Producto</h3>
                                        <Row>
                                            
                                            <Col md={12}>
                                                <Form.Group>
                                                <Form.Label>Descripción detalle</Form.Label>
                                                <textarea name="descripcion_detalle" className="form-control" rows={2} placeholder="" value={data.descripcion_detalle} onChange={e => {handleChangeData(e)}}></textarea>
                                                </Form.Group>
                                            </Col>  
                                            <Col md={12}>
                                            <Form.Group>
                                                <Form.Label>Resumen nutritivo</Form.Label>
                                                <ReactQuill theme="snow" value={data.resumen_nutritivo} onChange={handleChangeResumen} />
                                                
                                            </Form.Group>
                                               
                                            </Col>  
                                            <Col md={3}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Calorias:</Form.Label>
                                                    <input type="text" name='energia' className='form-control' value={data.energia} onChange={e => {handleChangeData(e)}}/>                                                    
                                                    <span className="help-block"></span>
                                                </Form.Group>  
                                            </Col>
                                            <Col md={3}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Proteínas:</Form.Label>
                                                    <input type="text" name='proteina' className='form-control' value={data.proteina} onChange={e => {handleChangeData(e)}}/>                                                    
                                                    <span className="help-block"></span>
                                                </Form.Group>  
                                            </Col>
                                            <Col md={3}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Grasas:</Form.Label>
                                                    <input type="text" name='grasa' className='form-control' value={data.grasa} onChange={e => {handleChangeData(e)}}/>                                                    
                                                    <span className="help-block"></span>
                                                </Form.Group>  
                                            </Col>
                                            <Col md={3}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Humedad:</Form.Label>
                                                    <input type="text" name='humedad' className='form-control' value={data.humedad} onChange={e => {handleChangeData(e)}}/>                                                    
                                                    <span className="help-block"></span>
                                                </Form.Group>  
                                            </Col>
                                            <Col md={3}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Etapa:</Form.Label>
                                                  
                                                    <Multiselect
                                                        options={etapas} // Options to display in the dropdown
                                                        onSelect={onSelectStage} // Function will trigger on select event
                                                        onRemove={onRemoveStage} // Function will trigger on remove event
                                                        displayValue="name" // Property name to display in the dropdown options
                                                        placeholder='Elija una o más etapas'
                                                    />                                   
                                                </Form.Group>  
                                            </Col>
                                            <Col md={3}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Actividad:</Form.Label>
                                                    <Multiselect
                                                        options={actividades} // Options to display in the dropdown
                                                        onSelect={onSelectActivity} // Function will trigger on select event
                                                        onRemove={onRemoveActivity} // Function will trigger on remove event
                                                        displayValue="name" // Property name to display in the dropdown options
                                                        placeholder='Elija una o más actividades'
                                                    />    
                                                </Form.Group>  
                                            </Col>
                                            <Col md={3}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Experiencia:</Form.Label>
                                                    <Multiselect
                                                        options={experiencias} // Options to display in the dropdown
                                                        onSelect={onSelectExperience} // Function will trigger on select event
                                                        onRemove={onRemoveExperience} // Function will trigger on remove event
                                                        displayValue="name" // Property name to display in the dropdown options
                                                        placeholder='Elija una o más experiencias'
                                                    />    
                                                </Form.Group>  
                                            </Col>
                                           

                                        </Row>
                                    </div>
                            
                                    <div className="section_backoffice">
                                        <h3>Datos de la Presentación</h3>
                                        <Row>
                                            <Col md={4}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Envase*:</Form.Label>
                                                    <Form.Control as="select" name="presentacion" required value={data.presentacion} onChange={e => {handleChangeData(e)}}>
                                                        <option value="">Seleccionar</option>
                                                        {presentaciones.map((item:any) => (
                                                             <option key={item.id} value={item.id}>
                                                                {item.name}   
                                                            </option>
                                                        ))}
                                                    </Form.Control>                                                    
                                                    {errors?.presentacion != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.presentacion}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>  
                                            </Col>
                                            <Col md={4}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Código SKU:</Form.Label>
                                                    <input type="text" name='codigo' required className='form-control' value={data.codigo} onChange={e => {handleChangeData(e)}}/>                                                    
                                                    {errors?.codigo != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.codigo}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>  
                                            </Col>
                                            <Col md={4}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Id Producto Alegra:</Form.Label>
                                                    <input type="text" name='idalegra' className='form-control' value={data.idalegra} onChange={e => {handleChangeData(e)}}/>                                                    
                                                   
                                                </Form.Group>  
                                            </Col>
                                            <Col md={12}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Descripción Alegra:</Form.Label>
                                                    <input type="text" name='descripcion_alegra' className='form-control' value={data.descripcion_alegra} onChange={e => {handleChangeData(e)}}/>                                                    
                                                   
                                                </Form.Group>  
                                            </Col>
                                            <Col md={12}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Concepto*:</Form.Label>
                                                    <input type="text" name='concepto' required className='form-control' value={data.concepto} onChange={e => {handleChangeData(e)}}/>                                                    
                                                    {errors?.concepto != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.concepto}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>  
                                            </Col>                                            
                                            <Col md={12}>
                                                <Form.Group>
                                                <Form.Label>Descripción técnica</Form.Label>
                                                <textarea name="nombre_presentacion" className="form-control" rows={2} placeholder="" value={data.nombre_presentacion} onChange={e => {handleChangeData(e)}}></textarea>
                                                </Form.Group>
                                            </Col>  
                                            <Col md={12}>
                                                <Form.Group>
                                                <Form.Label>Descripción presentación</Form.Label>
                                                <textarea name="descripcion_presentacion" className="form-control" rows={2} placeholder="" value={data.descripcion_presentacion} onChange={e => {handleChangeData(e)}}></textarea>
                                                </Form.Group>
                                            </Col>    
                                            <Col md={6}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Precio:</Form.Label>
                                                    <input type="number" name='precio' required className='form-control' value={data.precio} onChange={e => {handleChangeData(e)}}/>                                                    
                                                    {errors?.precio != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.precio}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>  
                                            </Col>
                                            <Col md={6}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Descuento:</Form.Label>
                                                    <input type="number" name='descuento' className='form-control' value={data.descuento} onChange={e => {handleChangeData(e)}}/>                                                    
                                                    <span className="help-block"></span>
                                                </Form.Group>  
                                            </Col>
                                           
                                            <Col md={12} className="pad_top_2 dis_fle spac_betw">
                                                <Col md={4} className="align-items-center txt_ali dis_fle con_btn_confirm">                                                    
                                                <Button className='btn_confirm' type="button" onClick={(e)=>handleSave(e)} disabled={loading}>
                                                    {loading ? (
                                                    <Spinner animation="border" role="status" style={{width: '1rem', height: '1rem'}}>
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Spinner>
                                                    ) : (
                                                    'Guardar'
                                                    )}
                                                </Button>                                                    
                                                </Col>
                                                <Col md={4} className="align-items-center txt_ali dis_fle cont_btn_cancel">                                                    
                                                    <a href={process.env.REACT_APP_PUBLIC_URL + 'admin/productos'} className='btn_cancelar_registro btn'>Cancelar</a>                                                        
                                                </Col>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>   
                        </Row>                            
                    </Form>
                </div>
            </Container>  
        </div>
        </BackLayout>

)};
export default RegistrarProducto;
