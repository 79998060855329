import React, { CSSProperties } from 'react';
import { Button, Container, Nav, Navbar as NavbarBs, NavItem
  , Row , Col ,NavDropdown, NavLink, DropdownButton, Dropdown
} from "react-bootstrap";
import { useShoppingCart } from "../../context/ShoppingCartContext";
import './style.css';
import {useState, useEffect} from 'react';
import {IndexService} from '../../business/IndexService';
import LoginOption from '../login/loginOption';
import {AuthService} from '../../business/AuthService';

const contactToggle = React.forwardRef(({ children, onClick }:any, ref:any) => (
  <a
    href=""
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
   
  >
   
    {children}
  </a>
));

export interface StylesDictionary{
  [Key: string]: CSSProperties;
}

function NavbarUser() {
  const { openCart, cartQuantity } = useShoppingCart()
  const [show, setShow] = useState(false);
  const [showSus, setShowSus] = useState(false);
  const [showUsu, setShowUsu] = useState(false);
  const [showWeb, setShowWeb] = useState(false);
  const [scroll, setScroll] = useState('');
  const [active, setActive] = useState('');
  const [notes, setNotes] = useState<any>([]);
  const [objectStyle, setObjectStyle] = useState<any>({});
  const [fechingNotes, setFetchingNotes] = useState(false);

  const showDropdown = (e:any)=>{
      setShow(!show);
  }
  const hideDropdown = (e:any) => {
      setShow(false);
  }
  const showDropdownSus = (e:any)=>{
    setShowSus(!showSus);
  }
  const hideDropdownSus = (e:any) => {
      setShowSus(false);
  }
  const showDropdownUsu = (e:any)=>{
    setShowUsu(!showUsu);
  }
  const hideDropdownUsu = (e:any) => {
      setShowUsu(false);
  }
  const showDropdownWeb = (e:any)=>{
    setShowWeb(!showWeb);
  }
  const hideDropdownWeb = (e:any) => {
      setShowWeb(false);
  }
  let ruta = document.location.pathname.split('/').slice(0,3).join('/');
  

  useEffect(() => {      
    getNotes();
  }, []);


  const getNotes = async () => { 
    const getInstance = IndexService.getInstance();
    setFetchingNotes(true);

    await getInstance.getNotes()
        .then((response:any) => {
              
          
              if(response.success === true)
              {
                let notes = [];
                notes.push(response.notes.mensaje_uno);
                notes.push(response.notes.mensaje_dos);
                setNotes(notes);

              }
              else{
                
              }
              setFetchingNotes(false);
            }   
          )
        .catch((err:any) => {
          setFetchingNotes(false);
        });

    }

  const getLogout = async () => { 
    const getInstance = AuthService.getInstance();

    await getInstance.logout()
        .then((response:any) => {
              localStorage.removeItem('token');
              localStorage.removeItem('rol');
              localStorage.removeItem('name');
              localStorage.removeItem('ucode');
              localStorage.removeItem('promoCode');
              localStorage.removeItem('te');
              setTimeout(() => {
                window.location.href = '/'
              }, 1000);
            }   
          )
        .catch((err:any) => {
          localStorage.removeItem('token');
              localStorage.removeItem('rol');
              localStorage.removeItem('name');
              localStorage.removeItem('ucode');
              localStorage.removeItem('promoCode');
              localStorage.removeItem('te');
              setTimeout(() => {
                window.location.href = '/'
              }, 1000);
        });

  }

  const logout = ()=>{
    getLogout();
  }
  
  return (
    <>
   <div className='cont_navbar_backoffice'>
    <NavbarBs collapseOnSelect expand="lg" className='mb-3 navbar-wtp' >
      <Container className="style_cont_resp container-fluid">
        <div>
            <NavbarBs.Brand href="/" className=''>
                <img
                  alt="Logo Origo"
                  src={process.env.REACT_APP_PUBLIC_URL + 'images/common/logo_origo.webp'}                  
                  className="sticky-logo align-top"
                />
            </NavbarBs.Brand>
          </div>
        <NavbarBs.Toggle aria-controls="responsive-navbar-nav" />
        <NavbarBs.Collapse id="responsive-navbar-nav">
          <Nav className="nav_w navs" variant="tabs" defaultActiveKey={ruta}>
            <NavItem className="display_responsive">
            <div className="title_menu_responsive">Menu</div><hr></hr>
            </NavItem>
            <NavItem>
            <Nav.Link className='a_nav_back' href={"/cliente/resumen"}>
              RESUMEN
            </Nav.Link>
            </NavItem>
            <NavItem>
            <Nav.Link className='a_nav_back' href={"/cliente/compras"}>
              COMPRAS
            </Nav.Link>
            </NavItem>
            <NavItem>
            <Nav.Link className='a_nav_back' href={"/cliente/suscripciones"}>
              SUSCRIPCIONES
            </Nav.Link>
            </NavItem>
           
            
          </Nav>
          <div className="justify-content-md-end" style={{textAlign: 'right'}}>
            <LoginOption logout={logout}/> 
            <Button
                onClick={openCart}
                style={{ width: "2.8rem", height: "2.8rem", position: "relative", padding: "0.3rem" }}
                variant="outline-primary"
                className="btn-grey"
              >
                <img
                  alt="ShopCart"
                  src={process.env.REACT_APP_PUBLIC_URL + 'images/common/cart.webp'}
                  
                />
                

                <div
                  className="rounded-circle bg-origo d-flex justify-content-center align-items-center"
                  style={{
                    color: "white",
                    width: "1.5rem",
                    height: "1.5rem",
                    position: "absolute",
                    top: 0,
                    right: 0,
                    transform: "translate(25%, 0%)",
                  }}
                >
                {cartQuantity}
              </div>
            </Button>
          </div>

        </NavbarBs.Collapse>
      </Container>
    </NavbarBs>
    </div>
  </>
  )

}

export default NavbarUser;