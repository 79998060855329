import React from 'react';
import "../reporteVentas/style.css";
import { Container, Row, Col, Form, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-js-pagination';
import { useState, useCallback, useEffect } from 'react';
import BackLayout from "../../../components/layout/BackLayout/BackLayout";
import {PurchaseService} from '../../../business/PurchaseService';
import { formatNumber } from "../../../utils/formatCurrency";
import LoadingTable from "../../../components/skeletons/LoadingTable";
import Tracking from './tracking';

let filtersDefault = {
    from: '',
    to: '',
    status: '0',
    search: ''

}

function Compras (){
    const [data, setData] = useState<any>({data: []});
    const [filters, setFilters] = useState(filtersDefault);
    const [loading, setLoading] = useState(false);
    const [showTracking, setShowTracking] = useState(false);
    const [url, setUrl] = useState("");

    useEffect(() => {      
        getMyPurchases(1);     
      }, []);

    const getMyPurchases = async (pageNumber:number) => { 
        const getInstance = PurchaseService.getInstance();
        setLoading(true);

        await getInstance.getMyPurchases({page: pageNumber, from: filters.from, to: filters.to, search: filters.search})
            .then((response:any) => {
                
                    if(response.success === true)
                    {
                        setData(response.data);

                    }
                    setLoading(false);
                    
                })
            .catch((err:any) => {
                setLoading(false);
            });

    }

    const handlePageChange = (pageNumber:any) => {
        getMyPurchases(pageNumber);
    }

    const handleSearch = (e:any) => {
        getMyPurchases(1);
    }

    const handleChangeFilter= (event: any) => {
    
        const { name, value} = event.target;
        setFilters({
            ...filters,
            [name]: value
          });      
        
      };

      const handleClose = (event:any) => {
        event.preventDefault();
        setUrl("");
        setShowTracking(false);
    }
    const handleShowTracking = (url:string) => {
        setUrl(url);
        setShowTracking(true);
    }

    return (
        <BackLayout>
        <div className='fond_backoffice'>
            <Container>                
            <div className='cont_title'>
                <h1>Compras</h1>
            </div>
            <div className='cont_data'>
                <Container className='cont_filters'>
                    <Row className='row_filter'>
                        <Col md={4} className="align-items-center txt_ali dis_fle">
                            <Col md={2}>
                                <label htmlFor="search">Buscar:</label>
                            </Col>
                            <Col md={10}>   
                            <input name="search" type="search" className='form-control' placeholder='n° de pedido' value={filters.search} onChange={(e) => handleChangeFilter(e)}/>
                            </Col>
                        </Col>
                        <Col md={3} className="align-items-center txt_ali dis_fle">
                            <Col md={6}>
                            <label htmlFor="from">F. entrega (desde):</label>
                            </Col>
                            <Col md={6}>
                                <input name="from"type="date" className='form-control' value={filters.from} onChange={(e)=>handleChangeFilter(e)}/>
                            </Col>
                        </Col>
                        <Col md={3} className="align-items-center txt_ali dis_fle">
                            <Col md={6}>
                                <label htmlFor="to">F. entrega(hasta):</label>
                            </Col>
                            <Col md={6}>
                                <input name="to"type="date" className='form-control' value={filters.to} onChange={(e)=>handleChangeFilter(e)}/>
                            </Col>
                        </Col>
                        <Col md={2} className="align-items-center txt_ali dis_fle">
                            <Button type="button" className='btn_filter_search'  onClick={(e)=>handleSearch(e)}>Buscar</Button>
                        </Col>
                    </Row>
                </Container>
                <Table responsive className='table_backoffice'>
                <thead>
                    <tr>
                        <th>Nro. Pedido</th>
                        <th>Fecha compra</th>
                        <th>Fecha entrega</th>
                        <th>Costo envío</th>
                        <th>Ori Points usados</th>
                        <th>Total pagado</th>
                        <th>Estado</th>
                        <th>Tracking</th>
                        <th>Ver detalle</th>
                    </tr>
                </thead>
                {loading==true ? (
                        <LoadingTable rows={10} cols={9}/>
                    )
                    
                    :
                    (<tbody>
                    {data.data.map((item:any) => {
                        return (    
                    <tr>
                    <th>{item.nro_pedido}</th>
                        <td>{item.fecha_compra}</td>
                        <td>{item.fecha_entrega}</td>
                        <td>{formatNumber(item.costo_envio)}</td>
                        <td>{formatNumber(item.creditos_usados)}</td>
                        <td>{formatNumber(item.total)}</td>                     
                        <td>
                            <span className="label label-lg label-light-primary label-inline">{item.estado}</span>
                        </td>
                        <td className='text_icon dis_fle'>
                            <a onClick={(e) => handleShowTracking(item.url_tracking)}  href="javascript:;" className="open-track">
                                <div className='cont_ver_detalle'>                                
                                    <img src={process.env.REACT_APP_PUBLIC_URL + 'images/common/tracking.webp'} alt="Tracking" />
                                </div>
                            </a>&nbsp;{item.vnombreestado}</td>                      
                        <td className='text_icon'>
                            <a href={process.env.REACT_APP_PUBLIC_URL + "cliente/compras/detalle/" + item.id_pedido}>
                                <div className='cont_ver_detalle'>                                
                                    <img src={process.env.REACT_APP_PUBLIC_URL + 'images/common/lupa.webp'} alt="Ver detalle" />
                                </div>
                            </a>
                        </td>
                    </tr>
                    )
                })}
                
                </tbody>)}
                </Table>
                <div className='pagination_back'>                    
                {data.total > 0 && (<Pagination
                    activePage={data.current_page}
                    itemsCountPerPage={data.per_page}
                    totalItemsCount={data.total}
                    pageRangeDisplayed={5}
                    onChange={(e) => handlePageChange(e)}
                    innerClass={"pagination"}
                    itemClass={"page-item"}
                    linkClass={"page-link"}
                    />)}


                </div>
            </div>
            </Container>
        </div>
        <Tracking 
            show={showTracking} 
            handleClose={handleClose} 
            url={url}
        />
         </BackLayout>
)};
export default Compras;
