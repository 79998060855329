import  NavbarBackOffice  from "../../Navbar/NavbarBackoffice"
import  NavbarUser  from "../../Navbar/NavbarUser"
import  NavbarBackOfficeH  from "../../Navbar/NavbarBackofficeH"

const BackLayout = ({children}:any) => {

    let rol = localStorage.getItem('rol');
    if (rol != null)
    {
        switch(rol){
            case '1': return (
                <><NavbarBackOffice />{children}</>
            );
            break;
            default: return (
                <><NavbarUser />{children}</>
            );
        }
    }
    else{
        return (<>{children}</>);
    }
   
   
};

export default BackLayout;
