import "./style.css";
import {Container,Row,Col} from 'react-bootstrap'

const Footer = () => (
    <div className="footer footer_pad backfooter">
        <div className="container_fa"> 				
            <a className="back_barra" href="https://api.whatsapp.com/send?phone=+51940583169&amp;text=Saludos Origo Pet, revisé su página" target="_blank">
                <img src="https://origo.pet/images/social/whatsapp.png"/>
            </a>			
        </div>
        <Container>
            <h6 className="subtitle_foo">CONTÁCTANOS</h6>
            <Row className="row_dat">
                <Col md={4} className="bord_rig dis_fle_cont">
                    <div>
                     <img className="img_footer" src={process.env.REACT_APP_PUBLIC_URL + "images/social/mail-wo.webp"} alt="Correo Electronico"/>
                    </div>
                    <a className='subtitle1' href="mailto:info@origo.pet">info@origo.pet</a>
                </Col>
                <Col md={4} className='bord_rig dis_fle_cont'>
                    <div>
                        <img className="img_footer" src={process.env.REACT_APP_PUBLIC_URL + "images/social/telefono-white.webp"} alt="Telefono"/>
                    </div>
                    <span className='subtitle1'>Oficina central: <a className='subtitle1' href="tel:+51955477566" target="_blank">940 583 169</a></span>
                </Col>
                <Col md={4} className="dis_fle_cont">
                    <span className='subtitle1'>Síguenos en redes:</span>
                    <div>
                        <a href="https://www.facebook.com/origo.pet/" target="_blank">
                            <img className="img_footer" src={process.env.REACT_APP_PUBLIC_URL + "images/social/facebook-wo.webp"} alt="Facebook"/>
                        </a>
                        <a href="https://www.instagram.com/origo.pet/?hl=es" target="_blank">
                            <img className="img_footer" src={process.env.REACT_APP_PUBLIC_URL + "images/social/instagram-wo.webp"} alt="Instagram"/>
                        </a>
                    </div>
                  </Col>
            </Row>
            <div className='subtitle1'>
            &copy; <span className="copyright">Copyright 2024 ORIGO PET | Todos los derechos reservados</span>
            </div>
        </Container>
    </div>
   
);

export default Footer;