import React from 'react';
import "../reporteVentas/style.css";
import BackLayout from "../../../components/layout/BackLayout/BackLayout";
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-js-pagination';
import { useState, useCallback, useEffect } from 'react';
import ModalRecompensa from './modalRecompensas';
import {RewardService} from '../../../business/RewardService';
import LoadingTable from "../../../components/skeletons/LoadingTable";

let filtersDefault = {
    from: '',
    to: '',
    search: '',

}


function Recompensas (){
    const [showRecompensa, setShow] = useState(false);
    const handleShowRecompensa = () => setShow(true);
    
    const [data, setData] = useState<any>({data: []});
    const [filters, setFilters] = useState(filtersDefault);
    const [loading, setLoading] = useState(false);

    useEffect(() => {      
        getRewards(1);    
      }, []);

    const getRewards = async (pageNumber:number) => { 
        const getInstance = RewardService.getInstance();
        setLoading(true);

        await getInstance.getRewards({page: pageNumber, from: filters.from, to: filters.to, search: filters.search})
            .then((response:any) => {
                
                    if(response.success === true)
                    {
                        setData(response.rewards);

                    }
                    setLoading(false);
                    
                })
            .catch((err:any) => {
                setLoading(false);
            });

    }

    const handlePageChange = (pageNumber:any) => {
        getRewards(pageNumber);
    }

    const handleSearch = (e:any) => {
        getRewards(1);
    }

    const handleChangeFilter= (event: any) => {
    
        const { name, value} = event.target;
        setFilters({
            ...filters,
            [name]: value
          });      
        
    };
    const handleCloseRecompensa = () => {
        setShow(false);
        getRewards(data.current_page); 
    }

    return (
        <BackLayout>
        <div className='fond_backoffice'>
            <Container>                
            <div className='cont_title'>
                <h1>Recompensas</h1>
            </div>
            <div className='cont_data'>
                <Container className='cont_filters'>
                    <Row className='row_filter'>
                        <Col md={2} className="align-items-center txt_ali dis_fle">
                            <Col md={4}>
                                <label htmlFor="search">Buscar:</label>
                            </Col>
                            <Col md={8}>   
                            <input name="search" type="search" className='form-control' placeholder='apellidos, nombres, n°doc' value={filters.search} onChange={(e)=>handleChangeFilter(e)}/>
                            </Col>
                        </Col>  
                        <Col md={3} className="align-items-center txt_ali dis_fle">
                            <Col md={4}>
                            <label htmlFor="from">F. Desde:</label>
                            </Col>
                            <Col md={8}>
                                <input name="from" type="date" className='form-control' value={filters.from} onChange={(e)=>handleChangeFilter(e)}/>
                            </Col>
                        </Col>
                        <Col md={3} className="align-items-center txt_ali dis_fle">
                            <Col md={4}>
                                <label htmlFor="to">F. Hasta:</label>
                            </Col>
                            <Col md={8}>
                                <input name="to" type="date" className='form-control' value={filters.to} onChange={(e)=>handleChangeFilter(e)}/>
                            </Col>
                        </Col>                    
                        <Col md={2} className="align-items-center txt_ali dis_fle">
                            <Button className='btn_filter_search' type="button" onClick={(e)=>handleSearch(e)}>Buscar</Button>
                        </Col>
                        <Col md={2} className="align-items-center txt_ali dis_fle">
                            <Button className='btn_export_excel' onClick={handleShowRecompensa}>Agregar</Button>
                        </Col>
                    </Row>
                </Container>
                <Table responsive className='table_backoffice'>
                <thead>
                    <tr>
                        <th>Fecha de creación</th>
                        <th>Apellidos y Nombres</th>                        
                        <th>Tipo Documento</th>
                        <th>N° Documento</th>
                        <th>Recompensa</th>
                    </tr>
                </thead>
                {loading==true ? (
                        <LoadingTable rows={10} cols={5}/>
                    )
                    
                    :
                    (
                <tbody>
                    {data.data.map((item:any) => {
                        return (    
                    <tr>
                        <td>{item.created_at}</td>
                        <td>{item.cliente}</td>
                        <td>{item.tipdoc}</td>
                        <td>{item.numdoc}</td>
                        <td>{item.recompensa}</td>
                    </tr>
                    )
                })}
                </tbody>)}
                </Table>
                <div className='pagination_back'>                    
                    {data.total > 0 && (<Pagination
                        activePage={data.current_page}
                        itemsCountPerPage={data.per_page}
                        totalItemsCount={data.total}
                        pageRangeDisplayed={5}
                        onChange={(e) => handlePageChange(e)}
                        innerClass={"pagination"}
                        itemClass={"page-item"}
                        linkClass={"page-link"}
                        />)}

                </div>
            </div>
            </Container>
            <ModalRecompensa show={showRecompensa} handleClose={handleCloseRecompensa} />  
        </div>
        </BackLayout>
)};
export default Recompensas;
