import { api } from "../api";
import ActionType from "../utils/customTypes";

export class TestimonialService extends api {

    private static classInstance?: TestimonialService;
        
    constructor(token: string, type: ActionType) {
        super( process.env.REACT_APP_BASE_URL,token);    
    } 
  
    public static getInstance() {
        let token = localStorage.getItem("token");
        if (!this.classInstance) {
            this.classInstance = new TestimonialService(token!==undefined && token !== null ? token:'', 'GET');
        }
        
        return this.classInstance;
    }   

    public getTestimonials= (data:any) => this.instance.post('/testimonials/get-testimonials', data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getTestimonial= (data:any) => this.instance.post('/testimonials/get-testimonial', data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public insertTestimonial= (data:any) => this.instance.post('/testimonials/insert-testimonial', data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });   

    public updateTestimonial= (data:any) => this.instance.post('/testimonials/update-testimonial', data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });  

    public deleteTestimonial= (data:any) => this.instance.post('/testimonials/delete-testimonial', data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });  
    
}