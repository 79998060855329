import React, { PureComponent } from 'react';
import "../reporteVentas/style.css";
import BackLayout from "../../../components/layout/BackLayout/BackLayout";
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

import { useState, useCallback, useEffect } from 'react';
import { PieChart, Pie, Sector, LineChart, Line, AreaChart, Area, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import {SalesService} from '../../../business/SalesService';


 
  const COLORS = ['#262626','#fe6f5e','#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
  const MONTHS = ['','Enero','Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio','Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

function DashboardAdmin (){
        const [datav, setDataV] = useState<any>();
        const [loadingV, setLoadingV] = useState(false);
        const [data, setData] = useState<any>([]);
        const [loading, setLoading] = useState(false);
        const [datal, setDataL] = useState<any>();
        const [loadingl, setLoadingL] = useState(false);
        const [datac, setDataC] = useState<any>();
        const [loadingC, setLoadingC] = useState(false);
        useEffect(() => {      
            getTopProducts();   
            getSalesByCategory(); 
            getSuscriptionsLastMonths();
            getUsersLastMonths();
          }, []);
    
        const getTopProducts = async () => { 
            const getInstance = SalesService.getInstance();
            setLoadingV(true);
    
            await getInstance.getTopProducts()
                .then((response:any) => {
                    
                        if(response.success === true)
                        {
                            setDataV(response.data);
    
                        }
                        setLoadingV(false);
                        
                    })
                .catch((err:any) => {
                    setLoadingV(false);
                });
    
        }

        const getSalesByCategory = async () => { 
            const getInstance = SalesService.getInstance();
            setLoading(true);
    
            await getInstance.getSalesByCategory()
                .then((response:any) => {
                    
                        if(response.success === true)
                        {
                            setData(response.data);
    
                        }
                        setLoading(false);
                        
                    })
                .catch((err:any) => {
                    setLoading(false);
                });
    
        }

        const getSuscriptionsLastMonths = async () => { 
            const getInstance = SalesService.getInstance();
            setLoadingC(true);
    
            await getInstance.getSuscriptionsLastMonths()
                .then((response:any) => {
                    
                        if(response.success === true)
                        {
                            let  datos = response.data.map((col:any, index:number) => { return {
                                "name": MONTHS[col.name],
                                "Suscripciones": col.Suscripciones,
                               
                              }});
                            setDataC(datos);
    
                        }
                        setLoadingC(false);
                        
                    })
                .catch((err:any) => {
                    setLoadingC(false);
                });
    
        }

        const getUsersLastMonths = async () => { 
            const getInstance = SalesService.getInstance();
            setLoadingL(true);
    
            await getInstance.getUsersLastMonths()
                .then((response:any) => {
                    
                        if(response.success === true)
                        {
                            let  datos = response.data.map((col:any, index:number) => { return {
                                "name": MONTHS[col.name],
                                "Usuarios": col.Usuarios,
                               
                              }});
                            setDataL(datos);
    
                        }
                        setLoadingL(false);
                        
                    })
                .catch((err:any) => {
                    setLoadingL(false);
                });
    
        }

        return (
            <BackLayout>
            <div className='fond_backoffice'>  
                <Container>      
                <div className='cont_title'>
                    <h1>Hola {localStorage.getItem('name')}</h1>
                </div>     
                <div className='cont_data'>     
                    <Row>                     
                        
                        <Col md={8} className="">
                            <div className="card back_detalle_venta">
                                <div className="">
                                    <div className="pad_bot_15">
                                        <h2 className="title_detalle_venta">Top ventas</h2>
                                        <p className="" style={{textAlign:'left'}}>Última semana</p>
                                    </div>
                                </div>
                                <div>     
                                <ResponsiveContainer width="95%" height={200}>
                                    <BarChart width={300} height={250} data={datav}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="Monto" fill="#fe6f5e" />
                                    <Bar dataKey="Unidades" fill="#262626" />
                                    </BarChart>
                                </ResponsiveContainer>
                                     
                                </div>                     
                                
                            </div>
                        </Col>
                        
                        <Col md={4} className="pad_bot_15">
                            <div className="card back_detalle_venta">
                                <div className="">
                                    <div className="pad_bot_15">
                                        <h2 className="title_detalle_venta">Ventas por categoría</h2>
                                        <p className="" style={{textAlign:'left'}}>Última semana</p>
                                    </div>
                                </div>
                                <div>     
                                <ResponsiveContainer width="95%" height={200}>
                                
                                <PieChart>
                                    <Pie dataKey="value" data={data} label>
                                    {data.map((entry:any, index:number) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                    </Pie>
                                    <Tooltip />
                                    <Legend />
                                </PieChart>
                                </ResponsiveContainer>
                                     
                                </div>                     
                                
                            </div>
                        </Col>
                       
                        <Col md={4} className="pad_top_15">
                            <div className="card back_detalle_venta">
                                <div className="">
                                    <div className="pad_bot_15">
                                        <h2 className="title_detalle_venta">Suscripciones</h2>
                                        <p className="" style={{textAlign:'left'}}>Últimos 5 meses</p>
                                    </div>
                                </div>
                                <div>     
                                <ResponsiveContainer width="100%" height={200}>
                                <AreaChart
                                width={500}
                                height={400}
                                data={datac}
                                margin={{
                                    top: 10,
                                    right: 30,
                                    left: 0,
                                    bottom: 0,
                                }}
                                >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Area type="monotone" dataKey="Suscripciones" stroke="#8884d8" fill="#262626" fillOpacity="1" />
                                </AreaChart>
                            </ResponsiveContainer>
                                     
                                </div>                     
                                
                            </div>
                        </Col>
                        <Col md={4} className="pad_top_15">
                            <div className="card back_detalle_venta">
                                <div className="">
                                    <div className="pad_bot_15">
                                        <h2 className="title_detalle_venta">Nuevos Usuarios</h2>
                                        <p className="" style={{textAlign:'left'}}>Últimos 5 meses</p>
                                    </div>
                                </div>
                                <div>     
                                <ResponsiveContainer width="100%" height={200}>
                                <LineChart
                                    width={500}
                                    height={300}
                                    data={datal}
                                    margin={{
                                        top: 5,
                                        right: 20,
                                        left: 0,
                                        bottom: 5,
                                    }}
                                    >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Line type="monotone" dataKey="Usuarios" stroke="#8884d8" activeDot={{ r: 8 }} />
                                </LineChart>
                            </ResponsiveContainer>
                                     
                                </div>                     
                                
                            </div>
                        </Col>
                      
                    </Row>
                </div>
            </Container>
            </div>
        </BackLayout>
    )};
export default DashboardAdmin;
