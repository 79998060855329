import "./style.css";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { useState, useRef } from 'react';

type TooltipProps = {
    texttool: string
}  
function Tooltip(props: TooltipProps) {
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const texttool = props.texttool;

    const TooltipComponent  = () => (
      <Popover className="cont_tooltip">
        <div >
            {texttool}
        </div>
      </Popover>
    );
  
    return (
      <>
        
        <OverlayTrigger trigger="click" placement="right" overlay={TooltipComponent()} rootClose>
          <span className="tooltip_checkput" ref={target} onClick={() => setShow(!show)}>?</span>
        </OverlayTrigger>
        
      </>
    );
  }

export default Tooltip;
