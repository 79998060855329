import { createContext, ReactNode, useContext, useState, useEffect } from "react"
import { ShoppingCart } from "../components/ShoppingCart"
import { useLocalStorage } from "../hooks/useLocalStorage"
import { ToastContainer, toast } from 'react-toastify';

type ShoppingCartProviderProps = {
  children: ReactNode
}

type CartItem = {
  id: number
  name: string
  category: string
  idLine: number
  line: string
  price: number
  quantity: number
  percentageDiscount: number
  normalPrice:number
  image:string
  presentation:string
  type: number
  promo:number
  total: number
  periodicity:number
  canEdit: boolean
  onceBuy: boolean
}

type ShoppingCartContext = {
  openCart: () => void
  closeCart: () => void
  getItemQuantity: (id: number) => number
  addItemToCart: (item: CartItem) => void
  increaseCartQuantity: (id: number) => void
  decreaseCartQuantity: (id: number) => void
  changeBuyType: (id: number, buyType: number) => void
  changePeriodicity: (id: number, periodicity: number) => void
  removeFromCart: (id: number) => void
  makePurchase: () => void
  updatePriceItem: (item: CartItem) => void
  overrideCartItems: (items: CartItem[]) => void
  cartQuantity: number
  cartItems: CartItem[]
  totalCart:number
  hasRecurrentPurchase:boolean
  isFrozen: boolean
  hasRegularPurchase : boolean
  isAuthenticated: boolean
  toCheckout: boolean

}

const ShoppingCartContext = createContext({} as ShoppingCartContext)

export function useShoppingCart() {
  return useContext(ShoppingCartContext)
}
export function ShoppingCartProvider({ children }: ShoppingCartProviderProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [toCheckout, setToCheckout] = useState(false);
  
  
  const [cartItems, setCartItems] = useLocalStorage<CartItem[]>(
    "shopping-cart",
    []
  );
  
  const isAuthenticated = localStorage.getItem('token') != undefined && localStorage.getItem('token') != null ? true : false;
  
  
  setInterval(function validToken(){
    let time = localStorage.getItem("te");
    
    if(time !== undefined && time != null && isAuthenticated == true)
    {
      let now = new Date(Date.now());
      let end = new Date(parseInt(time) + (2*60*60*1000));
     
      if(now >= end)
      {
            localStorage.removeItem('token');
            localStorage.removeItem('rol');
            localStorage.removeItem('name');
            localStorage.removeItem('te');

            toast.error('Sesión expirada', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: 0,
                });  
                
            setTimeout(() => {
                window.location.href = '/login'
              }, 1000);
      }
    }

  }, 30000);

 
  const cartQuantity = cartItems.reduce(
    (quantity, item) => item.quantity + quantity,
    0
  )

  const totalCart = cartItems.reduce((total, cartItem) => {   
         
    return total + cartItem?.total;
  }, 0)
 

  const hasRecurrentPurchase = !!cartItems.filter(item => item.type === 2).length;
  const isFrozen = !!cartItems.filter(item => item.idLine == 3 || item.id == 38 || item.id == 39 || item.id == 40 || item.id == 61 || item.id == 62 || item.id == 63).length;
  const hasRegularPurchase = !!cartItems.filter(item => item.type === 1).length;


  const openCart = () => setIsOpen(true)
  const closeCart = () => setIsOpen(false)
  function getItemQuantity(id: number) {
    return cartItems.find(item => item.id === id)?.quantity || 0
  }
 
 function getTotalItem(item: CartItem)
 {
   let free = 0;    
    if(item.promo == 1)
    {
      free = Math.floor(item.quantity / 2);
    }  
    if(item.promo == 2)
    {
      free = Math.floor(item.quantity / 3);
    }   
  return ((item?.price || 0) * item.quantity) - (item?.price || 0) * free;
 }
  function increaseCartQuantity(id: number) {
    let copy = [...cartItems];
    const index = copy.findIndex(object => {
      return object.id === id;
    });
    let elem = {...copy[index]};
    elem.quantity = elem.quantity + 1;
    elem.total = getTotalItem(elem);
    
    
    copy[index] = elem;
    setCartItems(copy);
  }
  function decreaseCartQuantity(id: number) {
    let copy = [...cartItems];
    const index = copy.findIndex(object => {
      return object.id === id;
    });
    let elem = {...copy[index]};
    elem.quantity = elem.quantity==1? 1: elem.quantity - 1;
    elem.total = getTotalItem(elem);
    
    copy[index] = elem;
    setCartItems(copy);
  }
  function removeFromCart(id: number) {
    setCartItems(currItems => {
      return currItems.filter(item => item.id !== id)
    })
  }
  function addItemToCart(item: CartItem) {
    let copy = [...cartItems];
    const index = copy.findIndex(object => {
      return object.id === item.id;
    });
    
    if(index >= 0)
    {
      toast.error('El producto ya fue agregado.',{
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        }); 
    }
    else{
      if(item.promo > 0)
      {
        item.price = item.normalPrice;
      }
      item.total = getTotalItem(item);
      copy.push(item);
      setCartItems(copy);
  
      toast.success('Producto agregado.',{
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        });  
    }
   
    
  }
  function updatePriceItem(item: CartItem) {
    let copy = [...cartItems];
    const index = copy.findIndex(object => {
      return object.id === item.id;
    });
    let elem = {...copy[index]};
    elem.price = item.price;
    elem.normalPrice = item.normalPrice;
    elem.percentageDiscount = item.percentageDiscount;
    elem.promo = item.promo;
    elem.total = getTotalItem(elem);
    
    copy[index] = elem;
    setCartItems(copy);
  }

  function changeBuyType(id: number, buyType: number) {
    let copy = [...cartItems];
    const index = copy.findIndex(object => {
      return object.id === id;
    });
    let elem = {...copy[index]};
    elem.type = buyType;

    if(buyType==1)
    {
      elem.periodicity = 0;
    }
    else{
      elem.periodicity = 7;
    }
    
    copy[index] = elem;
    setCartItems(copy);
  }

  function changePeriodicity(id: number, periodicity: number) {
    let copy = [...cartItems];
    const index = copy.findIndex(object => {
      return object.id === id;
    });
    let elem = {...copy[index]};
    elem.periodicity = periodicity;
    
    copy[index] = elem;
    setCartItems(copy);
  }

  function overrideCartItems(items: CartItem[]){
    setCartItems(items);
  }

  
  function makePurchase(){
    
    if(isAuthenticated){
      window.location.href='/checkout';
    }
    else{
      window.location.href='/login';

      
    }
    
  }

  


  return (
    <ShoppingCartContext.Provider
      value={{
        getItemQuantity,
        addItemToCart,
        increaseCartQuantity,
        decreaseCartQuantity,
        changeBuyType,
        changePeriodicity,
        removeFromCart,
        openCart,
        closeCart,
        makePurchase,
        updatePriceItem,
        overrideCartItems,
        cartItems,
        cartQuantity,
        totalCart,
        hasRecurrentPurchase,
        isFrozen,
        hasRegularPurchase,
        isAuthenticated,
        toCheckout

      }}
    >
      {children}
      <ShoppingCart isOpen={isOpen} makePurchase={makePurchase} />
    </ShoppingCartContext.Provider>
  )
}
