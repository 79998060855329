import { Button, Container, Nav, Navbar as NavbarBs, NavItem
    , Row , Col ,NavDropdown
  } from "react-bootstrap";
  import { useShoppingCart } from "../../context/ShoppingCartContext";
  import Marquee from "../anuncio/marquee";
  import './style.css';
  import {  useState, useEffect } from "react";  
  import { useMediaQuery } from "../../hooks/useMediaQuery";
  
  type ProductProps = {
    idProduct: number
    idPresentation:number
    url: string
    image: string
    idLine: number
    category: string
    line: string
    name: string
    minPrice: number
    maxPrice: number
    maxPriceDescuento: number
    minPriceDescuento: number
    promo: number
    quantity: number
    percentageDiscount: number
    slug: string
    presentation:string
    onOpenPreview: any
    col: number
    
  }


function Product(props: ProductProps){
    
  let screen = useMediaQuery("sm");
    return (
        <div className={'product_item p-0 col-'+ (props.col)}>
            
            <div className="bg-alternative content_product">   
                <div className="cont_img_ig">
                    <div className="cont_img_product">
                        <a href={props.url} target='_blank'>
                            <img className="img_tienda" src={props.image} />
                        </a>
                        <div className="cont_search rounded-circle d-flex justify-content-center align-items-center">
                            <img className="img_search" alt="Ver detalle"
                                src={process.env.REACT_APP_PUBLIC_URL + 'images/common/lupa.webp'} 
                                onClick={(e)=>props.onOpenPreview(e,props.idProduct, props.idPresentation)} />
                                
                        </div>
                    </div>
                </div>
                <div className="cont_descr">
                <div className="name_product">
                {props.line} {' - '} {props.name} 
                {props.presentation!='' && (<span>&nbsp;({props.presentation})</span>)}
                </div>
                    <div className="slug_product" >
                        {props.category}
                    </div>
                    {/* <div className="price_product" >                        
                        {props.minPrice != props.maxPrice ? ('S/ ' + props.minPrice + ' - S/ ' + props.maxPrice) : ('S/ ' + props.minPrice) }
                    </div>  */}
                    {/* <div className="price_product" >                        
                        {props.minPrice != props.maxPrice ? ('S/ ' + props.minPriceDescuento + ' - S/ ' + props.maxPriceDescuento) : ('S/ ' + props.minPriceDescuento) }
                    </div>      */}
                    <div className="price_product">
                        {props.minPrice != props.maxPrice? (
                            props.minPriceDescuento === props.minPrice && props.maxPriceDescuento === props.maxPrice ? (
                                <>
                                    <div className="price_wrapper">
                                        <span className="price_normal">S/ {props.minPrice} - S/ {props.maxPrice}</span>
                                    </div>
                                </>
                            ) : (
                                props.minPriceDescuento != props.minPrice && props.maxPriceDescuento == props.maxPrice ? (
                                    <>
                                        <div className="price_wrapper">
                                            <span className="tachado">S/ {props.minPrice}</span>
                                        </div>
                                        <div className="price_wrapper">
                                            <span className="price_normal">S/ {props.minPriceDescuento} - S/ {props.maxPriceDescuento}</span>
                                        </div>
                                    </>
                                ) : (
                                    props.minPriceDescuento === props.minPrice && props.maxPriceDescuento !== props.maxPrice ? (
                                        <>
                                            <div className="price_wrapper">
                                                <span className="tachado">S/ {props.maxPrice}</span>
                                            </div>
                                            <div className="price_wrapper">
                                                <span className="price_normal">S/ {props.minPriceDescuento} - S/ {props.maxPriceDescuento}</span>
                                            </div>
                                        </>
                                    ) : (
                                        props.minPriceDescuento != props.minPrice && props.maxPriceDescuento !== props.maxPrice ? (
                                            <>
                                                <div className="price_wrapper">
                                                    <span className="tachado">S/ {props.minPrice}</span><span className="tachado">- S/ {props.maxPrice}</span>
                                                </div>
                                                <div className="price_wrapper">
                                                    <span className="price_normal">S/ {props.minPriceDescuento} - S/ {props.maxPriceDescuento}</span>
                                                </div>
                                            </>
                                        ) : (
                                            <>                                                
                                                <div className="price_wrapper">
                                                    <span className="price_normal">S/ {props.minPrice} - S/ {props.maxPrice}</span>
                                                </div>
                                            </>
                                        )
                                    )
                                )
                            )
                        ) : (
                            props.minPriceDescuento != props.minPrice ? (
                                <>
                                    <div className="price_wrapper">
                                        <span className="tachado-2">S/ {props.minPrice}</span> 
                                        <span className="price_normal">S/ {props.minPriceDescuento}</span>
                                    </div>
                                </>
                            ): (
                                <>
                                <div className="price_wrapper">
                                        <span className="price_normal">S/ {props.minPriceDescuento}</span>
                                    </div>
                                </>
                                )
                        )}
                    </div>               
                </div>
                {screen == true ? (
                    <div className="col cont_button_product">
                    <a className="button_product open-details-product" onClick={(e)=>props.onOpenPreview(e,props.idProduct, props.idPresentation)}>Vista rápida</a>
                    </div>
                ):
                (
                    <div></div>
                )
                
                }             
                

            </div>
            {props.percentageDiscount !=0 &&
                <div className="dscto_cont d-flex justify-content-center align-items-center">{props.percentageDiscount}% Dscto</div>    
            }
            {props.promo > 0 &&
                <div className="dscto_cont d-flex justify-content-center align-items-center">Promo: {props.promo == 1 ? '2x1': '3x2'}</div>    
            }
                
            
        </div>
    )
}

export default Product;