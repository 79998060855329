import React from 'react';
import "./style.css";
import BackLayout from "../../../components/layout/BackLayout/BackLayout";
import StarRating from "../../../components/starRating/StarRating";
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-js-pagination';
import { useState, useCallback, useEffect } from 'react';
import ModalConfirm from "../../../components/confirm/ModalConfirm";
import ModalAgregarTestimonio from './modalAgregarTestimonio';
import ModalEditarTestimonio from './modalEditarTestimonio';
import {TestimonialService} from '../../../business/TestimonialService';

let filtersDefault = {
    author: '',
    rating: '',

}
let confirmDefault = {
    id:0,
    author: '',
    show: false
}


function ListTestimonio (){
    const [data, setData] = useState<any>({data: []});
    const [selectedTestimonial, setSelectedTestimonial] = useState(0);
    const [filters, setFilters] = useState(filtersDefault);
    const [loading, setLoading] = useState(false);
    const [confirmData, setConfirmData] = useState(confirmDefault);

    const [showAgregarTestimonial, setShow] = useState(false);
    const handleShowAgregarTestimonial = () => setShow(true);
    const handleCloseAgregarTestimonial = () => 
    {
        setShow(false);
        getTestimonials(1);    
    }

    const handleReopenAgregarTestimonial = () => {
        setShow(false);
        setShow(true);
    }

    const [showEditarTestimonial, setshowEditarTestimonial] = useState(false);
    const handleShowEditarTestimonial = (e:any, id:number) => 
    {
        e.preventDefault();
        setSelectedTestimonial(id);
        
    }
    const handleCloseEditarTestimonial = () => {
        setSelectedTestimonial(0);
        setshowEditarTestimonial(false);
        getTestimonials(data.current_page);  
    }


    useEffect(() => {      
        getTestimonials(1);    
      }, []);

    useEffect(() => {      
    if(selectedTestimonial> 0)    
    {
        setshowEditarTestimonial(true);
    }
    }, [selectedTestimonial]);

    const getTestimonials = async (pageNumber:number) => { 
        const getInstance = TestimonialService.getInstance();
        setLoading(true);

        await getInstance.getTestimonials({page: pageNumber, author: filters.author, rating: filters.rating})
            .then((response:any) => {
                
                    if(response.success === true)
                    {
                        setData(response.data);

                    }
                    setLoading(false);
                    
                })
            .catch((err:any) => {
                setLoading(false);
            });

    }

    const deleteTestimonial = async (id:number) => { 
        const getInstance = TestimonialService.getInstance();
        setLoading(true);

        await getInstance.deleteTestimonial({id:id})
            .then((response:any) => {
                
                    if(response.success === true)
                    {
                        getTestimonials(data.current_page); 

                    }
                    setLoading(false);
                    
                })
            .catch((err:any) => {
                setLoading(false);
            });

    }

    const handlePageChange = (pageNumber:any) => {
        getTestimonials(pageNumber);
    }

    const handleSearch = (e:any) => {
        getTestimonials(1);
    }

    const handleChangeFilter= (event: any) => {
    
        const { name, value} = event.target;
        setFilters({
            ...filters,
            [name]: value
          });      
        
    };

    const handleClose= (event: any) => {
    
        setConfirmData({
            ...confirmData,
            id: 0,
            author: "",
            show: false
        })    
        
    };

    const handleDeleteTestimonial = (event:any, id:number) => {
        setConfirmData({
            ...confirmData,
            id: 0,
            author: "",
            show: false
        });
        deleteTestimonial(id);
    }


    return (
        <BackLayout>
        <div className='fond_backoffice'>
            <Container>                
            <div className='cont_title'>
                <h1>Web Testimonios</h1>
            </div>
            <div className='cont_data'>
                <Container className='cont_filters'>
                    <Row className='row_filter'>
                        <Col md={4} className="align-items-center txt_ali dis_fle">
                            <Col md={3}>
                                <label htmlFor="author">Buscar:</label>
                            </Col>
                            <Col md={9}>   
                            <input name="author" type="search" className='form-control' placeholder='Autor' value={filters.author} onChange={(e)=>handleChangeFilter(e)}/>
                            </Col>
                        </Col>
                        <Col md={4} className="align-items-center txt_ali dis_fle">
                            <Col md={3}>
                            <label htmlFor="rating">Calificación:</label>
                            </Col>
                            <Col md={9}>
                                <select name="rating" id="" className='form-control' value={filters.rating} onChange={(e)=>handleChangeFilter(e)}>
                                    <option value={0}>Seleccione</option>
                                    <option value={1}>1 estrella</option>
                                    <option value={2}>2 estrellas</option>
                                    <option value={3}>3 estrellas</option>
                                    <option value={4}>4 estrellas</option>
                                    <option value={5}>5 estrellas</option>
                                </select>
                            </Col>
                        </Col>                       
                        <Col md={2} className="align-items-center txt_ali dis_fle">
                            <Button className='btn_filter_search' type="button" onClick={(e)=>handleSearch(e)}>Buscar</Button>
                        </Col>
                        <Col md={2} className="align-items-center txt_ali dis_fle">
                            <Button className='btn_export_excel'  onClick={handleShowAgregarTestimonial}>Agregar</Button>
                        </Col>
                    </Row>
                </Container>
                <Table responsive className='table_backoffice'>
                <thead>
                    <tr>
                        <th>Descripción</th>
                        <th>Autor</th>
                        <th style={{minWidth:'150px', textAlign:'center'}}>Calificación</th> 
                        <th>Visible</th>               
                        <th>Editar</th>
                        <th>Eliminar</th>
                    </tr>
                </thead>
                <tbody>
                    {data.data.map((item:any) => {
                        return (    
                    <tr>
                        <td>{item.descripcion}</td>
                        <td>{item.nombre}</td>
                        <td style={{minWidth:'150px', textAlign:'center'}}><StarRating value={item.evaluacion} readonly={true}/></td>
                        <td>{item.visible==1 ? 'Visible': 'Oculto'}</td>
                        <td className='text_icon'>
                            <a className='cur_point' onClick={(e) => handleShowEditarTestimonial(e, item.id_testim)}>
                                <div className='cont_ver_detalle'>                                
                                    <img src={process.env.REACT_APP_PUBLIC_URL + 'images/common/edit.webp'} alt="Editar" />
                                </div>
                            </a>
                        </td>
                        <td className='text_icon'>
                            <a className="delete-cupon cur_point" onClick={(e) => handleDeleteTestimonial(e, item.id_testim)}>
                                <div className='cont_ver_detalle'>                                
                                    <img src={process.env.REACT_APP_PUBLIC_URL + 'images/common/trush.webp'} alt="Eliminar" />
                                </div>
                            </a>
                        </td>
                       
                    </tr>
                    )
                })}
                </tbody>
                </Table>
                <div className='pagination_back'>                    
                {data.total > 0 && (<Pagination
                    activePage={data.current_page}
                    itemsCountPerPage={data.per_page}
                    totalItemsCount={data.total}
                    pageRangeDisplayed={5}
                    onChange={(e) => handlePageChange(e)}
                    innerClass={"pagination"}
                    itemClass={"page-item"}
                    linkClass={"page-link"}
                    />)}

                </div>
            </div>
            </Container>
            <ModalConfirm text="" handleClose={handleClose} show={confirmData?.show} handleSave={handleDeleteTestimonial} action={"Estás seguro de eliminar el testimonio"} id={confirmData?.id}/>
            <ModalAgregarTestimonio show={showAgregarTestimonial} handleClose={handleCloseAgregarTestimonial} handleOpen={handleReopenAgregarTestimonial}/>  
            {selectedTestimonial > 0 && (<ModalEditarTestimonio show={showEditarTestimonial} handleClose={handleCloseEditarTestimonial} idTestimonio={selectedTestimonial}/> )}
        </div>
        </BackLayout>
)};
export default ListTestimonio;
