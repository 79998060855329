import React from 'react';
import "./membresia.css";
import BannerParallax from '../../components/bannerParallax/bannerParallax';
import { Container, Row, Col } from 'react-bootstrap';
import MainLayout from "../../components/layout/MainLayout/MainLayout";

function Membresia (){
    document.title = "ORIGO: Membresía";
    document.getElementsByTagName('meta')[3].content = "ORIGO: Membresía";
    
    return (
    <MainLayout>
    <div className='pad_initial membresia_origo'>
        <BannerParallax 
            img={process.env.REACT_APP_PUBLIC_URL + "images/banners/parallax.webp"}
            clase=""
            text= "MEMBRESÍA ORIGO"
        ></BannerParallax>
       <div className='section_plan_membresia'>
        <Container>
            <Row>
                <Col md={6} className='cont_plan_membresia'>
                    <div className="cont_text_plan_membresia">                        
                        <div className='cont_title_plan_membresia'>
                            <b>Suscríbete a un plan</b><br></br> de alimentación y accede a<br></br> una membresía origo
                        </div>
                        <div className='cont_list_plan_membresia'>
                            <ul className='list_plan_membresia'>
                                <li className='element_list_plan_emmebresia'>Plan de alimentación <b>personalizado.</b></li>
                                <li className='element_list_plan_emmebresia'>Envíos a domicilio <b>sin costo.</b></li>
                                <li className='element_list_plan_emmebresia'>Asesoría nutricional - veterinaria <b>gratuita.</b></li>
                                <li className='element_list_plan_emmebresia'><b>Descuentos especiales</b> con nuestros partners.</li> 
                            </ul>
                        </div>
                    </div>
                </Col>
                <Col md={6} className="cont_img_plan_membresia">
                    <div className='cont_img_pla_membresia'>
                        <img className='img_pl_membresia' src={process.env.REACT_APP_PUBLIC_URL + "images/membresia/membresia1.png"} alt="" />
                    </div>
                </Col>
            </Row>
            <Row className='pad_top_2'>
                <div className="align-items-center">
                    <div className='cont_btn'>
                        <a className="btn_origo" href="/tienda?categ=deshidratados">
                            
                            ¡EMPIEZA HOY!</a> 
                    </div>
                </div>
            </Row>
        </Container>
       </div>
       <div className='section_plan_incluye'>
            <div className='cont_title_plan_incluye'>
                <div className='title_plan_incluye'>¿Qué incluye mi <b>Membresía Origo?</b></div>
            </div>
            <Container className='cont_card_membresia'>
                <Row>
                    <Col md={5}>
                        <div className='card_membresia'>
                            <div className='cont_img_card_plan_incluye'>
                                <img className='img_card_membresia_principal' src={process.env.REACT_APP_PUBLIC_URL + "images/membresia/alimentacion.png"} alt=""/>
                            </div>
                            <div className='cont_title_car_membresia'><h1>Alimentación natural</h1></div>
                            <p className='parrafo_card_membresia'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse leo, auctor in dolor vitae, tempor lacinia justo. Vivamus ante mauris, vulputate sit amet viverra. Ipsum dolor sit amet, elit leo consectetur adipiscing elit leo. Suspendisse leo, auctor in dolor vitae, tempor lacinia justo</p>
                            <a href="" className='color_origo'><b>VER MÁS</b></a>
                        </div>
                    </Col>
                    <Col md={7}>
                        <Col md={12}>
                            <div className='card_membresia_dos'>
                                <Row>
                                    <Col md={3}>
                                        <div className='cont_img_card_plan_incluye'>
                                            <img className='img_card_membresia' src={process.env.REACT_APP_PUBLIC_URL + "images/membresia/seguro.png"} alt=""/>
                                        </div>
                                    </Col>
                                    <Col md={9} className='text_card_2'>
                                        <h2>Seguro de salud y contra accidentes</h2>
                                        <p className='parrafo_card_membresia_2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse leo, auctor in dolor vitae, tempor lacinia justo. Vivamus ante mauris, vulputate sit amet viverra</p>
                                        <a href="" className='color_origo'><b>VER MÁS</b></a>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col md={12} className='mrg_top_2'>
                            <div className='card_membresia_dos'>
                                <Row>
                                    <Col md={3}>
                                        <div className='cont_img_card_plan_incluye'>
                                            <img className='img_card_membresia' src={process.env.REACT_APP_PUBLIC_URL + "images/membresia/asesoria.png"} alt=""/>
                                        </div>
                                    </Col>
                                    <Col md={9} className='text_card_2'>
                                        <h2>Asesoría nutricional /veterinaria gratuita</h2>
                                        <p className='parrafo_card_membresia_2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse leo, auctor in dolor vitae, tempor lacinia justo. Vivamus ante mauris, vulputate sit amet viverra</p>
                                        <a href="" className='color_origo'><b>VER MÁS</b></a>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Col>
                </Row>
            </Container>
       </div>
       <div className='section_plan_membresia'>
            <Container>
                <Row>
                    <Col md={4}>
                        <div className='cont_membresia_option'>
                            <div className='cont_img_card_plan_incluye'>
                                <img className='img_card_membresia_3' src={process.env.REACT_APP_PUBLIC_URL + "images/membresia/descuento.png"} alt=""/>
                            </div>
                            <div>
                                <div className='cont_title_car_membresia'><h3>Descuentos y <br></br>beneficios exclusivos</h3></div>
                                <p className='parrafo_card_membresia_2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse leo, auctor in dolor vitae, tempor lacinia justo. Vivamus ante mauris, vulputate sit amet viverra. Ipsum dolor sit amet</p>
                                <a href="" className='color_origo'><b>VER MÁS</b></a>
                            </div>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className='cont_membresia_option'>
                            <div className='cont_img_card_plan_incluye'>
                                <img className='img_card_membresia_3' src={process.env.REACT_APP_PUBLIC_URL + "images/membresia/envio.png"} alt=""/>
                            </div>
                            <div>
                                <div className='cont_title_car_membresia'><h3>Envio a domicilio <br></br>gratis</h3></div>
                                <p className='parrafo_card_membresia_2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse leo, auctor in dolor vitae, tempor lacinia justo. Vivamus ante mauris, vulputate sit amet viverra. Ipsum dolor sit amet</p>
                                <a href="" className='color_origo'><b>VER MÁS</b></a>
                            </div>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className='cont_membresia_option'>
                            <div className='cont_img_card_plan_incluye'>
                                <img className='img_card_membresia_3' src={process.env.REACT_APP_PUBLIC_URL + "images/membresia/seguro.png"} alt=""/>
                            </div>
                            <div>
                                <div className='cont_title_car_membresia'><h3>Historia clínica <br></br>virtual de tu mascota</h3></div>
                                <p className='parrafo_card_membresia_2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse leo, auctor in dolor vitae, tempor lacinia justo. Vivamus ante mauris, vulputate sit amet viverra. Ipsum dolor sit amet</p>
                                <a href="" className='color_origo'><b>VER MÁS</b></a>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className='pad_top_2'>
                <div className="align-items-center">
                    <div className='cont_btn'>
                        <a className="btn_origo" href="/tienda?categ=deshidratados">
                            
                            ¡EMPIEZA HOY!</a> 
                    </div>
                </div>
            </Row>
            </Container>
        </div>
        
        
    </div>
    </MainLayout>
)};
export default Membresia;