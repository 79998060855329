import React, { CSSProperties } from 'react';
import { Button, Container, Nav, Navbar as NavbarBs, NavItem
  , Row , Col ,NavDropdown, NavLink, DropdownButton, Dropdown
} from "react-bootstrap"
import { useShoppingCart } from "../../context/ShoppingCartContext"
import Marquee from "../anuncio/marquee"
import './style.css';
import {useState, useEffect} from 'react';
import {IndexService} from '../../business/IndexService';
import LoginOption from '../login/loginOption';
import {AuthService} from '../../business/AuthService';



const contactToggle = React.forwardRef(({ children, onClick }:any, ref:any) => (
  <a
    href=""
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
   
  >
   
    {children}
  </a>
));

export interface StylesDictionary{
  [Key: string]: CSSProperties;
}

function Navbar() {
  const { openCart, cartQuantity } = useShoppingCart()
  const [show, setShow] = useState(false);
  const [scroll, setScroll] = useState('');
  const [active, setActive] = useState('');
  const [notes, setNotes] = useState<any>([]);
  const [objectStyle, setObjectStyle] = useState<any>({});
  const [fechingNotes, setFetchingNotes] = useState(false);

  const showDropdown = (e:any)=>{
      setShow(!show);
  }
  const hideDropdown = (e:any) => {
      setShow(false);
  }
  let ruta = document.location.pathname.split('/').slice(0,3).join('/');

  

  useEffect(() => {      
    getNotes();
  }, []);

 
  let objectStyles = {
    top: '0px', 
    transition: 'all 500ms ease 0s'
 } as React.CSSProperties;

  window.addEventListener('scroll', () => {
    let activeClass = '';

    if(window.scrollY > 20){
        activeClass = 'pos_fix';
        objectStyles = {
          top: '-2.3rem', transition: 'all 500ms ease 0s'
          
        }

    }
    else{
      activeClass = '';
      objectStyles = {
        top: '0px', 
        transition: 'all 500ms ease 0s'
        
      }
    

    }
    setActive( activeClass );
    setObjectStyle( objectStyles );
  });
  

  const getNotes = async () => { 
    const getInstance = IndexService.getInstance();
    setFetchingNotes(true);

    await getInstance.getNotes()
        .then((response:any) => {
              
          
              if(response.success === true)
              {
                let notes = [];
                notes.push(response.notes.mensaje_uno);
                notes.push(response.notes.mensaje_dos);
                setNotes(notes);

              }
              else{
                
              }
              setFetchingNotes(false);
            }   
          )
        .catch((err:any) => {
          setFetchingNotes(false);
        });

    }

  const getLogout = async () => { 
    const getInstance = AuthService.getInstance();

    await getInstance.logout()
        .then((response:any) => {
              localStorage.removeItem('token');
              localStorage.removeItem('rol');
              localStorage.removeItem('name');
              localStorage.removeItem('ucode');
              localStorage.removeItem('promoCode');
              localStorage.removeItem('te');
              setTimeout(() => {
                window.location.href = '/'
              }, 1000);
            }   
          )
        .catch((err:any) => {
          localStorage.removeItem('token');
              localStorage.removeItem('rol');
              localStorage.removeItem('name');
              localStorage.removeItem('ucode');
              localStorage.removeItem('promoCode');
              localStorage.removeItem('te');
              setTimeout(() => {
                window.location.href = '/'
              }, 1000);
        });

  }

  const logout = ()=>{
    getLogout();
  }
  
  return (
    <>
   <div className={`cont_navbar ${active}`} style={objectStyle}>
    {notes.length > 0 ? <Marquee notes={notes} message1={notes[0]} message2={notes[1]}/> : <div  style={{backgroundColor: '#ff6f5d'
            , width:'100%'
            , padding: '5px 0px'
            , minHeight: '2.2rem'}}></div>}
    <NavbarBs collapseOnSelect expand="lg" className={`mb-3 navbar-st `} >
      <Container className="style_cont_resp container-fluid">
          <div>
            <NavbarBs.Brand href="/" className=''>
                <img
                  alt="Logo Origo"
                  src={process.env.REACT_APP_PUBLIC_URL + 'images/common/logo_origo.webp'}                  
                  className="sticky-logo align-top"
                />    
            </NavbarBs.Brand> 
            <div className='display_responsive' style={{ position: "absolute", right: "1rem" }}>
            <LoginOption logout={logout}/> 
            <Button
                onClick={openCart}
                style={{ width: "2.8rem", height: "2.8rem", position: "relative", padding: "0.3rem" }}
                variant="outline-primary"
                className="btn-grey"
              >
                <img
                  alt="ShopCart"
                  src={process.env.REACT_APP_PUBLIC_URL + 'images/common/cart.webp'}
                  
                />
                

                <div
                  className="rounded-circle bg-origo d-flex justify-content-center align-items-center"
                  style={{
                    color: "white",
                    width: "1.5rem",
                    height: "1.5rem",
                    position: "absolute",
                    top: 0,
                    right: 0,
                    transform: "translate(25%, 0%)",
                  }}
                >
                {cartQuantity}
              </div>
            </Button>  
          </div>                 
          </div>          
        <NavbarBs.Toggle aria-controls="responsive-navbar-nav" />        
        <NavbarBs.Collapse id="responsive-navbar-nav">
          <Nav className="m-auto justify-content-center navs" variant="tabs" defaultActiveKey={ruta}>
            <NavItem className="display_responsive">
            <div className="title_menu_responsive">Menu</div><hr></hr>
            <a  className="btn-plan" href="/tienda" style={{ display: "none"}}>
              {/* style={{ display: "none"}} */}
                  ¡INICIA HOY!
            </a> 
            {/* <Button
                  
                  variant="outline-primary"
                  className="btn-plan"
                >
                  ¡CREA SU PLAN!
            </Button>  */}
            </NavItem>
            <NavItem >
              <Nav.Link href="/">HOME</Nav.Link>
            </NavItem>
            <NavItem>
              <NavDropdown onToggle={() => { window.location.href = '/tienda'}} title="TIENDA" id="tiendaDropdown" active={ruta == '/tienda'}
              show={show}
              onMouseEnter={showDropdown} 
              onMouseLeave={hideDropdown}             
            >

              <ul className="cont_list_categorias">
                    <li className="list_categorias">
                            <div className="dropdown-item">
                              <a className="dropdown-item-2 mayus_link_t" href={process.env.REACT_APP_PUBLIC_URL + "tienda?categ=alimentocompleto"}>Alimento completo</a>
                            </div>
                    </li>
                    <li className="list_categorias">
                            <div className="dropdown-item">
                              <a className="dropdown-item-2 mayus_link_t" href={process.env.REACT_APP_PUBLIC_URL + "tienda?categ=treats"}>Treats</a>
                            </div>
                    </li>
                    <li className="list_categorias">
                            <div className="dropdown-item">
                              <a className="dropdown-item-2 mayus_link_t" href={process.env.REACT_APP_PUBLIC_URL + "tienda?categ=masticables"}>Masticables</a>
                            </div>
                    </li>
                    <li className="list_categorias">
                            <div className="dropdown-item">
                              <a className="dropdown-item-2 mayus_link_t" href={process.env.REACT_APP_PUBLIC_URL + "tienda?categ=suplementoscbd"}>Suplementos y CBD</a>
                            </div>
                    </li>
                    <li className="list_categorias">
                            <div className="dropdown-item">
                              <a className="dropdown-item-2 mayus_link_t" href={process.env.REACT_APP_PUBLIC_URL + "tienda?categ=promociones"}>Promociones</a>
                            </div>
                    </li>
              </ul>
              
              </NavDropdown>
            </NavItem>
            {/* <NavItem >
              <Nav.Link href="/membresia-origo">MEMBRESÍA</Nav.Link>
            </NavItem> */}
            <NavItem>
            <Nav.Link href="/calcular-racion" >
              CALCULAR RACIÓN
            </Nav.Link>
            </NavItem>
            <NavItem>
            <Nav.Link href="/nosotros" >
              NOSOTROS
            </Nav.Link>
            </NavItem>
            <NavItem>
            {/* <Nav.Link href="/embajadores" >
              Embajadores
            </Nav.Link> */}
            </NavItem>
            <NavItem>
              <Nav.Link href="/faq" >
                FAQ
              </Nav.Link>
            </NavItem>
          </Nav>
          <div className="justify-content-md-end" style={{textAlign: 'right', display: 'flex'}}>
            <a  className="btn-plan hidden_responsive" href="/tienda" style={{ display: "none"}}>
              {/* style={{ display: "none"}} */}
                  ¡INICIA HOY!
            </a>
            <div className='hidden_responsive'>
            <LoginOption logout={logout}/> 
            <Button
                onClick={openCart}
                style={{ width: "2.8rem", height: "2.8rem", position: "relative", padding: "0.3rem" }}
                variant="outline-primary"
                className="btn-grey"
              >
                <img
                  alt="ShopCart"
                  src={process.env.REACT_APP_PUBLIC_URL + 'images/common/cart.webp'}
                  
                />
                

                <div
                  className="rounded-circle bg-origo d-flex justify-content-center align-items-center"
                  style={{
                    color: "white",
                    width: "1.5rem",
                    height: "1.5rem",
                    position: "absolute",
                    top: 0,
                    right: 0,
                    transform: "translate(25%, 0%)",
                  }}
                >
                {cartQuantity}
              </div>
            </Button>  
            </div>             
           
          </div>

        </NavbarBs.Collapse>
      </Container>
    </NavbarBs>
    </div>
  </>
  )

}

export default Navbar;