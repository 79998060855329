import React from 'react';
import "./style.css";
import { Form, Container, Row, Col, Button,Spinner } from 'react-bootstrap';
import { useShoppingCart } from "../../context/ShoppingCartContext";
import { useState, useEffect } from "react"
import {AuthService} from '../../business/AuthService';
import * as Yup from 'yup';
import { yupToFormErrors } from "formik";
import MainLayout from "../../components/layout/MainLayout/MainLayout";

let defaultUser = {
    email: ""
    ,password: ""
    ,appToken:process.env.REACT_APP_TOKEN
    ,submit: ""
  }

  const SignInSchema = Yup.object().shape({
    email: Yup.string().required("*Ingrese su usuario"),
  
    password: Yup.string()
      .required("*Ingrese su contraseña")
      .min(6, "La contraseña es muy corta - Debe contener al menos 6 caracteres"),
  });

function Login2 (){

    const { closeCart, cartItems, isFrozen
        , hasRecurrentPurchase, hasRegularPurchase
        , isAuthenticated } = useShoppingCart();
    
        if(isAuthenticated)
        {
            window.location.href='/';
        }
    const [user, setUser] = useState(defaultUser);
    const [errors, setErrors] = useState<any>();
    const [validated, setValidated] = useState<any>();
    const [loading, setLoadingButton] = useState(false);

    const handleChangeData= (event: any) => {
        const { name, value} = event.target;
       
        let val = value;
        
        
        setUser({
            ...user,
            [name]:  event.target.type==='radio' ? event.target.checked : val
          });
         
        
        
      };

    const login = async () => { 

        setLoadingButton(true);
    const loginInstance = AuthService.getInstance();
  
    await loginInstance.login(user)
        .then((data:any) => {
            if(data){
                if(data.status=='success')
                {
                    setLoadingButton(false);
                    localStorage.setItem('token', data.authorisation.token)
                    localStorage.setItem('rol', data.role)
                    localStorage.setItem('name', data.name)
                    localStorage.setItem("te", JSON.stringify(Date.now()));
                    window.location.href='/redirect';
                }
                else{
                    setErrors({ submit: "No autorizado" });
                    setLoadingButton(false);
                }
                
                
            }
        
            
        })
        .catch((err) => {
            setLoadingButton(false);
            if(err.messages)
            {
                setErrors({ submit: err.messages[0].description });
            }
            else{
                setErrors({ submit: 'Hubo un error' });;
            }
        
            
            

        })
    }
    const handleSubmit = ()=>{
        SignInSchema.validate(user, { abortEarly: false }).then(function() {
            // Success
            login();
            setErrors([]);
        }).catch(function (err) {
            let errorArray = {};
            const errors = yupToFormErrors(err);
            setErrors(errors);
            console.log(errors);
            setValidated(true);
           

            
        });
    }

    return (
    <MainLayout>
    <div className='back_login'>  
      <Container>
        <Row className='justify-content-md-center cont-vertical-center'>
          <Col md={6} className='vertical-center'>
            <div className='back_two2 pad_two'>
                <Form id="form-usuario" validated={validated}>
                    <Row className="">
                        <Col md={12}>
                            <div className="section_login_form">
                                <div className="">                                    
                                {hasRecurrentPurchase == true && ( 
                                    <div className="alert-section">
                                        <p>*Debe iniciar sesión para realizar compras recurrentes</p>
                                    </div>
                                )}
                                    <h3>INGRESO DE USUARIO</h3>
                                    <p> Bienvenido(a), por favor completar los datos solicitados para poder ingresar.</p>
                                    <Container>
                                        <Row className='justify-content-md-center pad_lef_cal mr_t_1'>
                                            <Col md={2} className='cont_icon_login'>
                                                <img src={process.env.REACT_APP_PUBLIC_URL + "images/common/login-icon.webp"} alt="Login" />
                                            </Col>
                                            <Col md={10}>                        
                                                <Form.Group> 
                                                    <input type="text" required name='email' className='form-control' placeholder='Usuario' onChange={e => {handleChangeData(e)}}/>                                                    
                                                    {errors?.email != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.email}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>   
                                            </Col>
                                        </Row>
                                        <Row className='justify-content-md-center pad_lef_cal mr_t_1'>
                                            <Col md={2} className='cont_icon_login'>
                                                <img src={process.env.REACT_APP_PUBLIC_URL + "images/common/pass-icon.webp"} alt="Password" />
                                            </Col>
                                            <Col md={10}>                        
                                                <Form.Group> 
                                                    <input type="password" required name='password' className='form-control' placeholder='Password' onChange={e => {handleChangeData(e)}}/>                                                    
                                                    {errors?.password != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.password}
                                                        </Form.Control.Feedback>
                                                    )}
                                                    
                                                </Form.Group>   
                                            </Col>
                                        </Row>
                                        <Row className='justify-content-md-center mr_t_1'>
                                        {errors?.submit != "" && (
                                                        <span  style={{color:'red'}}>
                                                            {errors?.submit}
                                                        </span>
                                                    )}
                                        </Row>
                                        <Row className='justify-content-md-center mr_t_1 pad_top_1'>
                                            <Col md={11}>                                     
                                                <button className='btn_origo_login' type="button"  onClick={(e)=>handleSubmit()} disabled={loading}>
                                                    {loading ? (
                                                    <Spinner animation="border" role="status" style={{width: '1rem', height: '1rem'}}>
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Spinner>
                                                    ) : (
                                                    'INGRESAR'
                                                    )}
                                                </button>
                                            </Col>
                                        </Row>
                                        <Row className='justify-content-md-center mr_t_1'>
                                            <Col md={11}>
                                                <div className=''>
                                                    <a href="/registro" className='option_login_text'>¿No tienes una cuenta? Regístrate</a>
                                                </div>
                                                <div className=''>
                                                    <a href="/checkout" className='option_login_text'>Continuar como invitado</a>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div> 
          </Col>
        </Row>
      </Container>
    </div>
    </MainLayout>
)};

export default Login2;