import React, { CSSProperties } from 'react';
import { Button, Container, Nav, Navbar as NavbarBs, NavItem
  , Row , Col ,NavDropdown, NavLink, DropdownButton, Dropdown
} from "react-bootstrap";
import { useShoppingCart } from "../../context/ShoppingCartContext";
import './style.css';
import {useState, useEffect} from 'react';
import {IndexService} from '../../business/IndexService';
import LoginOption from '../login/loginOption';
import {AuthService} from '../../business/AuthService';

const contactToggle = React.forwardRef(({ children, onClick }:any, ref:any) => (
  <a
    href=""
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
   
  >
   
    {children}
  </a>
));

export interface StylesDictionary{
  [Key: string]: CSSProperties;
}

function NavbarBackoffice() {
  const { openCart, cartQuantity } = useShoppingCart()
  const [show, setShow] = useState(false);
  const [showSus, setShowSus] = useState(false);
  const [showProd, setShowProd] = useState(false);
  const [showUsu, setShowUsu] = useState(false);
  const [showWeb, setShowWeb] = useState(false);
  const [scroll, setScroll] = useState('');
  const [active, setActive] = useState('');
  const [notes, setNotes] = useState<any>([]);
  const [objectStyle, setObjectStyle] = useState<any>({});
  const [fechingNotes, setFetchingNotes] = useState(false);

  const showDropdown = (e:any)=>{
      setShow(!show);
  }
  const hideDropdown = (e:any) => {
      setShow(false);
  }
  const showDropdownSus = (e:any)=>{
    setShowSus(!showSus);
  }
  const hideDropdownSus = (e:any) => {
      setShowSus(false);
  }
  const showDropdownProd = (e:any)=>{
    setShowProd(!showProd);
  }
  const hideDropdownProd = (e:any) => {
      setShowProd(false);
  }
  const showDropdownUsu = (e:any)=>{
    setShowUsu(!showUsu);
  }
  const hideDropdownUsu = (e:any) => {
      setShowUsu(false);
  }
  const showDropdownWeb = (e:any)=>{
    setShowWeb(!showWeb);
  }
  const hideDropdownWeb = (e:any) => {
      setShowWeb(false);
  }
  let ruta = document.location.pathname.split('/').slice(0,3).join('/');
  

  useEffect(() => {      
    getNotes();
  }, []);


  const getNotes = async () => { 
    const getInstance = IndexService.getInstance();
    setFetchingNotes(true);

    await getInstance.getNotes()
        .then((response:any) => {
              
          
              if(response.success === true)
              {
                let notes = [];
                notes.push(response.notes.mensaje_uno);
                notes.push(response.notes.mensaje_dos);
                setNotes(notes);

              }
              else{
                
              }
              setFetchingNotes(false);
            }   
          )
        .catch((err:any) => {
          setFetchingNotes(false);
        });

    }

  const getLogout = async () => { 
    const getInstance = AuthService.getInstance();

    await getInstance.logout()
        .then((response:any) => {
              localStorage.removeItem('token');
              localStorage.removeItem('rol');
              localStorage.removeItem('name');
              localStorage.removeItem('ucode');
              localStorage.removeItem('promoCode');
              localStorage.removeItem('te');
              setTimeout(() => {
                window.location.href = '/'
              }, 3000);
            }   
          )
        .catch((err:any) => {
          localStorage.removeItem('token');
              localStorage.removeItem('rol');
              localStorage.removeItem('name');
              localStorage.removeItem('ucode');
              localStorage.removeItem('promoCode');
              localStorage.removeItem('te');
              setTimeout(() => {
                window.location.href = '/'
              }, 3000);
        });

  }

  const logout = ()=>{
    getLogout();
  }
  
  return (
    <>
   <div className='cont_navbar_backoffice'>
    <NavbarBs collapseOnSelect expand="lg" className='mb-3 navbar-st' >
      <Container className="style_cont_resp container-fluid">
        <div>
            <NavbarBs.Brand href="/" className=''>
                <img
                  alt="Logo Origo"
                  src={process.env.REACT_APP_PUBLIC_URL + 'images/common/logo_origo.webp'}                  
                  className="sticky-logo align-top"
                />
            </NavbarBs.Brand>
          </div>
        <NavbarBs.Toggle aria-controls="responsive-navbar-nav" />
        <NavbarBs.Collapse id="responsive-navbar-nav">
          <Nav className="nav_w navs" variant="tabs" defaultActiveKey={ruta}>
            <NavItem className="display_responsive">
            <div className="title_menu_responsive">Menu</div><hr></hr>
            </NavItem>
            <NavItem>
            <Nav.Link className='a_nav_back' href={"/admin/dashboard"}>
              DASHBOARD
            </Nav.Link>
            </NavItem>
            <NavItem>  
            <Nav.Link className='a_nav_back' href={"/admin/reporte-ventas"}>
              VENTAS
            </Nav.Link>
            </NavItem>          
             
            
            <NavItem>
              <NavDropdown className='a_nav_back' title="SUSCRIPCIONES" id="comprasDropdown" active={ruta == '/admin/suscripciones' || ruta == '/admin/adogtados'}
              show={showSus}
              onMouseEnter={showDropdownSus} 
              onMouseLeave={hideDropdownSus}             
            >

              <ul className="cont_list_categorias">
                    <li className="list_categorias">
                            <div className="dropdown-item">
                              <a className="dropdown-item-2 mayus_link_t" href={"/admin/suscripciones"}>Listado de Suscripciones</a>
                            </div>
                    </li>
                    {/* <li className="list_categorias">
                            <div className="dropdown-item">
                              <a className="dropdown-item-2 mayus_link_t" href={"/admin/adogtados"}>Adogtados</a>
                            </div>
                    </li> */}
                  
              </ul>
              
              </NavDropdown>
            </NavItem>
              
            <NavItem>
            <Nav.Link className='a_nav_back' href={"/admin/cupones"}>
              CUPONES
            </Nav.Link>
            </NavItem>
            <NavItem>
              <NavDropdown className='a_nav_back' title="PRODUCTOS" id="comprasDropdown" active={ruta == '/admin/productos' || ruta == '/admin/promociones'}
              show={showProd}
              onMouseEnter={showDropdownProd} 
              onMouseLeave={hideDropdownProd}             
            >
              <ul className="cont_list_categorias">
                  <li className="list_categorias">
                      <div className="dropdown-item">
                        <a className="dropdown-item-2 mayus_link_t" href={"/admin/productos"}>Listado de Productos</a>
                      </div>
                  </li>
                  <li className="list_categorias">
                      <div className="dropdown-item">
                        <a className="dropdown-item-2 mayus_link_t" href={"/admin/promociones"}>Promociones</a>
                      </div>
                  </li>
              </ul>              
              </NavDropdown>
            </NavItem>
            <NavItem>
              <NavDropdown className='a_nav_back' title="USUARIOS" id="comprasDropdown" active={ruta == '/admin/usuarios' || ruta == '/admin/recompensas'}
              show={showUsu}
              onMouseEnter={showDropdownUsu} 
              onMouseLeave={hideDropdownUsu}             
            >
              <ul className="cont_list_categorias">
                  <li className="list_categorias">
                      <div className="dropdown-item">
                        <a className="dropdown-item-2 mayus_link_t" href={"/admin/usuarios"}>Listado de Usuarios</a>
                      </div>
                  </li>
                  <li className="list_categorias">
                      <div className="dropdown-item">
                        <a className="dropdown-item-2 mayus_link_t" href={"/admin/recompensas"}>Recompensas</a>
                      </div>
                  </li>
              </ul>              
              </NavDropdown>
            </NavItem>
            <NavItem>
              <NavDropdown className='a_nav_back' title="WEB" id="comprasDropdown" active={ruta == '/admin/home' || ruta == '/admin/testimonios' || ruta == '/admin/faq'}
              show={showWeb}
              onMouseEnter={showDropdownWeb} 
              onMouseLeave={hideDropdownWeb}             
            >
              <ul className="cont_list_categorias">
                  <li className="list_categorias">
                      <div className="dropdown-item">
                        <a className="dropdown-item-2 mayus_link_t" href={"/admin/home"}>Home</a>
                      </div>
                  </li>
                  <li className="list_categorias">
                      <div className="dropdown-item">
                        <a className="dropdown-item-2 mayus_link_t" href={"/admin/testimonios"}>Testimonios</a>
                      </div>
                  </li>
                  <li className="list_categorias">
                      <div className="dropdown-item">
                        <a className="dropdown-item-2 mayus_link_t" href={"/admin/faq"}>Faq</a>
                      </div>
                  </li>
              </ul>              
              </NavDropdown>
            </NavItem>
          </Nav>
          <div className="justify-content-md-end" style={{textAlign: 'right'}}>
            <LoginOption logout={logout}/> 
            <Button
                onClick={openCart}
                style={{ width: "2.8rem", height: "2.8rem", position: "relative", padding: "0.3rem" }}
                variant="outline-primary"
                className="btn-grey"
              >
                <img
                  alt="ShopCart"
                  src={process.env.REACT_APP_PUBLIC_URL + 'images/common/cart.webp'}
                  
                />
                

                <div
                  className="rounded-circle bg-origo d-flex justify-content-center align-items-center"
                  style={{
                    color: "white",
                    width: "1.5rem",
                    height: "1.5rem",
                    position: "absolute",
                    top: 0,
                    right: 0,
                    transform: "translate(25%, 0%)",
                  }}
                >
                {cartQuantity}
              </div>
            </Button>
          </div>

        </NavbarBs.Collapse>
      </Container>
    </NavbarBs>
    </div>
  </>
  )

}

export default NavbarBackoffice;