import Carousel from 'react-bootstrap/Carousel';
import Button from "react-bootstrap/Button";
import './style.css'


function CarouselText() {
  return (
    <div className='pos_ab_content_cen'>
      <ol className='carousel-text'>
        <li className='carousel-text-item'>
          <h2 className="color-secondary">Calcula su ración</h2>
          <p>Utiliza nuestra calculadora para obtener su ración ideal según peso, edad y actividad física. <a href='/calcular-racion' className='link_a_calcula'>Calcula aquí</a></p>
        </li>
        <li className='carousel-text-item'>          
          <h2 className="color-secondary">Haz tu pedido hoy y recibe mañana</h2>
          <p>Nos encargamos de llevar tu pedido a la puerta de tu casa lo más rápido posible. Recuerda que puedes suscribirte a un plan de envíos recurrentes.</p>
        </li>
        <li className='carousel-text-item'>          
          <h2 className="color-secondary">Inicia el cambio</h2>
          <p>Descongela su ración y sírvelo a temperatura ambiente. Prepárate para ver a tu perro feliz y sano todos los días.</p>
        </li>
      </ol>
    </div>
  );
}

export default CarouselText;