import './style.css';
import { Row , Col} from "react-bootstrap"
  
  type ButtonFaqProps = {
    slug: string
    nombre: string
  }
  

function ButtonFaq(props: ButtonFaqProps){
  
    return (
        <a className="btn-faq" href={props.slug}>{props.nombre}</a>
    );
}
export default ButtonFaq;