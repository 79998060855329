import Modal from 'react-bootstrap/Modal';
import "./style.css";
import { Button, CloseButton, Form
    , Row , Col , Spinner
  } from "react-bootstrap";
import { useState , useEffect } from 'react';
import {UserService} from '../../../business/UserService';
import {TestimonialService} from '../../../business/TestimonialService';
import * as Yup from 'yup';
import { yupToFormErrors } from "formik";
import 'react-quill/dist/quill.snow.css';
import StarRating from 'components/starRating/StarRating';
import { Multiselect } from 'multiselect-react-dropdown';

type ModalEditarProps = {
    idTestimonio: number
    handleClose: any
    show?: boolean

}

const validSquema = Yup.object().shape({    
    cliente: Yup.number().required("*Campo obligatorio")
    ,descripcion: Yup.string().required("*Campo obligatorio")
    ,evaluacion: Yup.number().required("*Campo obligatorio")
 });

const initialValues: any = {
    idTestimonio: 0,
    cliente: 0,
    evaluacion: 0,
    descripcion: "",
    visible: 0,
    clientes: []
};

function ModalEditarTestimonio(props:ModalEditarProps) {
    const [data, setData] = useState(initialValues);
    const [errors, setErrors] = useState<any>();
    const [validated, setValidated] = useState<any>();
    const [loading, setLoadingButton] = useState(false);
    const [clients, setClients] = useState([]);

    useEffect(() => {      
        if(errors == null)
        {
            setValidated(false);
        }
        else{
            setValidated(true);
        }
        
      }, [errors]);

    useEffect(() => {    
    if(props.idTestimonio > 0)  {
        getTestimonial(props.idTestimonio)
    }
    
    
    }, [data.idTestimonio]);

    const getClients = async (search:string) => { 
        const getInstance = UserService.getInstance();

        await getInstance.getClientList({search: search})
            .then((response:any) => {
                
                    if(response.success === true)
                    {
                        setClients(response.clients);

                    }
                    
                })
            .catch((err:any) => {
            });

    }

    const getTestimonial = async (id:number) => { 
        
        const getInstance = TestimonialService.getInstance();
            
        await getInstance.getTestimonial({idTestimonio: id})
            .then((response:any) => {
                
                if(response.success === true)
                {               

                    if(response.data.clientes.length > 0)
                    {
                        setClients(response.data.clientes);
                    }
                    setData(response.data);
                    
                    
                }
                
                    
            })
            .catch((err:any) => {
                
                console.log(err);
               
            });
    
    }

    const updateTestimonial = async () => { 
        setLoadingButton(true);
        
        const getInstance = TestimonialService.getInstance();
            
        await getInstance.updateTestimonial(data)
            .then((response:any) => {
                setLoadingButton(false);
                if(response.success === true)
                {
                    handleClose();
                }        
            })
            .catch((err:any) => {
                setLoadingButton(false);
                           
            });
    
    }

    const handleSearchClient = (value:string) => {
        if(value.length > 3){
            getClients(value);
        }
    }
    
    const onSelectClient = (selectedList:any, selectedItem:any) => {
      
        setData({...data,
            ['cliente']: selectedItem.value
    });
    }

    const onRemoveClient = (selectedList:any, removedItem:any) => {
        setData({
            ...data,
            ['cliente']: 0
        });
    }

    const handleChangeRating = (rating: number) => {

        setData({
            ...data,
            ['evaluacion'] : rating
        });
        
      };

    const handleChangeData= (event: any) => {
        const { name, value} = event.target;
        let val = value;

        if(name==='seccion' || name==='visible')
        {
            val = parseInt(value);
        }

        setData({
            ...data,
            [name] : value
        });
        
      };

      const handleSave= (event: any) => {
        validSquema.validate(data, { abortEarly: false }).then(function() {
            // Success
            updateTestimonial();

            setErrors(null);
        }).catch(function (err) {
            let errorArray = {};
            const errors = yupToFormErrors(err);
            console.log(errors);
            setErrors(errors);
            setValidated(true);
           
        });
        
      }

      const handleClose = () => {
        setData(initialValues);
        props.handleClose();
      }
    
  return (
    <Modal id="modal_agregar_faq" className='modal_faq' show={props.show} onHide={props.handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header  className="close-modal">   
            <h3 className='title_modal_faq'>Editar Testimonio</h3>             
            <CloseButton variant="white" onClick={handleClose}/>
        </Modal.Header>        
        <Modal.Body>                
            <div className='cont_modal_faq'>
                <Form className='form_agregar_faq' validated={validated}>
                    <Form.Group className='row'>
                        <Col md={12}>
                            <Form.Label>Cliente</Form.Label>
                        </Col>
                        <Col md={12}>
                            {data.idTestimonio > 0 && (<Multiselect
                                options={clients} // Options to display in the dropdown
                                onSelect={onSelectClient} // Function will trigger on select event
                                onRemove={onRemoveClient} // Function will trigger on remove event
                                displayValue="text" // Property name to display in the dropdown options
                                onSearch={handleSearchClient}
                                placeholder='Elija un cliente'
                                selectionLimit={1}
                                selectedValues={data.clientes}
                                className={errors?.cliente != "" && errors?.cliente !==undefined && data.cliente == null ? 'invalid' : ""}
                                />)}
                            <Form.Check>
                            <Form.Check.Input 
                                required
                                checked={data.cliente > 0}
                                style={{display:'none' }}
                                        />
                                {errors?.cliente != "" && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors?.cliente}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Check>
                        </Col>
                    </Form.Group>
                    <Form.Group className='row'>
                        <Col md={12}>
                            <Form.Label>Descripción</Form.Label>
                        </Col>
                        <Col md={12}>
                            <Form.Control as="textarea" rows={5} id="descripcion" name="descripcion" className='w_100' required value={data.descripcion} onChange={e => {handleChangeData(e)}}/>
                            {errors?.descripcion != "" && (
                                <Form.Control.Feedback type="invalid">
                                    {errors?.descripcion}
                                </Form.Control.Feedback>
                            )}
                        </Col>
                    </Form.Group>
                    <Form.Group className='row'>
                        <Col md={12}>
                            <Form.Label>Valoración</Form.Label>
                        </Col>
                        <Col md={12}>
                            <StarRating value={data.evaluacion} onChange={handleChangeRating}/>
                            <Form.Control as="input" id="evaluacion" style={{display: 'none'}} name="evaluacion" className='w_100' required value={data.evaluacion}/>
                            {errors?.respuesta != "" && (
                                <Form.Control.Feedback type="invalid">
                                    {errors?.respuesta}
                                </Form.Control.Feedback>
                            )}
                        </Col>
                    </Form.Group>
                    <Form.Group className='row'>
                        <Col md={12}>
                            <Form.Label>Visibilidad</Form.Label>
                        </Col>
                        <Col md={12}>
                            <Form.Control as="select" name="visible" value={data.visible} onChange={e => {handleChangeData(e)}}>
                                <option value={0}>Oculto</option>
                                <option value={1}>Visible</option>
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    <div className='cont_modal_confirm'>
                        <Col md={12} className='dis_fle'>           
                            <Col md={6} className="con_btn_confirm">
                                <Button className='btn_confirm' type="button" onClick={(e)=>handleSave(e)} disabled={loading}>
                                    {loading ? (
                                    <Spinner animation="border" role="status" style={{width: '1rem', height: '1rem'}}>
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                    ) : (
                                    'Guardar'
                                    )}
                                </Button> 
                            </Col>
                            <Col md={6} className="con_btn_confirm">
                                <button className='btn_cancel'  type="button" onClick={handleClose}>Cancelar</button> 
                            </Col>                
                        </Col>    
                    </div>
                </Form>
            </div>
        </Modal.Body>
    </Modal>
  );
}

export default ModalEditarTestimonio;