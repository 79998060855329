import React from 'react';
import "./style.css";
import BannerParallax from '../../../components/bannerParallax/bannerParallax';
import { Container, Row, Col, Form, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-js-pagination';
import { useState, useCallback, useEffect } from 'react';
import BackLayout from "../../../components/layout/BackLayout/BackLayout";
import ModalConfirm from "../../../components/confirm/ModalConfirm";
import {SalesService} from '../../../business/SalesService';
import { formatNumber } from "../../../utils/formatCurrency";
import LoadingTable from "../../../components/skeletons/LoadingTable";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

let filtersDefault = {
    purchaseFrom: '',
    purchaseTo: '',
    idSalesType: '0',
    search: '',
    roles: ''

}

let confirmDefault = {
    idPedido:0,
    nroPedido: '',
    show: false
}

const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData:any, fileName:any) => {

    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);

  };
function ReporteVentas (){
    const [data, setData] = useState<any>({data: []});
    const [filters, setFilters] = useState(filtersDefault);
    const [loading, setLoading] = useState(false);
    const [confirmData, setConfirmData] = useState(confirmDefault);

    useEffect(() => {      
        getSales(1);     
      }, []);

    const getSales = async (pageNumber:number) => { 
        const getInstance = SalesService.getInstance();
        setLoading(true);

        await getInstance.getSales({page: pageNumber, purchaseFrom: filters.purchaseFrom, purchaseTo: filters.purchaseTo, idSalesType: filters.idSalesType, search: filters.search, roles: filters.roles})
            .then((response:any) => {
                
                    if(response.success === true)
                    {
                        setData(response.sales);

                    }
                    setLoading(false);
                    
                })
            .catch((err:any) => {
                setLoading(false);
            });

    }

    const exportSales = async () => { 
    
        const getInstance = SalesService.getInstance();
                          
        await getInstance.export({purchaseFrom: filters.purchaseFrom, purchaseTo: filters.purchaseTo})
        .then((data:any) => {
            if(data.success==true){
              let  clonexlsData = data.sales.map((col:any, index:number) => { return {
                "Nro. Pedido": col.nro_pedido,
                "Nombres": col.nombres,
                "Primer Apellido": col.primer_apellido,
                "Segundo Apellido": col.segundo_apellido,
                "SKUS": col.skus,
                "Fecha de compra": col.fecha_compra,
              }});
              exportToCSV(clonexlsData, 'reporteventas.xls');
            }   
            
        })
        .catch((err) => {
          console.log(err);
        });
    }
    
    const updatePaymentPurchase = async (id:number) => { 
        const getInstance = SalesService.getInstance();
       
        await getInstance.updatePaymentPurchase({id: id})
            .then((response:any) => {
                setConfirmData({
                    ...confirmData,
                    show: false,
                    idPedido: 0,
                    nroPedido: ''
                });
                getSales(data?.current_page > 0 ? data?.current_page : 1);
            })
            .catch((err:any) => {
                
            });

    }
    

    const handlePageChange = (pageNumber:any) => {
        getSales(pageNumber);
    }

    const handleSearch = (e:any) => {
        getSales(1);
    }

    const handleExport = (e:any) => {
        e.preventDefault();
        exportSales();
    }

    const chargePurchase = (e:any, idPedido:number, nroPedido:string) => {
        setConfirmData({show:true, idPedido: idPedido, nroPedido:nroPedido})
    }

    const handleChangeFilter= (event: any) => {
    
        const { name, value} = event.target;
        setFilters({
            ...filters,
            [name]: value
          });      
        
      };

    const handleClose= (event: any) => {
    
        setConfirmData({
            ...confirmData,
            show: false
        })    
        
    };

    const handleUpdatePayment = (event:any, id:number) => {
        setConfirmData({
            ...confirmData,
            show: false
        });
        updatePaymentPurchase(id);
    }

    return (
        <BackLayout>
        <div className='fond_backoffice'>
            <Container>                
            <div className='cont_title'>
                <h1>Reporte Ventas Online</h1>
            </div>
            <div className='cont_data'>
                <Container className='cont_filters'>
                    <Row className='row_filter'>
                        <Col md={6} className="align-items-center txt_ali dis_fle">
                            <Col md={2}>
                                <label htmlFor="buscar">Buscar:</label>
                            </Col>
                            <Col md={10}>   
                            <input name="search" type="search" className='form-control' placeholder='n° de pedido, comprador' value={filters.search} onChange={(e) => handleChangeFilter(e)}/>
                            </Col>
                        </Col>
                        <Col md={3} className="align-items-center txt_ali dis_fle">
                            <Col md={3}>
                                <label htmlFor="usuario">Usuario:</label>
                            </Col>
                            <Col md={9}>    
                                <Form.Control required as="select" name="roles" value={filters.roles} onChange={(e)=>handleChangeFilter(e)}>                              
                                <option value="">Seleccione</option>
                                    <option value="2">Veterinaria/Petshop</option>
                                    <option value="3">Influenciador</option>
                                    <option value="4">Regular</option>
                                </Form.Control>
                            </Col>
                        </Col>
                        
                        <Col md={2} className="align-items-center txt_ali dis_fle">
                            <Button type="button" className='btn_filter_search' onClick={(e)=>handleSearch(e)}>Buscar</Button>
                        </Col>
                        <Col md={3} className="align-items-center txt_ali dis_fle">
                            <Col md={4}>
                            <label htmlFor="purchaseFrom">F. Desde:</label>
                            </Col>
                            <Col md={8}>
                                <input name="purchaseFrom"type="date" className='form-control' value={filters.purchaseFrom} onChange={(e)=>handleChangeFilter(e)}/>
                            </Col>
                        </Col>
                        <Col md={3} className="align-items-center txt_ali dis_fle">
                            <Col md={4}>
                                <label htmlFor="purchaseTo">F. Hasta:</label>
                            </Col>
                            <Col md={8}>
                                <input name="purchaseTo" type="date" className='form-control' value={filters.purchaseTo} onChange={(e)=>handleChangeFilter(e)}/>
                            </Col>
                        </Col>
                        <Col md={3} className="align-items-center txt_ali dis_fle">
                            <Col md={3}>
                            <label htmlFor="tipo_venta">T. Venta:</label>
                            </Col>
                            <Col md={9}>
                                <Form.Control required as="select" name="idSalesType" value={filters.idSalesType} onChange={(e)=>handleChangeFilter(e)}>                              
                                    <option value="">Seleccione</option>
                                    <option value="1">Web</option>
                                    <option value="2">Física</option>
                                    <option value="3">Web - Autenticado</option>
                                    <option value="4">Suscripción</option>
                                </Form.Control>
                            </Col>
                        </Col>
                        <Col md={2} className="align-items-center txt_ali dis_fle">
                            <Button type="button" className='btn_export_excel' onClick={(e)=>handleExport(e)} >Exportar Excel</Button>
                        </Col>
                    </Row>
                </Container>
                <Table responsive className='table_backoffice'>
                <thead>
                    <tr>
                        <th>Detalle</th>
                        <th>N° Pedido</th>
                        <th>N° Comprobante</th>
                        <th>F.Compra</th>
                        <th>F.Entrega</th>  
                        <th>Tipo Venta</th>
                        <th>Estado</th>
                        <th>Tipo de usuario</th>                  
                        <th>Comprador</th>
                        <th>Influencer</th>
                        <th>OriPoints usados</th>
                        <th>Dscto</th>
                        <th>Costo envio</th>
                        <th>Total Venta</th>
                        <th>Comisión venta</th>
                        <th>Puntos ganados</th>
                        <th>Estado Entrega</th>
                        
                    </tr>
                </thead>
                {loading==true ? (
                        <LoadingTable rows={10} cols={17}/>
                    )
                    
                    :
                    (<tbody>
                    
                    {data.data.map((item:any) => {
                        return (    
                    <tr key={item.id_pedido}>
                    
                        <td className='text_icon'>
                            <a href={`/admin/reporte-ventas/detalle/${item.id_pedido}`}>
                                <div className='cont_ver_detalle'>                                
                                    <img src={process.env.REACT_APP_PUBLIC_URL + 'images/common/lupa.webp'} alt="Ver detalle" />
                                </div>
                            </a>
                        </td>
                        <th>{item.nro_pedido}</th>
                        <td>{item.nro_comprobante}</td>
                        <td>{item.fecha_compra}</td>
                        <td>{item.fecha_entrega}</td>
                        <td>{item.tipo_venta}</td>
                        <td>
                            <span className="label label-lg label-light-primary label-inline">
                                
                                {item.tiespe_id_tiespe == 4 ?
                                <a className="alert_payment" onClick={(e)=> chargePurchase(e, item.id_pedido, item.nro_pedido)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#F6BE00" className="bi bi-exclamation-triangle" viewBox="0 0 16 16">
                                    <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"/>
                                    <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"/>
                                    </svg>{item.estado}
                                </a> : (item.tiespe_id_tiespe==2 ? (<span className='d-flex'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#008000" className="bi bi-check-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                                            </svg>{item.estado}</span>) : (item.tiespe_id_tiespe==1 ? (<span className='d-flex'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clock" viewBox="0 0 16 16">
                                                        <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
                                                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
                                                        </svg>{item.estado}</span>) : item.estado))
                                }
                            </span>   
                        </td>
                        <td>{item.tipo_usuario}</td>
                        <td>{item.nombre_referido}</td>
                        {item.nombre_owner != null ? (
                            <td><OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip id="tooltip5">{item.nombre_owner}</Tooltip>}
                          >
                            {({ ref, ...props }) => (
                              <span {...props} ref={ref}>
                                {item.nombre_owner.substring(0, item.nombre_owner.indexOf(' '))}
                              </span>
                            )}
                          </OverlayTrigger></td>) : (<td></td>)}
                        <td>{formatNumber(item.creditos_usados)}</td>
                        <td>{formatNumber(item.total_descuento)}</td>
                        <td>{formatNumber(item.costo_envio)}</td>
                        <td>{formatNumber(item.total_pagado)}</td>
                        <td>{formatNumber(item.comision_venta)}</td>
                        <td>{item.puntos_ganados}</td>
                        <td>{item.vnombreestado}</td>                     

                    </tr>
                    )
                    })}
                    </tbody>)}
                </Table>
                <div className='pagination_back'>                    
                {data.total > 0 && (<Pagination
                    activePage={data.current_page}
                    itemsCountPerPage={data.per_page}
                    totalItemsCount={data.total}
                    pageRangeDisplayed={5}
                    onChange={(e) => handlePageChange(e)}
                    innerClass={"pagination"}
                    itemClass={"page-item"}
                    linkClass={"page-link"}
                    />)}

                </div>
            </div>
            </Container>
        </div>
        <ModalConfirm text="" handleClose={handleClose} show={confirmData?.show} handleSave={handleUpdatePayment} action={"¿Estás seguro de validar el pago del pedido n° " + confirmData?.nroPedido} id={confirmData?.idPedido}/>
        </BackLayout>
)};
export default ReporteVentas;
