import React from 'react';
import "../reporteVentas/style.css";
import BackLayout from "../../../components/layout/BackLayout/BackLayout";
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-js-pagination';
import { useState, useCallback, useEffect } from 'react';
import ModalConfirm from "../../../components/confirm/ModalConfirm";
import {ProductService} from '../../../business/ProductService';
import { formatNumber } from "../../../utils/formatCurrency";
import LoadingTable from "../../../components/skeletons/LoadingTable";


let filtersDefault = {
    search: '',
    idCategory: '0',
    idLine: '0'
}

let confirmDefault = {
    idProduct:0,
    name: '',
    show: false,
    status: 0
}



function Productos (){
    const [data, setData] = useState<any>({data: []});
    const [filters, setFilters] = useState(filtersDefault);
    const [loading, setLoading] = useState(false);
    const [confirmDataStatus, setConfirmDataStatus] = useState(confirmDefault);
    const [confirmDataPromo, setConfirmDataPromo] = useState(confirmDefault);
    const [confirmDataDelete, setConfirmDataDelete] = useState(confirmDefault);
    const [linea, setLinea] = useState([]);
    const [categorias, setCategorias] = useState([]);

    useEffect(() => {      
        getLinesList(); 
        getCategoryList();   
        getProducts(1);     
      }, []);

      const getLinesList = async () => { 
        const getInstance = ProductService.getInstance();
        setLoading(true);

        await getInstance.getLinesList()
            .then((response:any) => {
                
                    if(response.success === true)
                    {
                        setLinea(response.data);

                    }
                    setLoading(false);
                    
                })
            .catch((err:any) => {
                setLoading(false);
            });

    }

    const getCategoryList = async () => { 
        const getInstance = ProductService.getInstance();
        setLoading(true);

        await getInstance.getCategoryList()
            .then((response:any) => {
                
                    if(response.success === true)
                    {
                        setCategorias(response.data);

                    }
                    setLoading(false);
                    
                })
            .catch((err:any) => {
                setLoading(false);
            });

    }

    const getProducts = async (pageNumber:number) => { 
        const getInstance = ProductService.getInstance();
        setLoading(true);

        await getInstance.getProducts({page: pageNumber, search: filters.search, idLine: filters.idLine, idCategory: filters.idCategory})
            .then((response:any) => {
                
                    if(response.success === true)
                    {
                        setData(response.products);

                    }
                    setLoading(false);
                    
                })
            .catch((err:any) => {
                setLoading(false);
            });

    }

    const updateProductStatus = async (id:number, status:number) => { 
        const getInstance = ProductService.getInstance();
       
        await getInstance.updateProductStatus({id: id, status: status})
            .then((response:any) => {
                setConfirmDataStatus({
                    ...confirmDataStatus,
                    show: false,
                    idProduct: 0,
                    name: '',
                    status: 0
                });
                getProducts(data?.current_page > 0 ? data?.current_page : 1);
            })
            .catch((err:any) => {
                
            });

    }

    const updateProductPromo = async (id:number, status:number) => { 
        const getInstance = ProductService.getInstance();
       
        await getInstance.updateProductPromo({id: id, status: status})
            .then((response:any) => {
                setConfirmDataStatus({
                    ...confirmDataPromo,
                    show: false,
                    idProduct: 0,
                    name: '',
                    status: 0
                });
                getProducts(data?.current_page > 0 ? data?.current_page : 1);
            })
            .catch((err:any) => {
                
            });

    }

    const deleteProduct = async (id:number) => { 
        const getInstance = ProductService.getInstance();
       
        await getInstance.deleteProduct({id: id})
            .then((response:any) => {
                setConfirmDataStatus({
                    ...confirmDataDelete,
                    show: false,
                    idProduct: 0,
                    name: '',
                    status: 0
                });
                getProducts(data?.current_page > 0 ? data?.current_page : 1);
            })
            .catch((err:any) => {
                
            });

    }

    const handlePageChange = (pageNumber:any) => {
        getProducts(pageNumber);
    }

    const handleSearch = (e:any) => {
        getProducts(1);
    }

    const handleChangeFilter= (event: any) => {
    
    const { name, value} = event.target;
    setFilters({
        ...filters,
        [name]: value
        });      
    
    };

    const handleCloseStatus= (event: any) => {
    
        setConfirmDataStatus({
            ...confirmDataStatus,
            show: false
        })    
        
    };

    const handleChangeStatus= (e:any,id:number, name: string) => {
        setConfirmDataStatus({
            ...confirmDataStatus,
            show: true,
            idProduct: id,
            name: name,
            status: e.target.checked
        });
    }

    const handleUpdateStatus = (event:any) => {
      
        setConfirmDataStatus({
            ...confirmDataStatus,
            show: false
        });
        updateProductStatus(confirmDataStatus.idProduct, confirmDataStatus.status);
    }

    const handleClosePromo= (event: any) => {
    
        setConfirmDataPromo({
            ...confirmDataPromo,
            show: false
        })    
        
    };

    const handleChangePromo= (e:any,id:number, name: string) => {
        setConfirmDataPromo({
            ...confirmDataPromo,
            show: true,
            idProduct: id,
            name: name,
            status: e.target.checked
        });
    }

    const handleUpdatePromo = (event:any) => {
      
        setConfirmDataPromo({
            ...confirmDataPromo,
            show: false
        });
        updateProductPromo(confirmDataPromo.idProduct, confirmDataPromo.status);
    }

    const handleConfirmDelete = (e:any,id:number, name: string) => {
        setConfirmDataDelete({show:true, idProduct: id, name: name, status: 0})
    }

    const handleCloseDelete= (event: any) => {
    
        setConfirmDataDelete({
            ...confirmDataDelete,
            show: false
        })    
        
    };

    const handleDeleteProduct = (event:any, id:number) => {
        setConfirmDataDelete({
            ...confirmDataDelete,
            show: false
        });
        deleteProduct(id);
    }

    return (
        <BackLayout>
        <div className='fond_backoffice'>
            <Container>                
            <div className='cont_title'>
                <h1>Productos</h1>
            </div>
            <div className='cont_data'>
                <Container className='cont_filters'>
                    <Row className='row_filter'>
                        <Col md={10}></Col>
                        <Col md={2} className="align-items-center txt_ali dis_fle">
                            <a href={process.env.REACT_APP_PUBLIC_URL + 'admin/registrar-producto'} className='btn_export_excel btn'>Agregar Producto</a>
                        </Col> 
                    </Row>
                    <Row className='row_filter'>
                        <Col md={4} className="align-items-center txt_ali dis_fle">
                            <Col md={4}>
                                <Form.Label htmlFor="buscar">Buscar:</Form.Label>
                            </Col>
                            <Col md={8}> 
                                <Form.Control as="input" name="search" type="search" placeholder='producto' value={filters.search} onChange={(e) => handleChangeFilter(e)}></Form.Control> 
                            </Col>
                        </Col>
                        <Col md={3} className="align-items-center txt_ali dis_fle">
                            <Col md={4}>
                                <Form.Label>Línea:</Form.Label>
                            </Col>
                            <Col md={8}>
                            <Form.Control as="select" name="idLine" value={filters.idLine} onChange={(e) => handleChangeFilter(e)}>
                                <option value={0}>Seleccione</option>
                                {linea.map((item:any) => (
                                    <option key={item.id} value={item.id}>
                                        {item.name}   
                                    </option>
                                    ))} 
                            </Form.Control>   
                            </Col>
                        </Col>
                        <Col md={3} className="align-items-center txt_ali dis_fle">
                            <Col md={4}>
                                <Form.Label>Categoría:</Form.Label>
                            </Col>
                            <Col md={8}>
                                <Form.Control as="select" name="idCategory" value={filters.idCategory} onChange={(e) => handleChangeFilter(e)}>
                                    <option value={0}>Seleccione</option>
                                    {categorias.map((item:any) => (
                                    <option key={item.id} value={item.id}>
                                        {item.name}   
                                    </option>
                                    ))}   
                                </Form.Control> 
                            </Col>
                        </Col>
                        <Col md={2} className="align-items-center txt_ali dis_fle">
                        <Button className='btn_filter_search' onClick={(e)=>handleSearch(e)}>Buscar</Button>
                        </Col>  
                    </Row>
                    
                </Container>
                <Table responsive className='table_backoffice'>
                <thead>
                    <tr>
                        {/* <th>Ver detalle</th> */}
                        <th>Nombre</th>
                        <th>Línea</th>
                        <th>Categoría</th>
                        
                        <th>Editar</th>
                        <th>Estado
                        </th>
                        <th>Promocionar
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data.data.map((item:any) => {
                        return (    
                    <tr key={item.id_propre}>
                        <td className='text_left'>{item.nombre}</td>
                        <td className='text_icon'>{item.linea_prod}</td>
                        <td className='text_icon'>{item.categ_prod}</td>
                        <td className='text_icon'>
                            <a href={process.env.REACT_APP_PUBLIC_URL + 'admin/editar-producto/' + item.id_produc} >
                                <div className='cont_ver_detalle'>                                
                                    <img src={process.env.REACT_APP_PUBLIC_URL + 'images/common/edit.webp'} alt="Editar" />
                                </div>
                            </a>
                        </td>
                        <td className='text_icon'> 
                            <Form.Check className=''>
                                <Form.Check.Input name='estado' type="checkbox" checked={item.estado==1 ? true : false} onChange={(e) => handleChangeStatus(e, item.id_produc, item.nombre)}/>
                            </Form.Check>
                        </td>
                        <td className='text_icon'>
                            <Form.Check className=''>
                                <Form.Check.Input name='promo' type="checkbox" checked={item.promocion==1 ? true : false} onChange={(e) => handleChangePromo(e, item.id_produc, item.nombre)}/>
                            </Form.Check>
                        </td>
                        <td className='text_icon'>
                            <a className="delete-cupon cur_point" onClick={(e) => handleConfirmDelete(e, item.id_produc, item.nombre)}>
                                <div className='cont_ver_detalle'>                                
                                    <img src={process.env.REACT_APP_PUBLIC_URL + 'images/common/trush.webp'} alt="Eliminar" />
                                </div>
                            </a>
                        </td>
                    </tr>
                    )
                })}
                </tbody>
                </Table>
                <div className='pagination_back'>                    
                {data.total > 0 && (<Pagination
                    activePage={data.current_page}
                    itemsCountPerPage={data.per_page}
                    totalItemsCount={data.total}
                    pageRangeDisplayed={5}
                    onChange={(e) => handlePageChange(e)}
                    innerClass={"pagination"}
                    itemClass={"page-item"}
                    linkClass={"page-link"}
                    />)}

                </div>
            </div>
            </Container>
        </div>
        <ModalConfirm text="" handleClose={handleCloseStatus} show={confirmDataStatus?.show} handleSave={handleUpdateStatus} action={"¿Estás seguro de "+ (confirmDataStatus?.status == 0 ? "Desactivar" : "Activar") +" el producto " + confirmDataStatus?.name} id={confirmDataStatus?.idProduct}/>
        <ModalConfirm text="" handleClose={handleClosePromo} show={confirmDataPromo?.show} handleSave={handleUpdatePromo} action={"¿Estás seguro de "+ (confirmDataPromo?.status == 0 ? "Desactivar del " : "Activar en el ") +"home al producto " + confirmDataPromo?.name} id={confirmDataPromo?.idProduct}/>
        <ModalConfirm text="" handleClose={handleCloseDelete} show={confirmDataDelete?.show} handleSave={handleDeleteProduct} action={"¿Estás seguro de eliminar al producto " + confirmDataDelete?.name} id={confirmDataDelete?.idProduct}/>
        
    </BackLayout>
)};
export default Productos;
