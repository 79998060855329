import Modal from 'react-bootstrap/Modal';
import "./style.css";
import BoxNumberPD from '../producto/boxNumberPD';
import {formatMoney} from '../../utils/formatCurrency';
import { Button, CloseButton, Form
    , Row , Col 
  } from "react-bootstrap"
import {useState, useEffect} from 'react';
import { useShoppingCart } from "../../context/ShoppingCartContext"

type Presentation = {
    idPresentation: number
    name: string
    presentation: string
    price: number
    percentageDiscount: number
    normalPrice:number
    tipo_promo: number
    image: string
    onceBuy: boolean
}


type ProductProps = {
    selected: number
    url: string
    image: string
    idLine: number
    line: string
    name: string
    category: string
    presentations: any
    handleClose?: any
    show?: boolean
}

function PreviewProduct(props:ProductProps) {
    
    const [selectedPresentation, setSelectedPresentation] = useState(props.presentations[props.selected]);
    const [activeIndex, setActiveIndex] = useState(props.selected);
    const [typeBuy, setTypeBuy] = useState(1);
    const [quantity, setQuantity] = useState(1);
    const [periodicity, setPeriodicity] = useState(7);
    const {
        getItemQuantity,
        addItemToCart,
        increaseCartQuantity,
        decreaseCartQuantity,
        removeFromCart,
      } = useShoppingCart()

    useEffect(() => {
        
      }, [selectedPresentation])

  
    const changePresentation = (event:any, index: number) => {
        event.preventDefault();
       
        setSelectedPresentation(props.presentations[index])
        setActiveIndex(index);
        setTypeBuy(1);  
        
    }

    const changePeriodicity = (event:any) => {        
        setPeriodicity(event.target.value);      
    }

    const changeBuyType = (type:number) => {        
        setTypeBuy(type);    
        
    }

    const increaseQuantity = () => {    
        setQuantity(quantity+1);      
        
    }

    const decreaseQuantity = () => {    
        if(quantity>1)
        {
           setQuantity(quantity-1);  
        }
            
    }

    const addToCart = () => {    
       
        let objCart = {
            id: selectedPresentation.idPresentation
            ,name: selectedPresentation.name
            ,category: props.category
            ,idLine: props.idLine
            ,line: props.line
            ,price: selectedPresentation.price
            ,quantity: quantity
            ,percentageDiscount: selectedPresentation.percentageDiscount
            ,normalPrice:selectedPresentation.normalPrice
            ,image: props.image
            ,presentation:selectedPresentation.presentation
            ,type: typeBuy
            ,promo:selectedPresentation.tipo_promo
            ,total:0
            ,periodicity: typeBuy==1 ? 0 : periodicity
            ,canEdit: true
            ,onceBuy: selectedPresentation.percentageDiscount > 0 || selectedPresentation.tipo_promo > 0 ? true : false
        }     

        addItemToCart(objCart);
    }


  return (
    <Modal
      show={props.show} onHide={props.handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
         <Modal.Header  className="close-modal">
         <CloseButton variant="white" onClick={props.handleClose}/>
        </Modal.Header>
      <Modal.Body>
      <Row >
        <Col md={6} className='txt_center bg-grey'>
            <img className="img_tienda" src={selectedPresentation.image!=null && selectedPresentation.image!='' ? (process.env.REACT_APP_BASE_URL_BACK + selectedPresentation.image) : props.image} />
        </Col>
        <Col md={6} className='pad_tt2'>                
            {selectedPresentation.tipo_promo > 0 &&
                <div className="dscto_cont d-flex justify-content-center align-items-center">Promo: {selectedPresentation.tipo_promo == 1 ? '2x1': '3x2'}</div>    
            }
            <h4 className="title_product_modal">{selectedPresentation.name}</h4>
            <p className="subtitle_product_modal">{props.line + ' - ' + props.category}</p>
            {selectedPresentation.normalPrice != selectedPresentation.price 
            && (
                <span className="tachado">{formatMoney(selectedPresentation.normalPrice)}</span>)}
           
            <span className="price_product_modal">{formatMoney(selectedPresentation.price)}</span>
           
            {selectedPresentation.presentation != '' && ( 
            <p>
            Escoger presentación:
            </p>
             )}
             {selectedPresentation.presentation == '' && ( 
            <p>
            
            </p>
             )}
             {selectedPresentation.presentation != '' && ( 
            <Row >
            {
                props.presentations.map((item:Presentation, index:number) => (
                    <div  key={'boxprod'+index} className={'box-prod '+ (index==activeIndex ? 'active' : '')}
                        onClick = {(e) => changePresentation(e,index)}
                    >{item.presentation}</div>
                ))
            }
            </Row>
            )}
            <br></br> 
            <Form.Group>
                <Form.Check type='radio' className='container_input_boot' required style={{marginBottom: '5px'}}>
                    <Form.Check.Input defaultChecked={true} value={1}  type="radio"  name="buyType" checked={typeBuy==1 ? true : false} onChange={(e) => changeBuyType(1)}/>
                    <Form.Check.Label style={{paddingTop:0}} className="c3">
                        <div>Una sola compra (Delivery: Lunes a Sábado)</div>
                    </Form.Check.Label> 
                </Form.Check> 
                {selectedPresentation.onceBuy == false && ( 
                <Form.Check type='radio' className='container_input_boot' required style={{marginBottom: '5px'}}>
                    <Form.Check.Input defaultChecked={true} value={2}  type="radio"  name="buyType" checked={typeBuy==2 ? true : false} onChange={(e) => changeBuyType(2)}/>
                    <Form.Check.Label style={{paddingTop:0}} className="c3">
                        <div>Compra recurrente <span className='color-primary'>(+5% de descuento)</span></div>
                    </Form.Check.Label> 
                </Form.Check> 
                )}
                {/* <Form.Label className="container_input c3" style={{marginBottom: '5px'}}>                    
                    <input type="radio" className="" value="1" name="buyType" checked={typeBuy==1 ? true : false} onChange={(e) => changeBuyType(1)}/>
                    <div>Una sola compra (Delivery: Lunes a Sábado)</div>
                    <span className="radius" style={{top:'6px'}}></span>
                </Form.Label> */}
                {/* <Form.Label className="container_input" style={{marginBottom: '5px'}}>
                <input type="radio" className="" value="2" name="buyType" checked={typeBuy==2 ? true : false} onChange={(e) => changeBuyType(2)}/>
                <div>Compra recurrente <span className='color-primary'>(+5% de descuento)</span></div>
                <span className="radius" style={{top:'6px'}}></span>
                </Form.Label> */}
            </Form.Group>
            {typeBuy == 2 && ( 
                <Form.Group className='dis_fle' style={{alignItems: 'center'}}>
                <Form.Label style={{marginBottom: '0px'}}>Se envía cada: &nbsp;</Form.Label>
                <select id="periodicityPreview" className="form-control" 
                    style={{width:'30%'}} name="day" 
                    onChange={(e) => changePeriodicity(e)}
                >
                        {Array.from({ length: 24 }).map((it, index) => (
                                <option value={7 + index}>{7 + index} {'días'}</option>
                            ))}
                    </select>
                <span className="help-block"></span>
                </Form.Group>
            )}
                <br></br>
                <div className='dis_fle'>
                    <BoxNumberPD
                        quantity={quantity}
                        increaseQuantity={increaseQuantity}
                        decreaseQuantity={decreaseQuantity}
                    />&nbsp;
                    <button className="btn_origov2"
                        onClick={() => addToCart()}>
                        AÑADIR AL CARRO
                    </button> 
                </div>
            
        </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer style={{justifyContent: "center"}}>
        <a href={props.url} target='_blank' className="text_md">MÁS DETALLES</a> 
      </Modal.Footer>
    </Modal>
  );
}

export default PreviewProduct