import { api } from "../api";
import ActionType from "../utils/customTypes";
import Cookies from 'universal-cookie';

export class TypeService extends api {

    private static classInstance?: TypeService;
        
    constructor(token: string, type: ActionType) {
        super( process.env.REACT_APP_BASE_URL,token);    
    } 
     
    public static getInstance() {
        let token = localStorage.getItem("token");
        if (!this.classInstance) {
            this.classInstance = new TypeService(token!==undefined && token !== null ? token:'', 'GET');
        }
        
        return this.classInstance;
    }   
     
    public getFinantialEntities = () => this.instance.post('get-finantial-entities').then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    
    

}