import React from 'react';
import "./faq.css";
import { Row, Col } from 'react-bootstrap';
import ButtonFaq from '../../components/frontUtility/butonFaq';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';
import MainLayout from "../../components/layout/MainLayout/MainLayout";
import { useState, useCallback, useEffect } from 'react';
import {IndexService} from '../../business/IndexService';

const handleClickScroll = () => {
    const element = document.getElementById("root");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
}

function Faq (){
    document.title = "ORIGO: PREGUNTAS FRECUENTES";
    document.getElementsByTagName('meta')[3].content = "ORIGO: PREGUNTAS FRECUENTES";
    document.getElementsByTagName('meta')[4].content = "Resuelve aquí todas tus dudas o escríbenos al 940583169.";

    const [answers, setAnswers] = useState<any>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {      
        getAllFaq();    
      }, []);

    const getAllFaq = async () => { 
        const getInstance = IndexService.getInstance();
        setLoading(true);

        await getInstance.getAllFaq()
            .then((response:any) => {
                
                    if(response.success === true)
                    {
                        setAnswers(response.faqs);

                    }
                    setLoading(false);
                    
                })
            .catch((err:any) => {
                setLoading(false);
            });

    }

    return (
    <MainLayout>
    <div className='pad_initial'>
        <section className="section_faq bg-color bg-faq section_faq_styles" id="preguntas-faq">
        <img onClick={handleClickScroll} className="arrow-faq" src={process.env.REACT_APP_PUBLIC_URL + "images/common/arrow-origo-2.webp"} alt="Flecha"/>
            <Row className="vh100">
                <Col md={6} className="center-flex pdt-70-top">
                    <div className="content__box-faq">
                        <p className="title-faq">¡Hola! Dime,</p>
                        <p className="title-faq">¿sobre qué tienes <span className="esp_resp">dudas?</span></p>
                        {loading==true ? (<div className="box-faq">
                            <ButtonFaq slug={`#a`} nombre={''} />
                            <ButtonFaq slug={`#b`} nombre={''} />
                            <ButtonFaq slug={`#c`} nombre={''} />
                            <ButtonFaq slug={`#d`} nombre={''} />
                            <ButtonFaq slug={`#e`} nombre={''} />
                            {/* <ButtonFaq slug={`#f`} nombre={''} /> */}
                            
                        </div>) : 
                        (<div className="box-faq">
                            {answers.map((item:any) => (
                                <ButtonFaq slug={`#${item.slug}`} nombre={item.nombre} />
                            ))}
                        </div>)}
                    </div>
                </Col>
            </Row>
        </section>
        {answers.map((item:any) => {
            return (
            <section id={item.slug} className="section_faq_accordion">
                <div className={`pad_tb dog-banner-${item.id_secvis}`}>
                    <Row>
                        <Col md={12} className="text-center center-flex">
                            <div className="width-r">
                                <p className="title-faq-sections">{item.nombre}</p>                                
                                <Accordion className={item.acordion}>
                                {item.pregunta_respuesta.map((itemres:any) => {
                                        return (    
                                        <Accordion.Item eventKey={`${itemres.id_prefre}`}>
                                            <Accordion.Header><span className='icon-tc'></span><h3>{itemres.pregunta}</h3></Accordion.Header>
                                            <Accordion.Body dangerouslySetInnerHTML={{__html: itemres.respuesta}}>                                            
                                            </Accordion.Body>
                                        </Accordion.Item>  
                                    )
                                })}
                                </Accordion>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
            )
        })}

    </div>
    </MainLayout>
)};

export default Faq;