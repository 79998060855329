import React from 'react';
import "./bannerParallax.css";
import { ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';

type BannerParallaxProps = {
    img: string
    clase: string
    text: string
}  

function BannerParallax(props: BannerParallaxProps){
    return (
        <ParallaxBanner className='parallax_prop' style={{ aspectRatio: '2 / 1'}}>
        <ParallaxBannerLayer image={props.img} speed={-20} />
            <ParallaxBannerLayer>
                <div className={`cont_parall ${props.clase}`}>
                    <div className='cont_escr'>
                        <div className='titulo_parall'>
                            <span>{props.text}</span>
                        </div>
                    </div>
                </div>
            </ParallaxBannerLayer>
        </ParallaxBanner>
    );
}
export default BannerParallax;