import { api } from "../api";
import ActionType from "../utils/customTypes";

export class RewardService extends api {

    private static classInstance?: RewardService;
        
    constructor(token: string, type: ActionType) {
        super( process.env.REACT_APP_BASE_URL,token);    
    } 
  
    public static getInstance() {
        let token = localStorage.getItem("token");
        if (!this.classInstance) {
            this.classInstance = new RewardService(token!==undefined && token !== null ? token:'', 'GET');
        }
        
        return this.classInstance;
    }   

    public getRewards= (data:any) => this.instance.post('/rewards/get-rewards', data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getClientOripoints= (data:any) => this.instance.post('/rewards/get-client-oripoints', data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });


    public insertReward= (data:any) => this.instance.post('/rewards/insert-reward', data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });
   
    
}