
import { Row, Col, Button, Stack, Form, CloseButton, Container } from "react-bootstrap"
import './style.css';
import Table from 'react-bootstrap/Table';
import Accordion from 'react-bootstrap/Accordion';

type ProductDescriptionProps = {
    title: string
    resumen: string
    description: string
    calorias: number
    proteinas: number
    grasas: number
    humedad: number
    showDescription: number
    showIngredient: number
    showAnalisys: number
}

function ProductDescriptionAccordion({ title, description, resumen, calorias, proteinas, grasas, humedad,showDescription,showIngredient,showAnalisys }: ProductDescriptionProps) {
 
    return (
        <div className="acor_detail_product">
            <Col md={12} className="cont_acoor" style={{paddingLeft:0,paddingRight:0,paddingTop:0}}>  
                <Accordion alwaysOpen >
                {showDescription === 1 && (
                <Accordion.Item eventKey="0">
                    <Accordion.Header><span className="filter_tip">Descripción</span></Accordion.Header>
                    <Accordion.Body className='back_acord_bod'>
                    <div className="tags">
                    <Col md={12} className='description_detail_producto'>
                        <div className="cont_resumen_pad">
                            <div className="description-producto">{description}</div><br></br>                            
                        </div>                
                    </Col>
                    </div> 
                    </Accordion.Body>
                </Accordion.Item>
                    )}
                {showIngredient === 1 && (
                <Accordion.Item eventKey="1">
                    <Accordion.Header><span className="filter_tip">Ingredientes</span></Accordion.Header>
                    <Accordion.Body className='back_acord_bod'>
                    <div>
                    <Col md={12}className="">
                            <div className="resumen-producto" dangerouslySetInnerHTML={{__html: resumen}}></div>
                    </Col>
                    </div>
                    </Accordion.Body>
                </Accordion.Item>
                )}
                {showAnalisys === 1 && (
                <Accordion.Item eventKey="2">
                    <Accordion.Header><span className="filter_tip">Análisis Garantizado</span></Accordion.Header>
                    <Accordion.Body className='back_acord_bod'>
                    <div>
                    <div className=''>
                        <Table responsive className="table_analisis">
                            <tbody>
                            <tr>
                                <td><p className="producto-caracteristicas">Calorías&nbsp;&nbsp;&nbsp;&nbsp;<span className="underline">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p></td>
                                <td><p className="producto-caracteristicas-data">{calorias}</p></td>
                            </tr>
                            <tr>
                                <td><p className="producto-caracteristicas">Proteína Min&nbsp;&nbsp;&nbsp;&nbsp;<span className="underline">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p></td>
                                <td><p className="producto-caracteristicas-data">{proteinas} %</p></td>
                            </tr>
                            <tr>
                                <td><p className="producto-caracteristicas">Grasa Min&nbsp;&nbsp;&nbsp;&nbsp;<span className="underline">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p></td>
                                <td><p className="producto-caracteristicas-data">{grasas} %</p></td>
                            </tr>
                            <tr>
                                <td><p className="producto-caracteristicas">Humedad Max&nbsp;&nbsp;&nbsp;&nbsp;<span className="underline">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p></td>
                                <td><p className="producto-caracteristicas-data">{humedad} %</p></td>
                            </tr>
                        </tbody>
                        </Table>
                    </div>
                    </div>
                    </Accordion.Body>
                </Accordion.Item>
                )}
                </Accordion>
            </Col>
        </div>
    )
}

export default ProductDescriptionAccordion;