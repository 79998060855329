import React, { useState, useEffect } from 'react';
import { Button, Container, Row, Col, Form,Spinner } from 'react-bootstrap';
import {CartItem} from 'components/CartItem';
import Tooltip from 'components/producto/tooltip';
import BeforeFooter from 'components/beforeFooter/beforeFooter';
import BackLayout from "../../../components/layout/BackLayout/BackLayout";
import {UserService} from '../../../business/UserService';
import {IndexService} from '../../../business/IndexService';
import {TypeService} from '../../../business/TypeService';
import * as Yup from 'yup';
import { yupToFormErrors } from "formik";

let tipoCliente =[
    {
        name: 'Seleccione'
        ,value: 0
    },
    {
        name: 'Regular'
        ,value: 4
    },
    {
        name: 'Influencer'
        ,value: 3
    },
    {
        name: 'Veterinaria/Petshop'
        ,value: 2
    },
    {
        name: 'Administrador'
        ,value: 1
    },
]

let tipoDocumento = [
    {
        name: 'Seleccione'
        ,value: 0
    },
    {
        name: 'DNI'
        ,value: 1 
    },
    {
        name: 'RUC'
        ,value: 2
    },
    {
        name: 'PASAPORTE'
        ,value: 3
    },
]
let tipoComision = [
    {
        name: 'Seleccione'
        ,value: 0
    },
    {
        name: 'Créditos de descuento'
        ,value: 1
    },
    {
        name: 'Remuneración económica'
        ,value: 2
    }
]
let rubro = [
    {
        name: 'Seleccione'
        ,value: 0 
    },
    {
        name: 'Veterinaria'
        ,value: 1 
    },
    {
        name: 'PetShop'
        ,value: 2    
    }    
]

const registerSquema = Yup.object().shape({
    nombres: Yup.string().required("*Campo obligatorio")
   ,apellido_paterno: Yup.string().required("*Campo obligatorio")
   ,apellido_materno: Yup.string().required("*Campo obligatorio")
   ,tipo_documento: Yup.number().min(1,"*Campo obligatorio").required("*Campo obligatorio")
   ,id_roles: Yup.number().min(1,"*Campo obligatorio").required("*Campo obligatorio")
   ,nro_documento: Yup.string().required("*Campo obligatorio")
   ,email: Yup.string().required("*Campo obligatorio")
   ,password: Yup.string().required("*Campo obligatorio")
   ,fecha_contrato: Yup.string().when('id_roles', (val:any, schema:any) => {
        if(val==2 || val==3) { 
        return Yup.string().required("*Campo obligatorio");
        } else { 
        return Yup.string().notRequired();
        }
    })
    ,tipos_comision: Yup.number().when('id_roles', (val:any, schema:any) => {
        if(val==2 || val==3) { 
        return Yup.number().min(1,"*Campo obligatorio");
        } else { 
        return Yup.number().notRequired();
        }
    })
    ,razon_social: Yup.string().when('id_roles', (val:any, schema:any) => {
        if(val==2) { 
        return Yup.string().required("*Campo obligatorio");
        } else { 
        return Yup.string().notRequired();
        }
    })
 });

const initialValues: any = {
    nombres: null,
    apellido_paterno: null,
    apellido_materno: null,
    tipo_documento: null,
    nro_documento: null,
    razon_social: null,
    email: null,
    rubro:null,
    password:null,
    fecha_contrato:null,
    tipos_comision: null,
    entidad_financiera:null,
    id_roles:1,
    idStatus:1,
    telefono:null,
    celular:null,
    titular:null,
    nro_cuenta:null,
    cci:null,
    direccion:null,
    codigo:null,
    mascotas: [{
        nombre_mascota: '',
        raza: '',
        fec_nac_mascota: ''
    }]
   
};

function RegistrarCliente (){
    const [data, setData] = useState(initialValues);
    const [breeds, setBreeds] = useState<any>([]);
    const [finantialEntities, setFinantialEntities] = useState<any>([]);
    const [errors, setErrors] = useState<any>();
    const [validated, setValidated] = useState<any>();
    const [loading, setLoadingButton] = useState(false);

    useEffect(() => {      
        getBreeds();
        getFinantialEntities();
      }, []);

    const getBreeds = async () => { 
        const getInstance = IndexService.getInstance();
            
        await getInstance.getDogBreeds()
            .then((response:any) => {
                    
                if(response.success === true)
                {
                    setBreeds(response.breeds);
                }
                    
            })
            .catch((err:any) => {
                
            });
    
    }

    const getFinantialEntities = async () => { 
        const getInstance = TypeService.getInstance();
            
        await getInstance.getFinantialEntities()
            .then((response:any) => {
                    
                if(response.success === true)
                {
                    setFinantialEntities(response.types);
                }
                    
            })
            .catch((err:any) => {
                
            });
    
    }

    const insertUser = async () => { 
        setLoadingButton(true);
        const getInstance = UserService.getInstance();
            
        await getInstance.insertUser(data)
            .then((response:any) => {
                setLoadingButton(false);
                if(response.success === true)
                {
                    window.location.href = '/admin/usuarios';
                }
                    
            })
            .catch((err:any) => {
                setLoadingButton(false);
            });
    
    }

    const handleChangeData= (event: any) => {
        const { name, value} = event.target;
        let copy = {...data};
        let val = value;
        
        if(name==='idDocType' || name==='id_roles' || name==='idResidenceCountry' || name==='idStatus')
        {
            val = parseInt(value);
        }

    
        setData({
            ...data,
            [name]: event.target.type==='checkbox' ? event.target.checked : val
        });
        
      };

      const handleChangeDataPet= (event: any, index:number) => {
        const { name, value} = event.target;
        let copy = {...data};
        let val = value;
        
        let newData = {
                ...copy.mascotas[index],
                [name]: val
            };
        copy.mascotas[index] = newData;

        setData(copy);
        
      };


      const handleSave= (event: any) => {
        registerSquema.validate(data, { abortEarly: false }).then(function() {
            // Success
            insertUser();
            setErrors([]);
        }).catch(function (err) {
            let errorArray = {};
            const errors = yupToFormErrors(err);
            setErrors(errors);
            console.log(errors);
            console.log(data);
            setValidated(true);
           
        });
        
      }

      const insertPet = (event:any) => {
       
        let copy = [...data['mascotas']];
        copy.push({nombre_mascota: '', fec_nac_mascota: '', raza: ''});
        setData({
            ...data,
            ['mascotas']: copy
          });
       
      }

      const deletePet = (event:any, index:number) => {
       
        let copy = [...data['mascotas']];
        copy.splice(index, 1); 
        setData({
            ...data,
            ['mascotas']: copy
          });
       
      }

    return (
        <BackLayout>
        <div className='fond_backoffice'>
            <Container>                
                <div className='cont_title'>
                    <h1>Registrar Usuario</h1>
                </div>
                <div className='cont_data'>                           
                    <Form id="form-registrar-producto" validated={validated}>
                        <Row className="">
                            <Col md={12}>
                                <div className="section_backoffice_form">
                                    <div className="">
                                        <h3>Datos Personales</h3>
                                        <Row>
                                            <Col md={3}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Tipo cliente*:</Form.Label>
                                                    <Form.Control as="select" required name="id_roles" value={data.id_roles} onChange={e => {handleChangeData(e)}}>
                                                        {tipoCliente.map(item => (
                                                        <option key={item.value} value={item.value}>{item.name}</option>
                                                        ))}                                                          
                                                    </Form.Control>                                                    
                                                    {errors?.id_roles != "" && (
                                                        <div className="invalid-feedback d-block" role="alert">
                                                            {errors?.id_roles}
                                                        </div>
                                                    )}
                                                </Form.Group>  
                                            </Col>
                                            
                                            <Col md={3}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Rubro:</Form.Label>
                                                    <Form.Control as="select" name="rubro" value={data.rubro} onChange={e => {handleChangeData(e)}}>
                                                        {rubro.map(item => (
                                                        <option key={item.value} value={item.value}>{item.name}</option>
                                                        ))}                                                          
                                                    </Form.Control>                                                    
                                                    {errors?.rubro != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.rubro}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>  
                                            </Col>
                                            <Col md={3}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Tipo documento*:</Form.Label>
                                                    <Form.Control as="select" name="tipo_documento" required value={data.tipo_documento} onChange={e => {handleChangeData(e)}}>
                                                        {tipoDocumento.map(item => (
                                                        <option key={item.value} value={item.value}>{item.name}</option>
                                                        ))}                                                          
                                                    </Form.Control>                                                    
                                                    {errors?.tipo_documento != "" && (
                                                        <div className="invalid-feedback d-block" role="alert">
                                                            {errors?.tipo_documento}
                                                        </div>
                                                    )}
                                                </Form.Group>  
                                            </Col>
                                            <Col md={3}>                              
                                                <Form.Group>                        
                                                    <Form.Label>N° documento*:</Form.Label>
                                                    <input type="text" name='nro_documento' required className='form-control' value={data.nro_documento} onChange={e => {handleChangeData(e)}}/>                                                    
                                                    {errors?.nro_documento != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.nro_documento}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>  
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group>
                                                <Form.Label>Razón Social*</Form.Label>
                                                    <input type="text" name='razon_social' className='form-control' required={data.id_roles==2 ? true: false}  value={data.razon_social} onChange={e => {handleChangeData(e)}}/>                                                    
                                                    {errors?.razon_social != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.razon_social}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>
                                            </Col> 
                                            <Col md={3}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Fecha de contrato*:</Form.Label>
                                                    <input type="date" name='fecha_contrato' required={data.id_roles==2 || data.id_roles == 3 ? true: false} className='form-control' value={data.fecha_contrato} onChange={e => {handleChangeData(e)}}/>                                                    
                                                    {errors?.fecha_contrato != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.fecha_contrato}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>  
                                            </Col>
                                            <Col md={3}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Tipo de comisión*:</Form.Label>
                                                        <Form.Control as="select" name="tipos_comision" required={data.id_roles==2 || data.id_roles == 3 ? true: false}  value={data.tipos_comision} onChange={e => {handleChangeData(e)}}>
                                                            {tipoComision.map(item => (
                                                            <option key={item.value} value={item.value}>{item.name}</option>
                                                            ))}                                                          
                                                        </Form.Control>                                                    
                                                        {errors?.tipos_comision != "" && (
                                                        <div className="invalid-feedback d-block" role="alert">
                                                            {errors?.tipos_comision}
                                                        </div>
                                                    )}
                                                    </Form.Group>  
                                            </Col>
                                            <Col md={4}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Nombres*:</Form.Label>
                                                    <input type="text" name='nombres' className='form-control' required value={data.nombres} onChange={e => {handleChangeData(e)}}/>                                                    
                                                    {errors?.nombres != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.nombres}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>  
                                            </Col>
                                            <Col md={4}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Primer apellido*:</Form.Label>
                                                    <input type="text" name='apellido_paterno' required className='form-control' value={data.apellido_paterno} onChange={e => {handleChangeData(e)}} />                                                    
                                                    {errors?.apellido_paterno != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.apellido_paterno}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>  
                                            </Col>
                                            <Col md={4}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Segundo apellido*:</Form.Label>
                                                    <input type="text" name='apellido_materno' required className='form-control' value={data.apellido_materno} onChange={e => {handleChangeData(e)}} />                                                    
                                                    {errors?.apellido_materno != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.apellido_materno}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>  
                                            </Col>                                            
                                            <Col md={6}>
                                                <Form.Group>
                                                <Form.Label>Dirección</Form.Label>
                                                    <input type="text" name='direccion' className='form-control' value={data.direccion} onChange={e => {handleChangeData(e)}}/>                                                    
                                                    {errors?.direccion != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.direccion}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>
                                            </Col> 
                                            <Col md={3}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Teléfono:</Form.Label>
                                                    <input type="text" name='telefono' className='form-control' value={data.telefono} onChange={e => {handleChangeData(e)}}/>                                                    
                                                    {errors?.telefono != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.telefono}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>  
                                            </Col>
                                            <Col md={3}>                              
                                                <Form.Group>                        
                                                    <Form.Label>celular:</Form.Label>
                                                    <input type="text" name='celular' className='form-control' value={data.celular} onChange={e => {handleChangeData(e)}}/>                                                    
                                                    {errors?.celular != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.celular}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>  
                                            </Col>
                                            
                                            <Col md={4}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Código único:</Form.Label>
                                                    <input type="text" name='codigo' className='form-control' value={data.codigo} onChange={e => {handleChangeData(e)}}/>                                                    
                                                    {errors?.codigo != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.codigo}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>  
                                            </Col>
                                            
                                            <Col md={4}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Email*:</Form.Label>
                                                    <input type="text" name='email' required className='form-control' value={data.email} onChange={e => {handleChangeData(e)}}/>                                                    
                                                    {errors?.email != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.email}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>  
                                            </Col>
                                            <Col md={4}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Contraseña*:</Form.Label>
                                                    <input type="password" name='password' required className='form-control' value={data.password} onChange={e => {handleChangeData(e)}}/>                                                    
                                                    {errors?.password != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.password}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>  
                                            </Col>
                                        </Row>
                                    </div>
                            
                                    <div className="section_backoffice">
                                        <h3>Información para transacciones</h3>
                                        <Row>
                                            <Col md={6}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Titular de la cuenta:</Form.Label>
                                                    <input type="text" name='titular' className='form-control' value={data.titular} onChange={e => {handleChangeData(e)}}/>                                                    
                                                    {errors?.titular != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.titular}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>  
                                            </Col>
                                            <Col md={6}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Entidad financiera:</Form.Label>
                                                    <Form.Control as="select" name="entidad_financiera" value={data.entidad_financiera} onChange={e => {handleChangeData(e)}}>
                                                        <option value="0">Seleccionar</option>
                                                        {finantialEntities.map((item:any) => (
                                                        <option key={item.id} value={item.id}>{item.name}</option>
                                                        ))}  
                                                    </Form.Control>                                                    
                                                    {errors?.entidad_financiera != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.entidad_financiera}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>  
                                            </Col>                                            
                                            <Col md={6}>                              
                                                <Form.Group>                        
                                                    <Form.Label>N° Cuenta:</Form.Label>
                                                    <input type="text" name='nro_cuenta' className='form-control' value={data.nro_cuenta} onChange={e => {handleChangeData(e)}}/>                                                    
                                                    {errors?.nro_cuenta != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.nro_cuenta}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>  
                                            </Col>   
                                            <Col md={6}>                              
                                                <Form.Group>                        
                                                    <Form.Label>CCI</Form.Label>
                                                    <input type="text" name='cci' className='form-control' value={data.cci} onChange={e => {handleChangeData(e)}}/>                                                    
                                                    {errors?.cci != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.cci}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>  
                                            </Col>                                             
                                        </Row>
                                    </div>
                                    <div className="section_backoffice" >
                                        <h3>Mascotas</h3>
                                        <div id="mascotasBox">
                                        {data.mascotas.map((item:any, index:number) => (
                                        <Row>
                                            <Col md={4}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Nombre:</Form.Label>
                                                    <input type="text" name='nombre_mascota' value={item.nombre_mascota} onChange={e => {handleChangeDataPet(e,index)}} className='form-control'/>                                                    
                                                   
                                                </Form.Group>  
                                            </Col>
                                            <Col md={3}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Fecha nacimiento:</Form.Label>
                                                    <input type="date" name='fec_nac_mascota' value={item.fec_nac_mascota} onChange={e => {handleChangeDataPet(e,index)}} className='form-control'/>                                                    
                                                    
                                                </Form.Group>  
                                            </Col>                                           
                                            <Col md={3}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Raza:</Form.Label>
                                                    <Form.Control as="select" name="raza" value={item.raza} onChange={e => {handleChangeDataPet(e,index)}} >
                                                        <option value="">Seleccionar</option>
                                                        {breeds.map((item:any) => (
                                                        <option key={item.id} value={item.id}>{item.name}</option>
                                                        ))}  
                                                    </Form.Control>                                                    
                                                   
                                                </Form.Group>  
                                            </Col>  
                                            <Col md={2} className='cont_trush_mascota'>     
                                                <Button className='btn_icon cont_ver_detalle' type="button" onClick={e => {deletePet(e,index)}}>                         
                                                    <img src={process.env.REACT_APP_PUBLIC_URL + 'images/common/trush-w.webp'} alt="Eliminar" />
                                                </Button>
                                            </Col>
                                         </Row>))}
                                         </div>
                                         <Row>
                                            <Col md={2} className='pad_top_1 dis_fle cont_btn_agre_mas'>     
                                                <Button className='btn_agregar_mascota' type="button" onClick={(e) => insertPet(e)}>Agregar mascota</Button>
                                            </Col>                                            
                                        </Row>
                                    </div>   

                                    <Col md={12} className="pad_top_2 dis_fle spac_betw">
                                        <Col md={4} className="align-items-center txt_ali dis_fle con_btn_confirm">                                                    
                                            <Button className='btn_confirm' type="button" onClick={(e)=>handleSave(e)} disabled={loading}>
                                                {loading ? (
                                                <Spinner animation="border" role="status" style={{width: '1rem', height: '1rem'}}>
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                                ) : (
                                                'Guardar'
                                                )}
                                            </Button>                                                   
                                        </Col>
                                        <Col md={4} className="align-items-center txt_ali dis_fle cont_btn_cancel">                                                    
                                            <a href={process.env.REACT_APP_PUBLIC_URL + 'admin/usuarios'} className='btn_cancelar_registro btn'>Cancelar</a>                                                        
                                        </Col>
                                    </Col>  

                                </div>
                            </Col>   
                        </Row>                            
                    </Form>
                </div>
            </Container>  
        </div>
        </BackLayout>

)};
export default RegistrarCliente;
