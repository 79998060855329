import Modal from 'react-bootstrap/Modal';
import "./style.css";
import { Button, CloseButton, Form
    , Spinner , Col , Row
  } from "react-bootstrap";
import { useState, useEffect } from 'react';
import uploadFile from '../../../business/FileService';
import {ProductService} from '../../../business/ProductService';

type ModalAddImageProps = {
    handleClose: any
    show?: boolean
    id: number
}

function ModalAddPresentationImage(props:ModalAddImageProps) {
    const [loading, setLoadingButton] = useState(false);
    const [selectedFile, setSelectedFile] = useState();
    const [preview, setPreview] = useState("");

    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        if (!selectedFile) {
            setPreview("")
            return
        }

        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile])

    const onSelectFile = (e:any) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined)
            return
        }

        // I've kept this example simple by using the first image instead of multiple
        setSelectedFile(e.target.files[0])
    }

    const insertImage = async (imagen:string, imagen_path:string) => { 
        
        const getInstance = ProductService.getInstance();
            
        await getInstance.insertPresentationImage({imagen:imagen, imagen_path: imagen_path, id: props.id})
            .then((response:any) => {
                setLoadingButton(false);
                if(response.success === true)
                {
                    handleClose();
                }
                
                    
            })
            .catch((err:any) => {
                setLoadingButton(false);
            
            });
    
    }


      const handleSave= (event: any) => {
        setLoadingButton(true);
        var result = uploadFile(selectedFile,'upload').then((response:any) => {
            
            if(response.status == 200)
            {
                let result = response.data.result;
                if(result.success == true)
                {
                    
                    insertImage(result.data.imagen, result.data.imagen_path);
                }
            }
            else{
                setLoadingButton(false);
            }
        });
       
        
      }

      const handleClose = () => {
        setPreview("");
        setSelectedFile(undefined);
        setLoadingButton(false);
        props.handleClose();
      }

  return (
    <Modal id="modal_agregar_cupon" className='modal_cupon' show={props.show} onHide={props.handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header  className="close-modal">   
            <h3 className='title_modal_cupon'>Slider Detalle Presentación</h3>             
            <CloseButton variant="white" onClick={handleClose}/>
        </Modal.Header>        
        <Modal.Body>                
            <div className='cont_modal_cupon'>
                <Form className='form_agregar_cupon' >
                    
                    <Form.Group className='row'>
                        <Col md={12}>      
                                             
                            <Form.Group className='row'>
                                <Form.Label>Imagen:</Form.Label>
                                
                                <Col md={12}>
                                    <Form.Control type="file" as='input' name='imagen' className='' onChange={onSelectFile}/>
                                    {selectedFile &&  <img className="edit-img-propre" style={{width: '100%'}} src={preview} /> }
                                </Col>
                                             
                            </Form.Group>  
                           
                        </Col>
                    </Form.Group>
                    <div className='cont_modal_confirm'>
                        <Col md={12} className='dis_fle'>           
                            <Col md={6} className="con_btn_confirm">
                                <Button className='btn_confirm' type="button" onClick={(e)=>handleSave(e)} disabled={loading}>
                                    {loading ? (
                                    <Spinner animation="border" role="status" style={{width: '1rem', height: '1rem'}}>
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                    ) : (
                                    'Guardar'
                                    )}
                                </Button>  
                            </Col>
                            <Col md={6} className="con_btn_confirm">
                                <button className='btn_cancel' type="button" onClick={handleClose}>Cancelar</button> 
                            </Col>                
                        </Col>    
                    </div>                  
                </Form>
            </div>
        </Modal.Body>
    </Modal>
  );
}

export default ModalAddPresentationImage;