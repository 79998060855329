import React from 'react';
import "../reporteVentas/style.css";
import BannerParallax from '../../../components/bannerParallax/bannerParallax';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';
import { useState, useCallback, useEffect } from 'react';
import BackLayout from "../../../components/layout/BackLayout/BackLayout";
import {SalesService} from '../../../business/SalesService';
import { formatNumber } from "../../../utils/formatCurrency";
import { useParams } from "react-router-dom";

let defaultData = {
         suscriptor: ""
        ,fecha_donacion: ""
        ,plan_donacion:""
        ,monto_donacion: 0
        ,estado_donacion: ""
        ,nro_tarjeta: ""
        ,marca_tarjeta: ""
        ,telefono_uno: ""
        ,telefono_dos: ""
        ,donacionesdetalle: [
        ]
    }
function DetalleDonacion (){
    const [data, setData] = useState(defaultData);
    const [detalle, setDetalle] = useState<any>([]);
    const [loading, setLoading] = useState(false);

    let params = useParams();
    let id = 0;
    if(params.id_donaci !== undefined)
    {
        id = parseInt(params.id_donaci);
    }
    

    useEffect(() => {      
        getDonation(id);     
      }, []);

    const getDonation = async (idDonation:number) => { 
        const getInstance = SalesService.getInstance();
        setLoading(true);

        await getInstance.getDonation({id: idDonation})
            .then((response:any) => {
                    setLoading(false);
                    if(response.success === true)
                    {
                        setData(response.data);

                    }
                    
                })
            .catch((err:any) => {
                setLoading(false);
            });

    }
    return (
        <BackLayout>
        <div className='fond_backoffice'>
            <Container>      
                <div className='cont_title'>
                    <h1>Detalle donación</h1>
                </div>     
                <div className='cont_data'>     
                    <div className="row">
                        <div className="col-md-5">
                            <div className="back_detalle_venta">
                                <div className="">
                                    <div className="">
                                        <h2 className="title_detalle_venta">{data.suscriptor}</h2>
                                    </div>
                                </div>
                                <div>     
                                    <div className="">
                                        <span className="subtitle_detalle_venta">Fecha de donación: </span>
                                        <span className="res_detalle_venta">{data.fecha_donacion}</span>
                                    </div>
                                    <div className="">
                                        <span className="subtitle_detalle_venta">Plan donación: </span>
                                        <span className="res_detalle_venta">{data.plan_donacion}</span>
                                    </div>
                                    <div className="">
                                        <span className="subtitle_detalle_venta">Monto donación: </span>
                                        <span className="res_detalle_venta">{data.monto_donacion}</span>
                                    </div>
                                    <div className="">
                                        <span className="subtitle_detalle_venta">Estado de donación: </span>
                                        <span className="res_detalle_venta">{data.estado_donacion}</span>
                                    </div>
                                    <div className="">
                                        <span className="subtitle_detalle_venta">N° de tarjeta: </span>
                                        <span className="res_detalle_venta">{data.nro_tarjeta}</span>
                                    </div>
                                    <div className="">
                                        <span className="subtitle_detalle_venta">Marca tarjeta: </span>
                                        <span className="res_detalle_venta">{data.marca_tarjeta}</span>
                                    </div>
                                    <div className="">
                                        <span className="subtitle_detalle_venta">Telefono 1: </span>
                                        <span className="res_detalle_venta">{data.telefono_uno}</span>
                                    </div>
                                    <div className="">
                                        <span className="subtitle_detalle_venta">Telefono 2: </span>
                                        <span className="res_detalle_venta">{data.telefono_dos}</span>
                                    </div>
                                </div>                     
                                
                            </div>
                        </div>
                        <div className="col-md-7 back_detalle_venta_productos">
                            <div className="">
                                <div className='cont_d_adogtados'>
                                    <div className="d-flex align-items-center flex-wrap">
                                        <div className='cont_img_adogtados'>
                                            <img src={process.env.REACT_APP_PUBLIC_URL + "images/suscripciones/adogtados.png"} alt="" />
                                        </div>
                                        <div>
                                            <h2 className='title_plan_donaci'>{data.plan_donacion}</h2>
                                            <h2 className='title_estado_donaci'>{data.estado_donacion}</h2>                                        
                                        </div>                        
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-xs-12">
                                        <h4 className="title_product_detail">Transacciones</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-12 col-xs-12">
                                                <div className="table-responsive">
                                                    <Table responsive className='table_backoffice'>
                                                        <thead>
                                                            <tr>
                                                                <th>Fecha programada</th>
                                                                <th>Monto</th>
                                                                <th>Fecha pago</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data.donacionesdetalle.map((itemped:any) => {
                                                                return (    
                                                                    <tr>
                                                                        <th>{itemped.fecha_programada}</th>
                                                                        <td>{itemped.monto}</td>
                                                                        <td>{itemped.fecha_pago}</td>               
                                                                        
                                                                    </tr> 
                                                                )
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    </BackLayout>
)};
export default DetalleDonacion;
