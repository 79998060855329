import React from 'react';
import "./style.css";
import BannerParallax from '../../components/bannerParallax/bannerParallax';
import MainLayout from "../../components/layout/MainLayout/MainLayout";
import { Container, Row, Col, Form } from 'react-bootstrap';
import {useState, useEffect} from 'react';
import {IndexService} from '../../business/IndexService';
import * as Yup from 'yup';
import { yupToFormErrors } from "formik";

let etapas = [
    {
        id_etapa: 1
        ,nombre: "Cachorro"
        ,descripcion: "(3-7 m)"
    },
    {
        id_etapa: 5
        ,nombre: "Jóven"
        ,descripcion: "(8-12 m)"
    },
    {
        id_etapa: 2
        ,nombre: "Adulto"
        ,descripcion: "(+12 m)"
    },
    {
        id_etapa: 3
        ,nombre: "Adulto Mayor"
        ,descripcion: "(+8 a)"
    },

]
let actividades = [
    {
        id_activi: 1
        ,nombre: "Baja"
    },
    {
        id_activi: 2
        ,nombre: "Normal"
    },
    {
        id_activi: 3
        ,nombre: "Alta"
    }
]

let infoInitial = {
    stage: '',
    weight: '',
    activity: ''
}

const infoSquema = Yup.object().shape({
    stage:  Yup.string().required("*Campo obligatorio")
    ,weight: Yup.string().required("*Campo obligatorio")
    ,activity: Yup.string().required("*Campo obligatorio")
    
 });

function CalcularRacionR (){

    const [show, setShow] = useState(false);
    const [data, setData] = useState<any>({});
    const [info, setInfo] = useState(infoInitial);
    const [errors, setErrors] = useState<any>();
    const [validated, setValidated] = useState(false);

    useEffect(() => {      
       if(show==true)
       {
        document.getElementById('sect_resultado')?.scrollIntoView();
       }
      }, [show]);

    const getCalculator = async () => { 
        const getInstance = IndexService.getInstance();
        
        await getInstance.getCalculator({type:2, stage: info.stage, activity: info.activity=='0'?null:info.activity, weight: info.weight})
        .then((response:any) => {
                
                setShow(true);
                
                
                setData(response);
    
            }   
          )
        .catch((err:any) => {
          
        });
    
      }

      const handleChange= (event: any) => {
    
        const { name, value} = event.target;
        setInfo({
            ...info,
            [name]: value
          });      
        
      };

    const calculate = (event:any) => {
        infoSquema.validate(info, { abortEarly: false }).then(function() {
            // Success
            getCalculator();
            setErrors([]);
        }).catch(function (err) {
            let errorArray = {};
            const errors = yupToFormErrors(err);
            setErrors(errors);
            console.log(errors);
            setValidated(true);
           
    
            
        });

    }

    return (
        <MainLayout>
        <div className='pad_initial'>
            <BannerParallax 
                img={process.env.REACT_APP_PUBLIC_URL + "images/banners/parallax.webp"}
                clase=""
                text= "CALCULAR RACIÓN"
      ></BannerParallax>
            <section id="sect_calcul" className="sect_inmid sect_calcul">
                <Container className="">
                    <div className="text-center">
                        <Form id="calcular_racion_form" validated={validated}>
                            <h3 className='color_origo'>¿CUÁNTO DEBE COMER MI MASCOTA?</h3>
                            <div className="marg_space q-form" id="">
                                <div className="marg_space">Ingresa los datos de tu perrito en nuestra calculadora para conocer su ración ideal</div>
                                <Row className='justify-content-md-center'>
                                    <Col md={6}>
                                        <Row>                                            
                                        <Col md={12} className='pad_bot_1'>                              
                                                <Form.Group>                        
                                                    <Form.Control required as="select" name="stage" value={info.stage} onChange={(e)=>handleChange(e)}>
                                                    <option value=''>Edad</option>

                                                        {etapas.map(item => (
                                                        <option key={item.id_etapa} value={item.id_etapa}>{item.nombre} {item.descripcion}</option>
                                                        ))}                                                          
                                                    </Form.Control>                                                    
                                                    {errors?.stage != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.stage}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>  
                                        </Col>                                        
                                        <Col md={12} className='pad_bot_1'>                              
                                                <Form.Group>                        
                                                    <Form.Control required as="select" name="activity" value={info.activity} onChange={(e)=>handleChange(e)}>
                                                    <option value=''>Actividad física</option>

                                                        {actividades.map(item => (
                                                        <option key={item.id_activi} value={item.id_activi}>{item.nombre}</option>
                                                        ))}                                                          
                                                    </Form.Control>                                                    
                                                    {errors?.activity != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.activity}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>  
                                        </Col>
                                        <Col md={12} className='pad_bot_1'>                              
                                            <Form.Group>                        
                                                <Form.Control required as="select" name="weight" value={info.weight} placeholder='Peso' onChange={(e)=>handleChange(e)}>  
                                                    <option value=''>Peso</option> 
                                                    {Array.from({ length: 45 }).map((it, index) => (
                                                        <option value={index+1}>{index+1}kg</option>
                                                    ))}
                                                                                                           
                                                </Form.Control>                                                    
                                                {errors?.weight != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.weight}
                                                        </Form.Control.Feedback>
                                                    )}
                                            </Form.Group>  
                                        </Col>
                                        
                                        <Col md={12}>
                                            <div className="q-form" id="">
                                                <button type="button" className="btn-calc" id="bnt-calc-racion" onClick={(e)=>calculate(e)}>CALCULAR</button>
                                            </div>                                    
                                        </Col>
                                        {/* <Col md={12}>
                                            <button className={"select-tipo btn-sel2 btn-sel "+ (type==2 ? "active" : "")} type="button" name="button" onClick={(e)=>handleChangeType(e,2)}>PERRO</button>
                                        </Col> */}
                                        {/* <Col md={6}>
                                        <button className={"select-tipo btn-sel2 btn-sel "+ (type==1 ? "active" : "")} type="button" name="button" onClick={(e)=>handleChangeType(e,1)}>GATO</button>
                                        </Col> */}
                                        </Row>
                                    
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    </div>
                </Container>
            </section>

            {show &&(<section id="sect_resultado" className="sect_inmid sect_result">
                <h3 className='color_origo'>LA RACIÓN IDEAL PARA TU PERRITO ES:</h3>
                <Row className='justify-content-md-center'>
                    <Col md={8}>
                        <Row>
                            <Col md={6}>
                                <div className='card_result'>
                                    <div className='text_result'><span className='color_origo'>{data.units}</span>&nbsp;hamburguesas</div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className='card_result'>
                                    <div className='text_result'><span className='color_origo'>{data.congelado_day}</span>&nbsp;gramos / día</div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                               
            </section>)}
        </div>
        </MainLayout>
)};
export default CalcularRacionR;
