import { Offcanvas, Stack, Button, Row, Col, Container } from "react-bootstrap"
import { useShoppingCart } from "../context/ShoppingCartContext"
import { formatMoney } from "../utils/formatCurrency"
import { CartItem } from "./CartItem"
import ProductSuggested from "./producto/productSuggested"
import Carousel from 'react-multi-carousel';

import 'react-multi-carousel/lib/styles.css';

import {useState, useEffect} from 'react';
import { getTokenSourceMapRange } from "typescript"
import { StoreService } from "business/StoreService"

type ShoppingCartProps = {
  isOpen: boolean
  makePurchase: () => void
}

export function ShoppingCart({ isOpen, makePurchase }: ShoppingCartProps) {
  const { closeCart, cartItems, totalCart } = useShoppingCart()
  
  // const [productsSuggested, setProducts] = useState([]);
  const [sliderDesktop, setSliderDesktop] = useState<any>([]);
  const [sliderResponsive, setSliderResponsive] = useState<any>([]);
  const [breeds, setBreeds] = useState<any>([]);
  const [productsSuggested, setProductsSuggested] = useState<any>([]);

  const [fetchingProducts, setFetchingProducts] = useState(false);

  useEffect(() => { 
         
    getRecommendedProducts();      
  }, []);

  const getRecommendedProducts = async () => { 
    const getInstance = StoreService.getInstance();
    setFetchingProducts(true);

    await getInstance.getRecommendedProducts({items: JSON.stringify(cartItems)})
        .then((response:any) => {
              
          
              if(response.success === true)
              {
                setProductsSuggested(response.products);

              }
              else{
                
              }
              setFetchingProducts(false);
            }   
          )
        .catch((err:any) => {
          setFetchingProducts(false);
        });

}

    const responsiveShopCard = {
      superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
      slidesToSlide: 1
      },
      desktop: {
      breakpoint: { max: 8000, min: 1024 },
      items: 2,
      slidesToSlide: 1
      },
      tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1
      },
      mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 2,
      slidesToSlide: 1
      }
    };
  
  return (
    <Offcanvas show={isOpen} onHide={closeCart} placement="end">
      <Offcanvas.Header closeButton className="headerCart">
        <Offcanvas.Title >RESUMEN DE COMPRA:</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Stack >
          <div className="box-cart" >
          {cartItems.map((item :any)=> (
            
            <CartItem key={item.id} {...item} />
          ))}
          </div>
          <div className="fw-bold fs-5 sp_betw" style={{marginTop: "15px"}}>
            <p>PAGO TOTAL:{" "}</p>
            <p className="color-primary">{formatMoney(totalCart)}</p>            
          </div>
          <div className="box-secondary">
            <p style={{marginBottom: "0px"}}>*Compra mínima s/ 25.00<br></br> *Precios incluyen IGV</p>
          </div>
          {/* <br></br> */}
          <div className="prod_suggs_lateral">
            <div className="prod_suggs_scroll">
            <h5 className="title_prod_suggs">TE PUEDE INTERESAR</h5>            
                {productsSuggested.map((item:any) => {
                        return (    
                        <ProductSuggested 
                          key={item.idPresentation}
                          id={item.idPresentation}
                          name={item.name}
                          category={item.category}
                          idLine={item.idLine}
                          line={item.line}
                          price={item.price}
                          percentageDiscount={Math.round(item.percentageDiscount)}
                          normalPrice={item.price}
                          image={process.env.REACT_APP_BASE_URL_BACK + item.img}
                          presentation={item.presentation}
                          type={item.id}
                          periodicity={item.id}
                          canEdit={true}
                          onceBuy={item.onceBuy}
                          promo={item.tipo_promo}
                          url={process.env.REACT_APP_PUBLIC_URL + "producto/" +item.slug}/>
                    )
                })}
                </div>
           </div>
          <Button onClick={makePurchase} className="btn-primary-origo" >
                REALIZAR PAGO
            </Button>
        </Stack>
      </Offcanvas.Body>
    </Offcanvas>
  )
}
