import React from "react";
import { default as CulqiProvider } from "./checkout";
import { Culqi } from "./context";
import  ButtonCulqi from "./ButtonCulqi";

const CulqiComponent = ({
  handleSetLoading,
  currency,
  handleSaveOrder,
  handleShowErrorNotification,
  handleOpenCulqiCh,
  setAmountCulqiCh
}) => {
  
  const publicStoreKey = process.env.REACT_APP_CULQI_KEY;
  const logo = process.env.REACT_APP_PUBLIC_URL + "images/common/logoblack-culqi.webp";

  return (
    <div className="">
        <CulqiProvider
          publicKey={publicStoreKey}
          currency={currency}
          title="Origo Pet"
          description="Se guardará tu tarjeta para cobros recurrentes"     
          onToken={(token) => {
            handleSetLoading(true);
            handleSaveOrder(token.id, token.metadata.installments);
          }}
          
          onError={(error) => {
             handleSetLoading(false);
            console.error("something bad happened", error);
            handleShowErrorNotification(error.user_message)
          }}
          options={{
            installments: true,
            style: {
                logo: logo,
                bannerColor: '#fe6f5e', // hexadecimal
                buttonBackground: '#fe6f5e', // hexadecimal
                buttonTextColor: '', // hexadecimal
                priceColor: '#000000', 
            },
            paymentMethods: {
              tarjeta: true,
              yape: false
            },
          }}
        >
            <Culqi>
            {({ openCulqi, setAmount}) => {
                return <ButtonCulqi handleOpenCulqiCh={handleOpenCulqiCh}
                  setAmountCulqiCh={setAmountCulqiCh}
                  handleOpenCulqi={openCulqi}  
                  setAmountCulqi={setAmount}  
                />;
                
              }}
            </Culqi>
        </CulqiProvider>
    </div>
  );
};

export default CulqiComponent;