import React, { useState, useEffect } from 'react';
import { Button, Container, Row, Col, Form, Spinner , Tab, Tabs} from 'react-bootstrap';
import BackLayout from "../../../components/layout/BackLayout/BackLayout";
import {ProductService} from '../../../business/ProductService';
import uploadFile from '../../../business/FileService';
import * as Yup from 'yup';
import { yupToFormErrors } from "formik";
import { Multiselect } from 'multiselect-react-dropdown';
import { useParams } from "react-router-dom";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Table from 'react-bootstrap/Table';
import ModalConfirm from "../../../components/confirm/ModalConfirm";

const validSquema = Yup.object().shape({    
    linea: Yup.number().required("*Campo obligatorio")
    ,categoria: Yup.number().required("*Campo obligatorio")
    ,nombre: Yup.string().required("*Campo obligatorio")
 });

 let filtersDefault = {
    search: ''
}

 let confirmDefault = {
    id:0,
    name: '',
    show: false,
    status: 0
}

function EditarProducto (){
    const [data, setData] = useState<any>({data: []});
    const [loading, setLoading] = useState(false);
    const [linea, setLinea] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [etapas, setEtapas] = useState([]);
    const [actividades, setActividades] = useState([]);
    const [experiencias, setExperiencias] = useState([]);
    const [validated, setValidated] = useState<any>();
    const [errors, setErrors] = useState<any>();
    const [loadingButton, setLoadingButton] = useState(false);
    const [selectedProductFile, setSelectedProductFile] = useState();
    const [selectedDetailFile, setSelectedDetailFile] = useState();
    const [previewProductFile, setPreviewProductFile] = useState("");
    const [previewDetailFile, setPreviewDetailFile] = useState("");
    const [presentations, setPresentations] = useState<any>([]);
    const [loadingPresentation, setLoadingPresentation] = useState(false);
    const [confirmDataStatus, setConfirmDataStatus] = useState(confirmDefault);
    const [confirmDataDelete, setConfirmDataDelete] = useState(confirmDefault);
    const [filters, setFilters] = useState(filtersDefault);

    let params = useParams();
    let idProduct = 0;
    if(params.id_product !== undefined)
    {
        idProduct = parseInt(params.id_product);
    }
    
    const getProduct = async (idProduct:number) => { 
        const getInstance = ProductService.getInstance();
        setLoading(true);

        await getInstance.getProduct({id: idProduct})
            .then((response:any) => {
                    setLoading(false);
                    if(response.success === true)
                    {
                        setData(response.data);

                    }
                    
                })
            .catch((err:any) => {
                setLoading(false);
            });

    }

    useEffect(() => {   
        
        getLinesList(); 
        getCategoryList();  
        getStageList();
        getActivityList(); 
        getExperienceList();  
        getProduct(idProduct);  
        getProductsPresentations(idProduct);      
      }, []);

      const getLinesList = async () => { 
        const getInstance = ProductService.getInstance();
        setLoading(true);

        await getInstance.getLinesList()
            .then((response:any) => {
                
                    if(response.success === true)
                    {
                        setLinea(response.data);

                    }
                    setLoading(false);
                    
                })
            .catch((err:any) => {
                setLoading(false);
            });

    }

    const getCategoryList = async () => { 
        const getInstance = ProductService.getInstance();
        setLoading(true);

        await getInstance.getCategoryList()
            .then((response:any) => {
                
                    if(response.success === true)
                    {
                        setCategorias(response.data);

                    }
                    setLoading(false);
                    
                })
            .catch((err:any) => {
                setLoading(false);
            });

    }

    const getStageList = async () => { 
        const getInstance = ProductService.getInstance();
        setLoading(true);

        await getInstance.getStageList()
            .then((response:any) => {
                
                    if(response.success === true)
                    {
                        setEtapas(response.data);

                    }
                    setLoading(false);
                    
                })
            .catch((err:any) => {
                setLoading(false);
            });

    }

    const getActivityList = async () => { 
        const getInstance = ProductService.getInstance();
        setLoading(true);

        await getInstance.getActivityList()
            .then((response:any) => {
                
                    if(response.success === true)
                    {
                        setActividades(response.data);

                    }
                    setLoading(false);
                    
                })
            .catch((err:any) => {
                setLoading(false);
            });

    }

    const getExperienceList = async () => { 
        const getInstance = ProductService.getInstance();
        setLoading(true);

        await getInstance.getExperienceList()
            .then((response:any) => {
                
                    if(response.success === true)
                    {
                        setExperiencias(response.data);

                    }
                    setLoading(false);
                    
                })
            .catch((err:any) => {
                setLoading(false);
            });

    }

    const getProductsPresentations = async (idProduct:number) => { 
        const getInstance = ProductService.getInstance();
        setLoadingPresentation(true);

        await getInstance.getProductsPresentations({id: idProduct, search: filters.search})
            .then((response:any) => {
                
                    if(response.success === true)
                    {
                        setPresentations(response.products);

                    }
                    setLoadingPresentation(false);
                    
                })
            .catch((err:any) => {
                setLoadingPresentation(false);
            });

    }

    const updateProduct = async (imgProduct:string) => { 
        setLoadingButton(true);
        const getInstance = ProductService.getInstance();
            
        await getInstance.updateProduct({...data, ['imagen_product']: imgProduct})
            .then((response:any) => {
                setLoadingButton(false);
                if(response.success === true)
                {
                    window.location.href = '/admin/productos'
                }
                
                    
            })
            .catch((err:any) => {
                setLoadingButton(false);
                console.log(err);
               
                
            });
    
    }

    const updateProductPresentationStatus = async (id:number, status:number) => { 
        const getInstance = ProductService.getInstance();
       
        await getInstance.updateProductPresentationStatus({id: id, status: status})
            .then((response:any) => {
                setConfirmDataStatus({
                    ...confirmDataStatus,
                    show: false,
                    id: 0,
                    name: '',
                    status: 0
                });
                getProductsPresentations(idProduct);
            })
            .catch((err:any) => {
                
            });

    }


    const deletePresentation = async (id:number) => { 
        const getInstance = ProductService.getInstance();
       
        await getInstance.deletePresentation({id: id})
            .then((response:any) => {
                setConfirmDataStatus({
                    ...confirmDataDelete,
                    show: false,
                    id: 0,
                    name: '',
                    status: 0
                });
                getProductsPresentations(idProduct);
            })
            .catch((err:any) => {
                
            });

    }

    const onSelectStage = (selectedList:any, selectedItem:any) => {
        setData({
            ...data,
            ['etapas']: selectedList
        });
    }

    const onRemoveStage = (selectedList:any, removedItem:any) => {
        setData({
            ...data,
            ['etapas']: selectedList
        });
    }

    const onSelectActivity = (selectedList:any, selectedItem:any) => {
        setData({
            ...data,
            ['actividades']: selectedList
        });
    }

    const onRemoveActivity = (selectedList:any, removedItem:any) => {
        setData({
            ...data,
            ['actividades']: selectedList
        });
    }

    const onSelectExperience = (selectedList:any, selectedItem:any) => {
        setData({
            ...data,
            ['experiencias']: selectedList
        });
    }

    const onRemoveExperience = (selectedList:any, removedItem:any) => {
        setData({
            ...data,
            ['experiencias']: selectedList
        });
    }

    const handleChangeResumen = (html: any) => {
        if(data?.id_produc > 0){
            setData({
                ...data,
                ['resumen_nutritivo'] : html
            });
        }
       
        
      };

    const handleChangeData= (event: any) => {
        const { name, value} = event.target;
        let copy = {...data};
        let val = value;
        
        if((name==='linea' || name==='categoria' || name==='presentacion') && value != '')
        {
            val = parseInt(value);
        }
        if((name==='precio' || name==='descuento') && value != '')
        {
            val = parseFloat(value);
        }

    
        setData({
            ...data,
            [name]: event.target.type==='checkbox' ? event.target.checked : val
        });
        
      };

    const  handleSave = (event: any) => {
        validSquema.validate(data, { abortEarly: false }).then(async function() {
            // Success
            
            var result = await uploadFile(selectedProductFile,'upload/productos').then((response:any) => {
                
                if(response.status == 200)
                {
                    let result = response.data.result;
                    if(result.success == true)
                    {
                       return result.data.imagen_path;
                        
                    }
                }
                return '';
                
            });

            updateProduct(result);

            setErrors(null);
        }).catch(function (err) {
            let errorArray = {};
            const errors = yupToFormErrors(err);
            console.log(errors);
            setErrors(errors);
            setValidated(true);
           
        });
        
    }

    const handleChangeFilter= (event: any) => {
    
        const { name, value} = event.target;
        setFilters({
            ...filters,
            [name]: value
            });      
        
        };

    const handleSearch = (e:any) => {
        getProductsPresentations(idProduct);
    }

    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        if (!selectedProductFile) {
            setPreviewProductFile("")
            return
        }

        const objectUrl = URL.createObjectURL(selectedProductFile)
        setPreviewProductFile(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedProductFile])

    const onSelectProductFile = (e:any) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedProductFile(undefined)
            return
        }

        // I've kept this example simple by using the first image instead of multiple
        setSelectedProductFile(e.target.files[0])
    }

    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        if (!selectedDetailFile) {
            setPreviewDetailFile("")
            return
        }

        const objectUrl = URL.createObjectURL(selectedDetailFile)
        setPreviewDetailFile(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedDetailFile])

    const onSelectDetailFile = (e:any) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedDetailFile(undefined)
            return
        }

        // I've kept this example simple by using the first image instead of multiple
        setSelectedDetailFile(e.target.files[0])
    }

    const handleCloseStatus= (event: any) => {
    
        setConfirmDataStatus({
            ...confirmDataStatus,
            show: false
        })    
        
    };

    const handleChangeStatus= (e:any,id:number, name: string) => {
        setConfirmDataStatus({
            ...confirmDataStatus,
            show: true,
            id: id,
            name: name,
            status: e.target.checked
        });
    }

    const handleUpdateStatus = (event:any) => {
      
        setConfirmDataStatus({
            ...confirmDataStatus,
            show: false
        });
        updateProductPresentationStatus(confirmDataStatus.id, confirmDataStatus.status);
    }

    const handleConfirmDelete = (e:any,id:number, name: string) => {
        setConfirmDataDelete({show:true, id: id, name: name, status: 0})
    }

    const handleCloseDelete= (event: any) => {
    
        setConfirmDataDelete({
            ...confirmDataDelete,
            show: false
        })    
        
    };

    const handleDeleteProduct = (event:any, id:number) => {
        setConfirmDataDelete({
            ...confirmDataDelete,
            show: false
        });
        deletePresentation(id);
    }

    return (
        <BackLayout>
        <div className='fond_backoffice'>
            <Container>                
                <div className='cont_title'>
                    <h1>Editar Producto</h1>
                </div>
                <div className='cont_data'> 
                    <div className='cont_tabs' style={{ display: 'block', padding: 30 }}>
                        <Tabs defaultActiveKey="second">
                            <Tab eventKey="first" title="Producto">                                                   
                                <Form id="form-registrar-producto" validated={validated}>
                                    <Row className="">
                                        <Col md={12}>
                                            <div className="section_backoffice_form">
                                                <div className="">
                                                    <h3>Datos del Producto</h3>
                                                    <Row>
                                                        <Col md={6}>                              
                                                            <Form.Group>                        
                                                                <Form.Label>Línea*:</Form.Label>
                                                                <Form.Control as="select" name="linea" required value={data.linea} onChange={e => {handleChangeData(e)}}>
                                                                <option value={''}>Seleccione</option>
                                                                    {linea.map((item:any) => (
                                                                    <option key={item.id} value={item.id}>
                                                                        {item.name}   
                                                                    </option>
                                                                    ))} 
                                                                </Form.Control>
                                                                {errors?.linea != "" && (
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {errors?.linea}
                                                                    </Form.Control.Feedback>
                                                                )}
                                                            </Form.Group>  
                                                        </Col>
                                                        <Col md={6}>                              
                                                            <Form.Group>                        
                                                                <Form.Label>Categoría*:</Form.Label>
                                                                <Form.Control as="select" name="categoria" required value={data.categoria} onChange={e => {handleChangeData(e)}}>
                                                                    <option value={''}>Seleccione</option>
                                                                    {categorias.map((item:any) => (
                                                                    <option key={item.id} value={item.id}>
                                                                        {item.name}   
                                                                    </option>
                                                                    ))}  
                                                                </Form.Control>                                                    
                                                                {errors?.categoria != "" && (
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {errors?.categoria}
                                                                    </Form.Control.Feedback>
                                                                )}
                                                            </Form.Group>  
                                                        </Col>
                                                        <Col md={6}>                              
                                                            <Form.Group>                        
                                                                <Form.Label>Nombre*:</Form.Label>
                                                                <input type="text" name='nombre' required className='form-control' value={data.nombre} onChange={e => {handleChangeData(e)}}/>                                                    
                                                                {errors?.nombre != "" && (
                                                                    <Form.Control.Feedback type="invalid">
                                                                        {errors?.nombre}
                                                                    </Form.Control.Feedback>
                                                                )}
                                                            </Form.Group>  
                                                        </Col>
                                                    
                                                        <Col md={12}>
                                                            <Form.Group>
                                                            <Form.Label>Descripción</Form.Label>
                                                            <textarea name="descripcion" className="form-control" rows={2} placeholder="" value={data.descripcion} onChange={e => {handleChangeData(e)}}></textarea>
                                                            </Form.Group>
                                                        </Col>  
                                                        <Col md={6}>
                                                        {(selectedProductFile=='' || selectedProductFile===undefined) && data.imagen_product != null && (<Row className="pad_top_15">
                                                                <div id="img_box" className="pad_bot_15">
                                                                    <div>
                                                                    <img className="edit-img-propre" width="150px" src={process.env.REACT_APP_BASE_URL_BACK + data.imagen_product} alt="" data-img="56"/>
                                                                    
                                                                    </div>
                                                                </div>
                                                            </Row>  ) }                            
                                                            <Form.Group>                        
                                                                <Form.Label>Imagen:</Form.Label>
                                                                <Form.Control type="file" as='input' name='imagen_suscripcion_producto' className='form-control' onChange={onSelectProductFile}/>
                                                                {selectedProductFile &&  <img className="edit-img-propre" style={{width: '200px'}} src={previewProductFile} /> }
                                                            </Form.Group>  
                                                        </Col>
                                                        
                                                    </Row>
                                                </div>
                                            
                                                <div className="section_backoffice">
                                                <h3>Detalle del Producto</h3>
                                                    <Row>
                                                    
                                                        <Col md={12}>
                                                            <Form.Group>
                                                            <Form.Label>Descripción detalle</Form.Label>
                                                            <textarea name="descripcion_detalle" className="form-control" rows={2} placeholder="" value={data.descripcion_detalle} onChange={e => {handleChangeData(e)}}></textarea>
                                                            </Form.Group>
                                                        </Col>  
                                                        <Col md={12}>
                                                        <Form.Group>
                                                                <Form.Label>Resumen nutritivo</Form.Label>
                                                                <ReactQuill theme="snow" value={data.resumen_nutritivo} onChange={handleChangeResumen} />
                                                                <Form.Control as="textarea" id="resumen_nutritivo" style={{display: 'none'}} rows={5} name="resumen_nutritivo" className='w_100' value={data.resumen_nutritivo}/>
                                        
                                                            </Form.Group>
                                                        </Col>  
                                                        <Col md={3}>                              
                                                            <Form.Group>                        
                                                                <Form.Label>Calorias:</Form.Label>
                                                                <input type="text" name='energia' className='form-control' value={data.energia} onChange={e => {handleChangeData(e)}}/>                                                    
                                                                <span className="help-block"></span>
                                                            </Form.Group>  
                                                        </Col>
                                                        <Col md={3}>                              
                                                            <Form.Group>                        
                                                                <Form.Label>Proteínas:</Form.Label>
                                                                <input type="text" name='proteina' className='form-control' value={data.proteina} onChange={e => {handleChangeData(e)}}/>                                                    
                                                                <span className="help-block"></span>
                                                            </Form.Group>  
                                                        </Col>
                                                        <Col md={3}>                              
                                                            <Form.Group>                        
                                                                <Form.Label>Grasas:</Form.Label>
                                                                <input type="text" name='grasa' className='form-control' value={data.grasa} onChange={e => {handleChangeData(e)}}/>                                                    
                                                                <span className="help-block"></span>
                                                            </Form.Group>  
                                                        </Col>
                                                        <Col md={3}>                              
                                                            <Form.Group>                        
                                                                <Form.Label>Humedad:</Form.Label>
                                                                <input type="text" name='humedad' className='form-control' value={data.humedad} onChange={e => {handleChangeData(e)}}/>                                                    
                                                                <span className="help-block"></span>
                                                            </Form.Group>  
                                                        </Col>
                                                        <Col md={3}>                              
                                                            <Form.Group>                        
                                                                <Form.Label>Etapa:</Form.Label>
                                                            
                                                                <Multiselect
                                                                    options={etapas} // Options to display in the dropdown
                                                                    onSelect={onSelectStage} // Function will trigger on select event
                                                                    onRemove={onRemoveStage} // Function will trigger on remove event
                                                                    displayValue="name" // Property name to display in the dropdown options
                                                                    placeholder='Elija una o más etapas'
                                                                    selectedValues={data.etapas}
                                                                />                                   
                                                            </Form.Group>  
                                                        </Col>
                                                        <Col md={3}>                              
                                                            <Form.Group>                        
                                                                <Form.Label>Actividad:</Form.Label>
                                                                <Multiselect
                                                                    options={actividades} // Options to display in the dropdown
                                                                    onSelect={onSelectActivity} // Function will trigger on select event
                                                                    onRemove={onRemoveActivity} // Function will trigger on remove event
                                                                    displayValue="name" // Property name to display in the dropdown options
                                                                    placeholder='Elija una o más actividades'
                                                                    selectedValues={data.actividades}
                                                                />    
                                                            </Form.Group>  
                                                        </Col>
                                                        <Col md={3}>                              
                                                            <Form.Group>                        
                                                                <Form.Label>Experiencia:</Form.Label>
                                                                <Multiselect
                                                                    options={experiencias} // Options to display in the dropdown
                                                                    onSelect={onSelectExperience} // Function will trigger on select event
                                                                    onRemove={onRemoveExperience} // Function will trigger on remove event
                                                                    displayValue="name" // Property name to display in the dropdown options
                                                                    placeholder='Elija una o más experiencias'
                                                                    selectedValues={data.experiencias}
                                                                />    
                                                            </Form.Group>  
                                                        </Col>
                                                    
                                                        <Col md={12} className="pad_top_2 dis_fle spac_betw">
                                                            <Col md={4} className="align-items-center txt_ali dis_fle con_btn_confirm">                                                    
                                                            <Button className='btn_confirm' type="button" onClick={(e)=>handleSave(e)} disabled={loading}>
                                                                {loading ? (
                                                                <Spinner animation="border" role="status" style={{width: '1rem', height: '1rem'}}>
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </Spinner>
                                                                ) : (
                                                                'Guardar'
                                                                )}
                                                            </Button>                                                   
                                                            </Col>
                                                            <Col md={4} className="align-items-center txt_ali dis_fle cont_btn_cancel">                                                    
                                                                <a href={process.env.REACT_APP_PUBLIC_URL + 'admin/productos'} className='btn_cancelar_registro btn'>Cancelar</a>                                                        
                                                            </Col>
                                                        </Col>
                                                    </Row>
                                                    
                                                </div>                                    
                                            </div>
                                        </Col>   
                                    </Row>                            
                                </Form>
                            </Tab>
                            <Tab eventKey="second" title="Presentaciones">
                            <Container>
                                <div className='section_backoffice_form'>
                                    <Container className='cont_filters'>
                                        <Row className='row_filter'>
                                            <Col md={6} className="align-items-center txt_ali dis_fle">
                                                <Col md={4}>
                                                    <Form.Label htmlFor="buscar">Buscar SKU:</Form.Label>
                                                </Col>
                                                <Col md={8}> 
                                                    <Form.Control as="input" name="search" type="search" placeholder='' value={filters.search} onChange={(e) => handleChangeFilter(e)}></Form.Control> 
                                                </Col>
                                            </Col>
                                            <Col md={3} className="align-items-center txt_ali dis_fle">
                                                <Button className='btn_filter_search' onClick={(e)=>handleSearch(e)} >Buscar</Button>
                                            </Col>                          
                                            <Col md={3} className="align-items-center txt_ali dis_fle">
                                                <a href={process.env.REACT_APP_PUBLIC_URL + 'admin/registrar-presentacion/'+idProduct} className='btn_export_excel btn'>Agregar Presentación</a>
                                            </Col> 
                                        </Row>                                        
                                    </Container>
                                    <Table responsive className='table_backoffice'>
                                        <thead>
                                            <tr>
                                                {/* <th>Ver detalle</th> */}
                                                <th>SKU</th>
                                                <th>Nombre</th>
                                                <th>Precio</th> 
                                                <th>Precio Descuento</th>  
                                                <th>Privado</th>                                         
                                                <th>Editar</th>
                                                <th>Estado</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {presentations.map((item:any) => {
                                                return (    
                                            <tr key={item.id_propre}>
                                                <td className='text_left'>{item.codigo}</td>
                                                <td className='text_left'>{item.concepto}</td>
                                                <td className='text_icon'>{item.precio}</td>
                                                <td className='text_icon'>{item.precio_descuento}</td>
                                                <td className='text_icon'>{item.privado == 1 ? 'Sí' : 'No'}</td>
                                                <td className='text_icon'>
                                                    <a href={process.env.REACT_APP_PUBLIC_URL + 'admin/editar-presentacion/' + item.id_propre} >
                                                        <div className='cont_ver_detalle'>                                
                                                            <img src={process.env.REACT_APP_PUBLIC_URL + 'images/common/edit.webp'} alt="Editar" />
                                                        </div>
                                                    </a>
                                                </td>
                                              
                                                <td className='text_icon'> 
                                                    <Form.Check className=''>
                                                        <Form.Check.Input name='estado' type="checkbox" checked={item.estado==1 ? true : false} onChange={(e) => handleChangeStatus(e, item.id_propre, item.concepto)}/>
                                                    </Form.Check>
                                                </td>
                                              
                                                <td className='text_icon'>
                                                    <a className="delete-cupon cur_point" onClick={(e) => handleConfirmDelete(e, item.id_propre, item.concepto)}>
                                                        <div className='cont_ver_detalle'>                                
                                                            <img src={process.env.REACT_APP_PUBLIC_URL + 'images/common/trush.webp'} alt="Eliminar" />
                                                        </div>
                                                    </a>
                                                </td>
                                            </tr>
                                            )
                                        })}
                                        </tbody>
                                    </Table>
                                </div>
                            </Container>
                            </Tab>
                        </Tabs>
                    </div>   
                </div>
            </Container>  
        </div>
        <ModalConfirm text="" handleClose={handleCloseStatus} show={confirmDataStatus?.show} handleSave={handleUpdateStatus} action={"¿Estás seguro de "+ (confirmDataStatus?.status == 0 ? "Desactivar" : "Activar") +" el producto " + confirmDataStatus?.name} id={confirmDataStatus?.id}/>
        <ModalConfirm text="" handleClose={handleCloseDelete} show={confirmDataDelete?.show} handleSave={handleDeleteProduct} action={"¿Estás seguro de eliminar al producto " + confirmDataDelete?.name} id={confirmDataDelete?.id}/>
        
        </BackLayout>

)};
export default EditarProducto;
