import React from 'react';
import "../reporteVentas/style.css";
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import BackLayout from "../../../components/layout/BackLayout/BackLayout";
import {SalesService} from '../../../business/SalesService';
import { formatNumber } from "../../../utils/formatCurrency";
import { useParams } from "react-router-dom";
import { useState, useCallback, useEffect } from 'react';

let defaultData = {
    comprador: "",
    fecha : "", 
    tarjeta : "", 
    marca_tarjeta : "", 
    estado : "", 
    dia : "", 
    producto : "", 
    periodo : "", 
    direccion_envio : "", 
    telefono_uno : "", 
    telefono_dos : "", 
    ubigeo : "", 
    total_suscripcion : 0, 
    racion_recomendada : "", 
    referencia : "", 
    observaciones : "", 
    subtotal : 0, 
    descuento : 0, 
    monto_total : 0, 
    total_plan : 0,   
    costo_envio: 0
}

function SuscripcionDetalle (){
    const [data, setData] = useState(defaultData);
    const [detalle, setDetalle] = useState<any>([]);
    const [loading, setLoading] = useState(false);

    let params = useParams();
    let id = 0;
    if(params.id_suscri !== undefined)
    {
        id = parseInt(params.id_suscri);
    }
    

    useEffect(() => {      
        getSuscription(id);     
      }, []);

    const getSuscription = async (idSuscription:number) => { 
        const getInstance = SalesService.getInstance();
        setLoading(true);

        await getInstance.getSuscription({id: idSuscription})
            .then((response:any) => {
                    setLoading(false);
                    if(response.success === true)
                    {
                        setData(response.data);
                        setDetalle(response.detalle);

                    }
                    
                })
            .catch((err:any) => {
                setLoading(false);
            });

    }
    return (
        <BackLayout>
        <div className='fond_backoffice'>
            <Container>      
                <div className='cont_title'>
                    <h1>Detalle suscripción</h1>
                </div>     
                <div className='cont_data'>     
                    <div className="row">
                        <div className="col-md-4">
                            <div className="back_detalle_venta">
                                <div className="">
                                    <div className="">
                                        <h2 className="title_detalle_venta">{data.comprador}</h2>
                                    </div>
                                </div>
                                <div>     
                                    <div className="">
                                        <span className="subtitle_detalle_venta">Fecha de suscripción: </span>
                                        <span className="res_detalle_venta">{data.fecha}</span>
                                    </div>
                                    <div className="">
                                        <span className="subtitle_detalle_venta">Estado de suscripción: </span>
                                        <span className="res_detalle_venta">{data.estado}</span>
                                    </div>
                                    <div className="">
                                        <span className="subtitle_detalle_venta">N° de tarjeta: </span>
                                        <span className="res_detalle_venta">{data.tarjeta}</span>
                                    </div>
                                    <div className="">
                                        <span className="subtitle_detalle_venta">Marca tarjeta: </span>
                                        <span className="res_detalle_venta">{data.marca_tarjeta}</span>
                                    </div>
                                    <div className="">
                                        <span className="subtitle_detalle_venta">Periodo de entrega: </span>
                                        <span className="res_detalle_venta">{data.periodo}</span>
                                    </div>
                                    <div className="">
                                        <span className="subtitle_detalle_venta">Direccion de entrega: </span>
                                        <span className="res_detalle_venta">{data.direccion_envio}</span>
                                    </div>
                                    <div className="">
                                        <span className="subtitle_detalle_venta">Zona ubigeo: </span>
                                        <span className="res_detalle_venta">{data.ubigeo}</span>
                                    </div>
                                    <div className="">
                                        <span className="subtitle_detalle_venta">Referencia: </span>
                                        <span className="res_detalle_venta">{data.referencia}</span>
                                    </div>
                                    <div className="">
                                        <span className="subtitle_detalle_venta">Telefono 1: </span>
                                        <span className="res_detalle_venta">{data.telefono_uno}</span>
                                    </div>
                                    <div className="">
                                        <span className="subtitle_detalle_venta">Telefono 2: </span>
                                        <span className="res_detalle_venta">{data.telefono_dos}</span>
                                    </div>
                                    <div className="">
                                        <span className="subtitle_detalle_venta">Observaciones: </span>
                                        <span className="res_detalle_venta">{data.observaciones}</span>
                                    </div>
                                </div>                     
                                
                            </div>
                        </div>
                        <div className="col-md-8 back_detalle_venta_productos">
                            <div className="">
                                <div className='cont_d_venta_p'>
                                    <div className="d-flex align-items-center flex-wrap">                        
                                        <div className="d-flex align-items-center flex-lg-fill justify_c">
                                            <div className="d-flex flex-column">
                                                <span className="font_total_dv">Subtotal</span>
                                                <span className=""><span className="">S/ </span>{formatNumber(data.subtotal)}</span>
                                            </div>
                                        </div>    
                                        <div className="d-flex align-items-center flex-lg-fill justify_c">
                                            <div className="d-flex flex-column">
                                                <span className="font_total_dv">Descuento 5%</span>
                                                <span className=""><span className="">-S/ </span>{formatNumber(data.descuento)}</span>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center flex-lg-fill justify_c">
                                            <div className="d-flex flex-column">
                                                <span className="font_total_dv">Delivery</span>
                                                <span className=""><span className="">S/ </span>{formatNumber(data.costo_envio)}</span>
                                            </div>
                                        </div> 
                                        <div className="pad_20 d-flex align-items-center flex-lg-fill justify_c">
                                            <div className="d-flex flex-column">
                                                <span className="font_total_dv"><b>TOTAL</b></span>
                                                <span className="fw_bold"><span className="">S/ {formatNumber(data.monto_total)}</span></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-xs-12">
                                        <h4 className="title_product_detail">Productos</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-12 col-xs-12">
                                                <div className="table-responsive">
                                                    <Table responsive className='table_backoffice'>
                                                        <thead>
                                                            <tr>
                                                                <th>Producto</th>
                                                                <th>Cantidad</th>
                                                                <th>P. Unitario</th>
                                                                <th>Importe</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {detalle.map((itemped:any) => {
                                                                    return (    
                                                                        <tr>
                                                                        <th>{itemped.producto}</th>
                                                                        <td>{itemped.cantidad}</td>
                                                                        <td>{itemped.precio_unitario}</td>
                                                                        <td>{formatNumber(itemped.total)}</td>                    
                                                                        
                                                                    </tr> 
                                                                )
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    </BackLayout>
)};
export default SuscripcionDetalle;
