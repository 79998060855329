import { Button, Stack } from "react-bootstrap"
import './style.css';

type BenefitProps = {
  image: string
  title: string
  description: string
  lastClass: string
}

function Benefit({ image, title, description, lastClass }: BenefitProps) {
 
  return (
    <div className="benefit-item">
      <div className={"image-wrapper " + lastClass}>
        <img
          src={image}
          style={{ height: "3.5rem" }}
          alt="Beneficios"
        />
      </div>     
      <h2 className="color-primary">{title}</h2>
      <div className="color-secondary">
        <ul style={{fontSize: '1.1rem', textAlign: 'left'}} dangerouslySetInnerHTML={{__html: description}}></ul>
      </div>
    </div>
  )
}

export default Benefit;