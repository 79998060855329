import React from 'react';
import "./embajadores.css";
import { Row, Col, Button } from 'react-bootstrap';
import Paso from '../../components/frontUtility/programSteps';
import ButonCreateAccount from '../../components/frontUtility/butonCreateAccount';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';
import MainLayout from "../../components/layout/MainLayout/MainLayout";

let pasos = [
    {
        image: process.env.REACT_APP_PUBLIC_URL + "images/embajadores/programa/tag.webp"
        ,number: "1"
        ,textParagraph: "Una vez que el referido ingrese a nuestra plataforma online, cree su cuenta de usuario y utilice tu código de descuento, accederá en su primera compra a un descuento del 10% y quedará ligado indefinidamente a tu cuenta de manera automática."
    },
    {
        image: process.env.REACT_APP_PUBLIC_URL + "images/embajadores/programa/click.webp"
        ,number: "2"
        ,textParagraph: "Si utilizó el link al momento de crear la cuenta, ya no necesitará ingresar manualmente el código de descuento para acceder al beneficio ni para asociar las cuentas."
    },
    {
        image: process.env.REACT_APP_PUBLIC_URL + "images/embajadores/programa/moneda.webp"
        ,number: "3"
        ,textParagraph: "A partir de la segunda compra del usuario, y por todas sus compras futuras por el mismo medio, tu obtendrás de manera inmediata Ori Points equivalentes al 5% del valor de cada compra del usuario."
    },
]
let pasos2 = [
    
    {
        image: process.env.REACT_APP_PUBLIC_URL + "images/embajadores/programa/money.webp"
        ,number: "4"
        ,textParagraph: "Podrás consultar y utilizar todos los Ori Points generados por tus referidos de manera automática en tu perfil de usuario."
    },
    {
        image: process.env.REACT_APP_PUBLIC_URL + "images/embajadores/programa/car_.webp"
        ,number: "5"
        ,textParagraph: "Podrás utilizar los Ori Points de manera inmediata posterior al momento en que fueron generados."
    },
    {
        image: process.env.REACT_APP_PUBLIC_URL + "images/embajadores/programa/heart.webp"
        ,number: "6"
        ,textParagraph: "Al momento de finalizar la compra, podrás seleccionar si quieres utilizar tus Ori Points para pagar parcial o totalmente tus productos."
    },
]

function Embajadores (){

    const handleClickScroll = () => {
        const element = document.getElementById('recomendarOrigo');
        if (element) {
          // 👇 Will scroll smoothly to the top of the next section
          element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    return (
    <MainLayout>
    <div>
        <section className="section_embajador bg-color bg-embajador">
            <Row>
                <Col md={5}>
                </Col>
                <Col md={6} className="pdt-100 center-flex">
                    <div className="pdt-top">
                        <p className="title-emb">Programa de</p>
                        <p className="title-emb2">Referidos Origo</p>
                        <p className="text-emb">Para nosotros es muy importante compartir nuestra filosofía de alimentación con la mayor cantidad de perros y gatos posibles, por eso, hemos creado el programa de referidos Origo en donde tu podrás ser parte de este gran cambio positivo en su calidad de vida y al mismo tiempo obtener beneficios adicionales.</p>
                        <p></p>
                        <p className="text-emb pdt-text-embajadores">Es sencillo. Recomienda a tus amigos que prueben Origo a través de nuestra plataforma online (<u>www.Origo.pet</u>) ofreciéndoles 10% de descuento en su primera compra y podrás ganar <strong>OriPoints </strong>equivalentes al 5% del valor de todas las compras futuras de cada referido tuyo. <br></br>Automáticamente y de por vida.</p>
                        <p className="firma-emb" onClick={handleClickScroll} ><strong>¡Se parte del cambio!</strong></p>
                        
                    </div>
                </Col>
                <Col md={1}>
                </Col>
            </Row>
        </section>
        <section className="comoser dog-banner-old" id="recomendarOrigo">
            <div>
                <div className="row-2">
                    <Col md={12} className="f-wrap pdt-75">
                        <div className="text-center">
                            <img className="img-refresh hidden_responsive" src={process.env.REACT_APP_PUBLIC_URL + "images/common/origoo.webp"}/>
                            <p className="fs_cs title-ser hidden_responsive">¿Cómo recomendar Origo?</p>
                            <p className="fs_cs title-ser hidden_web">¿Cómo ser un</p>
                            <p><span className='esp_emb_or'>Embajador Origo?</span></p>
                        </div>
                        <Row className="bg-transparencia content-embajadores">
                            <Col md={6} className="pdt-content hidden_responsive">
                                <div className="spaces-paso">
                                    <p className="title-paso org-paso"><span className="neg-paso">PASO 1. </span>CREA TU CUENTA EN ORIGO.PET</p>
                                    <p className="text-paso">Ingresa a nuestra plataforma, haz click en <span  className="neg-pas">REFERIDOS ORIGO</span> y crea, por primera y única vez, tu cuenta de usuario. Necesitamos saber de ti y tus datos más importantes</p>
                                </div>

                                <div className="spaces-paso">
                                    <p className="title-paso org-paso"><span className="neg-paso">PASO 2. </span>GENERA TU CÓDIGO O LINK DE DESCUENTO</p>
                                    <p className="text-paso">En tu perfil podrás generar un código de descuento (siempre será el mismo) que únicamente te identificará a ti como usuario.
                                    <span className="neg-pas">Con este código, podrás ofrecer 10% de descuento</span> en la primera compra a todas las personas que quieras.
                                    Este descuento aplica para todos los usuarios que vayan a realizar una compra a través de la plataforma online de ORIGO por primera vez. </p>
                                </div>

                                <div className="spaces-paso">
                                    <p className="title-paso org-paso"><span className="neg-paso">PASO 3. </span>COMPARTE TU CÓDIGO / LINK</p>
                                    <p className="text-paso">Una vez que hayas generado el código o link, puedes compartirlo por el medio de tu preferencia (Instagram, WhatsApp, Facebook, Twitter, etc).
                                        Si compartes el link de descuento tus amigos no necesitarán ingresar manualmente el código de descuento al momento de crear su cuenta.</p>
                                </div>
                            </Col>
                            <Col md={6} className="pdt-content hidden_responsive">
                                <div className="spaces-paso">
                                    <p className="title-paso org-paso"><span className="neg-paso">PASO 4. </span>REVISA TUS GANANCIAS</p>
                                    <p className="text-paso">A partir de la segunda compra, cada persona que haya utilizado tu código o link para hacer una compra en nuestra plataforma te generará una <span className="neg-pas">ganancia equivalente al 5% del valor de su compra,</span> cada vez que compre en Origo.pet. Para siempre.
                    ​                 <br></br>
                    ​                 <br></br>
                                    Mantente al tanto de las compras de tus referidos y los <span className="neg-pas">Ori Points</span> que ellos te generan consultando tu perfil de usuario.
                                    Es automático y muy sencillo de consultar.</p>
                                    <div className="ori-point">
                                        1 Ori Point =  S/ 1.00
                                    </div>
                                </div>
                                <div className="spaces-paso">
                                    <p className="title-paso org-paso"><span className="neg-paso">PASO 5. </span>UTILIZA TUS ORI POINTS</p>
                                    <p className="text-paso">Con los Ori Points generados por tus referidos, podrás pagar total o parcialmente cualquier producto que quieras comprar en nuestra tienda online.
                                    </p>
                                </div>
                            </Col>
                            <Col md={12} className='hidden_web section_pasos_acordion'>
                                <Accordion className='acordion_pasos'>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header><span className='icon-tc'></span><h3><p className="neg-paso">PASO 1. </p>CREA TU CUENTA EN ORIGO.PET</h3></Accordion.Header>
                                        <Accordion.Body>
                                        <p className="text-paso">Ingresa a nuestra plataforma, haz click en <span  className="neg-pas">REFERIDOS ORIGO</span> y crea, por primera y única vez, tu cuenta de usuario. Necesitamos saber de ti y tus datos más importantes</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header><span className='icon-tc'></span><h3><p className="neg-paso">PASO 2. </p>GENERA TU CÓDIGO O LINK DE DESCUENTO</h3></Accordion.Header>
                                        <Accordion.Body>
                                        <p className="text-paso">En tu perfil podrás generar un código de descuento (siempre será el mismo) que únicamente te identificará a ti como usuario.
                                        <span className="neg-pas">Con este código, podrás ofrecer 10% de descuento</span> en la primera compra a todas las personas que quieras.
                                        Este descuento aplica para todos los usuarios que vayan a realizar una compra a través de la plataforma online de ORIGO por primera vez. </p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header><span className='icon-tc'></span><h3><p className="neg-paso">PASO 3. </p>COMPARTE TU CÓDIGO / LINK</h3></Accordion.Header>
                                        <Accordion.Body>
                                        <p className="text-paso">Una vez que hayas generado el código o link, puedes compartirlo por el medio de tu preferencia (Instagram, WhatsApp, Facebook, Twitter, etc).
                                        Si compartes el link de descuento tus amigos no necesitarán ingresar manualmente el código de descuento al momento de crear su cuenta.</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header><span className='icon-tc'></span><h3><p className="neg-paso">PASO 4. </p>REVISA TUS GANANCIAS</h3></Accordion.Header>
                                        <Accordion.Body>
                                        <p className="text-paso">A partir de la segunda compra, cada persona que haya utilizado tu código o link para hacer una compra en nuestra plataforma te generará una <span className="neg-pas">ganancia equivalente al 5% del valor de su compra,</span> cada vez que compre en Origo.pet. Para siempre.
                        ​                 <br></br>
                        ​                 <br></br>
                                        Mantente al tanto de las compras de tus referidos y los <span className="neg-pas">Ori Points</span> que ellos te generan consultando tu perfil de usuario.
                                        Es automático y muy sencillo de consultar.</p>
                                        <div className="ori-point">
                                            1 Ori Point =  S/ 1.00
                                        </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header><span className='icon-tc'></span><h3><p className="neg-paso">PASO 5. </p>UTILIZA TUS ORI POINTS</h3></Accordion.Header>
                                        <Accordion.Body>
                                        <p className="text-paso">Con los Ori Points generados por tus referidos, podrás pagar total o parcialmente cualquier producto que quieras comprar en nuestra tienda online.</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Col>
                        </Row>    
                        <ButonCreateAccount text="Crea tu cuenta aquí" url={process.env.REACT_APP_PUBLIC_URL + "registro"}/>                        
                    </Col>
                </div>
            </div>
        </section>
        <section className="programa embajador-banner-1 into-funcionamiento">
            <div>
                <Col md={12} className="text-center">
                    <p className="title-programa">Funcionamiento del programa de</p>
                    <p className="subtitle-programa">Referidos Origo</p>
                </Col>
            </div>
            <Row className="row-funcionamiento">
                <Col md={6} className="">
                    {pasos.map((item) => (
                        <Paso image={item.image}
                        number={item.number}
                        textParagraph={item.textParagraph} />
                    ))}                    
                </Col>
                <Col md={6} className="">
                    {pasos2.map((item) => (
                        <Paso image={item.image}
                        number={item.number}
                        textParagraph={item.textParagraph} />
                    ))}                     
                </Col>
            </Row>
        </section>
        <section className="terminos-cond section_embajadores">
            <Row>
                <Col md={12} className="text-center">
                    <p className="terminos">Términos y condiciones</p>
                </Col>
            </Row>
            <Accordion className='acordion_terminos'>
                <Accordion.Item eventKey="0">
                    <Accordion.Header><span className='icon-tc'></span><h3>PAUTAS GENERALES</h3></Accordion.Header>
                    <Accordion.Body>
                    <p>El Programa de Referidos te permite conseguir OriPoints por recomendar nuestros productos
                    con tu código o link promocional.<br></br><br></br>
                    Estos códigos o links promocionales deberán utilizarse debidamente para que nuestro sistema
                    pueda detectarlo y el usuario quede vinculado a tu cuenta de embajador.<br></br><br></br>
                    Cuando los usuarios utilizan el código o link promocional y compran en nuestro sitio www.origo.pet,
                    éstos quedarán vinculados a tu cuenta y conseguirás el 5% del valor de su compra en
                    OriPoints en todas sus compras futuras.<br></br><br></br>
                    Para facilitar la publicidad de nuestros productos, podremos facilitar banners, imágenes, cupones de
                    descuento para tus referidos u otras herramientas. Queda expresamente prohibido su uso fuera de
                    la promoción de nuestros productos y deberán respetar la identidad corporativa de la marca
                    (tipografía y color del logo, valores de la marca, nombres comerciales, etc).</p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header><span className='icon-tc'></span><h3>REQUISITOS DE CUMPLIMIENTO</h3></Accordion.Header>
                    <Accordion.Body>
                    <p>Para participar y recibir los beneficios de nuestro Programa de Referidos, debes cumplir con este
                    acuerdo. En el caso de que lo infrinjas, nos reservamos el derecho, además de cualquier otro
                    derecho y recurso que nos asista, a retener a perpetuidad los beneficios que, de otro modo, te
                    hubiesen correspondido con arreglo a este acuerdo, con independencia de que estén o no
                    directamente relacionadas con dicha infracción. Todo ello sin necesidad de aviso y sin perjuicio de
                    los derechos que asistan a Origo Pet Food S.A.C. de reclamar daños y perjuicios que excedan de
                    dicho importe. Asimismo, nos reservamos el derecho a suspender tu cuenta o cualquier relación que
                    nos una.<br></br><br></br>
                    También, deberás facilitarnos cualquier información que te solicitemos para verificar el
                    cumplimiento del acuerdo.<br></br><br></br>
                    Tú, como embajador, debes garantizar que ninguno de tus sitios web o canales donde difundas tus
                    códigos promocionales infringen ninguna ley o código de conducta, y que tampoco lo hacen tus
                    actividades como participante en nuestro Programa de Referidos.<br></br><br></br>
                    Aparte de obtener beneficios por estar adscrito al programa de Referidos, no podrás realizar
                    ninguna otra comunicación o manifestación pública relativa a este acuerdo, sin contar con nuestra
                    previa autorización por escrito. Asimismo, no podrás realizar manifestaciones falsas ni tergiversar o
                    distorsionar la relación establecida entre nosotros, ni declarar o hacer entender, ya sea expresa o
                    tácitamente, la existencia de cualquier vinculación o asociación entre nosotros, excepto en la
                    medida expresamente autorizada bajo el presente acuerdo.</p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header><span className='icon-tc'></span><h3>EXONERACIÓN DE RESPONSABILIDAD</h3></Accordion.Header>
                    <Accordion.Body>
                    <p>No garantizamos que nuestro Programa de Referidos Origo funcione sin interrupción, sin errores
                    o según lo esperado.<br></br><br></br>
                    Ni nosotros ni ninguna de nuestras entidades vinculadas seremos responsables, ni asumiremos
                    responsabilidad alguna por daños indirectos, consecuenciales, punitivos o cualquier lucro cesante,
                    pérdida de fondo de comercio, de uso o de datos que se derive de tu actividad como Embajador
                    Origo</p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header><span className='icon-tc'></span><h3>MODIFICACIONES</h3></Accordion.Header>
                    <Accordion.Body>
                    <p>Nos reservamos el derecho de cambiar las condiciones de nuestro Programa de Referidos Origo
                    en cualquier momento.
                    Los cambios y revisiones se comunicarán mediante correo electrónico o actualización de este
                    acuerdo. Tu continuidad en el Programa de Referidos Origo constituirá la aceptación de dichos
                    cambios.</p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header><span className='icon-tc'></span><h3>SUSPENSIÓN DE CUENTA DEL PROGRAMA DE REFERIDOS ORIGO</h3></Accordion.Header>
                    <Accordion.Body>
                    <p>En caso de existir una causa justificada, nosotros estaremos legitimados y podremos suspender tu
                    cuenta o resolver, con efectos inmediatos, el presente acuerdo, así como tu derecho a recibir
                    cualquier compensación por ello, notificándotelo por correo en el caso que surja cualquiera de las
                    siguientes situaciones: </p>
                    <ol className='list-type'>
                    <li>Que hubieras cometido un incumplimiento del presente acuerdo</li>
                    <li>Que no lo subsanes en un plazo de siete (7) días</li>
                    <li>Que consideremos posible que tengamos que enfrentarnos a reclamaciones o responsabilidades
                        por razón de o derivadas de tu participación en el Programa de Referidos</li>
                    <li>Que hayas participado en el Programa de Referidos con fines ilegales, engañosos o
                        fraudulentos</li>
                    <li>Que consideremos que nuestra marca o nuestra reputación puede verse perjudicada por ti o por
                        tu participación en el Programa de Referidos</li>
                    <li>Que consideremos que estemos o podamos quedar sujetos a requisitos de cobro de impuestos en
                        relación con este acuerdo o con las actividades que cualquiera de las partes realice con arreglo a
                        este acuerdo</li>
                    <li>Que hayamos resuelto previamente este acuerdo contigo (suspendido tu cuenta) o con otras
                        personas que determinemos se encuentran vinculadas a ti o actúan en concierto contigo por la
                        razón que sea</li>
                    <li>Que consideremos que tu sitio web o acciones publicitarias no cumplen con este acuerdo</li>
                    <li>Que hayamos resuelto y dado por terminado el Programa de Referidos Origo en la forma y
                        versión en la que generalmente lo ponemos a disposición de los participantes.</li>
                    </ol>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Col md={12}>
                <ButonCreateAccount text="Crea tu cuenta aquí" url={process.env.REACT_APP_PUBLIC_URL + "registro"}/>                        
            </Col>                  
        </section>
    </div>
    </MainLayout>
)};

export default Embajadores;