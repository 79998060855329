import { api } from "../api";
import ActionType from "../utils/customTypes";


export class PurchaseService extends api {

    private static classInstance?: PurchaseService;
        
    constructor(token: string, type: ActionType) {
        super( process.env.REACT_APP_BASE_URL,token);    
    } 
     
    public static getInstance() {
        let token = localStorage.getItem("token");
        if (!this.classInstance) {
            this.classInstance = new PurchaseService(token!==undefined && token !== null ? token:'', 'GET');
        }
        
        return this.classInstance;
        
    }   
     
    public getMyPurchases = (data:any) => this.instance.post('/purchase/get-my-purchases',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getPurchaseDetail = (data:any) => this.instance.post('/purchase/get-purchase-detail', data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getMySuscriptions = (data:any) => this.instance.post('/purchase/get-my-suscriptions',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getSuscriptionDetail = (data:any) => this.instance.post('/purchase/get-suscription-detail', data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getMyDonations = (data:any) => this.instance.post('/purchase/get-my-donations', data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getDonationDetail = (data:any) => this.instance.post('/purchase/get-donation-detail', data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public updateSuscription= (data:any) => this.instance.post('/purchase/update-suscription', data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public updateDonation= (data:any) => this.instance.post('/purchase/update-donation', data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });
   
}