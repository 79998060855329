import "./style.css";
import { useState , useEffect } from 'react';

const StarRating = (props:any) => {
    const [rating, setRating] = useState(props.value);
    const [hover, setHover] = useState(0);

    const onChange = (index:number) => {
        setRating(index);
        props.onChange(index);
    };

    if(props.readonly==true)
    {
        return (
            <div className="star-rating">
              {[...Array(5)].map((star, index) => {
                index += 1;
                
                  return (
                  
                     <img className='star' src={process.env.REACT_APP_PUBLIC_URL + "images/common/" + (index <= (hover || rating) ? "star-s.webp" : "star.webp")} alt="Star" />
                  
                  );
              })}
            </div>
          );
    }
    else{
        return (
            <div className="star-rating">
              {[...Array(5)].map((star, index) => {
                index += 1;
                return (
                  <button
                    type="button"
                    key={index}
                    className={index <= (hover || rating) ? "on" : "off"}
                    onClick={() => onChange(index)}
                    onMouseEnter={() => setHover(index)}
                    onMouseLeave={() => setHover(rating)}
                  >
                    <span className="star">&#9733;</span>
                  </button>
                );
              })}
            </div>
          );
    }
   
  };

  export default StarRating;