import './style.css';
import { Row , Col} from "react-bootstrap"
  
  type PasoProps = {
    textParagraph: string
    image: string
    number: string
  }
  

function Paso(props: PasoProps){
  
    return (
        <div className="box-programa">
            <div className="image-numb">
                <div>
                    <p className="number-p">{props.number}.  </p>
                </div>
                <div>
                    <img className="tag" src={props.image} alt="Programa"/>
                </div>
            </div>
            <p className="parraf-programa">{props.textParagraph}</p>
        </div>
    );
}
export default Paso;