import { api } from "../api";
import ActionType from "../utils/customTypes";

export class WebService extends api {

    private static classInstance?: WebService;
        
    constructor(token: string, type: ActionType) {
        super( process.env.REACT_APP_BASE_URL,token);    
    } 
  
    public static getInstance() {
        let token = localStorage.getItem("token");
        if (!this.classInstance) {
            this.classInstance = new WebService(token!==undefined && token !== null ? token:'', 'GET');
        }
        
        return this.classInstance;
    }   

    public getPublicity= () => this.instance.post('/web/get-publicity').then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public insertImage= (data:any) => this.instance.post('/web/insert-image', data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });
   
    public updatePublicity= (data:any) => this.instance.post('/web/update-publicity', data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public deleteImage= (data:any) => this.instance.post('/web/delete-image', data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });
   
    
}