import React from 'react';
import { useShoppingCart } from "../../context/ShoppingCartContext"

/**
 * Redirect Screen Component
 * @packageDocumentation
 * @module Redirect
 */
const RedirectRol: React.FC<{}> = () => {
    let role=localStorage.getItem('rol');

    switch(role)
      {
          case '1': window.location.href='/admin/dashboard';
              break;
          default:
                window.location.href = '/cliente/resumen';
      }
    

 return (
        <></>
      );
        
}


export default RedirectRol;
