import React from 'react';
import "./style.css";
import { Row, Col, Form, Button } from 'react-bootstrap';

function BeforeFooter(){
    return (
        <>
        <Row className="">
            <Col md={12} className="cont_cont_a">
                <img className="img_cont_contact" src={process.env.REACT_APP_PUBLIC_URL + "images/social/mensaje.webp"} alt="Mensaje"/>
                <span className="title_cont_contact">¡Cuéntanos lo que necesitas!</span>
                <a className="btn-cont-contact" href="mailto:info@origo.pet">CONTÁCTENOS</a>
            </Col>
            {/* <Col md={6} className="cont_cont_b">
                <img className="img_cont_contact" src={process.env.REACT_APP_PUBLIC_URL + "images/social/chat.webp"} alt="Chat"/>
                <span className="title_cont_contact">¿Quieres un plan personalizado?</span>
                <button className="btn-cont-contact">EMPECEMOS</button>
            </Col> */}
        </Row>
        
        </>
    );
}
export default BeforeFooter;