import Modal from 'react-bootstrap/Modal';
import "./style.css";
import { Button, CloseButton, Form
    , Row , Col , Spinner
  } from "react-bootstrap";
import { useState , useEffect } from 'react';

import {FaqService} from '../../../business/FaqService';
import * as Yup from 'yup';
import { yupToFormErrors } from "formik";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


let section = [
    {
        id_section: 1
        ,nombre: 'Deshidratados'
    },
    {
        id_section: 2
        ,nombre: 'Barf'
    },
    {
        id_section: 3
        ,nombre: 'Treats'
    },
    {
        id_section: 4
        ,nombre: 'Productos CBD'
    },
    {
        id_section: 5
        ,nombre: 'Pedidos y Delivery'
    },
    {
        id_section: 6
        ,nombre: 'Referidos Origo'
    },

]
type ModalAgregarFaqProps = {
    handleClose: any
    handleOpen: any
    show?: boolean

}

const validSquema = Yup.object().shape({    
    seccion: Yup.number().required("*Campo obligatorio")
    ,pregunta: Yup.string().required("*Campo obligatorio")
    ,respuesta: Yup.string().required("*Campo obligatorio")
 });

const initialValues: any = {
    seccion: 1,
    pregunta: "",
    respuesta: "",
};

function ModalAgregarFaq(props:ModalAgregarFaqProps) {
    const [data, setData] = useState(initialValues);
    const [errors, setErrors] = useState<any>();
    const [validated, setValidated] = useState<any>();
    const [loading, setLoadingButton] = useState(false);

    useEffect(() => {      
        if(errors == null)
        {
            setValidated(false);
        }
        else{
            setValidated(true);
        }
        
      }, [errors]);

    const insertFaq = async () => { 
        setLoadingButton(true);
        const getInstance = FaqService.getInstance();
            
        await getInstance.insertFaq(data)
            .then((response:any) => {
                setLoadingButton(false);
                if(response.success === true)
                {
                    handleClose();
                }        
            })
            .catch((err:any) => {
                setLoadingButton(false);
                console.log(err);
                
                setErrors({
                    seccion: err.result.errors.seccion !== undefined ? err.result.errors.seccion[0] : null,
                    pregunta: err.result.errors.pregunta !== undefined ? err.result.errors.pregunta[0] : null,
                    respuesta: err.result.errors.respuesta !== undefined ? err.result.errors.respuesta[0] : null,
                });                
            });
    
    }

    const handleChangeAnswer = (html: any) => {

        setData({
            ...data,
            ['respuesta'] : html
        });
        
      };

    const handleChangeData= (event: any) => {
        const { name, value} = event.target;
        let val = value;

        if(name==='seccion')
        {
            val = parseInt(value);
        }

        setData({
            ...data,
            [name] : value
        });
        
      };

      const handleSave= (event: any) => {
        validSquema.validate(data, { abortEarly: false }).then(function() {
            // Success
            insertFaq();

            setErrors(null);
        }).catch(function (err) {
            let errorArray = {};
            const errors = yupToFormErrors(err);
            console.log(errors);
            setErrors(errors);
            setValidated(true);
           
        });
        
      }

      const handleClose = () => {
        setData(initialValues);
        props.handleClose();
      }
    
  return (
    <Modal id="modal_agregar_faq" className='modal_faq' show={props.show} onHide={props.handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header  className="close-modal">   
            <h3 className='title_modal_faq'>Agregar FAQ</h3>             
            <CloseButton variant="white" onClick={handleClose}/>
        </Modal.Header>        
        <Modal.Body>                
            <div className='cont_modal_faq'>
                <Form className='form_agregar_faq' validated={validated}>
                    <Form.Group className='row'>
                        <Col md={12}>
                            <Form.Label>Sección</Form.Label>
                        </Col>
                        <Col md={12}>
                            <Form.Control as="select" name="seccion" value={data.seccion} onChange={e => {handleChangeData(e)}}>
                            {section.map((item) => (
                                <option key={item.id_section} value={item.id_section}>{item.nombre}</option>
                            ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    <Form.Group className='row'>
                        <Col md={12}>
                            <Form.Label>Pregunta</Form.Label>
                        </Col>
                        <Col md={12}>
                            <Form.Control as="textarea" rows={5} id="pregunta" name="pregunta" className='w_100' required value={data.pregunta} onChange={e => {handleChangeData(e)}}/>
                            {errors?.pregunta != "" && (
                                <Form.Control.Feedback type="invalid">
                                    {errors?.pregunta}
                                </Form.Control.Feedback>
                            )}
                        </Col>
                    </Form.Group>
                    <Form.Group className='row'>
                        <Col md={12}>
                            <Form.Label>Respuesta</Form.Label>
                        </Col>
                        <Col md={12}>
                            <ReactQuill theme="snow" value={data.respuesta} onChange={handleChangeAnswer} />
                            <Form.Control as="textarea" id="respuesta" style={{display: 'none'}} rows={5} name="respuesta" className='w_100' required value={data.respuesta}/>
                            {errors?.respuesta != "" && (
                                <Form.Control.Feedback type="invalid">
                                    {errors?.respuesta}
                                </Form.Control.Feedback>
                            )}
                        </Col>
                    </Form.Group>
                    <div className='cont_modal_confirm'>
                        <Col md={12} className='dis_fle'>           
                            <Col md={6} className="con_btn_confirm">
                                <Button className='btn_confirm' type="button" onClick={(e)=>handleSave(e)} disabled={loading}>
                                    {loading ? (
                                    <Spinner animation="border" role="status" style={{width: '1rem', height: '1rem'}}>
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                    ) : (
                                    'Guardar'
                                    )}
                                </Button> 
                            </Col>
                            <Col md={6} className="con_btn_confirm">
                                <button className='btn_cancel'  type="button" onClick={handleClose}>Cancelar</button> 
                            </Col>                
                        </Col>    
                    </div>
                </Form>
            </div>
        </Modal.Body>
    </Modal>
  );
}

export default ModalAgregarFaq;