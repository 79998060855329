import React, { useState, useEffect } from 'react';
import { Button, Container, Row, Col, Form, Spinner } from 'react-bootstrap';
import BackLayout from "../../../components/layout/BackLayout/BackLayout";
import {ProductService} from '../../../business/ProductService';
import uploadFile from '../../../business/FileService';
import * as Yup from 'yup';
import { yupToFormErrors } from "formik";
import { Multiselect } from 'multiselect-react-dropdown';
import { useParams } from "react-router-dom";

const validSquema = Yup.object().shape({    
    presentacion: Yup.number().required("*Campo obligatorio")
    ,codigo: Yup.string().required("*Campo obligatorio")
    ,concepto: Yup.string().required("*Campo obligatorio")
    ,precio: Yup.number().required("*Campo obligatorio")
 });

 let confirmDefault = {
    id:0,
    name: '',
    show: false
}

function AgregarPresentacion (){
    const [data, setData] = useState<any>({presentacionesimg: []});
    const [loading, setLoading] = useState(false);
    const [presentaciones, setPresentaciones] = useState([]);
    const [validated, setValidated] = useState<any>();
    const [errors, setErrors] = useState<any>();
    const [product, setProduct] = useState("");
    const [selectedProductFile, setSelectedProductFile] = useState();
    const [previewProductFile, setPreviewProductFile] = useState("");

    let params = useParams();
    let idProduct = 0;
    if(params.id_product !== undefined)
    {
        idProduct = parseInt(params.id_product);
        data.idProduc = idProduct;
    }

    useEffect(() => {      
        getPresentationList();  
        getProduct(idProduct);
      }, []);

    const getPresentationList = async () => { 
        const getInstance = ProductService.getInstance();
        setLoading(true);

        await getInstance.getPresentationList()
            .then((response:any) => {
                
                    if(response.success === true)
                    {
                        setPresentaciones(response.data);

                    }
                    setLoading(false);
                    
                })
            .catch((err:any) => {
                setLoading(false);
            });

    }

    const insertProductPresentation = async (imagen:string) => { 
        const getInstance = ProductService.getInstance();
        setLoading(true);

        await getInstance.insertProductPresentation({...data, ['imagen']: imagen})
            .then((response:any) => {
                    setLoading(false);
                    if(response.success === true)
                    {
                        window.location.href = process.env.REACT_APP_PUBLIC_URL + 'admin/editar-presentacion/' + response.id

                    }
                    
                })
            .catch((err:any) => {
                setLoading(false);
            });

    }


    const getProduct = async (id:number) => { 
        const getInstance = ProductService.getInstance();

        await getInstance.getProduct({id: id})
            .then((response:any) => {
                
                    if(response.success === true)
                    {
                        setProduct(response.data.nombre);

                    }
                    
                })
            .catch((err:any) => {
            });

    }

    const handleChangeData= (event: any) => {
        const { name, value} = event.target;
        let copy = {...data};
        let val = value;
        
        if((name==='linea' || name==='categoria' || name==='presentacion') && value != '')
        {
            val = parseInt(value);
        }
        if((name==='precio' || name==='descuento') && value != '')
        {
            val = parseFloat(value);
        }

    
        setData({
            ...data,
            [name]: event.target.type==='checkbox' ? event.target.checked : val
        });
        
      };
    
    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        if (!selectedProductFile) {
            setPreviewProductFile("")
            return
        }

        const objectUrl = URL.createObjectURL(selectedProductFile)
        setPreviewProductFile(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedProductFile])

    const onSelectProductFile = (e:any) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedProductFile(undefined)
            return
        }

        // I've kept this example simple by using the first image instead of multiple
        setSelectedProductFile(e.target.files[0])
    }


    const  handleSave = (event: any) => {
        validSquema.validate(data, { abortEarly: false }).then(async function() {
           
            var result = await uploadFile(selectedProductFile,'upload/productos').then((response:any) => {
                
                if(response.status == 200)
                {
                    let result = response.data.result;
                    if(result.success == true)
                    {
                       return result.data.imagen_path;
                        
                    }
                }
                return '';
                
            });

            insertProductPresentation(result);

            setErrors(null);
        }).catch(function (err) {
            let errorArray = {};
            const errors = yupToFormErrors(err);
            console.log(errors);
            setErrors(errors);
            setValidated(true);
           
        });
        
    }

    return (
        <BackLayout>
        <div className='fond_backoffice'>
            <Container>                
                <div className='cont_title'>
                    <h3><a href={process.env.REACT_APP_PUBLIC_URL + 'admin/editar-producto/'+ idProduct}>{product}</a> - Registrar Presentación</h3>
                </div>
                <div className='cont_data'>                           
                    <Form id="form-registrar-producto" validated={validated}>
                        <Row className="">
                            <Col md={12}>
                                <div className="section_backoffice_form">
                                    <div className="">
                                        <h3>Datos de la Presentación</h3>
                                        
                                        <Row>
                                       
                                            <Col md={4}>                              
                                                <Form.Group>     
                                                    <Form.Label>Envase*:</Form.Label>                   
                                                    <Form.Control as="select" name="presentacion" required value={data.presentacion} onChange={e => {handleChangeData(e)}}>
                                                        <option value="">Seleccionar</option>
                                                        {presentaciones.map((item:any) => (
                                                             <option key={item.id} value={item.id}>
                                                                {item.name}   
                                                            </option>
                                                        ))}
                                                    </Form.Control>                                                    
                                                    {errors?.presentacion != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.presentacion}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>  
                                            </Col>
                                            <Col md={4}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Código SKU:</Form.Label>
                                                    <input type="text" name='codigo' required className='form-control' value={data.codigo} onChange={e => {handleChangeData(e)}}/>                                                    
                                                    {errors?.codigo != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.codigo}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>  
                                            </Col>
                                            <Col md={4}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Id Producto Alegra:</Form.Label>
                                                    <input type="text" name='idalegra' className='form-control' value={data.idalegra} onChange={e => {handleChangeData(e)}}/>                                                    
                                                   
                                                </Form.Group>  
                                            </Col>
                                            <Col md={12}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Descripción Alegra:</Form.Label>
                                                    <input type="text" name='descripcion_alegra' className='form-control' value={data.descripcion_alegra} onChange={e => {handleChangeData(e)}}/>                                                    
                                                   
                                                </Form.Group>  
                                            </Col>
                                            <Col md={12}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Concepto*:</Form.Label>
                                                    <input type="text" name='concepto' required className='form-control' value={data.concepto} onChange={e => {handleChangeData(e)}}/>                                                    
                                                    {errors?.concepto != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.concepto}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>  
                                            </Col>                                            
                                            <Col md={12}>
                                                <Form.Group>
                                                <Form.Label>Descripción técnica</Form.Label>
                                                <textarea name="nombre_presentacion" className="form-control" rows={2} placeholder="" value={data.nombre_presentacion} onChange={e => {handleChangeData(e)}}></textarea>
                                                </Form.Group>
                                            </Col>  
                                            <Col md={12}>
                                                <Form.Group>
                                                <Form.Label>Descripción presentación</Form.Label>
                                                <textarea name="descripcion_presentacion" className="form-control" rows={2} placeholder="" value={data.descripcion_presentacion} onChange={e => {handleChangeData(e)}}></textarea>
                                                </Form.Group>
                                            </Col>    
                                            <Col md={6}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Precio:</Form.Label>
                                                    <input type="number" name='precio' required className='form-control' value={data.precio} onChange={e => {handleChangeData(e)}}/>                                                    
                                                    {errors?.precio != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.precio}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>  
                                            </Col>
                                            <Col md={6}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Descuento:</Form.Label>
                                                    <input type="number" name='descuento' className='form-control' value={data.descuento} onChange={e => {handleChangeData(e)}}/>                                                    
                                                    <span className="help-block"></span>
                                                </Form.Group>  
                                            </Col>
                                            <Col md={6}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Imagen:</Form.Label>

                                                    <Form.Control type="file" as='input' name='imagen_suscripcion_producto' className='form-control' onChange={onSelectProductFile}/>
                                                    {selectedProductFile &&  <img className="edit-img-propre" style={{width: '200px'}} src={previewProductFile} /> }
                                                </Form.Group>  
                                            </Col>

                                            <Col md={12} className="pad_top_2 dis_fle spac_betw">
                                                <Col md={4} className="align-items-center txt_ali dis_fle con_btn_confirm">                                                    
                                                    <Button className='btn_confirm' type="button" onClick={(e)=>handleSave(e)} disabled={loading}>
                                                        {loading ? (
                                                        <Spinner animation="border" role="status" style={{width: '1rem', height: '1rem'}}>
                                                            <span className="visually-hidden">Loading...</span>
                                                        </Spinner>
                                                        ) : (
                                                        'Guardar'
                                                        )}
                                                    </Button>                                                   
                                                </Col>
                                                <Col md={4} className="align-items-center txt_ali dis_fle cont_btn_cancel">                                                    
                                                    <a href={process.env.REACT_APP_PUBLIC_URL + 'admin/editar-producto/'+ idProduct} className='btn_cancelar_registro btn'>Cancelar</a>                                                        
                                                </Col>
                                            </Col>                                           
                                            
                                        </Row>
                                    </div>
                                </div>
                            </Col>   
                        </Row>                            
                    </Form>
                </div>
            </Container>  
        </div>
        </BackLayout>

)};
export default AgregarPresentacion;
