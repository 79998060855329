import React from 'react';
import "../reporteVentas/style.css";
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import BackLayout from "../../../components/layout/BackLayout/BackLayout";
import Table from 'react-bootstrap/Table';
import Pagination from 'react-js-pagination';
import { useState, useCallback, useEffect } from 'react';
import ModalConfirm from "../../../components/confirm/ModalConfirm";
import ModalAgregarFaq from './modalAgregarFaq';
import ModalEditarFaq from './modalEditarFaq';
import {FaqService} from '../../../business/FaqService';

let filtersDefault = {
    seccion: 0,
    search: '',

}
let confirmDefault = {
    id:0,
    pregunta: '',
    show: false
}

let selectedFaqInitial = {
    prefre: 0,
    respuesta: "",
    pregunta: "",
    seccion: 1
}

function ListFaq (){
    const [data, setData] = useState<any>({data: []});
    const [selectedFaq, setSelectedFaq] = useState(selectedFaqInitial);
    const [filters, setFilters] = useState(filtersDefault);
    const [loading, setLoading] = useState(false);
    const [confirmData, setConfirmData] = useState(confirmDefault);

    const [showAgregarFaq, setShow] = useState(false);
    const handleShowAgregarFaq = () => setShow(true);
    const handleCloseAgregarFaq = () => 
    {
        setShow(false);
        getFaq(1);    
    }
    const handleReopenAgregarFaq = () => {
        setShow(false);
        setShow(true);
    }

    const [showEditarFaq, setshowEditarFaq] = useState(false);
    const handleShowEditarFaq = (prefre:number, respuesta:string, pregunta:string, seccion:number) => 
    {
        setSelectedFaq({...selectedFaq,
            ['prefre']: prefre,
            ['respuesta']: respuesta,
            ['pregunta']: pregunta,
            ['seccion']: seccion,
        });

        
    }
    const handleCloseEditarFaq = () => {
        setSelectedFaq({...selectedFaq,
            ['prefre']: 0,
            ['respuesta']: "",
            ['pregunta']: "",
            ['seccion']: 1,
        });
        setshowEditarFaq(false);
        getFaq(data.current_page);  
    }

    useEffect(() => {      
        getFaq(1);    
      }, []);

      useEffect(() => {      
        if(selectedFaq.prefre > 0)    
        {
            setshowEditarFaq(true);
        }
      }, [selectedFaq]);

    const getFaq = async (pageNumber:number) => { 
        const getInstance = FaqService.getInstance();
        setLoading(true);

        await getInstance.getFaq({page: pageNumber, seccion: filters.seccion, search: filters.search})
            .then((response:any) => {
                
                    if(response.success === true)
                    {
                        setData(response.faqs);

                    }
                    setLoading(false);
                    
                })
            .catch((err:any) => {
                setLoading(false);
            });

    }

    

    const handleChangeFilter= (event: any) => {
    
        const { name, value} = event.target;
        setFilters({
            ...filters,
            [name]: value
          });      
        
    };

    const updateFaq = async (id:number) => { 
        const getInstance = FaqService.getInstance();
       
        await getInstance.updateFaq({id: id})
            .then((response:any) => {
                setConfirmData({
                    ...confirmData,
                    show: false
                });
                getFaq(data?.current_page > 0 ? data?.current_page : 1);
            })
            .catch((err:any) => {
                
            });

    }

    const handlePageChange = (pageNumber:any) => {
        getFaq(pageNumber);
    }

    const handleSearch = (e:any) => {
        getFaq(1);
    }

    const handleUpdate = (event:any, id:number) => {
        setConfirmData({
            ...confirmData,
            show: false
        });
        updateFaq(id);
    }

    const deleteFaq = async (id:number) => { 
        const getInstance = FaqService.getInstance();
        setLoading(true);

        await getInstance.deleteFaq({id:id})
            .then((response:any) => {
                
                    if(response.success === true)
                    {
                        getFaq(data.current_page); 

                    }
                    setLoading(false);
                    
                })
            .catch((err:any) => {
                setLoading(false);
            });

    }

    const handleConfirmDelete = (e:any,id:number, pregunta: string) => {
        setConfirmData({show:true, id: id, pregunta: pregunta})
    }

    const handleDeleteFaq = (event:any, id:number) => {
        setConfirmData({
            ...confirmData,
            id: 0,
            pregunta: "",
            show: false
        });
        deleteFaq(id);
    }

    const handleClose= (event: any) => {
    
        setConfirmData({
            ...confirmData,
            id: 0,
            pregunta: "",
            show: false
        })    
        
    };

    return (
        <BackLayout>
        <div className='fond_backoffice'>
            <Container>                
            <div className='cont_title'>
                <h1>Web FAQ</h1>
            </div>
            <div className='cont_data'>
                <Container className='cont_filters'>
                    <Row className='row_filter'>
                        <Col md={4} className="align-items-center txt_ali dis_fle">
                            <Col md={3}>
                                <label htmlFor="search">Buscar:</label>
                            </Col>
                            <Col md={9}>   
                            <input name="search" type="search" className='form-control' placeholder='pregunta, respuesta' value={filters.search} onChange={(e)=>handleChangeFilter(e)}/>
                            </Col>
                        </Col>
                        <Col md={4} className="align-items-center txt_ali dis_fle">
                            <Col md={3}>
                            <label htmlFor="seccion">Seccion:</label>
                            </Col>
                            <Col md={9}>
                                <select name="seccion" id="seccion" className='form-control'  value={filters.seccion} onChange={(e)=>handleChangeFilter(e)}>
                                    <option value={0}>Seleccione</option>
                                    <option value={1}>Deshidratados</option>
                                    <option value={2}>Snack</option>
                                    <option value={3}>Treats</option>
                                    <option value={4}>Productos cbd</option>
                                    <option value={5}>Pedidos y delivery</option>
                                    <option value={6}>Suscripción</option>
                                </select>
                            </Col>
                        </Col>                       
                        <Col md={2} className="align-items-center txt_ali dis_fle">
                            <Button className='btn_filter_search' type="button" onClick={(e)=>handleSearch(e)}>Buscar</Button>
                        </Col>
                        <Col md={2} className="align-items-center txt_ali dis_fle">
                            <Button className='btn_export_excel' onClick={handleShowAgregarFaq}>Agregar</Button>
                        </Col>
                    </Row>
                </Container>
                <Table responsive className='table_backoffice'>
                <thead>
                    <tr>
                        <th>Sección</th>
                        <th>Pregunta</th>
                        <th>Respuesta</th>                
                        <th>Editar</th>
                        <th>Eliminar</th>
                    </tr>
                </thead>
                <tbody>
                    {data.data.map((item:any) => {
                        return (    
                    <tr>
                        <td>{item.nombre}</td>
                        <td className='text_left'>{item.pregunta}</td>
                        <td className='text_left' dangerouslySetInnerHTML={{__html: item.respuesta}}></td>
                        <td className='text_icon'>
                            <a className='cur_point' onClick={(e) => handleShowEditarFaq(item.id_prefre, item.respuesta, item.pregunta, item.secfaq_id_secfaq)} >
                                <div className='cont_ver_detalle'>                                
                                    <img src={process.env.REACT_APP_PUBLIC_URL + 'images/common/edit.webp'} alt="Editar" />
                                </div>
                            </a>
                        </td>
                        <td className='text_icon'>
                            <a className="delete-cupon cur_point" onClick={(e) => handleConfirmDelete(e, item.id_prefre, item.pregunta)}>
                                <div className='cont_ver_detalle'>                                
                                    <img src={process.env.REACT_APP_PUBLIC_URL + 'images/common/trush.webp'} alt="Eliminar" />
                                </div>
                            </a>
                        </td>
                    </tr>
                    )
                })}
                </tbody>
                </Table>
                <div className='pagination_back'>                    
                {data.total > 0 && (<Pagination
                    activePage={data.current_page}
                    itemsCountPerPage={data.per_page}
                    totalItemsCount={data.total}
                    pageRangeDisplayed={5}
                    onChange={(e) => handlePageChange(e)}
                    innerClass={"pagination"}
                    itemClass={"page-item"}
                    linkClass={"page-link"}
                    />)}

                </div>
            </div>
            </Container>
            <ModalConfirm text="" handleClose={handleClose} show={confirmData?.show} handleSave={handleDeleteFaq} action={"Estás seguro de eliminar la faq " + confirmData?.pregunta + ""} id={confirmData?.id}/>
            <ModalAgregarFaq show={showAgregarFaq} handleClose={handleCloseAgregarFaq} handleOpen={handleReopenAgregarFaq}/>  
            {selectedFaq.prefre > 0 && (<ModalEditarFaq show={showEditarFaq} handleClose={handleCloseEditarFaq} data={selectedFaq}/>  )}
        </div>
        </BackLayout>
)};
export default ListFaq;
