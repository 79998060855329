import logo from './logo.svg';
import './App.css';
import Roots from '../src/rutas/roots.tsx'
import { ToastContainer, toast } from 'react-toastify';
import { Provider } from 'react-redux';
import { store } from '../src/store';
import 'react-toastify/dist/ReactToastify.min.css';
import { Container } from "react-bootstrap"
import  Footer  from "../src/components/includes/Footer"
import { ShoppingCartProvider } from "../src/context/ShoppingCartContext"
import { ParallaxProvider  } from 'react-scroll-parallax';
import 'react-date-picker/dist/DatePicker.css';


function App() {
  return (
    <div className="App">
      <ParallaxProvider>
     <ShoppingCartProvider>
     <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            />
      
        <Roots />
        <Footer />
       
    </ShoppingCartProvider>
    </ParallaxProvider>

      
      
    </div>
  );
}

export default App;
