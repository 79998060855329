import "./style.css";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Form, Modal } from 'react-bootstrap';
import { useState, useRef, useEffect } from 'react';

import Popover from 'react-bootstrap/Popover';



type PurchaseOptionProps = {
  type: number
  changetype: (buyType: number) => void
}  
function PurchaseOption({type, changetype}: PurchaseOptionProps) {
  const [show, setShow] = useState(false);
    
    const target = useRef(null);

    const PopoverComponent  = (active:number) => (
      <Popover id="popover-basic" >
          <div           
          >
              <div className="cont_ov">
                  <div className="title_ov">Elegir opción de compra: </div>
                  <div className={active==1 ? 'btn_compra_r_o' : 'btn_compra_u_o'} onClick={() => changetype(1)}>COMPRA ÚNICA</div>
                  <div className={active==2 ? 'btn_compra_r_o' : 'btn_compra_u_o'} onClick={() => changetype(2)}>RECURRENTE</div>
                  
              </div>
              
          </div>
      </Popover>
    );
    
    return (
      <>
      <OverlayTrigger trigger="click" placement="bottom" overlay={PopoverComponent(type)} rootClose>
        <div className="edit_tipo_compra" ref={target} onClick={() => setShow(!show)}>
          {type ==2 &&
            <span className="recu_uni">Recurrente</span> 
          }
          {type ==1 &&
            <span className="recu_uni">Compra única</span> 
          }
            <img src={process.env.REACT_APP_PUBLIC_URL + "images/common/edit.webp"} alt="Editar"/>
        </div>
        </OverlayTrigger>
        
      </>
    );
  }

export default PurchaseOption;
