
import React from 'react';
import { useState, useCallback, useEffect } from 'react';
import { useMediaQuery } from "../../hooks/useMediaQuery"

function Marquee(props:any) {
   
    const [names, setNames] = useState(props.notes);
  
    const [newName, setnewName] = useState(names[0]);

    let screen= useMediaQuery('sm');

    const shuffle = useCallback(() => {
        const index = Math.floor(Math.random() * names.length);
        setnewName(names[index]);
    }, []);

    useEffect(() => {
        const intervalID = setInterval(shuffle, 5000);
        return () => clearInterval(intervalID);
    }, [shuffle]);


    return(
        <>
        {screen ==false ? (
            <div className="marquee2" style={{backgroundColor: '#ff6f5d'
            , width:'100%'
            , color: '#ffffff'
            , fontWeight: 'bold'
            , fontSize: '1rem'
            , padding: '5px 0px'}}>
                <div>
               
                    <span>{props.message1}</span>
                    <span>{props.message2}</span>
                    
                </div>
            </div>
        ) :
        (<div className="marquee" style={{backgroundColor: '#ff6f5d'
            , width:'100%'
            , color: '#ffffff'
            , fontWeight: 'bold'
            , fontSize: '1rem'
            , padding: '5px 0px'
        }}>{newName}</div>)}
        </>
    )
}

export default Marquee;