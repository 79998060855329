import { api } from "../../src/api";
import ActionType from "../../src/utils/customTypes";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export class AuthService extends api {

    private static classInstance?: AuthService;
        
    constructor(token: string, type: ActionType) {
        super( process.env.REACT_APP_BASE_URL,token);    
    } 
  
    public static getInstance() {
        let token = localStorage.getItem("token");
        if (!this.classInstance) {
            this.classInstance = new AuthService(token!==undefined && token !== null ? token:'', 'GET');
        }
        
        return this.classInstance;
    }   
     
    public getUserCheckout = () => this.instance.post('/auth/get-user-checkout').then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });
   
     
    public login = (data : any) => this.instance.post('/auth/login',data).then(response => {
        if (response) {
           
            return response;
        }
    
    }).catch((error: any) => {
        
        return Promise.reject(error);
    });

    public logout = () => this.instance.post('/auth/logout').then(response => {
        if (response) {
           
            return response;
        }
    
    }).catch((error: any) => {
        
        return Promise.reject(error);
    });

    public changeRole = (data : any) => this.instance.post('/auth/update-change-role',data).then(response => {
        if (response) {
            
            return response;
        }
    
    }).catch((error: any) => {
        
        return Promise.reject(error);
    });
    
    public register = (data : any) => this.instance.post('/auth/register',data).then(response => {
        if (response) {
           
            return response;
        }
    
    }).catch((error: any) => {
        
        return Promise.reject(error);
    });
}