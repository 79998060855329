import axios from 'axios';

async function uploadFile(selectedFile:any, directory:string) {
    // Create an object of formData
        let message;
        const formData = new FormData();
        formData.append('file', selectedFile!);
        formData.append('directory', directory!);
        let token = localStorage.getItem("token");

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'authorization': 'Bearer ' + token
            },
        };
            
        return await axios.post(process.env.REACT_APP_BASE_URL+'docs/upload-file', formData, config).then(response => {
            return response;            
        });
    
}


export default uploadFile;