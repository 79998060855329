import React from 'react';
import "../reporteVentas/style.css";
import { Container, Row, Col, Form, Button, CloseButton } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-js-pagination';
import { useState, useEffect } from 'react';
import BackLayout from "../../../components/layout/BackLayout/BackLayout";
import {PromoService} from '../../../business/PromoService';
import ModalEditarPromocion from './modalEditarPromocion';
import ModalAgregarPromocion from './modalAgregarPromocion';
import ModalConfirm from "../../../components/confirm/ModalConfirm";
import LoadingTable from "../../../components/skeletons/LoadingTable";
import { formatDate } from "../../../utils/parsers"

let filtersDefault = {
    from: '',
    to: '',
    status: '',
    type: ''

}
let confirmDefault = {
    idPromocion:0,
    code: '',
    show: false,
    status: 0
}

function ProductosPromociones (){
    const [data, setData] = useState<any>({data: []});
    const [filters, setFilters] = useState(filtersDefault);
    const [loading, setLoading] = useState(false);
    const [confirmData, setConfirmData] = useState(confirmDefault);
    const [confirmDataStatus, setConfirmDataStatus] = useState(confirmDefault);
    const [idPromocionSelected, setIdPromocionSelected] = useState(0);

    const [showAgregarPromocion, setShow] = useState(false);
    const handleShowAgregarPromocion = () => setShow(true);
    const handleCloseAgregarPromocion = () => {
        setShow(false);
        getPromos(1); 
    
    }
    const handleReopenAgregarPromocion = () => {
        setShow(false);
        setShow(true);
    }

    const [showEditarPromocion, setshowEditarPromocion] = useState(false);
    const handleShowEditarPromocion = (e:any, idPromocion:number) => {
        setIdPromocionSelected(idPromocion);
        setshowEditarPromocion(true);
    }
    const handleCloseEditarPromocion = () => {
        setshowEditarPromocion(false);
        setIdPromocionSelected(0);
        getPromos(1);  
    }

    useEffect(() => {      
        getPromos(1);    
      }, []);

    const getPromos = async (pageNumber:number) => { 
        const getInstance = PromoService.getInstance();
        setLoading(true);

        await getInstance.getPromos({page: pageNumber, from: filters.from, to: filters.to, status: filters.status, type: filters.type})
            .then((response:any) => {
                
                    if(response.success === true)
                    {
                        setData(response.promos);

                    }
                    setLoading(false);
                    
                })
            .catch((err:any) => {
                setLoading(false);
            });

    }

    const deletePromo = async (id:number) => { 
        const getInstance = PromoService.getInstance();
        setLoading(true);

        await getInstance.deletePromo({id:id})
            .then((response:any) => {
                
                    if(response.success === true)
                    {
                        getPromos(data.current_page); 

                    }
                    setLoading(false);
                    
                })
            .catch((err:any) => {
                setLoading(false);
            });

    }

    const handleConfirmDelete = (e:any,id:number, code: string) => {
        setConfirmData({show:true, idPromocion: id, code: code, status:0})
    }

    const handleDeletePromocion = (event:any, id:number) => {
        setConfirmData({
            ...confirmData,
            show: false
        });
        deletePromo(id);
    }

    const handleChangeFilter= (event: any) => {
    
        const { name, value} = event.target;
        setFilters({
            ...filters,
            [name]: value
          });      
        
    };

    const handleCloseStatus= (event: any) => {
    
        setConfirmDataStatus({
            ...confirmDataStatus,
            show: false
        })    
        
    };

    const handleChangeStatus= (e:any,id:number, name: string) => {
        setConfirmDataStatus({
            ...confirmDataStatus,
            show: true,
            idPromocion: id,
            code: name,
            status: e.target.checked
        });
    }

    const handleUpdateStatus = (event:any) => {
      
        setConfirmDataStatus({
            ...confirmDataStatus,
            show: false
        });
        updatePromoStatus(confirmDataStatus.idPromocion, confirmDataStatus.status);
    }

    const updatePromoStatus = async (id:number, status:number) => { 
        const getInstance = PromoService.getInstance();
       
        await getInstance.updatePromoStatus({id: id, status: status})
            .then((response:any) => {
                setConfirmDataStatus({
                    ...confirmDataStatus,
                    show: false,
                    idPromocion: 0,
                    code: '',
                    status: 0
                });
                getPromos(data?.current_page > 0 ? data?.current_page : 1);
            })
            .catch((err:any) => {
                
            });

    }

    const updatePromocion = async (id:number) => { 
        const getInstance = PromoService.getInstance();
       
        await getInstance.updatePromo({id: id})
            .then((response:any) => {
                setConfirmData({
                    ...confirmData,
                    show: false,
                    idPromocion: 0,
                    code: ''
                });
                getPromos(data?.current_page > 0 ? data?.current_page : 1);
            })
            .catch((err:any) => {
                
            });

    }

    const handlePageChange = (pageNumber:any) => {
        getPromos(pageNumber);
    }

    const handleSearch = (e:any) => {
        getPromos(1);
    }

    

    const handleClose= (event: any) => {
    
        setConfirmData({
            ...confirmData,
            show: false
        })    
        
    };

    const handleUpdate = (event:any, id:number) => {
        setConfirmData({
            ...confirmData,
            show: false
        });
        updatePromocion(id);
    }
    return (
        <BackLayout>
        <div className='fond_backoffice'>
            <Container>                
            <div className='cont_title'>
                <h1>Promociones</h1>
            </div>
            <div className='cont_data'>
                <Container className='cont_filters'>
                    <Row className='row_filter'>
                        <Col md={10}></Col>                       
                        <Col md={2} className="align-items-center txt_ali dis_fle">
                            <Button className='btn_export_excel' onClick={handleShowAgregarPromocion}>Agregar</Button>
                        </Col>
                    </Row>
                    <Row className='row_filter'>
                        <Col md={2} className="align-items-center txt_ali dis_fle">
                        <label htmlFor="buscar">Buscar:</label>
                            
                        </Col>
                        <Col md={2} className="align-items-center txt_ali dis_fle">
                            <Form.Control as="select" name="type" value={filters.type} onChange={(e)=>handleChangeFilter(e)}>
                                <option value="">Tipo de promoción</option>
                                <option value="1">2x1</option>
                                <option value="2">3x2</option>
                                <option value="3">Delivery gratis</option>
                                <option value="4">Producto gratis</option>
                            </Form.Control>
                        </Col>
                        <Col md={3} className="align-items-center txt_ali dis_fle">
                            <Col md={4}>
                            <label htmlFor="from">F. Desde:</label>
                            </Col>
                            <Col md={8}>
                                <input name="from"type="date" className='form-control'  value={filters.from} onChange={(e)=>handleChangeFilter(e)}/>
                            </Col>
                        </Col>
                        <Col md={3} className="align-items-center txt_ali dis_fle">
                            <Col md={4}>
                                <label htmlFor="to">F. Hasta:</label>
                            </Col>
                            <Col md={8}>
                                <input name="to" type="date" className='form-control' value={filters.to} onChange={(e)=>handleChangeFilter(e)}/>
                            </Col>
                        </Col>                        
                        <Col md={2} className="align-items-center txt_ali dis_fle">
                            <Button className='btn_filter_search' type="button" onClick={(e)=>handleSearch(e)}>Buscar</Button>
                        </Col>
                        
                    </Row>
                </Container>
                <Table responsive className='table_backoffice'>
                <thead>
                    <tr>
                        <th>Fecha de creación</th>
                        <th>Tipo promoción</th>
                        <th>Código</th>
                        <th>Periódo válido</th>
                        <th>Monto S/</th>
                        <th>Productos</th>
                        <th>Estado</th>
                        <th>Editar</th>
                        <th>Eliminar</th>
                    </tr>
                </thead>
                {loading==true ? (
                        <LoadingTable rows={10} cols={12}/>
                    )
                    
                    :
                    (<tbody>
                    {data.data.map((item:any) => {
                        return (    
                    <tr>
                        <td>{formatDate(item.created_at, 'yyyy-MM-DD HH:mm:ss')}</td>
                        <td>{item.tipo}</td>
                        <td>{item.codigo}</td>
                        <td>{item.fecha_inicio}<br></br> {item.fecha_vencimiento}</td>
                        <td>{item.monto}</td>
                        <td>{item.presentaciones.map((producto:any) => {
                            return (   
                                <li>{producto.prodpres}</li>
                                    )
                                })}
                        </td>  
                        <td className='text_icon'>                         
                            <Form.Check className=''>
                                <Form.Check.Input name='estado' type="checkbox" checked={item.estado==1 ? true : false} onChange={(e) => handleChangeStatus(e, item.id_promo, item.codigo)}/>
                            </Form.Check>
                        </td>
                        <td className='text_icon'>
                            <a className='cur_point' onClick={(e) => handleShowEditarPromocion(e, item.id_promo)}>
                                <div className='cont_ver_detalle'>                                
                                    <img src={process.env.REACT_APP_PUBLIC_URL + 'images/common/edit.webp'} alt="Editar" />
                                </div>
                            </a>
                        </td>
                        <td className='text_icon'>
                            <a className="delete-Promocion cur_point" onClick={(e) => handleConfirmDelete(e, item.id_promo, item.codigo)}>
                                <div className='cont_ver_detalle'>                                
                                    <img src={process.env.REACT_APP_PUBLIC_URL + 'images/common/trush.webp'} alt="Eliminar" />
                                </div>
                            </a>
                        </td>
                    </tr>
                    )
                })}
                </tbody>)}
                </Table>
                <div className='pagination_back'>                    
                {data.total > 0 && (<Pagination
                    activePage={data.current_page}
                    itemsCountPerPage={data.per_page}
                    totalItemsCount={data.total}
                    pageRangeDisplayed={5}
                    onChange={(e) => handlePageChange(e)}
                    innerClass={"pagination"}
                    itemClass={"page-item"}
                    linkClass={"page-link"}
                    />)}


                </div>
            </div>
            </Container>
            <ModalConfirm text="" handleClose={handleClose} show={confirmData?.show} handleSave={handleDeletePromocion} action={"¿Estás seguro de eliminar esta promoción " + confirmData?.code + "?"} id={confirmData?.idPromocion}/>
            <ModalConfirm text="" handleClose={handleCloseStatus} show={confirmDataStatus?.show} handleSave={handleUpdateStatus} action={"¿Estás seguro de "+ (confirmDataStatus?.status == 0 ? "Desactivar" : "Activar") +" la promoción " + confirmDataStatus?.code} id={confirmDataStatus?.idPromocion}/>
        
            <ModalAgregarPromocion show={showAgregarPromocion} handleClose={handleCloseAgregarPromocion} handleOpen={handleReopenAgregarPromocion}/>            
            {idPromocionSelected > 0 && (<ModalEditarPromocion show={showEditarPromocion} handleClose={handleCloseEditarPromocion} idPromocion={idPromocionSelected}/> )}           
        </div>
        </BackLayout>
        
)};
export default ProductosPromociones;
