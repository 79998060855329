import React from 'react';
import {useState, useEffect} from 'react';
import { Button, Container, Spinner, Navbar as NavbarBs, NavItem
    , Row , Col ,Form, CloseButton
  } from "react-bootstrap"
import './style.css'
// project imports
import CarouselHome from '../../components/home/carousel';
import CarouselText from '../../components/home/carouselText';
import Benefit from '../../components/cards/beneficio/benefit';
import Product from '../../components/producto/product';
import { ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import BannerParallax from 'components/bannerParallax/bannerParallax';
import CardMenu from 'components/cardMenu/cardMenu';
import ReactPlayer from 'react-player'
import PreviewProduct from 'components/producto/previewProduct';
import {IndexService} from '../../business/IndexService';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Testimonio from 'components/testimonios/testimonio';
import BeforeFooter from 'components/beforeFooter/beforeFooter';
import ModalPlan from 'components/home/modalPlan';
import Modal from 'react-bootstrap/Modal';
import {formatMoney} from '../../utils/formatCurrency';
import * as Yup from 'yup';
import { yupToFormErrors } from "formik";
import MainLayout from "../../components/layout/MainLayout/MainLayout";
import { CarouselMenuResponsive } from 'components/home/carouselMenuResponsive';
import { useFormik } from 'formik';
import { useMediaQuery } from "../../hooks/useMediaQuery";

type Presentation = {
    idPresentation: number
    ,name: string
    ,presentation: string
    ,price: number
    ,percentageDiscount: number
    ,normalPrice: number
    ,image: string
    ,onceBuy:boolean
  }
  
  type ProductProds = {
    idProduct: number
    ,idPresentation: number
    ,selected: number
    ,url: string
    ,image: string
    ,idLine: number
    ,line: string
    ,name: string
    ,category: string
    ,presentaciones: Presentation[]
  }

  type ModalPlanProps = {
    onOpenPreviewPlan: any    
  }
  
  let initalValueProduct = {
    idProduct: 0
    ,idPresentation: 0
    ,selected: -1
    ,url: ""
    ,image: ""
    ,idLine: 0
    ,line: ""
    ,name: ""
    ,category: ""
    ,presentaciones: []
  }

  let planinfo = {
     petName: ""
    ,idBreed: 5
    ,weight: 15
    ,years: 2
    ,months: 1
    ,ownerName: ""
    ,ownerEmail: ""
  }

  

  const planSquema = Yup.object().shape({
    petName:  Yup.string().required("*Campo obligatorio")
    ,idBreed: Yup.number().min(1,"*Campo obligatorio")
    ,weight: Yup.number().required("*Campo obligatorio")
    ,years: Yup.number().required("*Campo obligatorio")
    ,months: Yup.number().required("*Campo obligatorio")
    
 });

 let EMAIL_REGX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


 const planOwnerSquema = Yup.object().shape({
    ownerName:  Yup.string().required("*Campo obligatorio")
    ,ownerEmail: Yup.string().email("Email inválido").required("*Campo obligatorio").matches(EMAIL_REGX, "Email inválido.")
    
 });

/**
 * Login Screen Component
 * @packageDocumentation
 * @module Login
 */
const Home: React.FC<{}> = () => {

  const [show, setShow] = useState(false);
  const [product, setProduct] = useState<ProductProds>(initalValueProduct);
  const [products, setProducts] = useState<any>([]);
  const [testimonios, setTestimonios] = useState<any>([]);

  const [modalPlan, setModalPlan] = useState<ModalPlanProps>();
  const [modalPlans, setModalPlans] = useState<any>([]);
  const [showcalc, setShowCalc] = useState(false);
  const handleCloseCalc = () => setShowCalc(false);
 

  const [sliderDesktop, setSliderDesktop] = useState<any>([]);
  const [sliderResponsive, setSliderResponsive] = useState<any>([]);
  const [breeds, setBreeds] = useState<any>([]);
  
  const [fetchingProducts, setFetchingProducts] = useState(false);
  const [fetchingSliders, setFetchingSliders] = useState(true);
  const [plan, setPlan] = useState(planinfo);
  const [totalPlan, setTotalPlan] = useState(0);
  const [errors, setErrors] = useState<any>();
  const [validated, setValidated] = useState(false);
  const [loadingPlan, setLoadingPlan] = useState(false);
  const [fetchingTestimonials, setFetchingTestimonials] = useState(false);

  useEffect(() => {      
    getProducts();
    getSliderHome();
    getBreeds();
    getTestimonials();
  }, []);

  useEffect(() => {      
    setShow(true);
  }, [product.selected]);

  const getProducts = async () => { 
    const getInstance = IndexService.getInstance();
    setFetchingProducts(true);

    await getInstance.getPromotionalProducts()
        .then((response:any) => {
              setFetchingProducts(false);
          
              if(response.success === true)
              {
                setProducts(response.products);

              }
              else{
                
              }
            }   
          )
        .catch((err:any) => {
          setFetchingProducts(false);
        });

    }

    const getSliderHome = async () => { 
        const getInstance = IndexService.getInstance();
        setFetchingSliders(true);
    
        await getInstance.getSliderHome()
            .then((response:any) => {
                  
              
                  if(response.success === true)
                  {
                    setSliderDesktop(response.desktop);
                    setSliderResponsive(response.responsive);
    
                  }
                  else{
                    
                  }
                  setFetchingSliders(false);
                }   
              )
            .catch((err:any) => {
              setFetchingSliders(false);
            });
    
    }
  
    const getBreeds = async () => { 
        const getInstance = IndexService.getInstance();
            
        await getInstance.getDogBreeds()
            .then((response:any) => {
                    
                if(response.success === true)
                {
                    setBreeds(response.breeds);
                }
                    
            })
            .catch((err:any) => {
                
            });
    
    }

    const getTestimonials = async () => { 
        const getInstance = IndexService.getInstance();
        setFetchingTestimonials(true);
    
        await getInstance.getTestimonials()
            .then((response:any) => {
                  setFetchingTestimonials(false);
              
                  if(response.success === true)
                  {
                    setTestimonios(response.data);
    
                  }
                  else{
                    
                  }
                }   
              )
            .catch((err:any) => {
                setFetchingTestimonials(false);
            });
    
        }

    const getSuscriptionPlanCalc = async () => { 
        const getInstance = IndexService.getInstance();
        setLoadingPlan(true);
        await getInstance.getSuscriptionPlanCalc({data: plan})
            .then((response:any) => {
                setTotalPlan(response.totalPlan);   
                setLoadingPlan(false);      
            })
            .catch((err:any) => {
                setLoadingPlan(false);   
            });
    
    }

  const handleClose = (event:any) => {
    event.preventDefault();
    let copy = {...product};    
    copy.idPresentation = 0;
    copy.selected = -1;
    setProduct(copy);
    setShow(false);
  }

  

  const handleShow = () => setShow(true);

  const onOpenPreview = (event:any,idProduct:Number, idPresentation:number) => {
    let prod = products.find((item:any) => item.idPresentation === idPresentation);
    
    let indexPresentation = prod.presentaciones.findIndex((object:any) => {
      return object.idPresentation === idPresentation;
    });
    var obj = {...product};
      obj.idProduct = prod.idProduct;
      obj.idPresentation= prod.idPresentation;
      obj.selected= indexPresentation;
      obj.url=process.env.REACT_APP_PUBLIC_URL + "producto/" +prod.slug;
      obj.image=process.env.REACT_APP_BASE_URL_BACK + prod.img;
      obj.idLine= prod.idLine;
      obj.line= prod.line;
      obj.name= prod.name;
      obj.category= prod.category;
      obj.presentaciones= prod.presentaciones;
     
      setProduct(obj);
    
    
  }

  const handleChangeInfoPlan= (event: any) => {
    
    const { name, value} = event.target;
    setPlan({
        ...plan,
        [name]: name==='idBreed' || name==='years' || name==='months' || name==='weight' ? parseInt(value) : value
      });      
    
  };
  const handleShowCalc = () => {
    planSquema.validate(plan, { abortEarly: false }).then(function() {
        // Success
        setShowCalc(true);
        setErrors([]);
    }).catch(function (err:any) {
        let errorArray = {};
        const errors = yupToFormErrors(err);
        setErrors(errors);
        console.log(errors);
        setValidated(true);
       

        
    });
  };
  
  const calcularRacion= (event: any) => {
    planOwnerSquema.validate(plan, { abortEarly: false }).then(function() {
        // Success
        getSuscriptionPlanCalc();
        setErrors([]);
    }).catch(function (err:any) {
        let errorArray = {};
        const errors = yupToFormErrors(err);
        setErrors(errors);
        console.log(errors);
        setValidated(true);
       

        
    });
    
  };
 
    const responsive = {
        superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
        slidesToSlide: 3
        },
        desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2,
        slidesToSlide: 1
        },
        tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
        slidesToSlide: 1
        },
        mobile: {
        breakpoint: { max: 767, min: 0 },
        items: 1,
        slidesToSlide: 1
        }
    };

    const responsiveNuestraTienda = {
        superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
        slidesToSlide: 3
        },
        desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
        slidesToSlide: 1
        },
        tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 1
        },
        mobile: {
        breakpoint: { max: 767, min: 0 },
        items: 2,
        slidesToSlide: 1
        }
    };

    let screen = useMediaQuery("sm");

    useEffect(() => {      
        console.log(screen,'sreen');
    }, [screen]);

    const isResponsive: number = screen ? 0 : 1;

  return (
    <MainLayout>
   <div className='pad_initial' style={{background: "#ffffff"}}>
        <CarouselHome sliderDesktop={sliderDesktop} sliderResponsive={sliderResponsive} isLoading={fetchingSliders}/>
        <Container className='container-section'>
        <div className="container-section-beneficios">
            <h1 className='color-primary'><b>BENEFICIOS DE</b> CAMBIAR A ORIGO</h1>
            <div className="container-lines">
        
                <Benefit image={process.env.REACT_APP_PUBLIC_URL + "images/home/semana1.webp"} 
                title='Semana 1'
                description='<li>Disfruta la comida.</li><li>Mayor energía.</li>'
                lastClass=''
                />
        
                <Benefit image={process.env.REACT_APP_PUBLIC_URL + "images/home/semana2.webp"} 
                title='Semana 2'
                description='<li>Heces compactas e inoloras.</li><li>Mejor aliento.</li>'
                lastClass=''
                />
        
                <Benefit image={process.env.REACT_APP_PUBLIC_URL + "images/home/semana3.webp"} 
                title='Semana 3'
                description='<li>Pelo más suave y brilloso.</li><li>Menos caída de pelo.</li>'
                lastClass=''
                />
        
                <Benefit image={process.env.REACT_APP_PUBLIC_URL + "images/home/mes3.webp"} 
                title='Mes 3'
                description='<li>Peso ideal.</li><li>Más resistencia.</li>'
                lastClass=''
                />
        
                <Benefit image={process.env.REACT_APP_PUBLIC_URL + "images/home/mes4.webp"} 
                title='Mes 4'
                description='<li>Perro más sano.</li><li>Menos veterinarios.</li>'
                lastClass='last-child'
                />
        
            </div>
        </div>
        <div className="align-items-center con_btn_tienda">
            <a href="/tienda" className='btn_origo'>¿INICIAMOS EL CAMBIO?</a> 
        </div>
        </Container>
        <BannerParallax 
                img={process.env.REACT_APP_PUBLIC_URL + "images/banners/parallax-origo.webp"}
                clase="cover"
                text= ""
        ></BannerParallax>        
        <div className='container-section sec_90'>
            <h1 className='color-primary'>¿<b>CÓMO</b> FUNCIONA?</h1>
            <br></br>
            <Row className='justify-content-md-center'>
                <Col md={4}>
                <div className="cont_img_funcion">
                    <img
                    className='dog_w'
                    src={process.env.REACT_APP_PUBLIC_URL + "images/home/dog-origo2.webp"}                    
                    alt="Mascota"
                    />
                </div>    
                </Col>
                <Col md={6} className='row_carousel_text'>
                    <div className='cont_rela_center'>
                        <CarouselText/>  
                    </div>                 
                </Col>
                
            </Row>
            <br></br>
            <div className="align-items-center">
                <div className='cont_btn'>
                    <a className="btn_origo" href="/tienda?categ=barf">
                        
                        ¡INICIA HOY!</a> 
                </div>
            </div>
        </div>
        <div className='bg-esp-grad pad_25'>
        <Container className='align-items-center bg-alternative player-wrapper vid_tam'>
            <ReactPlayer className="react-player" 
                light={process.env.REACT_APP_PUBLIC_URL + 'images/home/img-video-prev.webp'}
                playing url='https://youtu.be/avsv6YYtuo8' 
                width='100%'
                height='100%'/>       
        </Container>
        </div>
        <CarouselMenuResponsive/>
        {/* <div className='back_card_hom'>
            <div className='container-section align-items-center' style={{width: '90%'}}>
                <h1 className='color-primary'><b>NUESTROS</b> MENÚS</h1>
                <Row className='pad_top_2'>                            
                    <Col md={4} className='pad_bot_2'>
                        <CardMenu 
                            title="Pollo"
                            img={process.env.REACT_APP_PUBLIC_URL + "images/home/pollo.webp"}
                            imgTop={process.env.REACT_APP_PUBLIC_URL + "images/home/pollo.webp"}
                            // imgTop={process.env.REACT_APP_PUBLIC_URL + "images/home/pato-card.webp"}
                            text="Un clásico en el menú e infaltable en la dieta de tu perro, receta ideal para iniciarse en la dieta BARF."
                            btn= "CONOCE MÁS"
                            href= "producto/receta-de-pollo-800gr-perros-barf"
                        ></CardMenu> 
                    </Col>                     
                    <Col md={4} className='pad_bot_2'>
                        <CardMenu 
                            title="Pavo Pato"
                            img={process.env.REACT_APP_PUBLIC_URL + "images/home/pavo-pato.webp"}
                            imgTop={process.env.REACT_APP_PUBLIC_URL + "images/home/pavo-pato.webp"}
                            // imgTop={process.env.REACT_APP_PUBLIC_URL + "images/home/res-card.webp"}
                            text="Combinación de dos proteínas bajas en grasas saturadas y fáciles de digerir. Ideal para perros alérgicos o con sobre peso."
                            btn= "CONOCE MÁS"
                            href= "producto/receta-de-pavo-pato-800gr-perros-barf"
                        ></CardMenu> 
                    </Col>         
                    <Col md={4} className='pad_bot_2'>
                        <CardMenu 
                            title="Codorniz Res"
                            img={process.env.REACT_APP_PUBLIC_URL + "images/home/codorniz-res.webp"}
                            imgTop={process.env.REACT_APP_PUBLIC_URL + "images/home/codorniz-res.webp"}
                            // imgTop={process.env.REACT_APP_PUBLIC_URL + "images/home/pollo-card.webp"}
                            text="Dos proteínas de calidad y alto contenido de Hierro, ideal para fortalecer el sistema inmunológico y asegurar un óptimo desarrollo en cachorros."
                            btn= "CONOCE MÁS"
                            href= "producto/receta-de-res-codorniz-800gr-perros-barf"
                        ></CardMenu> 
                    </Col>                  
                </Row>
            </div>
        </div> */}
        <div className='back_tr'>
            <Container className='container-section'>
            <Form id="form-suscription-plan" validated={validated}>
            <Row>
               
                <Col md={9} className='cont_part_1_estima'>
                
                    <div className='text_estima title_estima color-primary'><b>ESTIMA EL COSTO</b> DE TU PLAN</div>
                    
                    {screen == false ? (
                    <div className='text_estima'>
                    <Form.Group className='pad_bot_05'>
                        <Form.Label className='label_calc_res' htmlFor="name_mascota">El nombre de mi mascota es:</Form.Label>
                        <Form.Control required as="input" id="name_mascota" placeholder='Argos' name="petName" className='' onChange={handleChangeInfoPlan} value={plan.petName}/>
                        {errors?.petName != "" && (
                            <Form.Control.Feedback type="invalid">
                                {errors?.petName}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group className='pad_bot_05'>
                        <Form.Label className='label_calc_res' htmlFor="idBreed">Su raza es:</Form.Label>
                        <Form.Control required as="select" id="idBreed" name="idBreed" value={plan.idBreed} onChange={e => {handleChangeInfoPlan(e)}}>
                            {breeds.map((item:any, index:any) => (
                                <option value={item.id}>{item.name}</option>
                            ))}
                        </Form.Control>
                        {errors?.idBreed != "" && (
                            <Form.Control.Feedback type="invalid">
                                {errors?.idBreed}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group className='pad_bot_05'>
                        <Form.Label className='label_calc_res' htmlFor="weight">Su peso es:</Form.Label>
                        <Form.Control required as="select" id="weight" name="weight" value={plan.weight} onChange={e => {handleChangeInfoPlan(e)}}>
                            {Array.from({ length: 45 }).map((it, index) => (
                                <option value={index+1}>{index+1}kg</option>
                            ))}
                        </Form.Control>
                        {errors?.weight != "" && (
                            <Form.Control.Feedback type="invalid">
                                {errors?.weight}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group className='pad_bot_05'>
                        <Form.Label className='label_calc_res' htmlFor="years">Su edad es:</Form.Label>
                        <div className="d-flex">
                            <div className="flex-grow-1 me-2">
                                <Form.Control required as="select" id="years" name="years" value={plan.years} onChange={e => {handleChangeInfoPlan(e)}}>
                                    {Array.from({ length: 20 }).map((it, index) => (
                                        <option value={index+1}>{index+1} {index==0 ? 'año' : 'años'}</option>
                                    ))}
                                </Form.Control>
                            </div>
                            <div className="flex-grow-1">
                                <Form.Control required as="select" id="months" name="months" value={plan.months} onChange={e => {handleChangeInfoPlan(e)}}>
                                    {Array.from({ length: 12 }).map((it, index) => (
                                        <option value={index}>{index} {index==1 ? 'mes' : 'meses'}</option>
                                    ))}
                                </Form.Control>
                            </div>
                        </div>
                        {errors?.years != "" && (
                            <Form.Control.Feedback type="invalid">
                                {errors?.years}
                            </Form.Control.Feedback>
                        )}
                        {errors?.months != "" && (
                            <Form.Control.Feedback type="invalid">
                                {errors?.months}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>


                </div>
                
                    ):
                    (
                        <div className='text_estima'>
                        <span className='calc_estima'>
                        <span>Mi perro se llama&nbsp;</span>
                            <Form.Group>
                            <input required id="name_mascota" name="petName" className='input_home_calc' onChange={handleChangeInfoPlan} value={plan.petName}/>&nbsp;
                            {errors?.petName != "" && (
                                <Form.Control.Feedback type="invalid">
                                    {errors?.petName}
                                </Form.Control.Feedback>
                            )}
                            </Form.Group>
                            <span>y es un&nbsp;</span>
                            <Form.Group>
                                <Form.Control required as="select" name="idBreed" value={plan.idBreed} onChange={e => {handleChangeInfoPlan(e)}}>
                                {breeds.map((item:any, index:any) => (
                                    <option value={item.id}>{item.name}</option>
                                ))}
                                </Form.Control>
                                {errors?.idBreed != "" && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors?.idBreed}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </span>
                        <span className='calc_estima'>
                            de&nbsp;
                            <Form.Group>
                                <Form.Control required as="select" name="weight" value={plan.weight} onChange={e => {handleChangeInfoPlan(e)}}>
                                    {Array.from({ length: 45 }).map((it, index) => (
                                    <option value={index+1}>{index+1}kg</option>
                                ))}
                                </Form.Control>
                                {errors?.weight != "" && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors?.weight}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                            &nbsp;de peso y&nbsp;
                            <Form.Group>
                                <Form.Control required as="select" name="years" value={plan.years} onChange={e => {handleChangeInfoPlan(e)}}>
                                    {Array.from({ length: 20 }).map((it, index) => (
                                        <option value={index+1}>{index+1} {index==0 ? 'año' : 'años'}</option>
                                    ))}
                                </Form.Control>
                                {errors?.years != "" && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors?.years}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                            <Form.Group>
                            <Form.Control required as="select" name="months" value={plan.months} onChange={e => {handleChangeInfoPlan(e)}}>
                                {Array.from({ length: 12 }).map((it, index) => (
                                    <option value={index}>{index} {index==1 ? 'mes' : 'meses'}</option>
                                ))}
                            </Form.Control>
                            {errors?.months != "" && (
                                <Form.Control.Feedback type="invalid">
                                    {errors?.months}
                                </Form.Control.Feedback>
                            )}
                            </Form.Group>
                            &nbsp;de edad.
                        </span>
                    </div>
                    )
                    }
                    <div className='cont_btn_origo_calc'>
                        <a className="btn_origo" onClick={handleShowCalc}>CALCULAR</a> 
                    </div>
                </Col>
                
                <Col md={3} className='cont_img_estima'>
                    <img src={process.env.REACT_APP_PUBLIC_URL + "images/home/img-alim.webp"} alt="Plan alimenticio mascota" />
                </Col>
                
            </Row>
            </Form>
            </Container>

        </div>        
        <Container>  
        </Container>
        <div className='home-tienda-container container-section align-items-center mrg-l-r'>
            <h1 className='color-primary'><b>NUESTRA</b> TIENDA</h1>
            <br></br>
            {/* <Row className='align-items-center d-flex'>
            
            <Col md={12}>                
                <Row style={{justifyContent:'center'}}> */}
                <Carousel className='carousel-tienda cont_card_tes' 
                        swipeable={true}
                        draggable={true}
                        showDots={false}
                        responsive={responsiveNuestraTienda}
                        ssr={true} // means to render carousel on server-side.
                        infinite={true}
                        autoPlay={false}
                        autoPlaySpeed={5000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={500}
                        //removeArrowOnDeviceType={["tablet","mobile"]}
                        renderArrowsWhenDisabled={true}
                        >
                        {products.filter((item:any) => item.presentaciones.length > 0).map((item:any, index:any) => (
                            <Product key={'prod'+item.idProduct+'-'+index}
                            
                            idProduct={item.idProduct}
                            idPresentation={item.presentaciones[0]?.idPresentation}
                            url={process.env.REACT_APP_PUBLIC_URL + "producto/" +item.slug}
                            image={process.env.REACT_APP_BASE_URL_BACK + item.img}
                            idLine={item.idLine}
                            category={item.category}
                            line={item.line}
                            name={item.name}
                            minPrice={item.precio_minimo}
                            maxPrice={item.precio_maximo}
                            maxPriceDescuento = {item.precio_maximo_descuento}
                            minPriceDescuento = {item.precio_minimo_descuento}
                            promo={item.tipo_promo}
                            quantity={item.quantity}
                            col= {12}
                            percentageDiscount={Math.round(item.presentaciones[0]?.percentageDiscount)}
                            slug={item.presentaciones[0]?.slug}
                            presentation={item.presentaciones.length == 1 ? item.presentaciones[0]?.presentation : ''}
                            onOpenPreview={onOpenPreview}/>
                        ))}
                    </Carousel>
                {/* </Row>  
            </Col>
            </Row> */}
            <div className='con_btn_tienda'>
                <a href='/tienda' className='btn_origo'>VER TODA LA TIENDA</a>
            </div>
           
        </div>
        <div className='bg-alternative pad_t_5'>            
            <h1 className='color-primary'><b>TESTIMONIOS</b></h1>
            <div className='row_testimonio pad_t_5 pad_b_5'>                
                <Col md={2} className='col_testimonio'>
                    <img className='img-lef' src={process.env.REACT_APP_PUBLIC_URL + "images/home/plato-procesado.webp"} alt="Alimento procesado" />
                </Col>
                <Col md={8} className='w_100'>
                    <Carousel className='cont_card_tes' 
                        swipeable={true}
                        draggable={true}
                        showDots={true}
                        responsive={responsive}
                        ssr={true} // means to render carousel on server-side.
                        infinite={true}
                        autoPlay={true}
                        autoPlaySpeed={5000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={500}
                        removeArrowOnDeviceType={["tablet","mobile"]}
                        renderArrowsWhenDisabled={true}
                        >
                        {testimonios.map((item:any) => {
                                return (    
                                <Testimonio
                                description = {item.descripcion}
                                name = {item.nombre}
                                value = {item.evaluacion}
                                />
                            )
                        })}
                    </Carousel>
                </Col>
                {/* <Col md={2} className='col_testimonio'>
                    <img className='img-rig' src={process.env.REACT_APP_PUBLIC_URL + "images/home/plato-verdura.webp"} alt="Plato de verduras" />
                </Col> */}
            </div>
        </div>
                    
        <BeforeFooter></BeforeFooter>

        {product.selected >= 0 && (<PreviewProduct 
            selected = {product.selected}
            url= {product.url}
            image= {product.image}
            idLine= {product.idLine}
            line= {product.line}
            name= {product.name}
            category= {product.category}
            presentations= {product.presentaciones}
            show={show} 
            handleClose={handleClose} 
        />)}

        <Modal className='modal_plan_home' show={showcalc} onHide={handleCloseCalc}
         aria-labelledby="contained-modal-title-vcenter"
         centered>
            <Modal.Header  className="close-modal">                
                <CloseButton variant="white" onClick={handleCloseCalc}/>
            </Modal.Header>
            <Modal.Body>                
                <h3 className='title_modal_plan'>INGRESA TUS DATOS PARA CALCULAR</h3>
                <div className='cont_modal_plan'>
                <Form id="form-suscription-plan-owner" validated={validated}>
                    <p className='question_modal_plan'>¿Cuál es tu nombre?</p>
                    <input type="text" required className='form-control input-no-border' name="ownerName" value={plan.ownerName} onChange={(e) => handleChangeInfoPlan(e)}/>
                    {errors?.ownerName != "" && (
                                <Form.Control.Feedback type="invalid">
                                    {errors?.ownerName}
                                </Form.Control.Feedback>
                            )}
                    <p className='question_modal_plan'>¿Cuál es tu correo?</p>
                    <input type="text" required className='form-control input-no-border' name="ownerEmail" value={plan.ownerEmail} onChange={(e) => handleChangeInfoPlan(e)}/>
                    {errors?.ownerEmail != "" && (
                                 <div className="invalid-feedback d-block" role="alert">
                                    {errors?.ownerEmail}
                                </div>
                            )}
                    <div className="con_btn_tienda">
                        <button id='calc_plan_modal' type="button" className='btn_origo' onClick={(e)=>calcularRacion(e)}>
                            {loadingPlan ? (
                                <Spinner animation="border" role="status" style={{width: '1rem', height: '1rem'}}>
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            ) : (
                            'CALCULAR'
                            )}
                        </button> 
                    </div>
                </Form>
                    {totalPlan> 0 && (<div id='div_respond' className='respond '>
                        <div className='cont_respond'>
                            <h4 className='subtitle_modal_plan'>Aliméntalo con Origo desde:</h4>
                            <div className='plan_respond'>{formatMoney(totalPlan)}<span className='color_origo'>&nbsp;/&nbsp;día</span></div>
                        </div>
                        <div className="con_btn_tienda">
                            <a href="/tienda" className='btn_origo'>COMPRAR</a> 
                        </div>
                    </div>)}
                </div>

            </Modal.Body>
        </Modal>
   </div>
   </MainLayout>
        
)}


export default Home;
