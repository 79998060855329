import Modal from 'react-bootstrap/Modal';
import { Button, CloseButton, Form
    , Row , Col 
  } from "react-bootstrap"

import {StoreService} from '../../business/StoreService';

type ButtonCulqiProps = {
    handleOpenCulqi?: any
    setAmountCulqi: any
    handleOpenCulqiCh?: any
    setAmountCulqiCh: any
    handleShowErrorNotification: any
    data:any
}


function ButtonCulqi(props:ButtonCulqiProps) {
    

  return (
    <button type="button" className="btn-cont-compra btn-cont-contac" onClick={(e) => props.handleOpenCulqiCh(props.handleOpenCulqi, props.setAmountCulqi)}>CONTINUAR</button>
  );
}

export default ButtonCulqi