import Modal from 'react-bootstrap/Modal';
import "./style.css";
import { Button, CloseButton, Form
    , Spinner , Col 
  } from "react-bootstrap";
import { useState, useEffect } from 'react';
import {UserService} from '../../../business/UserService';
import {RewardService} from '../../../business/RewardService';
import * as Yup from 'yup';
import { yupToFormErrors } from "formik";
import { Multiselect } from 'multiselect-react-dropdown';

type ModalRecompensaProps = {
    handleClose: any
    show?: boolean
}

const validSquema = Yup.object().shape({
    cliente: Yup.number().required("*Campo obligatorio")
   ,recompensa: Yup.number().required("*Campo obligatorio")
 });

const initialValues: any = {
    cliente: null,
    recompensa: 0,
    oripoints_actuales: 0
};

function ModalRecompensa(props:ModalRecompensaProps) {
    const [data, setData] = useState(initialValues);
    const [clients, setClients] = useState([]);
    const [errors, setErrors] = useState<any>();
    const [validated, setValidated] = useState<any>();
    const [loading, setLoadingButton] = useState(false);

    useEffect(() => {      
        if(errors == null)
        {
            setValidated(false);
        }
        else{
            setValidated(true);
        }
        
      }, [errors]);

    const getClients = async (search:string) => { 
        const getInstance = UserService.getInstance();

        await getInstance.getClientList({search: search})
            .then((response:any) => {
                
                    if(response.success === true)
                    {
                        setClients(response.clients);

                    }
                    
                })
            .catch((err:any) => {
            });

    }

    const getClientOripoints = async (id:number) => { 
        const getInstance = RewardService.getInstance();

        await getInstance.getClientOripoints({id: id})
            .then((response:any) => {
                
                    if(response.success === true)
                    {
                        setData({...data,
                                ['oripoints_actuales']: response.oripoints_actuales,
                                ['cliente']: id
                        });

                    }
                    
                })
            .catch((err:any) => {
            });

    }

    const insertReward = async () => { 
        setLoadingButton(true);
        const getInstance = RewardService.getInstance();
            
        await getInstance.insertReward(data)
            .then((response:any) => {
                setLoadingButton(false);
                if(response.success === true)
                {
                    handleClose();
                }
                
                    
            })
            .catch((err:any) => {
                setLoadingButton(false);
                console.log(err);
               
                setErrors({
                    codigo: err.result.errors.codigo !== undefined ? err.result.errors.codigo[0] : null,
                    primera_compra: err.result.errors.primera_compra !== undefined ? err.result.errors.primera_compra[0] : null,
                    tipo_descuento: err.result.errors.tipo_descuento !== undefined ? err.result.errors.tipo_descuento[0] : null,
                    valor: err.result.errors.valor !== undefined ? err.result.errors.valor[0] : null,
                    limite: err.result.errors.limite !== undefined ? err.result.errors.limite[0] : null,
                    fecha_inicio: err.result.errors.fecha_inicio !== undefined ? err.result.errors.fecha_inicio[0] : null,
                    fecha_vencimiento: err.result.errors.fecha_vencimiento !== undefined ? err.result.errors.fecha_vencimiento[0] : null,
                });
                
            });
    
    }

    const handleSearchClient = (value:string) => {
        if(value.length > 3){
            getClients(value);
        }
    }
    
    const onSelectClient = (selectedList:any, selectedItem:any) => {
        getClientOripoints(selectedItem.value);
    }

    const onRemoveClient = (selectedList:any, removedItem:any) => {
        setData({
            ...data,
            ['cliente']: null,
            ['oripoints_actuales']:0
        });
    }

    const handleChangeData= (event: any) => {
        const { name, value} = event.target;
        let copy = {...data};
        let val = value;
        
        if(name==='tipo_descuento')
        {
            val = parseInt(value);
        }

    
        setData({
            ...data,
            [name]: event.target.type==='checkbox' ? event.target.checked : val
        });
        
      };

      const handleSave= (event: any) => {
        validSquema.validate(data, { abortEarly: false }).then(function() {
            // Success
            insertReward();

            setErrors(null);
        }).catch(function (err) {
            let errorArray = {};
            const errors = yupToFormErrors(err);
            console.log(errors);
            setErrors(errors);
            setValidated(true);
           
        });
        
      }

      const handleClose = () => {
        setData(initialValues);
        props.handleClose();
      }

  return (
    <Modal id="modal_agregar_cupon" className='modal_cupon' show={props.show} onHide={props.handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header  className="close-modal">   
            <h3 className='title_modal_cupon'>Recompensa Oripoint</h3>             
            <CloseButton variant="white" onClick={handleClose}/>
        </Modal.Header>        
        <Modal.Body>                
            <div className='cont_modal_cupon'>
                <Form className='form_agregar_cupon' validated={validated}>
                    
                    <Form.Group className='row'>
                        <Col md={3}>
                            <Form.Label>Cliente</Form.Label>
                        </Col>
                        <Col md={9}>
                            <Multiselect
                                options={clients} // Options to display in the dropdown
                                onSelect={onSelectClient} // Function will trigger on select event
                                onRemove={onRemoveClient} // Function will trigger on remove event
                                displayValue="text" // Property name to display in the dropdown options
                                onSearch={handleSearchClient}
                                placeholder='Elija un cliente'
                                selectionLimit={1}
                                className={errors?.cliente != "" && errors?.cliente !==undefined && data.cliente == null ? 'invalid' : ""}
                                />
                            <Form.Check>
                            <Form.Check.Input 
                                required
                                checked={data.cliente > 0}
                                style={{display:'none' }}
                                        />
                                {errors?.cliente != "" && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors?.cliente}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Check>
                        </Col>
                    </Form.Group>
                    <Form.Group className='row'>
                        <Col md={3}>
                            <Form.Label>Oripoint actuales</Form.Label>
                        </Col>
                        <Col md={9}>
                            <Form.Control as="input" type="text" name="oripoints_actuales" value={data.oripoints_actuales} readOnly/>
                        </Col>
                    </Form.Group>
                    <Form.Group className='row'>
                        <Col md={3}>
                            <Form.Label>Recompensa</Form.Label>
                        </Col>
                        <Col md={9}>
                            <Form.Control as="input" type="text" required name="recompensa" value={data.recompensa} onChange={e => {handleChangeData(e)}}/>
                        </Col>
                    </Form.Group> 
                    <div className='cont_modal_confirm'>
                        <Col md={12} className='dis_fle'>           
                            <Col md={6} className="con_btn_confirm">
                                <Button className='btn_confirm' type="button" onClick={(e)=>handleSave(e)} disabled={loading}>
                                    {loading ? (
                                    <Spinner animation="border" role="status" style={{width: '1rem', height: '1rem'}}>
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                    ) : (
                                    'Guardar'
                                    )}
                                </Button>  
                            </Col>
                            <Col md={6} className="con_btn_confirm">
                                <button className='btn_cancel' onClick={handleClose}>Cancelar</button> 
                            </Col>                
                        </Col>    
                    </div>                  
                </Form>
            </div>
        </Modal.Body>
    </Modal>
  );
}

export default ModalRecompensa;