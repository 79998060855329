import { api } from "../api";
import ActionType from "../utils/customTypes";


export class AccountService extends api {

    private static classInstance?: AccountService;
        
    constructor(token: string, type: ActionType) {
        super( process.env.REACT_APP_BASE_URL,token);    
    } 
     
    public static getInstance() {
        let token = localStorage.getItem("token");
        if (!this.classInstance) {
            this.classInstance = new AccountService(token!==undefined && token !== null ? token:'', 'GET');
        }
        
        return this.classInstance;
        
    }   
     
    public getProfile = () => this.instance.post('/account/get-profile').then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getDashboard = () => this.instance.post('/account/get-dashboard').then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });


    public updateProfile = (data:any) => this.instance.post('/account/update-profile',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public updatePassword = (data:any) => this.instance.post('/account/update-password',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

   
}