import React, { useState, useEffect } from 'react';
import { Button, Container, Row, Col, Form, Spinner } from 'react-bootstrap';
import BackLayout from "../../../components/layout/BackLayout/BackLayout";
import {ProductService} from '../../../business/ProductService';
import uploadFile from '../../../business/FileService';
import * as Yup from 'yup';
import { yupToFormErrors } from "formik";
import { Multiselect } from 'multiselect-react-dropdown';
import { useParams } from "react-router-dom";
import ModalAddPresentationImage from './modalAddPresentationImage';
import ModalConfirm from "../../../components/confirm/ModalConfirm";

const validSquema = Yup.object().shape({    
    presentacion: Yup.number().required("*Campo obligatorio")
    ,codigo: Yup.string().required("*Campo obligatorio")
    ,concepto: Yup.string().required("*Campo obligatorio")
    ,precio: Yup.number().required("*Campo obligatorio")
 });

 let confirmDefault = {
    id:0,
    name: '',
    show: false
}

function EditarPresentacion (){
    const [data, setData] = useState<any>({presentacionesimg: []});
    const [loading, setLoading] = useState(false);
    const [presentaciones, setPresentaciones] = useState([]);
    const [validated, setValidated] = useState<any>();
    const [errors, setErrors] = useState<any>();
    const [loadingButton, setLoadingButton] = useState(false);
    const [selectedProductFile, setSelectedProductFile] = useState();
    const [selectedDetailFile, setSelectedDetailFile] = useState();
    const [previewProductFile, setPreviewProductFile] = useState("");
    const [previewDetailFile, setPreviewDetailFile] = useState("");
    const [showAddImage, setShow] = useState(false);
    const [confirmData, setConfirmData] = useState(confirmDefault);

    let params = useParams();
    let id = 0;
    if(params.id_presen !== undefined)
    {
        id = parseInt(params.id_presen);
    }

    useEffect(() => {      
        getPresentationList();    
        getProductPresentation(id);    
      }, []);

      const getProductPresentation = async (idPresen:number) => { 
        const getInstance = ProductService.getInstance();
        setLoading(true);

        await getInstance.getProductPresentation({id: idPresen})
            .then((response:any) => {
                    setLoading(false);
                    if(response.success === true)
                    {
                        setData(response.data);

                    }
                    
                })
            .catch((err:any) => {
                setLoading(false);
            });

    }

    const getPresentationList = async () => { 
        const getInstance = ProductService.getInstance();
        setLoading(true);

        await getInstance.getPresentationList()
            .then((response:any) => {
                
                    if(response.success === true)
                    {
                        setPresentaciones(response.data);

                    }
                    setLoading(false);
                    
                })
            .catch((err:any) => {
                setLoading(false);
            });

    }

    const updateProductPresentation = async (imgProduct:string) => { 
        const getInstance = ProductService.getInstance();
        setLoading(true);

        await getInstance.updateProductPresentation({...data, ['imagen']: imgProduct})
            .then((response:any) => {
                    setLoading(false);
                    if(response.success === true)
                    {
                        //

                    }
                    
                })
            .catch((err:any) => {
                setLoading(false);
            });

    }

    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        if (!selectedProductFile) {
            setPreviewProductFile("")
            return
        }

        const objectUrl = URL.createObjectURL(selectedProductFile)
        setPreviewProductFile(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedProductFile])

    const onSelectProductFile = (e:any) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedProductFile(undefined)
            return
        }

        // I've kept this example simple by using the first image instead of multiple
        setSelectedProductFile(e.target.files[0])
    }


    const deletePresentationImage = async (idImage:number) => { 
        setLoadingButton(true);
        const getInstance = ProductService.getInstance();
            
        await getInstance.deletePresentationImage({id: idImage})
            .then((response:any) => {
                setLoadingButton(false);
                getProductPresentation(id);
                
            })
            .catch((err:any) => {
                setLoadingButton(false);                
            });
    
    }

    const handleCloseAddImage = () => {
        setShow(false);
        getProductPresentation(id);
    }

    const handleChangeData= (event: any) => {
        const { name, value} = event.target;
        let copy = {...data};
        let val = value;
        
        if((name==='linea' || name==='categoria' || name==='presentacion') && value != '')
        {
            val = parseInt(value);
        }
        if((name==='precio' || name==='descuento') && value != '')
        {
            val = parseFloat(value);
        }

    
        setData({
            ...data,
            [name]: event.target.type==='checkbox' ? event.target.checked : val
        });
        
      };

    const handleShowAddImage = () => setShow(true);

    const handleConfirmDelete = (e:any,id:number, name: string) => {
        setConfirmData({show:true, id: id, name: name})
    }

    const handleDeleteImg = (event:any, id:number) => {
        setConfirmData({
            ...confirmData,
            show: false
        });
        deletePresentationImage(id);
    }

    const handleClose= (event: any) => {
    
        setConfirmData({
            ...confirmData,
            show: false
        })    
        
    };

    const  handleSave = (event: any) => {
        validSquema.validate(data, { abortEarly: false }).then(async function() {
           
            var result = await uploadFile(selectedProductFile,'upload/productos').then((response:any) => {
                
                if(response.status == 200)
                {
                    let result = response.data.result;
                    if(result.success == true)
                    {
                       return result.data.imagen_path;
                        
                    }
                }
                return '';
                
            });

            updateProductPresentation(result);

            setErrors(null);
        }).catch(function (err) {
            let errorArray = {};
            const errors = yupToFormErrors(err);
            console.log(errors);
            setErrors(errors);
            setValidated(true);
           
        });
        
    }

    return (
        <BackLayout>
        <div className='fond_backoffice'>
            <Container>                
                <div className='cont_title'>
                <h3><a href={process.env.REACT_APP_PUBLIC_URL + 'admin/editar-producto/'+ data.produc_id_produc}>{data.nombreProducto}</a> - Editar Presentación</h3>
                </div>
                <div className='cont_data'>                           
                    <Form id="form-registrar-producto" validated={validated}>
                        <Row className="">
                            <Col md={12}>
                                <div className="section_backoffice_form">
                                    <div className="">
                                        <h3>Datos de la Presentación</h3>
                                        <Row>
                                            <Col md={4}>                              
                                                <Form.Group>     
                                                    <Form.Label>Envase*:</Form.Label>                   
                                                    <Form.Control as="select" name="presentacion" required value={data.presentacion} onChange={e => {handleChangeData(e)}}>
                                                        <option value="">Seleccionar</option>
                                                        {presentaciones.map((item:any) => (
                                                             <option key={item.id} value={item.id}>
                                                                {item.name}   
                                                            </option>
                                                        ))}
                                                    </Form.Control>                                                    
                                                    {errors?.presentacion != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.presentacion}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>  
                                            </Col>
                                            <Col md={4}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Código SKU:</Form.Label>
                                                    <input type="text" name='codigo' required className='form-control' value={data.codigo} onChange={e => {handleChangeData(e)}}/>                                                    
                                                    {errors?.codigo != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.codigo}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>  
                                            </Col>
                                            <Col md={4}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Id Producto Alegra:</Form.Label>
                                                    <input type="text" name='idalegra' className='form-control' value={data.idalegra} onChange={e => {handleChangeData(e)}}/>                                                    
                                                   
                                                </Form.Group>  
                                            </Col>
                                            <Col md={12}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Descripción Alegra:</Form.Label>
                                                    <input type="text" name='descripcion_alegra' className='form-control' value={data.descripcion_alegra} onChange={e => {handleChangeData(e)}}/>                                                    
                                                   
                                                </Form.Group>  
                                            </Col>
                                            <Col md={12}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Concepto*:</Form.Label>
                                                    <input type="text" name='concepto' required className='form-control' value={data.concepto} onChange={e => {handleChangeData(e)}}/>                                                    
                                                    {errors?.concepto != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.concepto}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>  
                                            </Col>                                            
                                            <Col md={12}>
                                                <Form.Group>
                                                <Form.Label>Descripción técnica</Form.Label>
                                                <textarea name="nombre_presentacion" className="form-control" rows={2} placeholder="" value={data.nombre_presentacion} onChange={e => {handleChangeData(e)}}></textarea>
                                                </Form.Group>
                                            </Col>  
                                            <Col md={12}>
                                                <Form.Group>
                                                <Form.Label>Descripción presentación</Form.Label>
                                                <textarea name="descripcion_presentacion" className="form-control" rows={2} placeholder="" value={data.descripcion_presentacion} onChange={e => {handleChangeData(e)}}></textarea>
                                                </Form.Group>
                                            </Col>  
                                            
                                            <Col md={6}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Precio:</Form.Label>
                                                    <input type="number" name='precio' required className='form-control' value={data.precio} onChange={e => {handleChangeData(e)}}/>                                                    
                                                    {errors?.precio != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.precio}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>  
                                            </Col>
                                            <Col md={6}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Descuento:</Form.Label>
                                                    <input type="number" name='descuento' className='form-control' value={data.descuento} onChange={e => {handleChangeData(e)}}/>                                                    
                                                    <span className="help-block"></span>
                                                </Form.Group>  
                                            </Col>
                                            <Col md={6}>
                                            {(selectedProductFile=='' || selectedProductFile===undefined) && data.imagen != null && (<Row className="pad_top_15">
                                                    <div id="img_box" className="pad_bot_15">
                                                        <div>
                                                        <img className="edit-img-propre" width="150px" src={process.env.REACT_APP_BASE_URL_BACK + data.imagen} alt="" data-img="56"/>
                                                        
                                                        </div>
                                                    </div>
                                                </Row>  ) }                            
                                                <Form.Group>                        
                                                    <Form.Label>Imagen:</Form.Label>
                                                    <Form.Control type="file" as='input' name='imagen_suscripcion_producto' className='form-control' onChange={onSelectProductFile}/>
                                                    {selectedProductFile &&  <img className="edit-img-propre" style={{width: '200px'}} src={previewProductFile} /> }
                                                </Form.Group>  
                                            </Col>  
                                            <Col md={12} className="pad_top_2 dis_fle spac_betw">
                                                <Col md={4} className="align-items-center txt_ali dis_fle con_btn_confirm">                                                    
                                                    <Button className='btn_confirm' type="button" onClick={(e)=>handleSave(e)} disabled={loading}>
                                                        {loading ? (
                                                        <Spinner animation="border" role="status" style={{width: '1rem', height: '1rem'}}>
                                                            <span className="visually-hidden">Loading...</span>
                                                        </Spinner>
                                                        ) : (
                                                        'Guardar'
                                                        )}
                                                    </Button>                                                   
                                                </Col>
                                                <Col md={4} className="align-items-center txt_ali dis_fle cont_btn_cancel">                                                    
                                                    <a href={process.env.REACT_APP_PUBLIC_URL + 'admin/editar-producto/'+ data.produc_id_produc} className='btn_cancelar_registro btn'>Cancelar</a>                                                        
                                                </Col>
                                            </Col>
                                            <Col md={12}>
                                                <br></br>
                                                <hr></hr>
                                            <h3 className='cont_btn_agre_mas'>Slider  <Button className='btn_agregar_mascota' onClick={handleShowAddImage}>Agregar</Button></h3>
                                            <br></br>
                                                {data.presentacionesimg.map((item:any) => (
                                                        <div>
                                                            <div id="img_box" className="pad_bot_15 d-inline-flex">
                                                                <img className="edit-img-propre" width="150px" src={process.env.REACT_APP_BASE_URL_BACK + item.imagen_path} />
                                                                <a className="btn delete-imagen" data-img="56" onClick={(e) => handleConfirmDelete(e, item.id_preimg, item.imagen)}>
                                                                    <div className='cont_ver_detalle'>                                
                                                                        <img src={process.env.REACT_APP_PUBLIC_URL + 'images/common/cancel.webp'} alt="Cancelar" />
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        )
                                                    )}
                                               
                                            </Col>
                                            
                                        </Row>
                                    </div>
                                </div>
                            </Col>   
                        </Row>                            
                    </Form>
                </div>
            </Container>  
            <ModalConfirm text="" handleClose={handleClose} show={confirmData?.show} handleSave={handleDeleteImg} action={"¿Estás seguro de eliminar la imagen " + confirmData?.name + "?"} id={confirmData?.id}/>
        
            <ModalAddPresentationImage id={id} show={showAddImage} handleClose={handleCloseAddImage} />
        </div>
        </BackLayout>

)};
export default EditarPresentacion;
