import React from 'react';
import { useShoppingCart } from "../../context/ShoppingCartContext"

/**
 * Redirect Screen Component
 * @packageDocumentation
 * @module Redirect
 */
const Redirect: React.FC<{}> = () => {
    let role=localStorage.getItem('rol');

    const { cartItems } = useShoppingCart();

    if(cartItems.length>0 && role!='1')
    {
      window.location.href='/checkout';
    }
    else
    {
      switch(role)
      {
          case '1': window.location.href='/admin/dashboard';
              break;
          default:
              window.location.href = '/cliente/resumen';
      }
    }
    

 return (
        <></>
      );
        
}


export default Redirect;
