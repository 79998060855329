import React from 'react';
import "../reporteVentas/style.css";
import BackLayout from "../../../components/layout/BackLayout/BackLayout";
import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';
import { useState, useCallback, useEffect } from 'react';
import {AccountService} from '../../../business/AccountService';
import * as Yup from 'yup';
import { yupToFormErrors } from "formik";
import jsPDF, { GState } from "jspdf";
import autoTable from 'jspdf-autotable';
import moment from 'moment';
import 'moment/locale/es';
import { render } from '@testing-library/react';

let doc = new jsPDF('portrait','pt','a4');
doc.setFontSize(11);

let cuenta = {
    nombres: ""
    ,primer_apellido: ""
    ,segundo_apellido: ""
    ,email: ""
    ,creditos_disponibles: 0
    ,creditos_utilizados: 0
    ,creditos_acumulados: 0
    ,telefono_uno: ""
    ,telefono_dos: ""
    , rol: 0
    , fecha_contrato: ""
    , nro_documento: ""
}
const pass = {    
    password:  ""
    ,password_actual:  ""
    ,confirm_password:  ""
 };
const validSquema = Yup.object().shape({    
    nombres: Yup.string().required("*Campo obligatorio")
    ,primer_apellido: Yup.string().required("*Campo obligatorio")
    ,email: Yup.string().required("*Campo obligatorio")
 });

 const validPasswordSquema = Yup.object().shape({    
    password: Yup.string().required("*Campo obligatorio")
    ,password_actual: Yup.string().required("*Campo obligatorio")
    ,confirm_password: Yup.string().required("*Campo obligatorio")
 });


function MiCuenta (){
    const [data, setData] = useState(cuenta);
    const [dataPass, setDataPass] = useState(pass);
    const [totalFrecuentes, setTotalFrecuentes] = useState(0);
    const [totalHistorico, setTotalHistorico] = useState(0);
    const [totalInactivos, setTotalInactivos] = useState(0);
    const [totalAcumulados, setTotalAcumulados] = useState(0);
    const [totalUtilizados, setTotalUtilizados] = useState(0);
    const [totalRecompensa, setTotalRecompensa] = useState(0);
    const [totalAcumuladosFisica, setTotalAcumuladosFisica] = useState(0);
   
    const [loading, setLoading] = useState(false);
    const [loadingPass, setLoadingPass] = useState(false);
    const [validated, setValidated] = useState<any>();
    const [validatedPass, setValidatedPass] = useState<any>();
    const [errors, setErrors] = useState<any>();

    const getProfile = async () => { 
        const getInstance = AccountService.getInstance();
        
        await getInstance.getProfile()
            .then((response:any) => {
                    setLoading(false);
                    if(response.success === true)
                    {
                        setData(response.datosresumen);
                        setTotalFrecuentes(response.total_frecuentes);
                        setTotalHistorico(response.total_historico);
                        setTotalInactivos(response.inactivos);
                        setTotalAcumulados(response.total_acumulados);
                        setTotalUtilizados(response.total_utilizados);
                        setTotalRecompensa(response.total_recompensa);
                        setTotalAcumuladosFisica(response.total_acumulados_fisica);

                    }
                    
                })
            .catch((err:any) => {
            });

    }

    const updateProfile = async () => { 
        const getInstance = AccountService.getInstance();
        setLoading(true);

        await getInstance.updateProfile(data)
            .then((response:any) => {
                    setLoading(false);
                    
                    
                })
            .catch((err:any) => {
                setLoading(false);
            });

    }

    const updatePassword = async () => { 
        const getInstance = AccountService.getInstance();
        setLoadingPass(true);

        await getInstance.updatePassword(dataPass)
            .then((response:any) => {
                    setLoadingPass(false);
                    setDataPass(pass);
                    
                })
            .catch((err:any) => {
                setLoadingPass(false);
            });

    }

    useEffect(() => {   
        getProfile();      
      }, []);

    const handleChangeData= (event: any) => {
        const { name, value} = event.target;
        let copy = {...data};
        let val = value;
        
        setData({
            ...data,
            [name]: event.target.type==='checkbox' ? event.target.checked : val
        });
        
    };

    const handleChangePass= (event: any) => {
        const { name, value} = event.target;
        let val = value;
        
        setDataPass({
            ...dataPass,
            [name]:  val
        });
        
    };

    const  handleSave = (event: any) => {
        validSquema.validate(data, { abortEarly: false }).then(function() {
            // Success
            updateProfile();

            setErrors(null);
        }).catch(function (err) {
            let errorArray = {};
            const errors = yupToFormErrors(err);
            console.log(errors);
            setErrors(errors);
            setValidated(true);
           
        });
        
    }
    const  handleUpdatePassword = (event: any) => {
        validPasswordSquema.validate(dataPass, { abortEarly: false }).then(function() {
            // Success
            updatePassword();

            setErrors(null);
        }).catch(function (err) {
            let errorArray = {};
            const errors = yupToFormErrors(err);
            console.log(errors);
            setErrors(errors);
            setValidatedPass(true);
           
        });
        
    }

    const addFooters = (docel:any) => {
        const pageCount = docel.internal.getNumberOfPages()
        
      
        docel.setFont('helvetica', 'italic')
        docel.setFontSize(11);
        var img = new Image();
        img.src = process.env.PUBLIC_URL + '/images/logo.jpg';
        
        for (var i = 1; i <= pageCount; i++) {
          docel.setPage(i);
          docel.setDrawColor("#ff6f5d");
          docel.setLineWidth(5);
          docel.rect(30, 30, docel.internal.pageSize.width - 60, docel.internal.pageSize.height - 60, 'S');
          if(i==1)
          {
            docel.addImage(img, 'PNG',235,33, 115, 125, undefined,'FAST');
          }
          
          
          
          docel.saveGraphicsState();
        }
      }

    const createPdf = (name:string,contractDate:string,docNumber:string) => {
    
        /*props.handlePdfGenerated(1);*/
        doc = new jsPDF('portrait','pt','a4');
    
        autoTable(doc, ({
          styles: { overflow: "linebreak",fillColor: [255, 255, 255], fontSize: 17, fontStyle: 'bold', cellPadding: {top: 7} },
          columnStyles: { 0: { halign: 'center', fillColor: [255, 255, 255] } }, 
          margin: { top: 180 },
          body: [
            ['Acuerdo Comercial – Influenciadores']
          ],
         
        }))
    
        autoTable(doc, ({
            pageBreak: 'avoid',
            styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontSize: 11, cellPadding: {left: 25, right: 25}},
            columnStyles: {0: {halign: 'left', fillColor: [255, 255, 255] }},
            margin: { top: 100 },
            body:[
            ['Lima, ' + moment(contractDate).format("DD [de] MMMM [de] YYYY")]
            ],            
            willDrawCell: (data) => {
                data.row.height = 5;
            }
        
        }));
        autoTable(doc, ({
            pageBreak: 'avoid',
            styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontSize: 11, cellPadding: {left: 25, right: 25} },
            columnStyles: {0: {halign: 'left', fillColor: [255, 255, 255] }},
            margin: { top: 100 },
            body:[
            ['Señor/a'],
            [name]
            ],
            willDrawCell: (data) => {
                data.row.height = 15;
            }
        }));
        autoTable(doc, ({
            pageBreak: 'avoid',
            styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontSize: 11, cellPadding: {left: 25, right: 25} },
            columnStyles: {0: {halign: 'left', fillColor: [255, 255, 255] }},
            margin: { top: 100 },
            body:[
            ['De nuestra consideración.-']
            ],
            willDrawCell: (data) => {
                data.row.height = 5;
            }
        }));
        autoTable(doc, ({
            pageBreak: 'avoid',
            styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontSize: 11, cellPadding: {left: 25, right: 25} },
            columnStyles: {0: {halign: 'left', fillColor: [255, 255, 255] }},
            margin: { top: 100 },
            body:[
            ['Por medio de la presente nos permitimos señalar los términos del Acuerdo Comercial entre ORIGO PET FOOD S.A.C. (en adelante ORIGO) y '+ name +' (en adelante EL CLIENTE) por un periodo de 1 año desde la firma del presente acuerdo. Finalizado este periodo, de ser necesario, las partes podrán revisar y renegociar las condiciones acordadas antes de su renovación.']
            ],
            willDrawCell: (data) => {
                data.row.height = 55;
            }
        }));
        autoTable(doc, ({
            pageBreak: 'avoid',
            styles: { overflow: "linebreak",fillColor: [255, 255, 255], fontStyle: 'bold', fontSize: 11, cellPadding: {left: 25, right: 25} },
            columnStyles: { 0: { halign: 'left', fillColor: [255, 255, 255] } }, 
            margin: { top: 180 },
            body: [
              ['Modalidad de venta - ORIGO']
            ],
            willDrawCell: (data) => {
                data.row.height = 5;
            }
           
          }))
          autoTable(doc, ({
            pageBreak: 'avoid',
            styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontStyle: 'bold', fontSize: 11, cellPadding: {left: 25, right: 25}},
            columnStyles: { 0: { halign: 'left', fillColor: [255, 255, 255] } }, 
            margin: { top: 180 },
            body: [
              ['VENTA ONLINE:']
            ],
            willDrawCell: (data) => {
                data.row.height = 5;
            }
           
          }))
          autoTable(doc, ({
            pageBreak: 'avoid',
            styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontSize: 11, cellPadding: {left: 25, right: 25} },
            columnStyles: {0: {halign: 'left', fillColor: [255, 255, 255] }},
            margin: { top: 100 },
            body:[
            ['Toda venta de productos de ORIGO que se realice a través de la plataforma online: www.Origo.pet']
            ],
            willDrawCell: (data) => {
                data.row.height = 15;
            }
        }));
        autoTable(doc, ({
            pageBreak: 'avoid',
            styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontSize: 11, cellPadding: {left: 25, right: 25} },
            columnStyles: {0: {halign: 'left', fillColor: [255, 255, 255] }},
            margin: { top: 100 },
            body:[
            ['EL CLIENTE tiene la opción de ganar una comisión de 10% de todos los productos de ORIGO que compren los usuarios que fueron recomendados por EL CLIENTE.']
            ],
            willDrawCell: (data) => {
                data.row.height = 30;
            }
        }));
        autoTable(doc, ({
            styles: { overflow: "linebreak",fillColor: [255, 255, 255], fontSize: 12, fontStyle: 'bold', cellPadding: {left: 25, right: 25} },
            columnStyles: { 0: { halign: 'center', fillColor: [255, 255, 255] } }, 
            margin: { top: 180 },
            body: [
              ['PROGRAMA DE EMBAJADORES ORIGO – VENTA ONLINE']
            ],
           
          }))
          autoTable(doc, ({
            pageBreak: 'avoid',
            styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontSize: 11, cellPadding: {left: 25, right: 25} },
            columnStyles: {0: {halign: 'left', fillColor: [255, 255, 255] }},
            margin: { top: 100 },
            body:[
            ['Para nosotros es muy importante compartir nuestra filosofía de alimentación con la mayor cantidad de perros y gatos posibles, por eso, hemos creado el programa de embajadores Origo en donde tu podrás ser parte de este gran cambio positivo en su calidad de vida y al mismo tiempo obtener beneficios extras.']
            ],
            willDrawCell: (data) => {
                data.row.height = 40;
            }
        }));
        autoTable(doc, ({
            pageBreak: 'avoid',
            styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontSize: 11, cellPadding: {left: 25, right: 25} },
            columnStyles: {0: {halign: 'left', fillColor: [255, 255, 255] }},
            margin: { top: 100 },
            body:[
            ['Es sencillo. Recomienda a tus clientes que compren Origo a través de nuestra plataforma online (www.Origo.pet) y podrás ganar el 10% del valor de esa compra en Oripoints.']
            ],
            willDrawCell: (data) => {
                data.row.height = 138;
            }
        }));
        autoTable(doc, ({
            styles: { overflow: "linebreak",fillColor: [255, 255, 255], fontSize: 12, fontStyle: 'bold', cellPadding: {left: 25, right: 25} },
            columnStyles: { 0: { halign: 'center', fillColor: [255, 255, 255] } }, 
            margin: { top: 100 },
            body: [
              ['¿COMO SER UN EMBAJADOR ORIGO?']
            ],
            willDrawCell: (data) => {
                data.row.height = 40;
            }
          }))
          autoTable(doc, ({
            pageBreak: 'avoid',
            styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontStyle: 'bold', fontSize: 11, cellPadding: {left: 25, right: 25}},
            columnStyles: { 0: { halign: 'left', fillColor: [255, 255, 255] } }, 
            margin: { top: 180 },
            body: [
              ['PASO 1. CREA TU CUENTA EN ORIGO.PET']
            ],
            willDrawCell: (data) => {
                data.row.height = 5;
            }
           
          }))
          autoTable(doc, ({
            pageBreak: 'avoid',
            styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontSize: 11, cellPadding: {left: 25, right: 25} },
            columnStyles: {0: {halign: 'left', fillColor: [255, 255, 255] }},
            margin: { top: 100 },
            body:[
            ['Ingresa a nuestra plataforma y crea, por primera y única vez, tu cuenta de influenciador. Necesitamos saber de ti, tus datos importantes.']
            ],
            willDrawCell: (data) => {
                data.row.height = 20;
            }
        }));
        autoTable(doc, ({
            pageBreak: 'avoid',
            styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontStyle: 'bold', fontSize: 11, cellPadding: {left: 25, right: 25}},
            columnStyles: { 0: { halign: 'left', fillColor: [255, 255, 255] } }, 
            margin: { top: 180 },
            body: [
              ['PASO 2. GENERA TU CÓDIGO O LINK DE DESCUENTO']
            ],
            willDrawCell: (data) => {
                data.row.height = 5;
            }
           
          }))
          autoTable(doc, ({
            pageBreak: 'avoid',
            styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontSize: 11, cellPadding: {left: 25, right: 25} },
            columnStyles: {0: {halign: 'left', fillColor: [255, 255, 255] }},
            margin: { top: 100 },
            body:[
            ['En tu perfil podrás generar un código de descuento (siempre será el mismo) que únicamente te identificará a ti como influenciador. Con este código, podrás ofrecer 10% de descuento en la primera compra a todas las personas que quieras sin limite de veces. También podrás compartir tu link de descuento para que tus referidos no necesiten ingresar manualmente el código de descuento cuando creen su cuenta. Este código de descuento aplica para todos los usuarios que vayan a realizar una compra a través de la plataforma online de ORIGO por primera vez.']
            ],
            willDrawCell: (data) => {
                data.row.height = 70;
            }
        }));
        autoTable(doc, ({
            pageBreak: 'avoid',
            styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontStyle: 'bold', fontSize: 11, cellPadding: {left: 25, right: 25}},
            columnStyles: { 0: { halign: 'left', fillColor: [255, 255, 255] } }, 
            margin: { top: 180 },
            body: [
              ['PASO 3. COMPARTE TU CÓDIGO / LINK']
            ],
            willDrawCell: (data) => {
                data.row.height = 5;
            }
           
          }))
          autoTable(doc, ({
            pageBreak: 'avoid',
            styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontSize: 11, cellPadding: {left: 25, right: 25} },
            columnStyles: {0: {halign: 'left', fillColor: [255, 255, 255] }},
            margin: { top: 100 },
            body:[
            ['Una vez que hayas generado el código o link, puedes compartirlo por el medio de tu preferencia. También podrás compartir tu código o link por cualquier media digital (Whatsapp, Facebook, Instagram, Twitter, etc). Nosotros te entregaremos elementos gráficos para que tú puedas tener la libertad de utilizarlos (logotipo, flyer, etc).']
            ],
            willDrawCell: (data) => {
                data.row.height = 45;
            }
        }));
        autoTable(doc, ({
            pageBreak: 'avoid',
            styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontStyle: 'bold', fontSize: 11, cellPadding: {left: 25, right: 25}},
            columnStyles: { 0: { halign: 'left', fillColor: [255, 255, 255] } }, 
            margin: { top: 180 },
            body: [
              ['PASO 4. REVISA TUS GANANCIAS']
            ],
            willDrawCell: (data) => {
                data.row.height = 5;
            }
           
          }))
          autoTable(doc, ({
            pageBreak: 'avoid',
            styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontSize: 11, cellPadding: {left: 25, right: 25} },
            columnStyles: {0: {halign: 'left', fillColor: [255, 255, 255] }},
            margin: { top: 100 },
            body:[
            ['Mantente al tanto de las compras de tus referidos y las ganancias que ellos te generan consultando tu perfil de usuario. Es automático y muy sencillo de consultar. Utiliza tus Oripoints ganados en el momento que prefieras dentro de un periodo máximo de 6 meses.']
            ],
            willDrawCell: (data) => {
                data.row.height = 400;
            }
        }));
        autoTable(doc, ({
            styles: { overflow: "linebreak",fillColor: [255, 255, 255], fontSize: 12, fontStyle: 'bold', cellPadding: {left: 25, right: 25} },
            columnStyles: { 0: { halign: 'center', fillColor: [255, 255, 255] } }, 
            margin: { top: 100 },
            body: [
              ['TÉRMINOS Y CONDICIONES']
            ],
            willDrawCell: (data) => {
                data.row.height = 40;
            }
          }))
          autoTable(doc, ({
            pageBreak: 'avoid',
            styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontStyle: 'bold', fontSize: 11, cellPadding: {left: 25, right: 25}},
            columnStyles: { 0: { halign: 'left', fillColor: [255, 255, 255] } }, 
            margin: { top: 180 },
            body: [
              ['1. Pautas generales']
            ],
            willDrawCell: (data) => {
                data.row.height = 5;
            }
           
          }))
          autoTable(doc, ({
            pageBreak: 'avoid',
            styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontSize: 11, cellPadding: {left: 25, right: 25} },
            columnStyles: {0: {halign: 'left', fillColor: [255, 255, 255] }},
            margin: { top: 100 },
            body:[
            ['El Programa de embajadores te permite conseguir descuentos por recomendar nuestros productos con tu código o link promocional.']
            ],
            willDrawCell: (data) => {
                data.row.height = 25;
            }
        }));
        autoTable(doc, ({
            pageBreak: 'avoid',
            styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontSize: 11, cellPadding: {left: 25, right: 25} },
            columnStyles: {0: {halign: 'left', fillColor: [255, 255, 255] }},
            margin: { top: 100 },
            body:[
            ['Estos códigos o links promocionales deberán utilizarse debidamente para que nuestro sistema pueda detectarlo. Ganarás el 10% del monto de la compra de tu referido en su primera compra por la web en forma de Oripoints.']
            ],
            willDrawCell: (data) => {
                data.row.height = 35;
            }
        }));
        autoTable(doc, ({
            pageBreak: 'avoid',
            styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontSize: 11, cellPadding: {left: 25, right: 25} },
            columnStyles: {0: {halign: 'left', fillColor: [255, 255, 255] }},
            margin: { top: 100 },
            body:[
            ['Para facilitar la publicidad de nuestros productos, podremos facilitar banners, imágenes, cupones de descuento para tus usuarios u otras herramientas. Queda expresamente prohibido su uso fuera de la promoción de nuestros productos y deberán respetar la identidad corporativa de la marca (tipografía y color del logo, valores de la marca, nombres comerciales, etc).']
            ],
            willDrawCell: (data) => {
                data.row.height = 45;
            }
        }));
        autoTable(doc, ({
            pageBreak: 'avoid',
            styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontStyle: 'bold', fontSize: 11, cellPadding: {left: 25, right: 25}},
            columnStyles: { 0: { halign: 'left', fillColor: [255, 255, 255] } }, 
            margin: { top: 180 },
            body: [
              ['2. Requisitos de Cumplimiento']
            ],
            willDrawCell: (data) => {
                data.row.height = 5;
            }
           
          }))
          autoTable(doc, ({
            pageBreak: 'avoid',
            styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontSize: 11, cellPadding: {left: 25, right: 25} },
            columnStyles: {0: {halign: 'left', fillColor: [255, 255, 255] }},
            margin: { top: 100 },
            body:[
            ['Para participar y recibir los beneficios de nuestro Programa de Embajadores, debes cumplir con este acuerdo. En el caso de que lo infrinjas, nos reservamos el derecho, además de cualquier otro derecho y recurso que nos asista, a retener a perpetuidad los beneficios que, de otro modo, te hubiesen correspondido con arreglo a este acuerdo, con independencia de que estén o no directamente relacionadas con dicha infracción. Todo ello sin necesidad de aviso y sin perjuicio de los derechos que asistan a Origo de reclamar daños y perjuicios que excedan de dicho importe. Asimismo, nos reservamos el derecho a suspender tu cuenta o cualquier relación que nos una. También, deberás facilitarnos cualquier información que te solicitemos para verificar el cumplimiento del acuerdo.']
            ],
            willDrawCell: (data) => {
                data.row.height = 95;
            }
        }));
        autoTable(doc, ({
            pageBreak: 'avoid',
            styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontSize: 11, cellPadding: {left: 25, right: 25} },
            columnStyles: {0: {halign: 'left', fillColor: [255, 255, 255] }},
            margin: { top: 100 },
            body:[
            ['Tú, como embajador, debes garantizar que ninguno de tus sitios web o canales donde difundas tus códigos promocionales infringen ninguna ley o código de conducta, y que tampoco lo hacen tus actividades como participante en nuestro Programa de Embajadores.']
            ],
            willDrawCell: (data) => {
                data.row.height = 35;
            }
        }));
        autoTable(doc, ({
            pageBreak: 'avoid',
            styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontSize: 11, cellPadding: {left: 25, right: 25} },
            columnStyles: {0: {halign: 'left', fillColor: [255, 255, 255] }},
            margin: { top: 100 },
            body:[
            ['Aparte de obtener beneficios por estar adscrito al programa de embajadores, no podrás realizar ninguna otra comunicación o manifestación pública relativa a este acuerdo, sin contar con nuestra previa autorización por escrito. Asimismo, no podrás realizar manifestaciones falsas ni tergiversar o distorsionar la relación establecida entre nosotros, ni declarar o hacer entender, ya sea expresa o tácitamente, la existencia de cualquier vinculación o asociación entre nosotros, excepto en la medida expresamente autorizada bajo el presente acuerdo']
            ],
            willDrawCell: (data) => {
                data.row.height = 230;
            }
        }));
        autoTable(doc, ({
            pageBreak: 'avoid',
            styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontStyle: 'bold', fontSize: 11, cellPadding: {left: 25, right: 25}},
            columnStyles: { 0: { halign: 'left', fillColor: [255, 255, 255] } }, 
            margin: { top: 80 },
            body: [
              ['3. Exoneración de responsabilidad']
            ],
            willDrawCell: (data) => {
                data.row.height = 5;
            }
           
          }))
          autoTable(doc, ({
            pageBreak: 'avoid',
            styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontSize: 11, cellPadding: {left: 25, right: 25} },
            columnStyles: {0: {halign: 'left', fillColor: [255, 255, 255] }},
            margin: { top: 100 },
            body:[
            ['No garantizamos que nuestro Programa de Embajadores Origo funcione sin interrupción, sin errores o según lo esperado.']
            ],
            willDrawCell: (data) => {
                data.row.height = 15;
            }
        }));
        autoTable(doc, ({
            pageBreak: 'avoid',
            styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontSize: 11, cellPadding: {left: 25, right: 25} },
            columnStyles: {0: {halign: 'left', fillColor: [255, 255, 255] }},
            margin: { top: 100 },
            body:[
            ['Ni nosotros ni ninguna de nuestras entidades vinculadas seremos responsables, ni asumiremos responsabilidad alguna por daños indirectos, consecuenciales, punitivos o cualquier lucro cesante, pérdida de fondo de comercio, de uso o de datos que se derive de tu actividad como Embajador Origo.']
            ],
            willDrawCell: (data) => {
                data.row.height = 40;
            }
        }));
        autoTable(doc, ({
            pageBreak: 'avoid',
            styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontStyle: 'bold', fontSize: 11, cellPadding: {left: 25, right: 25}},
            columnStyles: { 0: { halign: 'left', fillColor: [255, 255, 255] } }, 
            margin: { top: 180 },
            body: [
              ['4. Modificaciones']
            ],
            willDrawCell: (data) => {
                data.row.height = 5;
            }
           
          }))
          autoTable(doc, ({
            pageBreak: 'avoid',
            styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontSize: 11, cellPadding: {left: 25, right: 25} },
            columnStyles: {0: {halign: 'left', fillColor: [255, 255, 255] }},
            margin: { top: 100 },
            body:[
            ['Nos reservamos el derecho de cambiar las condiciones de nuestro Programa de Embajadores Origo en cualquier momento.']
            ],
            willDrawCell: (data) => {
                data.row.height = 20;
            }
        }));
        autoTable(doc, ({
            pageBreak: 'avoid',
            styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontSize: 11, cellPadding: {left: 25, right: 25} },
            columnStyles: {0: {halign: 'left', fillColor: [255, 255, 255] }},
            margin: { top: 100 },
            body:[
            ['Los cambios y revisiones se comunicarán mediante correo electrónico o actualización de este acuerdo. Tu continuidad en el Programa de Embajadores Origo constituirá la aceptación de dichos cambios.']
            ],
            willDrawCell: (data) => {
                data.row.height = 25;
            }
        }));
        autoTable(doc, ({
            pageBreak: 'avoid',
            styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontStyle: 'bold', fontSize: 11, cellPadding: {left: 25, right: 25}},
            columnStyles: { 0: { halign: 'left', fillColor: [255, 255, 255] } }, 
            margin: { top: 180 },
            body: [
              ['5. Suspensión de cuenta del Programa de Embajadores Origo']
            ],
            willDrawCell: (data) => {
                data.row.height = 5;
            }
           
          }))
          autoTable(doc, ({
            pageBreak: 'avoid',
            styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontSize: 11, cellPadding: {left: 25, right: 25} },
            columnStyles: {0: {halign: 'left', fillColor: [255, 255, 255] }},
            margin: { top: 100 },
            body:[
            ['En caso de existir una causa justificada, nosotros estaremos legitimados y podremos suspender tu cuenta o resolver, con efectos inmediatos, el presente acuerdo, así como tu derecho a recibir cualquier compensación por ello, notificándotelo por correo en el caso que surja cualquiera de las siguientes situaciones:']
            ],
            willDrawCell: (data) => {
                data.row.height = 50;
            }
        }));
        autoTable(doc, ({
            pageBreak: 'avoid',
            styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontSize: 11, cellPadding: {left: 25, right: 25} },
            columnStyles: {0: {halign: 'left', fillColor: [255, 255, 255] }},
            margin: { top: 100 },
            body:[
            ['(a) Que hubieras cometido un incumplimiento del presente acuerdo.']
            ],
            willDrawCell: (data) => {
                data.row.height = 5;
            }
        }));
        autoTable(doc, ({
            pageBreak: 'avoid',
            styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontSize: 11, cellPadding: {left: 25, right: 25} },
            columnStyles: {0: {halign: 'left', fillColor: [255, 255, 255] }},
            margin: { top: 100 },
            body:[
            ['(b) Que no lo subsanes en un plazo de siete (7) días.']
            ],
            willDrawCell: (data) => {
                data.row.height = 5;
            }
        }));
        autoTable(doc, ({
            pageBreak: 'avoid',
            styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontSize: 11, cellPadding: {left: 25, right: 25} },
            columnStyles: {0: {halign: 'left', fillColor: [255, 255, 255] }},
            margin: { top: 100 },
            body:[
            ['(c) Que consideremos posible que tengamos que enfrentarnos a reclamaciones o responsabilidades por razón de o derivadas de tu participación en el Programa de Embajadores.']
            ],
            willDrawCell: (data) => {
                data.row.height = 25;
            }
        }));
        autoTable(doc, ({
            pageBreak: 'avoid',
            styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontSize: 11, cellPadding: {left: 25, right: 25} },
            columnStyles: {0: {halign: 'left', fillColor: [255, 255, 255] }},
            margin: { top: 100 },
            body:[
            ['(d) Que hayas participado en el Programa de Embajadores con fines ilegales, engañosos o fraudulentos.']
            ],
            willDrawCell: (data) => {
                data.row.height = 15;
            }
        }));
        autoTable(doc, ({
            pageBreak: 'avoid',
            styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontSize: 11, cellPadding: {left: 25, right: 25} },
            columnStyles: {0: {halign: 'left', fillColor: [255, 255, 255] }},
            margin: { top: 100 },
            body:[
            ['(e) Que consideremos que nuestra marca o nuestra reputación puede verse perjudicada por ti o por tu participación en el Programa de Embajadores.']
            ],
            willDrawCell: (data) => {
                data.row.height = 15;
            }
        }));
        autoTable(doc, ({
            pageBreak: 'avoid',
            styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontSize: 11, cellPadding: {left: 25, right: 25} },
            columnStyles: {0: {halign: 'left', fillColor: [255, 255, 255] }},
            margin: { top: 100 },
            body:[
            ['(f) Que consideremos que estemos o podamos quedar sujetos a requisitos de cobro de impuestos en relación con este acuerdo o con las actividades que cualquiera de las partes realice con arreglo a este acuerdo']
            ],
            willDrawCell: (data) => {
                data.row.height = 25;
            }
        }));
        autoTable(doc, ({
            pageBreak: 'avoid',
            styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontSize: 11, cellPadding: {left: 25, right: 25} },
            columnStyles: {0: {halign: 'left', fillColor: [255, 255, 255] }},
            margin: { top: 100 },
            body:[
            ['(g) Que hayamos resuelto previamente este acuerdo contigo (suspendido tu cuenta) o con otras personas que determinemos se encuentran vinculadas a ti, o actúan en concierto o connivencia contigo por la razón que sea.']
            ],
            willDrawCell: (data) => {
                data.row.height = 25;
            }
        }));
        autoTable(doc, ({
            pageBreak: 'avoid',
            styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontSize: 11, cellPadding: {left: 25, right: 25} },
            columnStyles: {0: {halign: 'left', fillColor: [255, 255, 255] }},
            margin: { top: 100 },
            body:[
            ['(h) Que consideremos que tu sitio web o acciones publicitarias no cumplen con este acuerdo']
            ],
            willDrawCell: (data) => {
                data.row.height = 5;
            }
        }));
        autoTable(doc, ({
            pageBreak: 'avoid',
            styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontSize: 11, cellPadding: {left: 25, right: 25} },
            columnStyles: {0: {halign: 'left', fillColor: [255, 255, 255] }},
            margin: { top: 100 },
            body:[
            ['(i) Que hayamos resuelto y dado por terminado el Programa de Embajadores Origo en la forma y versión en la que generalmente lo ponemos a disposición de los participantes.']
            ],
            willDrawCell: (data) => {
                data.row.height = 40;
            }
        }));
        autoTable(doc, ({
            pageBreak: 'avoid',
            styles: { overflow: "linebreak", fillColor: [255, 255, 255], fontSize: 11, cellPadding: {left: 25, right: 25} },
            columnStyles: {0: {halign: 'center', fillColor: [255, 255, 255] },
            1: {halign: 'center', fillColor: [255, 255, 255] }},
            margin: { top: 100 },
            body:[
            ['________________________________','________________________________'],
            ['LUCIANO VERME BELAUNDE',name.toUpperCase()],
            ['CEO ORIGO PET FOOD',docNumber]
            ],
            willDrawCell: (data) => {
                data.row.height = 15;
            }
        }));
        addFooters(doc);
        doc.save("acuerdo.pdf");
    }

    const  downloadPdf = async(e:any, name:string, contractDate:string, docNumber:string) => {
        e.preventDefault();
        createPdf(name,contractDate,docNumber);
    };

    return (
            <BackLayout>
            <div className='fond_backoffice'>  
                <Container>      
                <div className='cont_title'>
                    <h1>Mi cuenta</h1>
                </div>     
                <div className='cont_data'>     
                    <Row>
                        <Col md={4}>
                            <div className="back_detalle_venta">
                                <div className="">
                                    <div className="">
                                        <h2 className="title_detalle_venta">Oripoints</h2>
                                    </div>
                                </div>
                                <div>     
                                    {/* <div className="">
                                        <span className="subtitle_detalle_venta">Email: </span>
                                        <span className="res_detalle_venta">{cuenta.email}</span>
                                    </div>                                    
                                    <div className="">
                                        <span className="subtitle_detalle_venta">Telefono 1: </span>
                                        <span className="res_detalle_venta">{cuenta.telefono_uno}</span>
                                    </div>
                                    <div className="">
                                        <span className="subtitle_detalle_venta">Telefono 2: </span>
                                        <span className="res_detalle_venta">{cuenta.telefono_dos}</span>
                                    </div> */}
                                    <div className="">
                                        <h5>
                                            <span className="subtitle_detalle_venta">Disponibles: </span>
                                            <span className="">{totalAcumulados - totalUtilizados}</span>
                                        </h5>
                                    </div>
                                    <div className="">
                                        <h5>
                                            <span className="subtitle_detalle_venta">Utilizados: </span>
                                            <span className="">{totalUtilizados}</span>
                                        </h5>
                                    </div>
                                    <div className="">
                                        <h5>
                                            <span className="subtitle_detalle_venta">Acumulados: </span>
                                            <span className="">{totalAcumulados}</span>
                                        </h5>
                                    </div>
                                    <hr></hr>
                                    {data.rol == 3 && <Button className='btn_cancelar_registro' type="button" onClick={(e) => downloadPdf(e, data.nombres + ' '+ data.primer_apellido + ' '+ data.segundo_apellido, data.fecha_contrato, data.nro_documento)} >
                                        <div className='cont_ver_detalle'>                                
                                            {/* <img src={process.env.REACT_APP_PUBLIC_URL + 'images/common/doc.webp'} alt="Documento" />  */}
                                            Descargar contrato
                                        </div>
                                    </Button>}
                                </div>                     
                                
                            </div>
                        </Col>
                        <Col className="back_detalle_venta_productos">
                            <Form id="form-registrar-producto" validated={validated}>
                                <Row className="">
                                    <Col md={12}>
                                        <div className="section_backoffice_form">
                                            <div className="">
                                                <h3>Información de contacto</h3>
                                                <Row> 
                                                    <Col md={12}>
                                                        <Form.Group>
                                                        <Form.Label>Nombres</Form.Label>
                                                        <input type="text" name='nombres' required className='form-control' value={data.nombres} onChange={e => {handleChangeData(e)}}/>                                                    
                                                        {errors?.nombre != "" && (
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors?.nombres}
                                                            </Form.Control.Feedback>
                                                        )}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={12}>
                                                        <Form.Group>
                                                        <Form.Label>Primer Apellido</Form.Label>
                                                            <Form.Control as="input" name='primer_apellido' value={data.primer_apellido} onChange={e => {handleChangeData(e)}}></Form.Control>
                                                        </Form.Group>
                                                    </Col> 
                                                    <Col md={12}>
                                                        <Form.Group>
                                                        <Form.Label>Segundo Apellido</Form.Label>
                                                            <Form.Control as="input" name='segundo_apellido' value={data.segundo_apellido} onChange={e => {handleChangeData(e)}}></Form.Control>
                                                        </Form.Group>
                                                    </Col>  
                                                    <Col md={6}>
                                                        <Form.Group>
                                                        <Form.Label>Teléfono 1</Form.Label>
                                                            <Form.Control as="input" name='telefono_uno' value={data.telefono_uno} onChange={e => {handleChangeData(e)}}></Form.Control>
                                                        </Form.Group>
                                                    </Col> 
                                                    <Col md={6}>
                                                        <Form.Group>
                                                        <Form.Label>Teléfono 2</Form.Label>
                                                            <Form.Control as="input" name='telefono_dos' value={data.telefono_dos} onChange={e => {handleChangeData(e)}}></Form.Control>
                                                        </Form.Group>
                                                    </Col>       
                                                    <Col md={12}>
                                                        <Form.Group>
                                                        <Form.Label>Email</Form.Label>
                                                            <Form.Control as="input" name='email' value={data.email} onChange={e => {handleChangeData(e)}}></Form.Control>
                                                        </Form.Group>
                                                    </Col>                                                                                  
                                                </Row>
                                            </div>

                                            <Col md={12} className="pad_top_2 dis_fle spac_betw">
                                                <Col md={4} className="align-items-center txt_ali dis_fle">                                                    
                                                    <Button className='btn_guardar_registro' type="button" onClick={(e)=>handleSave(e)} disabled={loading}>
                                                        {loading ? (
                                                        <Spinner animation="border" role="status" style={{width: '1rem', height: '1rem'}}>
                                                            <span className="visually-hidden">Loading...</span>
                                                        </Spinner>
                                                        ) : (
                                                        'Guardar'
                                                        )}
                                                    </Button> 
                                                </Col>
                                            </Col>    
                                        </div>
                                    </Col>   
                                </Row>                            
                            </Form>
                            <Form id="form-actualizar-pass" validated={validatedPass}>
                            <Row>    
                                <Col md={12}>
                                <div className="section_backoffice_form">
                                <div className="alert_back">
                                    <div className='alert_text_icon'>
                                    <div className="alert_icon">
                                        <img src={process.env.REACT_APP_PUBLIC_URL + "images/common/alerty.webp"} alt="" />
                                    </div>
                                    <div className="alert_text">
                                        Cambie periódicamente sus contraseñas para tener mayor seguridad.
                                        <br/>¡Recuerde colocar una contraseña que no sea fácil de identificar!
                                    </div>
                                    </div>
                                    {/* <div className="alert_close">
                                        <button type="button" className="close">
                                            <img className="img_close" src={process.env.REACT_APP_PUBLIC_URL + "images/common/cancel.webp"} alt="" />
                                        </button>
                                    </div> */}
                                </div>
                                    <h3>Actualizar Password</h3>
                                    <Row>    
                                        <Col md={4}>
                                            <Form.Group>
                                            <Form.Label>Password Actual</Form.Label>
                                                <Form.Control as="input" type="password" required name='password_actual' value={dataPass.password_actual} onChange={e => {handleChangePass(e)}}></Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                            <Form.Label>Nuevo Password</Form.Label>
                                                <Form.Control as="input" type="password" required name='password' value={dataPass.password} onChange={e => {handleChangePass(e)}}></Form.Control>
                                            </Form.Group>
                                        </Col>  
                                        <Col md={4}>
                                            <Form.Group>
                                            <Form.Label>Confirmar Password</Form.Label>
                                                <Form.Control as="input" type="password" required name='confirm_password' value={dataPass.confirm_password} onChange={e => {handleChangePass(e)}}></Form.Control>
                                            </Form.Group>
                                        </Col> 
                                        <Col md={12} className="pad_top_2 dis_fle spac_betw">
                                            <Col md={4} className="align-items-center txt_ali dis_fle">                                                    
                                                <Button className='btn_guardar_registro' type="button" onClick={(e)=>handleUpdatePassword(e)} disabled={loadingPass}>
                                                    {loadingPass ? (
                                                    <Spinner animation="border" role="status" style={{width: '1rem', height: '1rem'}}>
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Spinner>
                                                    ) : (
                                                    'Actualizar password'
                                                    )}
                                                </Button> 
                                            </Col>
                                        </Col>                           
                                        </Row>
                                    
                                        </div>      
                                    </Col>                     
                                </Row>    
                            </Form>
                        </Col>
                    </Row>
                </div>
            </Container>
            </div>
        </BackLayout>
    )};
export default MiCuenta;
