import { api } from "../api";
import ActionType from "../utils/customTypes";

export class FaqService extends api {

    private static classInstance?: FaqService;
        
    constructor(token: string, type: ActionType) {
        super( process.env.REACT_APP_BASE_URL,token);    
    } 
  
    public static getInstance() {
        let token = localStorage.getItem("token");
        if (!this.classInstance) {
            this.classInstance = new FaqService(token!==undefined && token !== null ? token:'', 'GET');
        }
        
        return this.classInstance;
    }   

    public getFaq= (data:any) => this.instance.post('/faq/get-faq', data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public insertFaq= (data:any) => this.instance.post('/faq/insert-faq', data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });   

    public updateFaq= (data:any) => this.instance.post('/faq/update-faq', data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });  

    public deleteFaq= (data:any) => this.instance.post('/faq/delete-faq', data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });  
    
}