import { Button, Stack } from "react-bootstrap"
import { useShoppingCart } from "../context/ShoppingCartContext"
import storeItems from "./data/items.json"
import { formatMoney } from "../utils/formatCurrency"
import { Row, Col, Form } from 'react-bootstrap';
import PurchaseOption from '../components/producto/purchaseOption';
import BoxNumber from '../components/producto/boxNumber';
import {useState, useEffect} from 'react';

type PurchaseItemProps = {
  id: number
  name: string
  category: string
  line: string
  price: number
  total:number
  quantity: number
  percentageDiscount: number
  normalPrice:number
  img:string
  presentation:string
  periodicity:string
}

export function PurchaseItem(props: PurchaseItemProps ) {


  return (
        
    <div className="product_res">
        <div className="margin_product_res">
            <Row>
                <Col md={4}>
                    <div className="cont_img_res">
                        <img src={process.env.REACT_APP_BASE_URL_BACK + props.img} alt="" />
                    </div>                                            
                </Col>
                <Col md={8} className="">
                    <div className="sp_betw">
                        <div className="cont_tit_cant">
                            <div className="title_product_res">{props.name}</div>
                            
                                <div className="dis_fle">
                                    {props.quantity == 1 && 
                                        <span className="label_tipo">{props.quantity} unidad</span>
                                    }
                                    {props.quantity > 1 && 
                                        <span className="label_tipo">{props.quantity} unidades</span>
                                    }
                                </div>
                            
                        </div>
                       
                    </div>
                    <div>
                        <div className="cont_price_res sp_betw">
                            <div className="price_product_res">{props.total > 0 ? formatMoney(props.total) : ""}</div>
                                                              
                            {props.periodicity != "" &&  props.periodicity != null ? 
                                <div>Se envia cada {props.periodicity}</div>
                                    :

                                    <div>Compra única</div>
                               
                            }
                            
                           
                        </div>
                        
                    </div>
                </Col>
            </Row>
        </div>
    </div>
);

}
