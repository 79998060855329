import { api } from "../api";
import ActionType from "../utils/customTypes";

export class PromoService extends api {

    private static classInstance?: PromoService;
        
    constructor(token: string, type: ActionType) {
        super( process.env.REACT_APP_BASE_URL,token);    
    } 
  
    public static getInstance() {
        let token = localStorage.getItem("token");
        if (!this.classInstance) {
            this.classInstance = new PromoService(token!==undefined && token !== null ? token:'', 'GET');
        }
        
        return this.classInstance;
    }   

    public getPromos= (data:any) => this.instance.post('/get-promos', data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getPromo= (data:any) => this.instance.post('/get-promo', data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public insertPromo= (data:any) => this.instance.post('/insert-promo', data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public updatePromo= (data:any) => this.instance.post('/update-promo', data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public updatePromoStatus= (data:any) => this.instance.post('/update-promo-status', data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public deletePromo= (data:any) => this.instance.post('/delete-promo', data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });
   
    
}