import { Button, Container, Nav, Navbar as NavbarBs, NavItem
    , Row , Col ,NavDropdown
  } from "react-bootstrap"
  import { useShoppingCart } from "../../context/ShoppingCartContext"
  import Marquee from "../anuncio/marquee"
  import './style.css';
  import {useState} from 'react';
  
  type ProductSuggestedProps = {
    id: number
    name: string
    category: string
    idLine: number
    line: string
    price: number
    percentageDiscount: number
    normalPrice:number
    image:string
    presentation:string
    type: number
    periodicity:number
    canEdit:boolean
    promo:number
    url: string
    onceBuy:boolean
    
  }
  

function ProductSuggested(props: ProductSuggestedProps){
    const [quantity, setQuantity] = useState(1);
    const { addItemToCart,
    } = useShoppingCart()

    const addToCart = () => {
            let objCart = {
                id: props.id,
                name: props.name,
                category: props.category,
                idLine: props.idLine,
                line: props.line,
                price: props.price,
                quantity: quantity,
                percentageDiscount: props.percentageDiscount,
                normalPrice: props.normalPrice,
                image: props.image,
                presentation: props.presentation,
                type: 1,
                promo: props.promo,
                total: 0,
                periodicity: 1,
                canEdit: true,
                onceBuy: props.onceBuy
            };

            addItemToCart(objCart);
    };
    return (
        <div className="product_item  p-0 product-sugg-shopcar" key={props.id}>
            
            <div className="bg-alternative content_product">   
                <div className="cont_img_ig">
                    <div className="cont_img_product">
                        <a href={props.url} target='_blank'>
                            <img className="img_tienda" src={props.image} />
                        </a>
                        {/* <div className="cont_search rounded-circle d-flex justify-content-center align-items-center">
                            <img className="img_search" alt="Ver detalle"
                                src={process.env.REACT_APP_PUBLIC_URL + 'images/common/lupa.webp'}/>
                                
                        </div> */}
                    </div>
                </div>
                <div className="cont_descr">
                {props.promo > 0 &&
                <div className="dscto_cont d-flex justify-content-center align-items-center">Promo: {props.promo == 1 ? '2x1': '3x2'}</div>    
            }
                <div className="name_product">
                {props.line} {props.name} <br/>
                </div>
                    <div className="price_product" >
                        S/ {props.price}
                    </div>                    
                </div>                
                <div className="cont_button_product">
                    <a className="button_product_sugg open-details-product cur_point"  onClick={addToCart}>Agregar</a>
                </div>

            </div>
            {props.percentageDiscount !=0 &&
                <div className="dscto_cont d-flex justify-content-center align-items-center">{props.percentageDiscount}% Dscto</div>    
            }
                
            
        </div>
    )
}

export default ProductSuggested;