//import Carousel from 'react-bootstrap/Carousel';
import Carousel from 'react-multi-carousel';
import { Button, Container, Row , Col ,Form, CloseButton
  } from "react-bootstrap"
import CardMenu from 'components/cardMenu/cardMenu';
import './style.css';
import { useMediaQuery } from "../../hooks/useMediaQuery";
import {  useState, useEffect } from "react";


export const CarouselMenuResponsive = () => {
    const isResponsive = useMediaQuery('(max-width: 767px)');
    let screen = useMediaQuery("sm");

    useEffect(() => {      
        console.log(screen,'sreen');
    }, [screen]);
    
    const cardData = [
        {
        title: 'Pollo',
        img: process.env.REACT_APP_PUBLIC_URL + 'images/home/pollo.webp',
        text: 'Un clásico en el menú e infaltable en la dieta de tu perro, receta ideal para iniciarse en la dieta BARF.',
        href: 'producto/receta-de-pollo-800gr-perros-barf'
        },
        {
        title: 'Pavo Pato',
        img: process.env.REACT_APP_PUBLIC_URL + 'images/home/pavo-pato.webp',
        text: 'Combinación de dos proteínas bajas en grasas saturadas y fáciles de digerir. Ideal para perros alérgicos o con sobre peso.',
        href: 'producto/receta-de-pavo-pato-800gr-perros-barf'
        },
        {
        title: 'Codorniz Res',
        img: process.env.REACT_APP_PUBLIC_URL + 'images/home/codorniz-res.webp',
        text: 'Dos proteínas de calidad y alto contenido de Hierro, ideal para fortalecer el sistema inmunológico y asegurar un óptimo desarrollo en cachorros.',
        href: 'producto/receta-de-res-codorniz-800gr-perros-barf'
        }
    ];

    const responsiveNuestrMenu = {
        superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
        slidesToSlide: 3
        },
        desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2,
        slidesToSlide: 1
        },
        tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
        slidesToSlide: 1
        },
        mobile: {
        breakpoint: { max: 767, min: 0 },
        items: 1,
        slidesToSlide: 1
        }
    };
    
    return (
        <div className='back_card_hom'>
        <div className='container-section align-items-center' style={{ width: '90%' }}>
            <h1 className='color-primary'><b>NUESTROS</b> MENÚS</h1>
            {screen == false ? (
                <Carousel className='carousel-menu' //indicators={true} controls={true}
                    swipeable={true}
                    draggable={true}
                    showDots={false}
                    responsive={responsiveNuestrMenu}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    autoPlay={false}
                    autoPlaySpeed={5000}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={500}
                    //removeArrowOnDeviceType={["tablet","mobile"]}
                    renderArrowsWhenDisabled={true}
                
                >
                    {cardData.map((item, index) => (
                    //<Carousel.Item key={index}>
                        <CardMenu 
                        title={item.title}
                        img={item.img}
                        imgTop={item.img}
                        text={item.text}
                        btn="CONOCE MÁS"
                        href={item.href}
                        />
                    //</Carousel.Item>
                    ))}
                </Carousel>
            ) :
            (
                <Row className='pad_top_2'>
                    {cardData.map((item, index) => (
                    <Col md={4} className='pad_bot_2' key={index}>
                        <CardMenu 
                        title={item.title}
                        img={item.img}
                        imgTop={item.img}
                        text={item.text}
                        btn="CONOCE MÁS"
                        href={item.href}
                        />
                    </Col>
                    ))}
                </Row>
                )
        
            }
        </div>
        </div>
    );
};
