import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';
import { useState, useCallback, useEffect } from 'react';
import BackLayout from "../../../components/layout/BackLayout/BackLayout";
import {PurchaseService} from '../../../business/PurchaseService';
import { formatNumber } from "../../../utils/formatCurrency";
import { useParams } from "react-router-dom";

function ComprasDetalle (){

    const [pedido, setPedido] = useState<any>({pedidodetalle:[]});
    const [loading, setLoading] = useState(false);

    let params = useParams();
    let id = 0;
    if(params.id_pedido !== undefined)
    {
        id = parseInt(params.id_pedido);
    }
    

    useEffect(() => {      
        getPurchaseDetail(id);     
      }, []);

    const getPurchaseDetail = async (idPurchase:number) => { 
        const getInstance = PurchaseService.getInstance();
        setLoading(true);

        await getInstance.getPurchaseDetail({id: idPurchase})
            .then((response:any) => {
                    setLoading(false);
                    if(response.success === true)
                    {
                        setPedido(response.purchase);

                    }
                    
                })
            .catch((err:any) => {
                setLoading(false);
            });

    }

    return (
        <BackLayout>
        <div className='fond_backoffice'>
            <Container>      
                <div className='cont_title'>
                    <h1>Pedido Nro. {pedido.nro_pedido}</h1>
                </div>     
                <div className='cont_data'>     
                    <div className="row">
                        <div className="col-md-4">
                            <div className="back_detalle_venta">
                                <div className="">
                                    <div className="">
                                        <h2 className="title_detalle_venta"></h2>
                                    </div>

                                </div>
                                <div>
                                    
                                    <div className="">
                                        <span className="subtitle_detalle_venta">Medio de pago: </span>
                                        <span className="res_detalle_venta">{pedido.medio_pago}</span>
                                    </div>
                                    <div className="">
                                        <span className="subtitle_detalle_venta">Fecha de compra: </span>
                                        <span className="res_detalle_venta">{pedido.fecha_compra}</span>
                                    </div>
                                    <div className="">
                                        <span className="subtitle_detalle_venta">Fecha de entrega: </span>
                                        <span className="res_detalle_venta">{pedido.fecha_entrega}</span>
                                    </div>
                                    {pedido.tipven_id_tipven!=2 ? (<div>
                                    <div className="">
                                        <span className="subtitle_detalle_venta">Dirección de entrega: </span>
                                        <span className="res_detalle_venta">{pedido.direccion}</span>
                                    </div>
                                    <div className="">
                                        <span className="subtitle_detalle_venta">Zona ubigeo: </span>
                                        <span className="res_detalle_venta">{pedido.ubigeo}</span>
                                    </div>
                                    <div className="">
                                        <span className="subtitle_detalle_venta">Referencia: </span>
                                        <span className="res_detalle_venta">{pedido.referencia}</span>
                                    </div>
                                    <div className="">
                                        <span className="subtitle_detalle_venta">Telefono 1: </span>
                                        <span className="res_detalle_venta">{pedido.telefono_uno}</span>
                                    </div>
                                    <div className="">
                                        <span className="subtitle_detalle_venta">Telefono 2: </span>
                                        <span className="res_detalle_venta">{pedido.telefono_dos}</span>
                                    </div>
                                    </div>
                                    ): " "}
                                    <div className="">
                                        <span className="subtitle_detalle_venta">Observaciones: </span>
                                        <span className="res_detalle_venta">{pedido.observaciones}</span>
                                    </div>
                                    
                                    <div className="">
                                        <span className="subtitle_detalle_venta">Cupón utilizado: </span>
                                        <span className="res_detalle_venta">{pedido.cupon}</span>
                                    </div>
                                </div>                     
                                
                            </div>
                        </div>
                        <div className="col-md-8 back_detalle_venta_productos">
                            <div className="">
                                <div className='cont_d_venta_p'>
                                    <div className="d-flex align-items-center flex-wrap">                        
                                        <div className="d-flex align-items-center flex-lg-fill justify_c">
                                            <div className="d-flex flex-column">
                                                <span className="font_total_dv">Subtotal</span>
                                                <span className=""><span className="">S/ </span>{formatNumber(pedido.total)}</span>
                                            </div>
                                        </div>   
                                        <div className="d-flex align-items-center flex-lg-fill justify_c">
                                            <div className="d-flex flex-column">
                                                <span className="font_total_dv">Costo envío</span>
                                                <span className=""><span className="">S/ </span>{formatNumber(pedido.costo_envio)}</span>
                                            </div>
                                        </div>  
                                        <div className="d-flex align-items-center flex-lg-fill justify_c">
                                            <div className="d-flex flex-column">
                                                <span className="font_total_dv">OriPoints</span>
                                                <span className=""><span className="">-S/ </span>{formatNumber(pedido.oripoint_usados)}</span>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center flex-lg-fill justify_c">
                                            <div className="d-flex flex-column">
                                                <span className="font_total_dv">Descuento</span>
                                                <span className=""><span className="">-S/ </span>{formatNumber(pedido.total_descuento)}</span>
                                            </div>
                                        </div>
                                        <div className="pad_20 d-flex align-items-center flex-lg-fill justify_c">
                                            <div className="d-flex flex-column">
                                                <span className="font_total_dv"><b>TOTAL</b></span>
                                                <span className="fw_bold"><span className="">S/ {formatNumber(pedido.total_pagado)}</span></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-xs-12">
                                        <h4 className="title_product_detail">Detalle venta</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-12 col-xs-12">
                                                <div className="table-responsive">
                                                    <Table responsive className='table_backoffice'>
                                                        <thead>
                                                            <tr>
                                                                <th>Producto</th>
                                                                <th>Cantidad</th>
                                                                <th>P. Unitario</th>
                                                                <th>Descuento</th>  
                                                                <th>Ori Points utilizados</th>
                                                                <th>Importe</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {pedido.pedidodetalle.map((itemped:any) => {
                                                                    return (    
                                                                        <tr>
                                                                        <th>{itemped.producto}</th>
                                                                        <td>{itemped.cantidad}</td>
                                                                        <td>{itemped.precio_unitario}</td>
                                                                        <td>{formatNumber(itemped.total_descuento)}</td>
                                                                        <td>{formatNumber(itemped.oripoints_usados)}</td>
                                                                        <td>{formatNumber(itemped.total)}</td>                    
                                                                        
                                                                    </tr> 
                                                                )
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    </BackLayout>
)};
export default ComprasDetalle;
