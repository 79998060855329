import React, { useState, useEffect } from 'react';
import { Button, Container, Row, Col, Form } from 'react-bootstrap';
import "./style.css";
import BackLayout from "../../../components/layout/BackLayout/BackLayout";
import {WebService} from '../../../business/WebService';
import ModalAddImageDesktop from './modalAddImage';
import ModalAddImageResponsive from './modalAddImageResponsive';
import ModalConfirm from "../../../components/confirm/ModalConfirm";

const initialValues: any = {
    mensaje_uno: "",
    mensaje_dos: ""
};

let confirmDefault = {
    id:0,
    name: '',
    show: false
}

function AdministrarHome (){
    const [data, setData] = useState(initialValues);
    const [errors, setErrors] = useState<any>();
    const [loading, setLoadingButton] = useState(false);
    const [desktop, setDesktop] = useState<any>([]);
    const [responsive, setResponsive] = useState<any>([]);
    const [confirmData, setConfirmData] = useState(confirmDefault);

    const [showAddImage, setShow] = useState(false);
    const handleShowAddImage = () => setShow(true);

    const [showAddImageResponsive, setShowResponsive] = useState(false);
    const handleShowAddImageResponsive = () => setShowResponsive(true);

    useEffect(() => {      
        getPublicity();
        
      }, []);
    
    const getPublicity = async () => { 
        setLoadingButton(true);
        const getInstance = WebService.getInstance();
            
        await getInstance.getPublicity()
            .then((response:any) => {
                setLoadingButton(false);
                setData(response.data);
                setDesktop(response.desktop);
                setResponsive(response.responsive);
                
            })
            .catch((err:any) => {
                setLoadingButton(false);                
            });
    
    }

    const updatePublicity = async () => { 
        setLoadingButton(true);
        const getInstance = WebService.getInstance();
            
        await getInstance.updatePublicity(data)
            .then((response:any) => {
                setLoadingButton(false);
                getPublicity();
                
            })
            .catch((err:any) => {
                setLoadingButton(false);                
            });
    
    }

    const deleteImage = async (id:number) => { 
        setLoadingButton(true);
        const getInstance = WebService.getInstance();
            
        await getInstance.deleteImage({id: id})
            .then((response:any) => {
                setLoadingButton(false);
                getPublicity();
                
            })
            .catch((err:any) => {
                setLoadingButton(false);                
            });
    
    }

    const handleChangeData= (event: any) => {
        const { name, value} = event.target;
        let val = value;
        
        setData({
            ...data,
            [name]: event.target.type==='checkbox' ? event.target.checked : val
        });
        
      };

    const handleCloseAddImage = () => {
        setShow(false);
        getPublicity(); 
    }

    const handleCloseAddImageResponsive = () => {
        setShowResponsive(false);
        getPublicity(); 
    }

    const handleSave= (event: any) => {
        updatePublicity();
        
    }

    const handleConfirmDelete = (e:any,id:number, name: string) => {
        setConfirmData({show:true, id: id, name: name})
    }

    const handleDeleteImg = (event:any, id:number) => {
        setConfirmData({
            ...confirmData,
            show: false
        });
        deleteImage(id);
    }

    const handleClose= (event: any) => {
    
        setConfirmData({
            ...confirmData,
            show: false
        })    
        
    };

    return (
        <BackLayout>
        <div className='fond_backoffice'>
            <Container>                
                <div className='cont_title'>
                    <h1>Web Home</h1>
                </div>
                <div className='cont_data'>                           
                    <Form id="form-registrar-producto">
                        <Row className="">
                            <Col md={12}>
                                <div className="section_backoffice_form">
                                    <div className="">
                                        <h3>Publicidad</h3>
                                        <Row>
                                            <Col md={12}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Mensaje 1:</Form.Label>
                                                    <input type="text" name='mensaje_uno' className='form-control' value={data.mensaje_uno} onChange={e => {handleChangeData(e)}}/>                                                    
                                                    <span className="help-block"></span>
                                                </Form.Group>  
                                            </Col>
                                            <Col md={12}>                              
                                                <Form.Group>                        
                                                    <Form.Label>Mensaje 2:</Form.Label>
                                                    <input type="text" name='mensaje_dos' className='form-control' value={data.mensaje_dos} onChange={e => {handleChangeData(e)}}/>                                                    
                                                    <span className="help-block"></span>
                                                </Form.Group>  
                                            </Col> 
                                            <Col md={12} className="pad_top_2 dis_fle spac_betw">
                                                <Col md={4} className="align-items-center txt_ali dis_fle">                                                    
                                                        <Button className='btn_guardar_registro' type="button" onClick={(e)=>handleSave(e)} disabled={loading}>Guardar Publicidad</Button>                                                    
                                                </Col>
                                            </Col>                                           
                                        </Row>
                                    </div>
                                    <br></br>
                                    <hr></hr>
                                    <Row>
                                        <Col md={6}>
                                        <h3 className='cont_btn_agre_mas'>Slider Inicio <Button className='btn_agregar_mascota' onClick={handleShowAddImage}>Agregar</Button></h3>
                                        <br></br>
                                            {desktop.map((item:any) => (
                                                <div>
                                                    <p>Url: {item.url}</p>
                                                    <div id="img_box" className="pad_bot_15 d-inline-flex">
                                                        <img className="edit-img-propre" width="150px" src={process.env.REACT_APP_BASE_URL_BACK + item.imagen_path} alt="" data-img="56"/>
                                                        <a className="btn delete-imagen" data-img="56" onClick={(e) => handleConfirmDelete(e, item.id_webimg, item.imagen)}>
                                                            <div className='cont_ver_detalle'>                                
                                                                <img src={process.env.REACT_APP_PUBLIC_URL + 'images/common/cancel.webp'} alt="Cancelar" />
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                )
                                            )}
                                           
                                         </Col>
                                            <Col md={6}>
                                            <h3 className='cont_btn_agre_mas'>Slider Responsive <Button className='btn_agregar_mascota' onClick={handleShowAddImageResponsive}>Agregar</Button></h3>
                                            <br></br>
                                                {responsive.map((item:any) => (
                                                        <div>
                                                            <p>Url: {item.url}</p>
                                                            <div id="img_box" className="pad_bot_15 d-inline-flex">
                                                                <img className="edit-img-propre" width="150px" src={process.env.REACT_APP_BASE_URL_BACK + item.imagen_path} alt="" data-img="56"/>
                                                                <a className="btn delete-imagen" data-img="56" onClick={(e) => handleConfirmDelete(e, item.id_webimg, item.imagen)}>
                                                                    <div className='cont_ver_detalle'>                                
                                                                        <img src={process.env.REACT_APP_PUBLIC_URL + 'images/common/cancel.webp'} alt="Cancelar" />
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        )
                                                    )}
                                               
                                            </Col>
                                    </Row>
                                </div>
                            </Col>   
                        </Row>                            
                    </Form>
                </div>
            </Container>  
        </div>
        <ModalConfirm text="" handleClose={handleClose} show={confirmData?.show} handleSave={handleDeleteImg} action={"¿Estás seguro de eliminar la imagen " + confirmData?.name + "?"} id={confirmData?.id}/>
        
        <ModalAddImageDesktop show={showAddImage} handleClose={handleCloseAddImage} />
        <ModalAddImageResponsive show={showAddImageResponsive} handleClose={handleCloseAddImageResponsive} />
        </BackLayout>

)};
export default AdministrarHome;
