const CURRENCY_FORMATTER = new Intl.NumberFormat(undefined, {
  currency: "USD",
  style: "currency",
})

export function formatCurrency(number: number) {
  return CURRENCY_FORMATTER.format(number)
}
export function formatMoney(amount:any) {
  if(amount!=null && amount!=undefined)
  {
    amount = parseFloat(amount);
    return "S/ " + amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  else{
    return 0;
  }
  
}
export function formatNumber(amount:any) {
  if(amount!=null && amount!=undefined)
  {
    amount = parseFloat(amount);
    return amount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  else{
    return 0;
  }
  
}
