import React, { useState, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import "./style.css";
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useShoppingCart } from "../../context/ShoppingCartContext";

function LoginOption(props:any){

    const { isAuthenticated } = useShoppingCart();

    const popoverTop = (
        <Popover id="popover-trigger-click-root-close">
          <div>
          {isAuthenticated== false ? 
              <div className="cont_option_login">
                  <li><a href="/login" className=''>Iniciar sesión</a></li>
                  <li><a href="/registro" className=''>Registrarme</a></li>                  
              </div>
            :
            <div className="cont_option_login">
                  <li><a>¡Hola {localStorage.getItem('name')} !</a></li>
                  <hr></hr>
                  <li><a href={process.env.REACT_APP_PUBLIC_URL + "tienda"} className=''>Tienda</a></li>
                  <li><a href={process.env.REACT_APP_PUBLIC_URL + "cuenta"} className=''>Mi cuenta</a></li>
                  <li><a href={process.env.REACT_APP_PUBLIC_URL + "loadingdashboard"} className=''>Resumen</a></li>
                  <li><a href="javascript:;" onClick={(e) => props.logout()} className=''>Cerrar sesión</a></li>
              </div>}
          </div>
      </Popover>
      );

    return (
        <>
            <OverlayTrigger trigger="click" placement="bottom" rootClose overlay={popoverTop}>
                <Button className="btn-login-origo btn-grey">
                    <img alt="contacto" src={process.env.REACT_APP_PUBLIC_URL + (isAuthenticated ? 'images/common/contactoauth.webp' : 'images/common/contacto.webp')}/>
                </Button>
            </OverlayTrigger>
        </>
    );
}
export default LoginOption;