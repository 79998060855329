import React from 'react';
import "./style.css";
import { Form, Container, Row, Col, Button,Spinner } from 'react-bootstrap';
import * as Yup from 'yup';
import { yupToFormErrors } from "formik";
import { useState, useEffect } from "react"
import {AuthService} from '../../business/AuthService';
import MainLayout from "../../components/layout/MainLayout/MainLayout";

let tipoDocumento = [
    {
        name: 'DNI'
        ,value: 1 
    },
    {
        name: 'RUC'
        ,value: 2
    },
    {
        name: 'PASAPORTE'
        ,value: 3
    },
]

const registerSquema = Yup.object().shape({
    docType: Yup.number().required("*Campo obligatorio")
   ,docNumber: Yup.string().required("*Campo obligatorio")
   ,firstName: Yup.string().required("*Campo obligatorio")
   ,lastName: Yup.string().required("*Campo obligatorio")
   ,email: Yup.string().required("*Campo obligatorio")
 });

 let defaultUser = {
    docType: 0
    ,docNumber: ""
    ,email: ""
    ,firstName: ""
    ,lastName: ""
    ,lastName2: ""
    ,password: ""
  }

function RegistroUsuario (){

    const [user, setUser] = useState(defaultUser);
    const [errors, setErrors] = useState<any>();
    const [validated, setValidated] = useState<any>();
    const [loading, setLoadingButton] = useState(false);

    const handleChangeData= (event: any) => {
        const { name, value} = event.target;
       
        let val = value;
        if(name==='docNumber')
        {
            val = parseInt(value);
        }
        
        setUser({
            ...user,
            [name]:  event.target.type==='radio' ? event.target.checked : val
          });
         
        
        
      };

      const register = async () => { 
        setLoadingButton(true);
        const getInstance = AuthService.getInstance();
        
        await getInstance.register(user)
        .then((response:any) => {
            setLoadingButton(false);
            localStorage.setItem('token', response.authorisation.token)
            localStorage.setItem('rol', response.role)
            localStorage.setItem('name', response.name)
            window.location.href='/redirect';

            }   
          )
        .catch((err:any) => {
            setLoadingButton(false);
        });
    
      }

    const handleRegister = ()=>{
        registerSquema.validate(user, { abortEarly: false }).then(function() {
            // Success
            register();
            setErrors([]);
        }).catch(function (err) {
            let errorArray = {};
            const errors = yupToFormErrors(err);
            setErrors(errors);
            console.log(errors);
            setValidated(true);
            /*err.inner.forEach((e:any) => {
                
                if(e.path){
                    errorArray[e.path] = e.message;
                }
                
            });*/

            
        });
    }
    return (
    <MainLayout>
    <div className='back_login'>  
      <Container>
        <Row className='justify-content-md-center cont-vertical-center'>
          <Col md={6} className='vertical-center'>
            <div className='back_two2 pad_two'>
                <Form id="form-registrar-usuario" validated={validated}>
                    <Row className="">
                        <Col md={12}>
                            <div className="section_login_form">
                                <div className="">                                    
                                    <div className="alert-section" style={{display: "none"}}></div>
                                    <h3>USUARIO NUEVO</h3>
                                    <p> Bienvenido(a), debe completar los datos para crear una cuenta.</p>
                                    <Container>
                                        <Row className='justify-content-md-center pad_lef_cal mr_t_1'>
                                            <Col md={2} className='cont_icon_login'>
                                                <img src={process.env.REACT_APP_PUBLIC_URL + "images/common/doc-icon.webp"} alt="Documento de identidad" />
                                            </Col>
                                            <Col md={5}>                              
                                                <Form.Group>                        
                                                    <Form.Control required as="select" name="docType" onChange={e => {handleChangeData(e)}}>
                                                    <option key={0} value="">Tipo de documento</option>
                                                        {tipoDocumento.map(item => (
                                                        <option key={item.value} value={item.value}>{item.name}</option>
                                                        ))}                                                          
                                                    </Form.Control>                                                    
                                                     {errors?.docType != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.docType}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>  
                                            </Col>
                                            <Col md={5} className='mr_t_1_resp'>                        
                                                <Form.Group> 
                                                    <input required type="text" name='docNumber' className='form-control' placeholder='Nro documento' onChange={e => {handleChangeData(e)}}/>                                                    
                                                     {errors?.docNumber != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.docNumber}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </Form.Group>   
                                            </Col>
                                        </Row>
                                        <Row className='justify-content-md-center pad_lef_cal mr_t_1'>
                                            <Col md={2} className='cont_icon_login'>
                                                <img src={process.env.REACT_APP_PUBLIC_URL + "images/common/n.webp"} alt="Nombres" />
                                            </Col>
                                            <Col md={10}>                        
                                                <Form.Group> 
                                                    <input required type="text" name='firstName' className='form-control' placeholder='Nombres' onChange={e => {handleChangeData(e)}}/>                                                    
                                                     {errors?.firstName != "" && (
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors?.firstName}
                                                            </Form.Control.Feedback>
                                                        )}
                                                </Form.Group>   
                                            </Col>
                                        </Row>
                                        <Row className='justify-content-md-center pad_lef_cal mr_t_1'>
                                            <Col md={2} className='cont_icon_login'>
                                                <img src={process.env.REACT_APP_PUBLIC_URL + "images/common/a.webp"} alt="Apellido" />
                                            </Col>
                                            <Col md={5}>                        
                                                <Form.Group> 
                                                    <input required type="text" name='lastName' className='form-control' placeholder='Primer apellido' onChange={e => {handleChangeData(e)}}/>                                                    
                                                     {errors?.lastName != "" && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors?.lastName}
                                                            </Form.Control.Feedback>
                                                        )}
                                                </Form.Group>   
                                            </Col>
                                            <Col md={5} className='mr_t_1_resp'>                        
                                                <Form.Group> 
                                                    <input type="text" name='lastName2' className='form-control' placeholder='Segundo apellido' onChange={e => {handleChangeData(e)}}/>                                                    
                                                     {errors?.lastName2 != "" && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors?.lastName2}
                                                            </Form.Control.Feedback>
                                                        )}
                                                </Form.Group>   
                                            </Col>
                                        </Row>
                                        <Row className='justify-content-md-center pad_lef_cal mr_t_1'>
                                            <Col md={2} className='cont_icon_login'>
                                                <img src={process.env.REACT_APP_PUBLIC_URL + "images/common/login-icon.webp"} alt="Usuario" />
                                            </Col>
                                            <Col md={10}>                        
                                                <Form.Group> 
                                                    <input required type="text" name='email' className='form-control' placeholder='Email'  onChange={e => {handleChangeData(e)}}/>                                                    
                                                     {errors?.email != "" && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors?.email}
                                                            </Form.Control.Feedback>
                                                        )}
                                                </Form.Group>   
                                            </Col>
                                        </Row>
                                        <Row className='justify-content-md-center pad_lef_cal mr_t_1'>
                                            <Col md={2} className='cont_icon_login'>
                                                <img src={process.env.REACT_APP_PUBLIC_URL + "images/common/pass-icon.webp"} alt="Password" />
                                            </Col>
                                            <Col md={10}>                        
                                                <Form.Group> 
                                                    <input required type="password" name='password' className='form-control' placeholder='Password' onChange={e => {handleChangeData(e)}}/>                                                    
                                                    <span className="help-block"></span>
                                                </Form.Group>   
                                            </Col>
                                        </Row>
                                        <Row className='justify-content-md-center mr_t_1 pad_top_1'>
                                            <Col md={11}>                                        
                                                <button className='btn_origo_login'  onClick={(e)=>handleRegister()} disabled={loading}>
                                                    {loading ? (
                                                    <Spinner animation="border" role="status" style={{width: '1rem', height: '1rem'}}>
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Spinner>
                                                    ) : (
                                                    'CREAR CUENTA'
                                                    )}
                                                </button>
                                            </Col>
                                        </Row>
                                        <Row className='justify-content-md-center mr_t_1'>
                                            <Col md={11}>
                                                <div className=''>
                                                    <a href="/login" className='option_login_text'>Tengo cuenta de usuario, ir a login</a>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div> 
          </Col>
        </Row>
      </Container>
    </div>
    </MainLayout>
)};

export default RegistroUsuario;