import Carousel from 'react-bootstrap/Carousel';
import Button from "react-bootstrap/Button";
import './style.css';
import { useMediaQuery } from "../../hooks/useMediaQuery"
import {  useState, useEffect } from "react"

type Slider = {
  title: string,
  description: string,
  url: string,
  image: string,
  imagePath: string
}

type CarouselProps = {
  sliderDesktop: Slider[],
  sliderResponsive: Slider[],
  isLoading: boolean
}


function CarouselHome(props: CarouselProps) {

  let screen = useMediaQuery("sm");

  useEffect(() => {      
    console.log(screen);
  }, [screen]);



  return (
    <Carousel fade style={{minHeight: '70vh'}}>
    {props.isLoading == true ? 
      
      <div className="loading-slider"><img src={process.env.REACT_APP_PUBLIC_URL + "images/home/carga.gif"} alt="Cargando"/></div>
      :
      screen == true ?
        props.sliderDesktop.map((item:Slider, index:number) => (
          <Carousel.Item>
          <a href={item.url != null ? item.url : process.env.REACT_APP_PUBLIC_URL + "tienda"}>
          <img
                alt="home"
                src={process.env.REACT_APP_BASE_URL_BACK + item.imagePath}
                
                className="d-block w-100"
              />
          <Carousel.Caption>
              <div style={{width:'70%'}}>
              {item.title!=null && (<h6 className="color-secondary"><span className="title_slider_web">{item.title}</span></h6>)}
              {item.description!=null && (<div className='desc_slider_web' dangerouslySetInnerHTML={{__html: item.description}}></div>)}
              {/* <a className="btn_origo" href={item.url != null ? item.url : process.env.REACT_APP_PUBLIC_URL + "/tienda"}>¡INICIA HOY!</a>  */}
              </div>
          </Carousel.Caption>
          </a>
        </Carousel.Item>
        ))
        :
        props.sliderResponsive.map((item:Slider, index:number) => (
          <Carousel.Item className='cont-carou'>
          
          <img
                alt="home"
                src={process.env.REACT_APP_BASE_URL_BACK + item.imagePath}
                
                className="d-block w-100"
              />
          <Carousel.Caption>
              <div style={{width:'100%'}}>
              {item.title!=null && (<h6 className="title_slider_res color-secondary"><span>{item.title}</span></h6>)}
              {item.description!=null && (<div className='desc_slider_res' dangerouslySetInnerHTML={{__html: item.description}}></div>)}
                  <a className="btn_origo" href={item.url != null ? item.url : process.env.REACT_APP_PUBLIC_URL + "tienda"}>¡INICIA HOY!</a> 
              </div>
          </Carousel.Caption>
        </Carousel.Item>
        ))
        
      }
      </Carousel>
      
    
    
    
  );
}

export default CarouselHome;