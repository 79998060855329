import React from 'react';
import "./style.css";

type CardMenuProps = {    
    title: string
    img: string
    imgTop: string
    text: string
    btn: string
    href: string
}  

function CardMenu(props: CardMenuProps){
    return (
        <div className='cont_card_menu'>
            <h2 className="title_menu">{props.title}</h2>
            <div className="cont_card">
                <img src={props.img} alt="Card" />
                <img className="img-top" src={props.imgTop} alt="Comida" />
                <div className='cont_text_menu'>
                {props.text}
                </div>                
            </div>
            <div className='cont_btn'>
                <a href={props.href} className='btn_origo'>{props.btn}</a>
            </div>
        </div>
    );
}
export default CardMenu;