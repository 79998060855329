import React from 'react';
import "../reporteVentas/style.css";
import { Container, Row, Col, Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-js-pagination';
import { useState, useEffect } from 'react';
import BackLayout from "../../../components/layout/BackLayout/BackLayout";
import {SalesService} from '../../../business/SalesService';
import ModalConfirm from "../../../components/confirm/ModalConfirm";
import LoadingTable from "../../../components/skeletons/LoadingTable";

let filtersDefault = {
    from: '',
    to: '',
    idStatus: '0',
    suscriptor: ''

}
let confirmDefault = {
    idSuscription:0,
    suscriptor: '',
    show: false
}

function Donaciones (){
    const [data, setData] = useState<any>({data: []});
    const [filters, setFilters] = useState(filtersDefault);
    const [loading, setLoading] = useState(false);
    const [confirmData, setConfirmData] = useState(confirmDefault);

    useEffect(() => {      
        getDonations(1);     
      }, []);

    const getDonations = async (pageNumber:number) => { 
        const getInstance = SalesService.getInstance();
        setLoading(true);

        await getInstance.getDonations({page: pageNumber, from: filters.from, to: filters.to, idStatus: filters.idStatus, suscriptor: filters.suscriptor})
            .then((response:any) => {
                
                    if(response.success === true)
                    {
                        setData(response.suscriptions);

                    }
                    setLoading(false);
                    
                })
            .catch((err:any) => {
                setLoading(false);
            });

    }
    const handleChangeFilter= (event: any) => {
    
        const { name, value} = event.target;
        setFilters({
            ...filters,
            [name]: value
          });      
        
    };

    const updateSuscription = async (id:number) => { 
        const getInstance = SalesService.getInstance();
       
        await getInstance.updateDonation({id: id})
            .then((response:any) => {
                setConfirmData({
                    ...confirmData,
                    show: false,
                    idSuscription: 0,
                    suscriptor: ''
                });
                getDonations(data?.current_page > 0 ? data?.current_page : 1);
            })
            .catch((err:any) => {
                
            });

    }

    const handlePageChange = (pageNumber:any) => {
        getDonations(pageNumber);
    }

    const handleSearch = (e:any) => {
        getDonations(1);
    }

    const handleUnsuscribe = (e:any,id:number, suscriptor: string) => {
        setConfirmData({show:true, idSuscription: id, suscriptor: suscriptor})
    }

    const handleClose= (event: any) => {
    
        setConfirmData({
            ...confirmData,
            show: false
        })    
        
    };

    const unsuscribe = (event:any, id:number) => {
        setConfirmData({
            ...confirmData,
            show: false
        });
        updateSuscription(id);
    }

    return (
        <BackLayout>
        <div className='fond_backoffice'>
            <Container>                
            <div className='cont_title'>
                <h1>Adogtados</h1>
            </div>
            <div className='cont_data'>
                <Container className='cont_filters'>
                    <Row className='row_filter'>
                        <Col md={3} className="align-items-center txt_ali dis_fle">
                            <Col md={3}>
                                <label htmlFor="suscriptor">Buscar:</label>
                            </Col>
                            <Col md={9}>   
                            <input name="suscriptor" type="search" className='form-control' placeholder='donante' value={filters.suscriptor} onChange={(e)=>handleChangeFilter(e)}/>
                            </Col>
                        </Col>
                        <Col md={3} className="align-items-center txt_ali dis_fle">
                            <Col md={4}>
                            <label htmlFor="from">F. Desde:</label>
                            </Col>
                            <Col md={8}>
                                <input name="from" type="date" className='form-control' value={filters.from} onChange={(e)=>handleChangeFilter(e)}/>
                            </Col>
                        </Col>
                        <Col md={3} className="align-items-center txt_ali dis_fle">
                            <Col md={4}>
                                <label htmlFor="to">F. Hasta:</label>
                            </Col> 
                            <Col md={8}>
                                <input name="to"type="date" className='form-control' value={filters.to} onChange={(e)=>handleChangeFilter(e)}/>
                            </Col>
                        </Col>
                        <Col md={2} className="align-items-center txt_ali dis_fle">
                            <Col md={4}>
                            <label htmlFor="idStatus">Estado:</label>
                            </Col>
                            <Col md={8}>
                                <select name="idStatus" id="" className='form-control' value={filters.idStatus} onChange={(e)=>handleChangeFilter(e)}>
                                    <option value="0">Seleccione</option>
                                    <option value="1">Activo</option>
                                    <option value="2">Fallo en cargo</option>
                                    <option value="3">Anulado</option>
                                    <option value="4">Pendiente de pago</option>
                                </select>
                            </Col>
                        </Col>
                        
                        <Col md={1} className="align-items-center txt_ali dis_fle">
                            <Button className='btn_filter_search' type="button" onClick={(e)=>handleSearch(e)}>Buscar</Button>
                        </Col>
                    </Row>
                </Container>
                <Table responsive className='table_backoffice'>
                <thead>
                    <tr>
                        <th>Ver detalle</th>
                        <th>Donante</th>
                        <th>F. Donación</th>
                        <th>Plan</th>  
                        <th>Monto</th>
                        <th>Nro Tarjeta</th>                  
                        <th>Estado</th>
                        <th>Anular Donación</th>
                    </tr>
                </thead>
                {loading==true ? (
                        <LoadingTable rows={3} cols={8}/>
                    )
                    
                    :
                (
                <tbody>
                    {data.data.map((item:any) => {
                        return (    
                    <tr>
                        <td className='text_icon'>
                            <a href={process.env.REACT_APP_PUBLIC_URL + "admin/adogtados/detalle/" + item.id_donaci}>
                                <div className='cont_ver_detalle'>                                
                                    <img src={process.env.REACT_APP_PUBLIC_URL + 'images/common/lupa.webp'} alt="Ver detalle" />
                                </div>
                            </a>
                        </td>
                        <td>{item.suscriptor}</td>
                        <td>{item.fecha}</td>
                        <td> {item.plan}</td>
                        <td>{item.monto}</td>
                        <td>{item.nro_tarjeta}</td>
                        <td><span className="label label-lg label-light-primary label-inline">{item.estado}</span></td>
                        <td className='text_icon'>
                           <a className="desuscribirse cur_point" onClick={(e) => handleUnsuscribe(e, item.id_donaci, item.suscriptor)}>
                                <div className='cont_ver_detalle'>                                
                                    <img src={process.env.REACT_APP_PUBLIC_URL + 'images/common/cancel.webp'} alt="Cancelar" />
                                </div>
                            </a>
                        </td>
                    </tr>
                    )
                })}
                </tbody>
                )}
                </Table>
                <div className='pagination_back'>                    
                {data.total > 0 && (<Pagination
                    activePage={data.current_page}
                    itemsCountPerPage={data.per_page}
                    totalItemsCount={data.total}
                    pageRangeDisplayed={5}
                    onChange={(e) => handlePageChange(e)}
                    innerClass={"pagination"}
                    itemClass={"page-item"}
                    linkClass={"page-link"}
                    />)}

                </div>
            </div>
            </Container>
        </div>
        <ModalConfirm text="" handleClose={handleClose} show={confirmData?.show} handleSave={unsuscribe} action={"¿Estás seguro de anular la suscripción de " + confirmData?.suscriptor + "?"} id={confirmData?.idSuscription}/>
        
        </BackLayout>
)};
export default Donaciones;
