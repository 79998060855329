import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Redirect from '../../src/pages/redirect';
import RedirectRol from '../../src/pages/redirect/redirectrol';
import Home from '../pages/home/home';
import Tienda from '../pages/tienda/tienda';
import Nosotros from '../pages/nosotros/nosotros';
import Embajadores from '../pages/embajadores/embajadores';
import Faq from '../pages/faq/faq';
import Checkout from '../pages/checkout/checkout';
import Resumen from '../pages/resumen/resumen';
import CalcularRacion from '../pages/calcularRacion/calcularRacion';
import CalcularRacionR from '../pages/calcularRacion/calcularRacionR';
import DetalleProducto from '../pages/detalleProducto/detalleProducto';
import ReporteVentas from '../backoffice/pages/reporteVentas/reporteVentas';
import Suscripciones from '../backoffice/pages/suscripciones/suscripciones';
import Donaciones from '../backoffice/pages/suscripciones/donaciones';
import Cupones from '../backoffice/pages/cupones/cupones';
import ListFaq from '../backoffice/pages/faq/faq';
import Productos from '../backoffice/pages/productos/productos';
import Clientes from '../backoffice/pages/clientes/clientes';
import Recompensas from '../backoffice/pages/clientes/recompensas';
import RegistrarProductos from '../backoffice/pages/productos/registrarProducto';
import EditarProductos from '../backoffice/pages/productos/editarProducto';
import RegistrarClientes from '../backoffice/pages/clientes/registrarCliente';
import EditUser from '../backoffice/pages/clientes/editUser';
import Compras from '../backoffice/pages/compras/compras';
import MisSuscripciones from 'backoffice/pages/suscripciones/misSuscripciones';
import MisDonaciones from 'backoffice/pages/suscripciones/misDonaciones';
import DetalleMisSuscripcion from 'backoffice/pages/suscripciones/detalleMiSuscripcion';
import Login2 from '../pages/login/login';
import RegistroUsuario from '../pages/login/registro';
import ListTestimonio from 'backoffice/pages/testimonios/testimonios';
import ReporteVentasDetalle from 'backoffice/pages/reporteVentas/reporteVentaDetalle';
import ComprasDetalle from '../backoffice/pages/compras/comprasDetalle';
import SuscripcionDetalle from 'backoffice/pages/suscripciones/detalleSuscripcion';
import DetalleDonacion from 'backoffice/pages/suscripciones/detalleDonacion';
import AdminHome from 'backoffice/pages/home/home';
import EditarPresentacion from 'backoffice/pages/productos/editarPresentacion';
import Cuenta from 'backoffice/pages/cuenta/miCuenta';
import DashboardAdmin from 'backoffice/pages/dashboard/dashboardAdmin';
import DashboardUsuario from 'backoffice/pages/dashboard/dashboardUsuario';
import AgregarPresentacion from 'backoffice/pages/productos/agregarPresentacion';
import ProductosPromociones from '../backoffice/pages/productos/productosPromociones';
import Membresia from '../pages/membresia/membresia';




function Roots(props: any) {
  
    
  
    return (
      <div>        
        <BrowserRouter>
        <Routes>    
            <Route path="/redirect" element={<Redirect />}>
            </Route>
            <Route path="/loadingdashboard" element={<RedirectRol />}>
            </Route>
            <Route path="/" element={<Home />}>
            </Route>
            <Route path="/tienda" element={<Tienda />}>
            </Route>
            <Route path="/membresia-origo" element={<Membresia />}>
            </Route>
            <Route path="/nosotros" element={<Nosotros />}>
            </Route>
            <Route path="/embajadores" element={<Embajadores />}>
            </Route>
            <Route path="/faq" element={<Faq />}>
            </Route>
            <Route path="/checkout" element={<Checkout />}>
            </Route>
            <Route path="/resumen/:code" element={<Resumen />}>
            </Route>
            <Route path="/calcular-racion" element={<CalcularRacionR />}>
            </Route>
            <Route path="/producto/:slug" element={<DetalleProducto />}>
            </Route>
           
            <Route path="/admin/reporte-ventas" element={<ReporteVentas />}>
            </Route>
            <Route path="/admin/suscripciones" element={<Suscripciones />}>
            </Route>
            <Route path="/admin/suscripciones/detalle/:id_suscri" element={<SuscripcionDetalle />}>
            </Route>
            <Route path="/admin/adogtados" element={<Donaciones />}>
            </Route>
            <Route path="/admin/adogtados/detalle/:id_donaci" element={<DetalleDonacion />}>
            </Route>
            <Route path="/admin/cupones" element={<Cupones />}>
            </Route>
            <Route path="/admin/faq" element={<ListFaq />}>
            </Route>            
            <Route path="/admin/testimonios" element={<ListTestimonio />}>
            </Route>
            <Route path="/admin/productos" element={<Productos />}>
            </Route>            
            <Route path="/admin/registrar-producto" element={<RegistrarProductos />}>
            </Route>
            <Route path="/admin/editar-producto/:id_product" element={<EditarProductos />}>
            </Route>
            <Route path="/admin/editar-presentacion/:id_presen" element={<EditarPresentacion />}>
            </Route>
            <Route path="/admin/registrar-presentacion/:id_product" element={<AgregarPresentacion />}>
            </Route>
            <Route path="/admin/usuarios" element={<Clientes />}>
            </Route>
            <Route path="/admin/usuarios/nuevo" element={<RegistrarClientes />}>
            </Route>
            <Route path="/admin/usuarios/:id" element={<EditUser />}>
            </Route>
            <Route path="/admin/recompensas" element={<Recompensas />}>
            </Route>   
            <Route path="/admin/home" element={<AdminHome />}>
            </Route>   
            <Route path="/cuenta" element={<Cuenta />}>
            </Route>        
            <Route path="/admin/dashboard" element={<DashboardAdmin />}>
            </Route>  
            <Route path="/cliente/resumen" element={<DashboardUsuario />}>
            </Route>  
            <Route path="/cliente/compras" element={<Compras />}>
            </Route>
            <Route path="/cliente/compras/detalle/:id_pedido" element={<ComprasDetalle />}>
            </Route>
            <Route path="/cliente/suscripciones" element={<MisSuscripciones />}>
            </Route>
            <Route path="/cliente/donaciones" element={<MisDonaciones />}>
            </Route>
            <Route path="/cliente/suscripciones/detalle/:id_suscri" element={<DetalleMisSuscripcion />}>
            </Route>
            <Route path="/login" element={<Login2 />}>
            </Route>
            <Route path="/registro" element={<RegistroUsuario />}>
            </Route>
            <Route path="/admin/pedidos/detalle/:id" element={<ReporteVentasDetalle />}>
            </Route>
            <Route path="/admin/reporte-ventas/detalle/:id" element={<ReporteVentasDetalle />}>
            </Route>
            <Route path="/admin/suscripciones/detalle/:id" element={<DetalleMisSuscripcion />}></Route>
            <Route path="/admin/promociones" element={<ProductosPromociones />}>
            </Route>
        </Routes>
    </BrowserRouter>
      </div>
    );
  }

  export default Roots;