import Modal from 'react-bootstrap/Modal';

import { Button, CloseButton, Form
    , Row , Col 
  } from "react-bootstrap"

type TrackingProps = {
    handleClose?: any
    show?: boolean
    url: string
}

function Tracking(props:TrackingProps) {
    

  return (
    <Modal
      show={props.show} onHide={props.handleClose}
      fullscreen={true}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
        <Modal.Header  className="close-modal">
        <h2 style={{color: "#ff6f5d"}}>Seguimiento de ruta</h2>   <CloseButton variant="white" onClick={props.handleClose}/>
        </Modal.Header>
        <Modal.Body className="container-iframe">
       
            <iframe className="responsive-iframe" id="iframesrc" src={props.url}></iframe>
           
        </Modal.Body>
        <Modal.Footer style={{justifyContent: "center"}}>
            
        </Modal.Footer>
    </Modal>
  );
}

export default Tracking