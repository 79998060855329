import "./style.css";

type BoxNumberSProps = {
    quantity: number
    increaseQuantity: () => void
    decreaseQuantity: () => void
}  

function BoxNumberS(props: BoxNumberSProps){


    return (
        <>
        <div className="box-number">            
            <div className="cont_number_s" id="quantityProduct">
                <span className="con_elem">{props.quantity}</span>
            </div>
            <div className="btn_plus_minus_s">
                <span className="con_elem" onClick={props.decreaseQuantity}>-</span>
            </div>
            <div className="btn_plus_minus_s">
                <span className="con_elem" onClick={props.increaseQuantity}>+</span>
            </div>
        </div>
        </>
    );
}
export default BoxNumberS;