import React from 'react';
import { useState, useEffect } from "react"
import "./style.css";
import BannerParallax from '../../components/bannerParallax/bannerParallax';
import MainLayout from "../../components/layout/MainLayout/MainLayout";
import { Row, Col, Form, Button, Spinner, InputGroup } from 'react-bootstrap';
import {CartItem} from 'components/CartItem';
import Tooltip from 'components/producto/tooltip';
import Terms from './terms';
import BeforeFooter from 'components/beforeFooter/beforeFooter';
import { useShoppingCart } from "../../context/ShoppingCartContext"
import { ToastContainer, toast } from 'react-toastify';
import {StoreService} from '../../business/StoreService';
import {AuthService} from '../../business/AuthService';
import {IndexService} from '../../business/IndexService';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import CulqiComponent from 'components/culqi/Culqi'
import Culqi from 'components/culqi/Culqi'
import CulqiProvider from 'components/culqi/checkout'
import { formatMoney } from "../../utils/formatCurrency";

import * as Yup from 'yup';
import { yupToFormErrors } from "formik";



type purchaseData = {
  ucode: string
  promoCode: string
  totalCredits: number
  totalDiscount: number
  perDiscount: number
  valorPromo: number
  totalPromo: number
  totalDiscountSus: number
  taxes: number
  subtotalCart: number
  subtotal: number
  promoType: number
  promoItems: []
  promoItemsNames: string
  promoAmmount: number
  igv:number
  freeCost:number 
  shippingCost:number
  shippingDistrict: number
  total:number
  msgPromoDiscount: string
  alertMessage: string
  couponActive: boolean
  uniqueCodeActive: boolean
}

let defaultData = {
    ucode: ""
    ,promoCode: ""
    ,phone2: ""
    ,observations: ""
    ,reference: ""
    ,paymentType: 0
    ,tokenCulqi: ""
    ,installments: ""
    ,total: 0.0
    ,igv: 18.00
    ,taxes:0.0
    ,subtotal:0.0
    ,subtotalCart:0.0
    ,shippingCost: -1.0
    ,shippingDistrict: -1.0
    ,perDiscount: 0.00
    ,totalDiscount: 0.0
    ,tipoPromo: 0
    ,valorPromo: 0.0
    ,totalPromo: 0.0
    ,coinType: "S/"
    ,postalCode: ""
    ,district: 0
    ,freeCost: -1
    ,deliberyDate: ""
    ,time: 1
    ,totalCredits: 0
    ,promoItems: []
    ,totalDiscountSus: 0.0
    ,promoType: 0
    ,promoItemsNames: ""
    ,promoAmmount: 0.0
    ,msgPromoDiscount: ""
    ,alertMessage: ""
    ,couponActive: false
    ,uniqueCodeActive: false
    ,voucherType: 1
    ,ruc: ""
    ,companyName: ""
    ,acceptTerms: '0'
    ,docType: 0
    ,docNumber: ""
    ,email: ""
    ,firstName: ""
    ,lastName: ""
    ,lastName2: ""
    ,address: ""
    ,phone1: ""
    ,paymentMethod: 0
  }

  let defaultUser = {
    docType: 0
    ,docNumber: ""
    ,email: ""
    ,firstName: ""
    ,lastName: ""
    ,lastName2: ""
    ,address: ""
    ,reference: ""
    ,phone1: ""
    ,totalCredits: 0
    ,isFirstPurchase: false
    ,hasUCode: false
  }

  let defaultFreeItem = {
    id: 0
    ,name: ""
    ,category: ""
    ,idLine: 0
    ,line: ""
    ,price: 0
    ,quantity: 0
    ,percentageDiscount: 0
    ,normalPrice:0
    ,image:""
    ,presentation:""
    ,type: 0
    ,promo:0
    ,total: 0
    ,periodicity:0
    ,canEdit: false
    ,onceBuy: true
  }

  const checkoutSquema = Yup.object().shape({
     paymentType: Yup.number().required("*Campo obligatorio")
    ,district: Yup.number().min(1,"*Campo obligatorio")
    ,deliberyDate: Yup.string().required("*Campo obligatorio")
    ,voucherType: Yup.number().required("*Campo obligatorio")
    ,ruc: Yup.string().when('voucherType', (val:any, schema:any) => {
        if(val==2) {  //if address exist then apply min max else not
           return Yup.string().required("*Campo obligatorio");
        } else { 
           return Yup.string().notRequired();
        }
   })
    ,companyName: Yup.string().when('voucherType', (val:any, schema:any) => {
        if(val==2) {  //if address exist then apply min max else not
           return Yup.string().required("*Campo obligatorio");
        } else { 
           return Yup.string().notRequired();
        }
   })
    ,acceptTerms: Yup.boolean().oneOf([true],"*Debe leer y aceptar los términos y condiciones.")
    ,paymentMethod: Yup.number().required("*Campo obligatorio")
    ,reference: Yup.string().required("*Campo obligatorio")
    ,docType: Yup.number().required("*Campo obligatorio")
    ,docNumber: Yup.string().required("*Campo obligatorio")
    ,firstName: Yup.string().required("*Campo obligatorio")
    ,lastName: Yup.string().required("*Campo obligatorio")
    ,address: Yup.string().required("*Campo obligatorio")
    ,phone1: Yup.string().required("*Campo obligatorio")
    ,email: Yup.string().required("*Campo obligatorio")
  });

function Checkout (){
    const { closeCart, cartItems, isFrozen, totalCart
        , hasRecurrentPurchase, hasRegularPurchase, cartQuantity
        , isAuthenticated, updatePriceItem, overrideCartItems} = useShoppingCart();

    let stUcode = localStorage.getItem('ucode') != undefined && localStorage.getItem('ucode') != null ? localStorage.getItem('ucode') : "";
    
    let isActiveUcode = localStorage.getItem('ucode') != undefined && localStorage.getItem('ucode') != null ? true : false;
    
    let stCoupon = localStorage.getItem('coupon') != undefined && localStorage.getItem('coupon') != null ? localStorage.getItem('coupon') : "";
    
    let isActiveCoupon = localStorage.getItem('coupon') != undefined && localStorage.getItem('coupon') != null ? true : false;

    let stCredits = localStorage.getItem('credits') != undefined && localStorage.getItem('credits') != null ? localStorage.getItem('credits') : '0';
    
    let isActiveCredits = localStorage.getItem('credits') != undefined && localStorage.getItem('credits') != null ? true : false;


    const [loadingCoupon, setLoadingCouponButton] = useState(false);
    const [loadingUCode, setLoadingUCodeButton] = useState(false);
    const [loadingCredits, setLoadingCreditsButton] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [msgPromoDiscount, setMsgPromoDiscount] = useState("");
    const [msgUcode, setMsgUCode] = useState("");
    const [msgCredits, setMsgCredits] = useState("");
    const [couponActive, setCouponActive] = useState(isActiveCoupon);
    const [uniqueCodeActive, setUniqueCodeActive] = useState(isActiveUcode);
    const [creditsActive, setCreditsActive] = useState(isActiveCredits);
    const [ucode, setUcode] = useState(stUcode);
    const [promoCode, setPromoCode] = useState(stCoupon);
    const [totalCredits, setTotalCredits] = useState(stCredits);
    const [disabledDates, setDisabledDates] = useState([moment(new Date('2023-03-27')).format("YYYY-MM-DD")]);
    const [data, setData] = useState(defaultData);
    const [user, setUser] = useState(defaultUser);
    const [labelZone, setLabelZone] = useState("");
    const [freeItem, setFreeItem] = useState(defaultFreeItem);
    const [freeCost, setFreeCost] = useState(-1);
    const [promos, setPromos] = useState<any>([]);
    
    const [deliveryDate, setDeliveryDate] = useState("");
        
    const [show, setShow] = useState(false);
    const [errors, setErrors] = useState<any>();
    const [validated, setValidated] = useState<any>();


    const handleClose = (event:any) => {
        event.preventDefault();
        setShow(false);
    }
    const handleShow = () => setShow(true);

    useEffect(() => {
        const queryString = window.location.search;

        const urlParams = new URLSearchParams(queryString);

        let unique_code = urlParams.get('ucode');

        if(unique_code !==null)
        {
            
            setUcode(unique_code);

        }
        if(isAuthenticated == true)
        {
            getUserCheckout();
        }
        if(couponActive)
        {
            validatePromocionalCode(1);
        }   
        if(uniqueCodeActive)
        {
            validateUCode(1);
        }  
        if(creditsActive)
        {
            validateCredits(1);
        }   
        
    }, []);
  
    useEffect(() => {
        getCartInfo();
                 
    }, [totalCart,data.shippingDistrict,data.valorPromo,data.perDiscount,data.totalCredits, cartQuantity, hasRecurrentPurchase, isFrozen]);
 
    useEffect(() => {
        console.log('cambio freecost',freeCost);
        if(freeCost != null)
        {
            calcTotals(); 
        }
        
    }, [freeCost]);
 

    useEffect(() => {        
        getEnabledDates();        
    }, [data.district]);

    useEffect(() => {            
    }, [data.voucherType]);

    useEffect(() => {  
        document.getElementById('alertMessageSection')?.focus();          
              
    }, [alertMessage]);

    useEffect(() => {  
       if(isActiveCoupon==true)
       {
        setMsgPromoDiscount("");
       }        
              
    }, [isActiveCoupon]);

    useEffect(() => {  
        if(isActiveUcode==true)
        {
         setMsgUCode("");
        }        
               
     }, [isActiveUcode]);

     useEffect(() => {  
        if(isActiveCredits==true)
        {
         setMsgCredits("");
        }        
               
     }, [isActiveCredits]);

     useEffect(() => {  
        getPromos();    
               
     }, []);

    

    const handleSetLoading = (fn:any) =>{
      
        
    }

    const handleSaveOrder = (token:string, installments:number) =>{

        insertPaymentApi(token, installments);
           
    }

    const handleShowErrorNotification = (message:string) =>{
        setAlertMessage(message);
    }
   

    function calcTotals()
    {
        
        let items = [...cartItems];
        let obj = {...data};
        obj.deliberyDate = deliveryDate != "" ? moment(deliveryDate).format('YYYY-MM-DD') : "";
        
        let total = 0;
        let total_disc_sus = 0;
        let total_prod = 0;
        let total_sin_discount = 0;
        let igv = 18;
        

        for(let i = 0; i < items.length; i++)
        {
            let item = items[i];
            total = total + item.total;

            if(item.type==2)
            {
                total_disc_sus = total_disc_sus + (item.total) * 0.05;
            } 
            if(item.percentageDiscount == 0 && item.promo == null)
            {
                total_sin_discount = total_sin_discount + item.total;
            }                
            
        }

        if(isAuthenticated!==true)
        {
            obj.totalCredits = 0.0;
            obj.totalDiscount = 0.0;
            obj.perDiscount = 0.0;
            obj.valorPromo = 0.0;
            obj.totalPromo = 0.0;
            obj.totalDiscountSus = 0.0;
        }
        obj.taxes = total * 18 / 100.00;
        obj.subtotalCart = total;
        obj.subtotal = total - obj.taxes;
        obj.totalDiscount = (total * obj.perDiscount / 100.00);
        obj.totalDiscountSus = total_disc_sus;

        if(obj.promoCode != "")
        {
            if(obj.promoItems.length > 0)
            {
                for(let i = 0; i < items.length; i++)
                {
                    let item = items[i];
                    if(obj.promoItems.length > 0)
                    {
                    let exists = cartItems.find(it => it.id === item.id);
                    
                    if( exists !==undefined)
                    {
                        if(exists.quantity > 0 && exists.percentageDiscount == 0 && exists.promo == null)
                        {
                        total_prod = total_prod + item.quantity * item.price;
                        }
                        
                    }
                    }
                    
                    
                }
                if(obj.promoType == 0)
                {
                    obj.totalPromo = (total_prod * obj.valorPromo / 100.00);
                }
                else{
                    obj.totalPromo = total_prod >= obj.valorPromo ? obj.valorPromo : total_prod;
                }
            }
            else
            {
                if(obj.promoType == 0)
                {
                    obj.totalPromo = (total_sin_discount * obj.valorPromo / 100.00);
                }
                else{
                    obj.totalPromo = total_sin_discount >= obj.valorPromo ? obj.valorPromo : total_sin_discount;
                }
            }
            
        }
        else{
            obj.totalPromo = 0;

        }
        
        total = total - obj.totalDiscount - obj.totalDiscountSus;
        
        if(obj.totalPromo> total)
        {
            obj.totalPromo = total;
        }
        total = total - obj.totalPromo;

        if(obj.totalCredits > total){
            obj.totalCredits = total;
        }

        total = total - obj.totalCredits;

        obj.freeCost = freeCost;
        

        if(total >= obj.freeCost && obj.freeCost !== -1)
        {
            obj.shippingCost = 0.00;
            
        }
        else{
            if(obj.district>0)
            {
                if(hasRegularPurchase==false)
                {
                    obj.shippingCost = 5;
                }
                else{
                    obj.shippingCost = obj.shippingDistrict;
                }
            }
            else{
                obj.shippingCost = -1.0;
            }
           
        }

        obj.total = total + (obj.shippingCost > 0 ? obj.shippingCost : 0);
        
        setData(obj);
    }

    const getPromos = async () => { 
        const getInstance = IndexService.getInstance();
       
        await getInstance.getNotes()
            .then((response:any) => {
                  
              
                  if(response.success === true)
                  {
                    setPromos(response.promos);
    
                  }
                  else{
                    
                  }
                  
                }   
              )
            .catch((err:any) => {
              
            });
    
        }

    const getUserCheckout = async () => { 
        const getInstance = AuthService.getInstance();
        
        await getInstance.getUserCheckout()
        .then((response:any) => {
                let copyUser = {...user}
                copyUser.docType = response.docType==null ? 0: response.docType;
                copyUser.docNumber = response.docNumber==null ? '': response.docNumber;
                copyUser.email = response.email==null ? '': response.email;
                copyUser.firstName = response.firstName==null ? '': response.firstName;
                copyUser.lastName = response.lastName==null ? '': response.lastName;
                copyUser.lastName2 = response.lastName2==null ? '': response.lastName2;
                copyUser.address = response.address==null ? '': response.address;
                copyUser.reference = response.reference==null ? '': response.reference;
                copyUser.phone1 = response.phone1==null ? '': response.phone1;
                copyUser.totalCredits = response.totalCredits==null ? 0: response.totalCredits;
                setUser(copyUser);
                

            }   
          )
        .catch((err:any) => {
          
        });
    
      }

     
    const validatePromoCodeApi = async () => { 
        const getInstance = StoreService.getInstance();
        setLoadingCouponButton(true);

        let obj = {...data};
        let items = [...cartItems];
                            
        await getInstance.validatePromotionalCode({cupon: promoCode, items: JSON.stringify(items)})
        .then((response:any) => {
              setAlertMessage("");
              setLoadingCouponButton(false);
           
              if(response[0].success === true)
              {
                obj.promoType = response[0].tipo;
                obj.valorPromo = response[0].valor;
                obj.promoCode = promoCode==null ? "" : promoCode;
                obj.promoItems = response[0].productos_ids;
                obj.promoItemsNames = response[0].prod_validos;
                localStorage.setItem('coupon', promoCode==null ? "" : promoCode);
                setCouponActive(true);
                setData(obj);
    
              }
              else{
                setAlertMessage(response[0].mensaje);
                 
                  obj.promoAmmount = 0.0;
                  obj.promoItems = [];
                  obj.promoCode= "";
                  obj.promoItemsNames = "";
                  setCouponActive(false);
                  setData(obj);
              }
            }   
          )
        .catch((err:any) => {
          setLoadingCouponButton(false);
        });
    
    }

    const validateUCodeApi = async () => { 
        const getInstance = StoreService.getInstance();
        setLoadingUCodeButton(true);

        let obj = {...data};
                            
        await getInstance.validateUCode({data: ucode})
        .then((response:any) => {
              setAlertMessage("");
              setLoadingUCodeButton(false);
                         
              if(response.success === true)
              {
                obj.perDiscount = response.percentage;
                
                localStorage.setItem('ucode', ucode==null ? "" : ucode);
                setUniqueCodeActive(true);
                setData(obj);
    
              }
              else
              {
                    setAlertMessage(response.mensaje);
                 
                    obj.perDiscount = 0.0;
                    setUniqueCodeActive(false);
                    setData(obj);
              }
            }   
          )
        .catch((err:any) => {
            setLoadingUCodeButton(false);
        });
    
    }

    const getTotalOrderApi = async (open:any,setAmount:any) => { 
        const getInstance = StoreService.getInstance();
        
        let obj = {...data};
        obj.deliberyDate = deliveryDate != "" ? moment(deliveryDate).format('YYYY-MM-DD') : "";
                            
        await getInstance.getTotalOrder({data: JSON.stringify(obj), items: JSON.stringify(cartItems)})
        .then((response:any) => {
              setAlertMessage("");              

              if(response.success === true)
              {
                    setAmount(response.montoCulqi);
                    setTimeout(() => {
                        open.call();
                      }, 1000)                 
      
              }
              else{
                
                 //ocultar la carga
                  if(response.success==false && response.number == 1)
                  {
                    window.location.href='/login';
                  }
                  else{
                    setAlertMessage(response.message);
                    //focus en mensaje
                    if(response.removeProduct?.id > 0 )
                    {
                        let elem = cartItems?.find(x => x.id === response?.removeProduct.id);

                        if(elem!==undefined)
                        {
                            elem.price = response.removeProduct?.precio_descuento;
                            elem.percentageDiscount = response.removeProduct?.descuento;
                            elem.normalPrice = response.removeProduct?.precio_normal;
                            elem.promo = response.removeProduct?.tipo_promo;
                            updatePriceItem(elem);
                        }
                        
                    }
                    
                  }
              }
                         
              
            }   
          )
        .catch((err:any) => {
            //eliminar elmodal de loading
            setLoadingUCodeButton(false);
        });
    
    }

    const getCartInfo = async () => { 
        const getInstance = StoreService.getInstance();
        const result = await calcTotals();

        let obj = {...data};
        obj.deliberyDate = deliveryDate != "" ? moment(deliveryDate).format('YYYY-MM-DD') : "";
                            
        await getInstance.getTotalOrder({data: JSON.stringify(obj), items: JSON.stringify(cartItems)})
        .then((response:any) => {
              setAlertMessage("");              

              if(response.success === true)
              {
                    setFreeCost(response.freeCost);
                    setFreeItem(response.items_gratuito);  
                    if(response.freeCost > 0)
                    {
                        setLabelZone('Zona Urbana: Pedidos por encima de S/ '+ response.freeCost +' tienen delivery gratis.');
                    }
                    else{
                        setLabelZone("");
                    }  
                     
              }
              else{
                setAlertMessage(response.message);
                    setFreeCost(response.freeCost);
                    setFreeItem(response.items_gratuito); 
                    setLabelZone("");
                 //ocultar la carga
                  if(response.success==false && response.number == 1)
                  {
                    window.location.href='/login';
                  }
                  else{
                    
                    //focus en mensaje
                    if(response.removeProduct?.id > 0 )
                    {
                        let elem = cartItems?.find(x => x.id === response?.removeProduct.id);

                        if(elem!==undefined)
                        {
                            elem.price = response.removeProduct?.precio_descuento;
                            elem.percentageDiscount = response.removeProduct?.descuento;
                            elem.normalPrice = response.removeProduct?.precio_normal;
                            elem.promo = response.removeProduct?.tipo_promo;
                            updatePriceItem(elem);
                        }
                        
                    }
                    calcTotals();
                    
                  }
              }
                         
              
            }   
          )
        .catch((err:any) => {
            //eliminar elmodal de loading
            setLoadingUCodeButton(false);
        });
    
    }

    const insertPaymentApi = async (token:string, installments:number) => { 
        const getInstance = StoreService.getInstance();
        const result = await calcTotals();

        let obj = {...data};
        let userData = {...user};
        obj.deliberyDate = deliveryDate != "" ? moment(deliveryDate).format('YYYY-MM-DD') : "";
        obj.docType = userData.docType==null ? 0: userData.docType;
        obj.docNumber = userData.docNumber==null ? '': userData.docNumber;
        obj.email = userData.email==null ? '': userData.email;
        obj.firstName = userData.firstName==null ? '': userData.firstName;
        obj.lastName = userData.lastName==null ? '': userData.lastName;
        obj.lastName2 = userData.lastName2==null ? '': userData.lastName2;
        obj.address = userData.address==null ? '': userData.address;
        obj.reference = userData.reference==null ? '': userData.reference;
        obj.phone1 = userData.phone1==null ? '': userData.phone1;
                            
        await getInstance.insertPayment({data: JSON.stringify(obj), items: JSON.stringify(cartItems), tokenCulqi: token, installments: installments})
        .then((response:any) => {
              setAlertMessage("");              

              if(response.success === true)
              {
                localStorage.removeItem("shopping-cart");
                localStorage.removeItem("credits");
                localStorage.removeItem("coupon");
                localStorage.removeItem("unique_code");
                setTimeout(() => {
                    window.location.href = '/resumen/'+response.codigo;
                  }, 1000)   
                
              }
              else{
                 //ocultar la carga
                  if(response.success==false && response.number == 1)
                  {
                    window.location.href='/login';
                  }
                  else{
                    setAlertMessage(response.message)
                    //focus en mensaje
                  }
              }
                         
              
            }   
          )
        .catch((err:any) => {
            //eliminar elmodal de loading
            setLoadingUCodeButton(false);
        });
    
    }

    
    
    const validatePromocionalCode = (action:number) => { 
     
        let obj = {...data};
    
        if(action===1)
        {
            if(promoCode!="")
            {        
              validatePromoCodeApi();
              
            }
            else{
              setMsgPromoDiscount("*Debe ingresar un código");
            }
    
        }
        else{
            setCouponActive(false);
            obj.promoType = 0;
            obj.valorPromo = 0;
            obj.promoCode = "";
            obj.totalPromo = 0;
            obj.promoItems = [];
            obj.promoItemsNames = "";
            localStorage.removeItem('coupon');
    
       }
       setData(obj);
    }

    const validateUCode = (action:number) => { 
     
        let obj = {...data};
    
        if(action===1)
        {
            if(ucode!="")
            {        
                validateUCodeApi();
              
            }
            else{
              setMsgUCode("*Debe ingresar un código");
            }
    
        }
        else{
            setUniqueCodeActive(false);
            obj.perDiscount = 0.0;
            localStorage.removeItem('ucode');
    
       }
       setData(obj);
    }

    const validateCredits = (action:number) => { 
     
        let obj = {...data};
    
        if(action===1)
        {
            if(totalCredits!="")
            {        
                let creditsToUse = totalCredits==null ? 0 : parseFloat(totalCredits);
                if(creditsToUse> user.totalCredits)
                {
                    creditsToUse = user.totalCredits;
                }

                obj.totalCredits = creditsToUse;
                setCreditsActive(true);
                setTotalCredits(creditsToUse.toString());
                localStorage.setItem('credits', creditsToUse.toString());
              
            }
            else{
              setMsgCredits("*Debe ingresar la cantidad de oripoints a utilizar");
            }
    
        }
        else{
            setCreditsActive(false);
            obj.totalCredits = 0.0;
            localStorage.removeItem('credits');
    
       }
       setData(obj);
    }

    const handleChangeVoucher = (event: any, type:number) => {
       
        setData({
            ...data,
            ['voucherType']:  type
          });
    }

    const handleChangeData= (event: any) => {
        const { name, value} = event.target;
       
        let val = value;
        if(name==='district')
        {
            val = parseInt(value);
        }
        if(name==='totalCredits')
        {
          val = parseFloat(value);
        }
        if(name==='acceptTerms')
        {
           if(event.target.checked == true)
           {
                val = '1';
           }
           else{
                val = '0';
           }

         
        }
        
        
        setData({
            ...data,
            [name]:  event.target.type==='radio' ? event.target.checked : val
          });
         
        
        
      };

      const handleUserData= (event: any) => {
        const { name, value} = event.target;
               
        setUser({
            ...user,
            [name]:  event.target.type==='radio' ? event.target.checked : value
          });
         
        
        
      };


      const getEnabledDates = async () => { 
        const getInstance = StoreService.getInstance();
        
        await getInstance.getEnabledDates({district: data.district})
        .then((response:any) => {
                       
              if(response[0].success === true)
              {
                let disabledMap = response[0].enabled_days;
                disabledMap = disabledMap.map((row:any) => (moment(row).format('YYYY-MM-DD')))
          
                setDisabledDates(disabledMap);
                setDeliveryDate("");

                let copyData = {...data};
                if(response[0].costo_gratuito> 0)
                {
                    copyData.freeCost = parseFloat(response[0].costo_gratuito);

                }
                else{
                    copyData.freeCost = -1;
                }
                
                copyData.shippingDistrict = parseFloat(response[0].costo_envio);
                copyData.deliberyDate = "";
                
                if(response[0].id_zona==5 && copyData.freeCost > 0 && copyData.freeCost != -1)
                {
                    setLabelZone('Zona Urbana: Pedidos por encima de S/ '+ response[0].costo_gratuito +' tienen delivery gratis.')
                }
           
                setData(copyData);
    
              }
              else{
                setDisabledDates([]);
              }
            }   
          )
        .catch((err:any) => {
          
        });
    
      }

    const handleOpenCulqiCh = (open:any, setAmount:any) => {

        let obj = {...data};
        let userData = {...user};
        obj.deliberyDate = deliveryDate != "" ? moment(deliveryDate).format('YYYY-MM-DD') : "";
        obj.docType = userData.docType==null ? 0: userData.docType;
        obj.docNumber = userData.docNumber==null ? '': userData.docNumber;
        obj.email = userData.email==null ? '': userData.email;
        obj.firstName = userData.firstName==null ? '': userData.firstName;
        obj.lastName = userData.lastName==null ? '': userData.lastName;
        obj.lastName2 = userData.lastName2==null ? '': userData.lastName2;
        obj.address = userData.address==null ? '': userData.address;
        obj.reference = userData.reference==null ? '': userData.reference;
        obj.phone1 = userData.phone1==null ? '': userData.phone1;
  
        checkoutSquema.validate(obj, { abortEarly: false }).then(function() {
            // Success
            getTotalOrderApi(open,setAmount);
            setErrors([]);
        }).catch(function (err) {
            let errorArray = {};
            const errors = yupToFormErrors(err);
            setErrors(errors);
            console.log(errors);
            setValidated(true);
            /*err.inner.forEach((e:any) => {
                
                if(e.path){
                    errorArray[e.path] = e.message;
                }
                
            });*/

            
        });
    }
              
        
        
      
      const handleSetAmountCh = () => {

      }
          
    return (
        <MainLayout>
        <div className='pad_initial'>
            {/* <BannerParallax 
                img={process.env.REACT_APP_PUBLIC_URL + "images/banners/parallax1.webp"}
                clase="cover"
                text= "PROCESO DE COMPRA"
            ></BannerParallax> */}
            <section className="section_checkout">
                <div className="checkout_w">
                    <Row>
                        <Col md={7}>
                            <div className="part1">
                                <h3>DATOS DE COMPRA</h3>
                                <Form id="form-compra" validated={validated}>
                                    {alertMessage!="" && (
                                        <div className="alert-section" tabIndex={0} id="alertMessageSection" dangerouslySetInnerHTML={{ __html: alertMessage }}></div>
                                    )}
                                    {isAuthenticated == true && (
                                    <Row>   
                                        <Form.Label>Cupón de descuento: <Tooltip texttool="¿Tienes un cupón de descuento?"></Tooltip> </Form.Label>
                                        <span data-toggle="modal" data-target="#kt_modal_cod" className="fa fa-question-circle" title="Ingresa el código de tu referido y obtén un 10% de descuento en tu primera compra"></span>
                                        <Col md={6}>
                                            <Form.Group>
                                                <input type="text" className="form-control" name="promoCode" placeholder="Ingresar cupón" value={promoCode!==null ? promoCode : ""} onChange={e => {setPromoCode(e.target.value)}} disabled={couponActive}/>
                                                <span className="help-block msg-cupon">{msgPromoDiscount}</span>
                                            </Form.Group>                                
                                        </Col>
                                        <Col md={6} className="form-group d-flex-end cont_btn_check">
                                        {couponActive == false ? (
                                            <button type="button" className="btn-validate validate-code"  disabled={loadingCoupon}
                                                onClick={() =>validatePromocionalCode(1)}>
                                                    {loadingCoupon ? (
                                                    <Spinner animation="border" role="status" style={{width: '1rem', height: '1rem'}}>
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Spinner>
                                                    ) : (
                                                    'APLICAR'
                                                    )}
                                                
                                            </button>)
                                            :
                                            (<button type="button" className="btn-validate-outline validate-code" 
                                                onClick={() =>validatePromocionalCode(0)}>
                                                    REMOVER
                                                
                                            </button>)
                                        }

                                        </Col>
                                    </Row>)}
                                    {((user.isFirstPurchase == true && user.hasUCode == false)) && (
                                    <Row>   
                                        <Form.Label>Código de referido: <Tooltip texttool="Ingresa el código de tu referido y obtén un 10% de descuento en tu primera compra."></Tooltip></Form.Label>
                                        <span data-toggle="modal" data-target="#kt_modal_cod" className="fa fa-question-circle" title="Ingresa el código de tu referido y obtén un 10% de descuento en tu primera compra"></span>
                                        <Col md={6}>
                                            <Form.Group>
                                                <input type="text" className="form-control" name="ucode" placeholder="Ingresar código" value={ucode!==null ? ucode : ""} onChange={e => {setUcode(e.target.value)}} disabled={uniqueCodeActive}/>
                                                <span className="help-block msg-cupon">{msgUcode}</span>
                                            </Form.Group>                                
                                        </Col>
                                        <Col md={6} className="form-group d-flex-end cont_btn_check">
                                        {uniqueCodeActive == false ? (
                                            <button type="button" className="btn-validate validate-code"  disabled={loadingUCode}
                                                onClick={() =>validateUCode(1)}>
                                                    {loadingUCode ? (
                                                    <Spinner animation="border" role="status" style={{width: '1rem', height: '1rem'}}>
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Spinner>
                                                    ) : (
                                                    'UTILIZAR'
                                                    )}
                                                
                                            </button>)
                                            :
                                            (<button type="button" className="btn-validate-outline validate-code" 
                                                onClick={() =>validateUCode(0)}>
                                                    REMOVER
                                                
                                            </button>)
                                        }
                                            
                                        </Col>
                                    </Row>)}
                                    <Row className="">    
                                        <Col md={12}>                  
                                            <Form.Group>                                                             
                                                <Form.Label>Email:</Form.Label>
                                                <span data-toggle="modal" data-target="#kt_modal_email" className="fa fa-question-circle" title="A este correo se enviará el detalle de su compra"></span>
                                                <input required type="text" className="form-control" name="email" value={user.email} placeholder="email@cuenta.com" onChange={e => {handleUserData(e)}} disabled={isAuthenticated==true ? true: false}/>
                                                {errors?.email != "" && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors?.email}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>  
                                        </Col>     
                                    </Row>
                                    <Row className="">                          
                                        <Col md={6}>                              
                                            <Form.Group>                        
                                                <Form.Label>Tipo de documento:</Form.Label>
                                                <Form.Control required as="select" name="docType" value={user.docType} onChange={e => {handleUserData(e)}} disabled={isAuthenticated==true ? true: false}>
                                                    <option value="">Elegir tipo</option>
                                                    <option value="1">DNI</option>
                                                    <option value="2">RUC</option>
                                                    <option value="3">Pasaporte</option>
                                                    <option value="4">Otros</option>
                                                </Form.Control>
                                                
                                                {errors?.docType != "" && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors?.docType}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>  
                                        </Col>                                
                                        <Col md={6}>                             
                                            <Form.Group>                       
                                                <Form.Label>Número de documento:</Form.Label>
                                                <input required placeholder='Número' type="text" className="form-control" name="docNumber" value={user.docNumber} onChange={e => {handleUserData(e)}} disabled={isAuthenticated==true ? true: false}/>
                                                {errors?.docNumber != "" && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors?.docNumber}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>Nombres:</Form.Label>
                                                <input required type="text" className="form-control" placeholder="Nombres" name="firstName" value={user.firstName} onChange={e => {handleUserData(e)}} disabled={isAuthenticated==true ? true: false}/>
                                                <span className="help-block"></span>
                                                {errors?.firstName != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.firstName}
                                                        </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>Primer Apellido:</Form.Label>
                                                <input required type="text" className="form-control" placeholder="Primer Apellido" name="lastName" value={user.lastName} onChange={e => {handleUserData(e)}} disabled={isAuthenticated==true ? true: false}/>
                                                <span className="help-block"></span>
                                                {errors?.lastName != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.lastName}
                                                        </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                        <Form.Group>
                                            <Form.Label>Segundo Apellido:</Form.Label>
                                            <input type="text" className="form-control" placeholder="Segundo Apellido" name="lastName2" value={user.lastName2} onChange={e => {handleUserData(e)}} disabled={isAuthenticated==true ? true: false}/>
                                            <span className="help-block"></span>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Form.Group>
                                                <Form.Label>Dirección de envío:</Form.Label>
                                                <input required type="text" className="form-control" placeholder="Dirección exacta" name="address" value={user.address} onChange={e => {handleUserData(e)}}/>
                                                <span className="help-block"></span>
                                                {errors?.address != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.address}
                                                        </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        
                                    </Row>
                                    
                                    <Row>
                                        <Form.Label>Referencia:</Form.Label>
                                        <Col md={12}>
                                            <Form.Group>
                                                <textarea required name="reference" value={user.reference} className="form-control" rows={2} placeholder="Escribir las referencias, descripción de la fachada, indicaciones de seguridad, etc..." onChange={e => {handleUserData(e)}}></textarea>
                                                {errors?.reference != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.reference}
                                                        </Form.Control.Feedback>
                                                )}
                                        </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col md={6}>
                                            <Form.Group>
                                            <Form.Label>Distrito:</Form.Label>
                                            <Form.Control required as="select" name="district" value={data.district} onChange={e => {handleChangeData(e)}}>
                                                
                                                <option value="">Elegir distrito</option>
                                                <option value="48" data-zona="Zona Alejada 2" data-week="" data-weekend="09:00AM - 07:00PM">Ancon </option>
                                                <option value="50" data-zona="Zona Alejada 2" data-week="" data-weekend="09:00AM - 07:00PM">Asia </option>
                                                <option value="9" data-zona="Zona Urbana" data-week="09:00AM - 07:00PM" data-weekend="09:00AM - 07:00PM">Ate </option>
                                                <option value="6" data-zona="Zona Urbana" data-week="09:00AM - 07:00PM" data-weekend="09:00AM - 07:00PM">Barranco </option>
                                                <option value="30" data-zona="Zona Urbana" data-week="09:00AM - 07:00PM" data-weekend="09:00AM - 07:00PM">Bellavista </option>
                                                <option value="20" data-zona="Zona Urbana" data-week="09:00AM - 07:00PM" data-weekend="09:00AM - 07:00PM">Breña </option>
                                                <option value="27" data-zona="Zona Urbana" data-week="09:00AM - 07:00PM" data-weekend="09:00AM - 07:00PM">Callao </option>
                                                <option value="35" data-zona="Zona Alejada  1" data-week="09:00AM - 07:00PM" data-weekend="">Carabayllo </option>
                                                <option value="32" data-zona="Zona Urbana" data-week="09:00AM - 07:00PM" data-weekend="09:00AM - 07:00PM">Carmen de La Legua </option>
                                                <option value="41" data-zona="Zona Alejada  1" data-week="09:00AM - 07:00PM" data-weekend="">Chaclacayo </option>
                                                <option value="51" data-zona="Zona Alejada 2" data-week="" data-weekend="09:00AM - 07:00PM">Chilca </option>
                                                <option value="14" data-zona="Zona Urbana" data-week="09:00AM - 07:00PM" data-weekend="09:00AM - 07:00PM">Chorrillos </option>
                                                <option value="42" data-zona="Zona Alejada  1" data-week="09:00AM - 07:00PM" data-weekend="">Cieneguilla </option>
                                                <option value="43" data-zona="Zona Alejada 1" data-week="" data-weekend="09:00AM - 07:00PM">Comas </option>
                                                <option value="16" data-zona="Zona Urbana" data-week="09:00AM - 07:00PM" data-weekend="09:00AM - 07:00PM">El Agustino </option>
                                                <option value="25" data-zona="Zona Urbana" data-week="09:00AM - 07:00PM" data-weekend="09:00AM - 07:00PM">Independencia </option>
                                                <option value="11" data-zona="Zona Urbana" data-week="09:00AM - 07:00PM" data-weekend="09:00AM - 07:00PM">Jesus Maria </option>
                                                <option value="8" data-zona="Zona Urbana" data-week="09:00AM - 07:00PM" data-weekend="09:00AM - 07:00PM">La Molina </option>
                                                <option value="31" data-zona="Zona Urbana" data-week="09:00AM - 07:00PM" data-weekend="09:00AM - 07:00PM">La Perla </option>
                                                <option value="33" data-zona="Zona Urbana" data-week="09:00AM - 07:00PM" data-weekend="09:00AM - 07:00PM">La Punta </option>
                                                <option value="17" data-zona="Zona Urbana" data-week="09:00AM - 07:00PM" data-weekend="09:00AM - 07:00PM">La Victoria </option>
                                                <option value="19" data-zona="Zona Urbana" data-week="09:00AM - 07:00PM" data-weekend="09:00AM - 07:00PM">Lima </option>
                                                <option value="1" data-zona="Zona Urbana" data-week="09:00AM - 07:00PM" data-weekend="09:00AM - 07:00PM">Lince </option>
                                                <option value="26" data-zona="Zona Urbana" data-week="09:00AM - 07:00PM" data-weekend="09:00AM - 07:00PM">Los Olivos </option>
                                                <option value="40" data-zona="Zona Alejada  1" data-week="09:00AM - 07:00PM" data-weekend="">Lurigancho </option>
                                                <option value="37" data-zona="Zona Alejada  1" data-week="09:00AM - 07:00PM" data-weekend="">Lurin </option>
                                                <option value="12" data-zona="Zona Urbana" data-week="09:00AM - 07:00PM" data-weekend="09:00AM - 07:00PM">Magdalena del Mar </option>
                                                <option value="52" data-zona="Zona Alejada 2" data-week="" data-weekend="09:00AM - 07:00PM">Mala </option>
                                                <option value="4" data-zona="Zona Urbana" data-week="09:00AM - 07:00PM" data-weekend="09:00AM - 07:00PM">Miraflores </option>
                                                <option value="38" data-zona="Zona Alejada  1" data-week="09:00AM - 07:00PM" data-weekend="">Pachacamac </option>
                                                <option value="44" data-zona="Zona Alejada 2" data-week="" data-weekend="09:00AM - 07:00PM">Pucusana </option>
                                                <option value="10" data-zona="Zona Urbana" data-week="09:00AM - 07:00PM" data-weekend="09:00AM - 07:00PM">Pueblo Libre </option>
                                                <option value="36" data-zona="Zona Alejada  1" data-week="09:00AM - 07:00PM" data-weekend="">Puente Piedra </option>
                                                <option value="39" data-zona="Zona Alejada 2" data-week="09:00AM - 07:00PM" data-weekend="">Punta Hermosa </option>
                                                <option value="46" data-zona="Zona Alejada 2" data-week="" data-weekend="09:00AM - 07:00PM">Punta Negra </option>
                                                <option value="15" data-zona="Zona Urbana" data-week="09:00AM - 07:00PM" data-weekend="09:00AM - 07:00PM">Rimac </option>
                                                <option value="47" data-zona="Zona Alejada 2" data-week="" data-weekend="09:00AM - 07:00PM">San Bartolo </option>
                                                <option value="3" data-zona="Zona Urbana" data-week="09:00AM - 07:00PM" data-weekend="09:00AM - 07:00PM">San Borja </option>
                                                <option value="5" data-zona="Zona Urbana" data-week="09:00AM - 07:00PM" data-weekend="09:00AM - 07:00PM">San Isidro </option>
                                                <option value="28" data-zona="Zona Urbana" data-week="09:00AM - 07:00PM" data-weekend="09:00AM - 07:00PM">San Juan de Lurigancho </option>
                                                <option value="21" data-zona="Zona Urbana" data-week="09:00AM - 07:00PM" data-weekend="09:00AM - 07:00PM">San Juan de Miraflores </option>
                                                <option value="18" data-zona="Zona Urbana" data-week="09:00AM - 07:00PM" data-weekend="09:00AM - 07:00PM">San Luis </option>
                                                <option value="24" data-zona="Zona Urbana" data-week="09:00AM - 07:00PM" data-weekend="09:00AM - 07:00PM">San Martin de Porres </option>
                                                <option value="13" data-zona="Zona Urbana" data-week="09:00AM - 07:00PM" data-weekend="09:00AM - 07:00PM">San Miguel </option>
                                                <option value="29" data-zona="Zona Urbana" data-week="09:00AM - 07:00PM" data-weekend="09:00AM - 07:00PM">Santa Anita </option>
                                                <option value="53" data-zona="Zona Alejada 2" data-week="" data-weekend="09:00AM - 07:00PM">Santa Cruz de Flores </option>
                                                <option value="45" data-zona="Zona Alejada 2" data-week="" data-weekend="09:00AM - 07:00PM">Santa Maria del Mar </option>
                                                <option value="49" data-zona="Zona Alejada 2" data-week="" data-weekend="09:00AM - 07:00PM">Santa Rosa </option>
                                                <option value="2" data-zona="Zona Urbana" data-week="09:00AM - 07:00PM" data-weekend="09:00AM - 07:00PM">Santiago de Surco </option>
                                                <option value="7" data-zona="Zona Urbana" data-week="09:00AM - 07:00PM" data-weekend="09:00AM - 07:00PM">Surquillo </option>
                                                <option value="34" data-zona="Zona Alejada  1" data-week="09:00AM - 07:00PM" data-weekend="">Ventanilla </option>
                                                <option value="22" data-zona="Zona Urbana" data-week="09:00AM - 07:00PM" data-weekend="09:00AM - 07:00PM">Villa El Salvador </option>
                                                <option value="23" data-zona="Zona Urbana" data-week="09:00AM - 07:00PM" data-weekend="09:00AM - 07:00PM">Villa Maria del Triunfo </option>
                                            </Form.Control>
                                            {errors?.district != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.district}
                                                        </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                            <Form.Label>Día de entrega:</Form.Label><br></br>
                                            <DatePicker
                                                minDate={new Date()}
                                                view='month'
                                                locale='es-ES'
                                                clearIcon={null}
                                                className={ errors?.deliberyDate != "" && errors?.deliberyDate !==undefined ? "form-control is-invalid" : "form-control "}
                                                onClickDay={(date) => {
                                                    setDeliveryDate(moment(date).format('YYYY/MM/DD'))

                                                }}
                                                tileDisabled={({date}) => !disabledDates.includes(moment(date).format("YYYY-MM-DD")) }
                                                value={deliveryDate}
                                                required={true}
                                            />
                                                {errors?.deliberyDate != "" && (
                                                        <Form.Control.Feedback type="invalid" style={{display:'block'}}>
                                                            {errors?.deliberyDate}
                                                        </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                            
                                        </Col>
                                       
                                        <Col md={6}>
                                            <Form.Group>
                                            <Form.Label>Teléfono de Contacto 1:</Form.Label>
                                            <input required type="text" className="form-control" placeholder="Teléfono" name="phone1" value={user.phone1} onChange={e => {handleUserData(e)}}/>
                                            <span className="help-block"></span>
                                            {errors?.phone1 != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.phone1}
                                                        </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                            <Form.Label>Teléfono de Contacto 2:</Form.Label>
                                            <input type="text" className="form-control" placeholder="Teléfono" name="phone2" value={data.phone2} onChange={e => {handleChangeData(e)}}/>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                
                                    <Row >
                                        <Form.Label>¿Tienes alguna indicación para la entrega?</Form.Label>
                                        <Col md={12}>
                                            <Form.Group>
                                            <textarea className="form-control" rows={2} placeholder="Escribir indicaciones..." name="observations" value={data.observations} onChange={e => {handleChangeData(e)}}></textarea>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    {user.totalCredits > 0 && (
                                    <Row >   
                                        <Form.Label>Tienes <span className="color_origo"> {user.totalCredits} Ori Points</span> acumulados</Form.Label>
                                        <Col md={6}>
                                            <Form.Group>
                                                <input type="number" className="form-control" name="totalCredits" value={totalCredits==null?0:totalCredits} placeholder="" min="0" max={user.totalCredits} onChange={e => {setTotalCredits(e.target.value)}} disabled={creditsActive}/>
                                                <span className="help-block"></span>
                                            </Form.Group>                                
                                        </Col>
                                        <Col md={6} className="form-group d-flex-end cont_btn_check">
                                        {creditsActive == false ? (
                                            <button type="button" className="btn-validate validate-points"  disabled={loadingCredits}
                                                onClick={() =>validateCredits(1)}>
                                                    {loadingCredits ? (
                                                    <Spinner animation="border" role="status" style={{width: '1rem', height: '1rem'}}>
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Spinner>
                                                    ) : (
                                                    'UTILIZAR'
                                                    )}
                                                
                                            </button>)
                                            :
                                            (<button type="button" className="btn-validate-outline validate-points" 
                                                onClick={() =>validateCredits(0)}>
                                                    REMOVER
                                                
                                            </button>)
                                        }
                                        </Col>
                                    </Row>)}
                                    <Row>
                                        <Col md={12}>
                                            <Form.Group>
                                            <div className="title_medio_pago">MEDIO DE PAGO</div>  
                                            <br></br>                                   
                                                <Form.Check type='radio' className='' required>
                                                        <Form.Check.Input defaultChecked={true} value={1}  name='paymentType' type="radio"  onChange={e => {handleChangeData(e)}} />
                                                        <Form.Check.Label style={{paddingTop:0}} className="no_bold c3">Tarjeta de crédito/débito  </Form.Check.Label> 
                                                    </Form.Check> 
                                            <div>
                                                <img className="img-mpag img-fluid" src={process.env.REACT_APP_PUBLIC_URL + "images/common/masterCardIcon.webp"} alt="Master Card"/>
                                                <img className="img-mpag img-fluid" src={process.env.REACT_APP_PUBLIC_URL + "images/common/visaIcon.webp"} alt="Visa"/>
                                                <img className="img-mpag img-fluid" src={process.env.REACT_APP_PUBLIC_URL + "images/common/americanExpressIcon.webp"} alt="American Express"/>
                                                <img className="img-mpag img-fluid" src={process.env.REACT_APP_PUBLIC_URL + "images/common/dinnersClubIcon.webp"} alt="Dinner Club"/>
                                            </div>
                                                {errors?.paymentType != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.paymentType}
                                                        </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Form.Group>
                                            <div className="title_medio_pago">COMPROBANTE DE PAGO</div>
                                            <br></br>
                                            <div className='dis_fle'>
                                                <Col md={6}>
                                                    <Form.Check type='radio' className='' required>
                                                        <Form.Check.Input defaultChecked={true} value={1}  name='voucherType' type="radio"  onChange={e => {handleChangeVoucher(e,1)}} />
                                                        <Form.Check.Label style={{paddingTop:0}} className="no_bold c3">Boleta</Form.Check.Label> 
                                                    </Form.Check> 
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Check type='radio' className='' required>
                                                        <Form.Check.Input value={2} name='voucherType' type="radio" onChange={e => {handleChangeVoucher(e,2)}}/>
                                                        <Form.Check.Label style={{paddingTop:0}} className="no_bold c3">Factura</Form.Check.Label> 
                                                    </Form.Check> 
                                                </Col>
                                            </div>
                                            {errors?.voucherType != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.voucherType}
                                                        </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    {data.voucherType==2 && (<Row className="datos_factura">
                                        <Col md={6} className="form-group">
                                            <Form.Label>RUC:</Form.Label>
                                            <input required type="text" className="form-control" placeholder="Número de RUC"  name="ruc" value={data.ruc} onChange={e => {handleChangeData(e)}}/>
                                            {errors?.ruc != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.ruc}
                                                        </Form.Control.Feedback>
                                                )}
                                        </Col>
                                        <Col md={6} className="form-group">
                                            <Form.Label>Razón Social:</Form.Label>
                                            <input required type="text" className="form-control" placeholder="Razón Social"  name="companyName" value={data.companyName} onChange={e => {handleChangeData(e)}}/>
                                            {errors?.companyName != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.companyName}
                                                        </Form.Control.Feedback>
                                                )}
                                        </Col>
                                    </Row>)}
                                    <br></br>
                                    <Row>
                                        <Col md={12}>
                                            
                                            <Form.Group>                                
                                            <Form.Check className=''>
                                                <Form.Check.Input required value={data.acceptTerms} name='acceptTerms' type="checkbox"  onChange={e => {handleChangeData(e)}} />
                                                <span className="no_bold c1">
                                               &nbsp; Acepto los <a href="javascript:;" onClick={handleShow} className="terminos_condiciones">términos y condiciones.</a>
                                                </span>
                                                {errors?.acceptTerms != "" && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors?.acceptTerms}
                                                        </Form.Control.Feedback>
                                                )}
                                            </Form.Check> 
                                            
                                            </Form.Group>
                                            <div className='cont_btn_compra'>
                                            <CulqiComponent
                                                currency="PEN"
                                                handleSaveOrder={handleSaveOrder}
                                                handleShowErrorNotification={handleShowErrorNotification}
                                                handleSetLoading={handleSetLoading }
                                                handleOpenCulqiCh={handleOpenCulqiCh}
                                                setAmountCulqiCh={handleSetAmountCh}
                                            />
                                         
                                            </div>
                                        </Col>
                                    </Row>
                                    
                                </Form>
                            </div>
                        </Col>
                        <Col md={5}>
                            <div  className="part2">
                                <div className="cont_title_res">
                                    <h5>RESUMEN DE COMPRA</h5>
                                </div>
                                {cartItems.map(item => (
                                    <CartItem key={item.id} {...item} />
                                ))}
                                { freeItem.quantity > 0 && <CartItem key={freeItem.id} {...freeItem} />}
                               
                                <hr></hr>         
                                <Row className="cont_datos_res">
                                    <Col md={7} className="">
                                        <div> Sub Total</div>
                                    </Col>
                                    <Col md={5} className="text-right">
                                        <div className="subtotalOrigo">{formatMoney(data.subtotalCart)}</div>
                                    </Col>
                                    <Col md={7} className="">
                                        <div>  Costo Envío &nbsp;<Tooltip texttool="El costo de envio se calculará al elegir un distrito"></Tooltip></div>
                                    </Col>
                                    <Col md={5} className="text-right">
                                        <div id="costoEnvio"> {data.shippingCost==-1 ? '-': (data.shippingCost == 0 ? 'Gratuito' : formatMoney(data.shippingCost))}</div>
                                    </Col>
                                    <Col md={7} className="">
                                        <div>  Dscto. Suscripción 5%</div>
                                    </Col>
                                    <Col md={5} className="text-right">
                                        <div className="discountSus">- {formatMoney(data.totalDiscountSus)}</div>
                                    </Col>
                                    <Col md={7} className="">
                                        <div>  Descuento Referido <span className="perDiscount">{data.perDiscount}%</span> <span className="ucode">{data.ucode}</span></div>
                                    </Col>
                                    <Col md={5} className="text-right">
                                        <div className="discountFirstPurchase">- {formatMoney(data.totalDiscount)}</div>
                                    </Col>
                                    <Col md={7} className="">
                                        <div>  Descuento Cupón <span className="perDiscount">{data.promoType==0 ? data.valorPromo + "%" : ""}</span> <span className="ucode">{data.promoCode}</span></div>
                                    </Col>
                                    <Col md={5} className="text-right">
                                        <div className="discountFirstPurchase">- {formatMoney(data.totalPromo)}</div>
                                    </Col>
                                    <Col md={7} className="">
                                        <div>  Oripoints utilizados <span className="perDiscount"></span> <span className="ucode"></span></div>
                                    </Col>
                                    <Col md={5} className="text-right">
                                        <div className="discountFirstPurchase">- {formatMoney(data.totalCredits)}</div>
                                    </Col>
                                </Row>
                                <Row className="pad_b_0 cont_datos_res">
                                    <Col md={7} className="">
                                        <h4>PAGO TOTAL:</h4>
                                    </Col>
                                    <Col md={5} className="text-right color_origo">
                                        <h4 className="totalOrigo">{formatMoney(data.total)}</h4>
                                    </Col>
                                </Row>
                                <div className="cont_mess">
                                    <Col md={12} className=""></Col>
                                    <br></br>                                    
                                    <Form.Label className="">* Horario de entrega: 09:00am - 07:00pm</Form.Label>
                                    <Form.Label className="">* Compra mínima {data.coinType} 25.00 </Form.Label>
                                    <Form.Label className="">* Precios incluyen IGV </Form.Label>
                                    <Form.Label className="">* El envío para distritos de Zona 2 y 3 pueden demorar hasta 48 hrs. </Form.Label>
                                    {labelZone != "" && <Form.Label className="">* {labelZone} </Form.Label>}
                                    {hasRecurrentPurchase== true && <Form.Label className="">* Costo de envío por compras recurrentes posteriores: {data.coinType} 5.00</Form.Label>}
                                    {promos.map((item:any) => (
                                        <Form.Label className="">* {item.mensaje}</Form.Label>
                                    ))}
                                    
                                    <div className="cupon-prod-val"></div>
                                    <Col md={1} className=""></Col>
                                </div>
                            </div>
                            {isAuthenticated == false && (
                            <div className="cont_btn_r_c">
                                <div className="btn-register-checkout">
                                <span className="bold-fam">¡REGÍSTRESE HOY </span>Y OBTENGA 10% DE DSCTO. EN SU PRIMERA COMPRA!
                                </div>
                            </div>)}
                        </Col>
                    </Row>
                </div>                
                <BeforeFooter></BeforeFooter>
                <Terms 
                show={show} 
                handleClose={handleClose} 
                />
            </section>            
        </div>
        </MainLayout>
)};
export default Checkout;
