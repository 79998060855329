import React from 'react';
import "./style.css";
import { useState, useEffect } from "react"
import BannerParallax from '../../components/bannerParallax/bannerParallax';
import { Row, Col, Form } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import {StoreService} from '../../business/StoreService';
import moment from 'moment';
import { PurchaseItem } from 'components/PurchaseItem';
import { formatMoney } from "../../utils/formatCurrency"
import BeforeFooter from 'components/beforeFooter/beforeFooter';
import MainLayout from "../../components/layout/MainLayout/MainLayout";

let defaultData = {
    ucode: ""
    ,promoCode: ""
    ,observations: ""
    ,reference: ""
    ,paymentType: ""
    ,tokenCulqi: ""
    ,installments: ""
    ,total: 0.0
    ,igv: 18.00
    ,taxes:0.0
    ,subtotal:0.0
    ,subtotalCart:0.0
    ,shippingCost: -1.0
    ,shippingDistrict: -1.0
    ,perDiscount: 0.00
    ,totalDiscount: 0.0
    ,tipoPromo: 0
    ,valorPromo: 0.0
    ,totalPromo: 0.0
    ,coinType: "S/"
    ,postalCode: ""
    ,referedDiscount: 0.0
    ,couponDiscount: 0.0
    ,freeCost: 1000.00
    ,deliberyDate: ""
    ,time: 1
    ,totalCredits: 0
    ,promoItems: []
    ,totalDiscountSus: 0.0
    ,promoType: 0
    ,promoItemsNames: ""
    ,promoAmmount: 0.0
    ,msgPromoDiscount: ""
    ,alertMessage: ""
    ,couponActive: false
    ,uniqueCodeActive: false
    ,voucherType: 1
    ,ruc: ""
    ,companyName: ""

    ,paymentMethod: ""
    ,district: ""
    ,schedule:""
    ,docType: ""
    ,docNumber: ""
    ,email: ""
    ,firstName: ""
    ,lastName: ""
    ,lastName2: ""
    ,address: ""
    ,phone1: ""
    ,phone2: ""
    ,pedidodetalle: []
  }
function Resumen (){
    const [purchase, setPurchase] = useState(defaultData);
    const [isFetching, setIsFetching] = useState(false);

    let params = useParams();
    let id = params.code;

    const getPurchase = async (code:string) => {
        setIsFetching(true);
        const instance = StoreService.getInstance();
                            
        await instance.getPurchase({code: code})
        .then((response:any) => {
            if(response.success==true){
                
                setPurchase(response.data);
                setIsFetching(false);
                          
            }  
            else{
                window.location.href = "/tienda"
            }
        })
        .catch((err:any) => {
            console.log(err);
            setIsFetching(false);
        });
        
      } 

      useEffect(() => {   
        getPurchase(id!=undefined?id:"");         
    }, []);



    return (
        <MainLayout>
        <div className='pad_initial'>
            <BannerParallax 
                img={process.env.REACT_APP_PUBLIC_URL + "images/banners/parallax1.webp"}
                clase="cover"
                text= "RESUMEN DE COMPRA"
            ></BannerParallax>
            <section className="section_checkout">
            <div className="checkout_w success-shop">
                <Row>
                    <Col md={12}>
                        <div className="text_success">LA OPERACIÓN SE REALIZÓ CON <span className="bold_type">ÉXITO. ¡GRACIAS POR SU COMPRA!</span></div>                
                    </Col>
                </Row>
            </div>
                <div className="checkout_w">
                    <Row className="">
                        <Col md={7}>
                            <div className="part1">
                                <h3>RESUMEN DE SU PEDIDO</h3>
                                <Form id="form-compra">
                                    <Row>    
                                        <Col md={4}>                  
                                            <Form.Group>                                                              
                                                <Form.Label>Email:</Form.Label>
                                                <div>{purchase.email}</div>
                                                </Form.Group>  
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                        <Form.Group>
                                            <Form.Label>Nombres:</Form.Label>
                                            <div>{purchase.firstName}</div>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                        <Form.Group>
                                            <Form.Label>Primer Apellido:</Form.Label>
                                            <div>{purchase.lastName}</div>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                        <Form.Group>
                                            <Form.Label>Segundo Apellido:</Form.Label>
                                            <div>{purchase.lastName2}</div>
                                            </Form.Group>
                                        </Col>                                        
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Form.Group>
                                            <Form.Label>Dirección de envío:</Form.Label>
                                            <div>{purchase.address}</div>
                                            </Form.Group>
                                        </Col>
                                        
                                       
                                    </Row>                                    
                                    <Row>                                        
                                        <Col md={4}>
                                            <Form.Group>
                                            <Form.Label>Distrito:</Form.Label>
                                            <div>{purchase.district}</div>
                                            </Form.Group>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Group>                                                
                                            <Form.Label>Referencia:</Form.Label>
                                            <div>{purchase.reference}</div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col md={4}>
                                            <Form.Group>
                                            <Form.Label>Fecha de entrega:</Form.Label>
                                            <div>{moment(purchase.deliberyDate).format('DD/MM/YYYY')}</div>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                            <Form.Label>Teléfono contacto 1:</Form.Label>
                                            <div>{purchase.phone1}</div>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                            <Form.Label>Teléfono contacto 2:</Form.Label>
                                            <div>{purchase.phone2}</div>
                                            </Form.Group>
                                        </Col>
                                    </Row>                                
                                    <Row >
                                        <Col md={12}>
                                            <Form.Group>
                                            <Form.Label>Observaciones:</Form.Label>
                                            <div>{purchase.observations}</div>
                                            </Form.Group>
                                        </Col>
                                    </Row>                                    
                                </Form>
                            </div>
                            <div className="cont_btn_regreasr">
                                <button className="btn-regresar">REGRESAR A TIENDA</button>
                            </div>
                        </Col>
                        <Col md={5}>
                            <div  className="part2">
                                <Row className="cont_datos_res">
                                    <Col md={7} className="">
                                        <div> Sub Total</div>
                                    </Col>
                                    <Col md={5} className="text-right">
                                        <div className="subtotalOrigo"> {formatMoney(purchase.subtotal)}</div>
                                    </Col>
                                    <Col md={7} className="">
                                        <div>  Costo Envío</div>
                                    </Col>
                                    <Col md={5} className="text-right">
                                        <div id="costoEnvio"> {formatMoney(purchase.shippingCost)}</div>
                                    </Col>
                                    <Col md={7} className="">
                                        <div>  Total Descuento</div>
                                    </Col>
                                    <Col md={5} className="text-right">
                                        <div className="discountSus">-  {formatMoney(purchase.totalDiscount)}</div>
                                    </Col>
                                    
                                </Row>
                                <Row className="pad_b_0 cont_datos_res">
                                    <Col md={7} className="">
                                        <h4>TOTAL:</h4>
                                    </Col>
                                    <Col md={5} className="text-right color_origo">
                                        <h4 className="totalOrigo"> {formatMoney(purchase.total)}</h4>
                                    </Col>
                                </Row>
                                <div className="cont_mess">
                                    <Col md={12} className=""></Col>
                                    <br></br>                                    
                                    <Form.Label className="">* Costo de envío por compras recurrentes posteriores:  S/ 5.00</Form.Label>
                                    <div className="cupon-prod-val"></div>
                                    <Col md={1} className=""></Col>
                                </div><hr></hr>                                  
                                <div className="cont_title_res">
                                    <h5>PRODUCTOS:</h5>
                                </div>                                     
                                {purchase.pedidodetalle.map((item:any) => (
                                    <PurchaseItem key={item.id} {...item} />
                                ))}
                            </div>
                        </Col>
                    </Row>                      
                </div>              
                <BeforeFooter></BeforeFooter>
            </section>
        </div>
        </MainLayout>
)};
export default Resumen;
