import React from 'react';
import "./style.css";
import { Form, Container, Row, Col, Button } from 'react-bootstrap';
import Paso from '../../components/frontUtility/programSteps';
import ButonCreateAccount from '../../components/frontUtility/butonCreateAccount';
import Accordion from 'react-bootstrap/Accordion';
import BeforeFooter from 'components/beforeFooter/beforeFooter';
import Carousel from 'react-bootstrap/Carousel';
import BoxNumberPD from 'components/producto/boxNumberPD';
import ProductDescription from 'components/producto/productDescription';
import ProductDescriptionAccordion from 'components/producto/productDescriptionAccordion';
import {useState, useEffect} from 'react';
import { useShoppingCart } from "../../context/ShoppingCartContext";
import { formatMoney } from "../../utils/formatCurrency";
import { useParams } from "react-router-dom";
import {StoreService} from '../../business/StoreService';
import { isTemplateExpression } from 'typescript';
import MainLayout from "../../components/layout/MainLayout/MainLayout";

type PresentationImg = {
  imagen_path:string
}
type Presentation = {
  idPresentation: number
  ,name: string
  ,presentation: string
  ,price: number
  ,percentageDiscount: number
  ,normalPrice: number
  ,tipo_promo:number
  ,image: string
  ,description:string
  ,presentacionesimg: PresentationImg[]
  ,onceBuy:boolean
}

type ProductProds = {
  idProduct: number
  ,idLine:number
  ,idPresentation: number
  ,selected: number
  ,url: string
  ,image: string
  ,line: string
  ,name: string
  ,category: string
  ,details:string
  ,abstract:string
  ,energy:number
  ,proteins:number
  ,fats:number 
  ,humidity:number
  ,presentaciones: Presentation[]
}

let initalValueProduct = {
  idProduct: 0
  ,idLine: 0
  ,idPresentation: 0
  ,selected: -1
  ,url: ""
  ,image: ""
  ,line: ""
  ,name: ""
  ,category: ""
  ,presentaciones: []
}

function DetalleProducto (){
  let params = useParams();
  let slug =params.slug == undefined ? '0' : params.slug;

  const [quantity, setQuantity] = useState(1);
  const [product, setProduct] = useState<ProductProds>();
  const [loading, setLoading] = useState(true);
  const [selectedPresentation, setSelectedPresentation] = useState<Presentation>();
  const [typeBuy, setTypeBuy] = useState(1);
  const [periodicity, setPeriodicity] = useState(7);
  const [sendXDayVisible, setSendXDayVisible] = useState(false);

  const { addItemToCart,
  } = useShoppingCart()
 
  useEffect(() => {      
    getProduct(slug)      
  }, []);

  const increaseQuantity = () =>{
    setQuantity(quantity+1);
  }

  const decreaseQuantity = () => {    
    if(quantity>1)
    {
       setQuantity(quantity-1);  
    }
        
}

  const changePeriodicity = (event:any) => {        
    setPeriodicity(event.target.value);      
  }

  const changeBuyType = (type:number) => {        
    setTypeBuy(type);     
    if (type === 2) {
      setSendXDayVisible(true);
    } else {
      setSendXDayVisible(false);
    }
  }

  const addToCart = () => {    
       
    if(selectedPresentation!==undefined && product !== undefined)
    {
      let objCart = {
          id: selectedPresentation.idPresentation
          ,name: selectedPresentation.name
          ,category: product.category
          ,idLine: product.idLine
          ,line: product.line
          ,price: selectedPresentation.price
          ,quantity: quantity
          ,percentageDiscount: selectedPresentation.percentageDiscount
          ,normalPrice:selectedPresentation.normalPrice
          ,image: process.env.REACT_APP_BASE_URL_BACK + product.image
          ,presentation:selectedPresentation.presentation
          ,type: typeBuy
          ,promo: selectedPresentation.tipo_promo
          , total: 0
          ,periodicity: typeBuy==1 ? 0 : periodicity
          ,canEdit: true
          ,onceBuy: selectedPresentation.onceBuy
      }     

      addItemToCart(objCart);
    }
    
}

  const getProduct = async (slug:string) => { 
    const getInstance = StoreService.getInstance();
    

    await getInstance.getProduct({slug:slug})
        .then((response:any) => {
          
              if(response.success === true)
              {
                setSelectedPresentation(response.product.presentaciones[0])
                
                let copy = response.product;    
                copy.idPresentation = response.product.presentaciones[0].idPresentation;
                copy.selected = response.product.presentaciones[0].idPresentation;
                setProduct(copy);
                

              }
              else{
                
              }
              setLoading(false);
            }   
          )
        .catch((err:any) => {
          setLoading(false);
        });

    }

    const changePresentation = (event:any, index: number) => {
      event.preventDefault();
      setTypeBuy(1);
      setPeriodicity(7);
      setSelectedPresentation(product?.presentaciones[index]);
      
  }

  let showDescription = 0;
  let showIngredient = 0;
  let showAnalisys = 0;
  
  if (product?.idLine !== undefined) {
    if ([1, 2, 3].includes(product.idLine)) {
      showDescription = 1;
      showIngredient = 1;
      showAnalisys = 1;
    } else if ([5].includes(product.idLine)) {
      showDescription = 1;
      showIngredient = 0;
      showAnalisys = 0;
    } else if ([4,8].includes(product.idLine)) {
      showDescription = 1;
      showIngredient = 1;
      showAnalisys = 0;
    }
  }

  let showRecurrent = 1;
  if (product?.idLine !== undefined) {
    if ([5].includes(product.idLine)) {
      showRecurrent = 0;
    }
  }
    
    return (
    <MainLayout>
    <div className='back_detall'>
      <Container className=''>      
          <Row>
              <Col md={6} style={{position: 'relative'}}>
              {selectedPresentation?.tipo_promo !== undefined && selectedPresentation.tipo_promo > 0 &&
                    <div className="dscto_cont d-flex justify-content-center align-items-center">Promo: {selectedPresentation?.tipo_promo == 1 ? '2x1': '3x2'}</div>    
                }
                <div className='back_two h100'>              
                <Carousel fade>
                   {selectedPresentation?.presentacionesimg !==undefined && selectedPresentation?.presentacionesimg.length > 0 ? selectedPresentation?.presentacionesimg.map((item:PresentationImg, index:number) => (
                          <Carousel.Item>
                          <img key={'boxpreimg'+index}
                            className="d-block w-100-dp"
                            src={process.env.REACT_APP_BASE_URL_BACK + item.imagen_path}
                            alt="First slide"
                          />
                        </Carousel.Item>
                      )) :
                      <Carousel.Item>
                        {product?.image && (<img key={'boxpreimg1'}
                          className="d-block w-100-dp"
                          src={process.env.REACT_APP_BASE_URL_BACK + product?.image}
                          alt="First slide"
                        />)}
                    </Carousel.Item>
                  }
                  </Carousel>
                </div>                
              </Col>
              <Col md={6}>                
                <div className='back_two back_alig pad_2'>  
                  
                  <h4 className="title_product">{product?.name}</h4>
                  <p className="subtitle_product">{product?.line} - {product?.category}</p>
                  <div className="text3-r prod-descripcion" id="descDetalleprod">{selectedPresentation?.description}</div>
                  {/* <div className="text3-r prod-descripcion color-prod pad_bot_15">
                    <a href="#" className="text_click " data-toggle="modal" data-target="#table-detalle">Click aquí
                    </a> para ver cuadro de raciones y duración
                  </div> */}
                  {selectedPresentation?.percentageDiscount !== undefined && selectedPresentation.percentageDiscount > 0 && (<span className="tachado">S/ {selectedPresentation?.normalPrice}</span>) }        
                  <span className="price_product">S/ {selectedPresentation?.price}</span>
                  {selectedPresentation?.presentation != "" && (
                    <p>Escoger presentación:</p>
                  )}
                  {selectedPresentation?.presentation == "" && (
                    <p></p>
                  )}
                {selectedPresentation?.presentation != "" && (
                    <Row >
                    {
                        product?.presentaciones.map((item:Presentation, index:number) => (
                            <div  key={'boxprod'+index} className={'box-prod '+ (item.idPresentation==selectedPresentation?.idPresentation ? 'active' : '')}
                                onClick = {(e) => changePresentation(e,index)}
                            >{item.presentation}</div>
                        ))
                    }
                    </Row>
                  )}
                    <br></br> 
                  {product?.idProduct != 5 && product?.idProduct != 6 && (
                  <Form.Group>
                    <Form.Check type='radio' className='container_input_boot' required style={{marginBottom: '5px'}}>
                      <Form.Check.Input defaultChecked={true} value={1}  type="radio"  name="buyType" checked={typeBuy==1 ? true : false} onChange={(e) => changeBuyType(1)}/>
                      <Form.Check.Label style={{paddingTop:0}} className="c3">
                          <div>Una sola compra (Delivery: Lunes a Sábado)</div>
                      </Form.Check.Label> 
                    </Form.Check>
                    {selectedPresentation?.onceBuy == false && ( 
                    <Form.Check type='radio' className='container_input_boot' required style={{marginBottom: '1rem'}}>
                      <Form.Check.Input defaultChecked={true} value={2}  type="radio"  name="buyType" checked={typeBuy==2 ? true : false} onChange={(e) => changeBuyType(2)}/>
                      <Form.Check.Label style={{paddingTop:0}} className="c3">
                          <div>Compra recurrente <span className='color-primary'>(+5% de descuento)</span></div>
                      </Form.Check.Label> 
                    </Form.Check> 
                    )}

                    {/* <Form.Label className="container_input c3" style={{marginBottom: '5px'}}>                    
                        <input type="radio" className="" value="1" name="buyType" checked={typeBuy==1 ? true : false} onChange={(e) => changeBuyType(1)}/>
                        <div>Una sola compra (Delivery: Lunes a Sábado)</div>
                        <span className="radius" style={{top:'6px'}}></span>
                    </Form.Label>
                    <Form.Label className="container_input" style={{marginBottom: '5px'}}>
                    <input type="radio" className="" value="2" name="buyType" checked={typeBuy==2 ? true : false} onChange={(e) => changeBuyType(2)}/>
                    <div>Compra recurrente <span className='color-primary'>(+5% de descuento)</span></div>
                    <span className="radius" style={{top:'6px'}}></span>
                    </Form.Label> */}
                </Form.Group>
                  )}
                
                {typeBuy == 2  && ( 
                  <Col md={8} className='dis_fle'>           
                  <Form.Label style={{marginBottom: '0px'}}>Se envía cada: &nbsp;</Form.Label>
                  <Form.Group style={{alignItems: 'center'}}>
                      <select id="periodicityPreview" className="form-control" 
                          name="day" 
                          onChange={(e) => changePeriodicity(e)}
                      >
                          {Array.from({ length: 24 }).map((it, index) => (
                              <option value={7 + index}>{7 + index} {'días'}</option>
                          ))}
                      </select>
                      <span className="help-block"></span>
                      </Form.Group>
                  </Col>
                  )}
                  <br></br>
                    <div className='dis_fle'>
                       
                        <BoxNumberPD
                          quantity={quantity}
                          increaseQuantity={increaseQuantity}
                          decreaseQuantity={decreaseQuantity}
                        />&nbsp;
                        <button className="btn_origov2"
                            onClick={() => addToCart()}>
                            AÑADIR AL CARRO
                        </button> 
                    </div>

                  <div>
                  {(product?.idLine == 1 || product?.idLine == 3 || product?.idLine == 2 || product?.idLine == 4 || product?.idLine == 5|| product?.idLine == 8) ?  (<Col md={12}>
                {product!==undefined && (<ProductDescriptionAccordion 
                  title= {product.name}
                  description= {product.details}
                  resumen=  {product.abstract}
                  calorias= {product.energy}
                  proteinas= {product.proteins}
                  grasas= {product.fats}
                  humedad= {product.humidity}
                  showDescription={showDescription}
                  showIngredient={showIngredient}
                  showAnalisys={showAnalisys}
                /> )}                        
              </Col>) : (<Col md={12}><br></br> </Col>)}
                  </div> 
                </div>
              </Col>
              {/* {(product?.idLine == 1 || product?.idLine == 3) ?  (<Col md={12}>
                {product!==undefined && (<ProductDescription 
                  title= {product.name}
                  description= {product.details}
                  resumen=  {product.abstract}
                  calorias= {product.energy}
                  proteinas= {product.proteins}
                  grasas= {product.fats}
                  humedad= {product.humidity}
                /> )}                        
              </Col>) : (<Col md={12}><br></br> </Col>)} */}
          </Row>           
      </Container>   
      {product?.idLine == 1 && (<Container>
        <Row>
          <Col md={12}>
          <div className='presas_description back_two2 pad_two mar_top_3'>
            <Row>              
                <Col md={3} className='description_detail_producto'>
                    <div className="cont_img_presas">
                        <img src={process.env.REACT_APP_PUBLIC_URL + "images/tienda/pollo.webp"} alt="Pollo" />
                    </div>                
                </Col>
                <Col md={9} className='cont-vertical-center'>
                  <div className='vertical-center'>
                    <h3 className='title-presas'>¿Qué significan presas enteras?</h3>
                    <p className='parr-presas'>En la naturaleza, los depredadores cubren sus requerimientos nutricionales cazando y alimentándose con animales enteros. Por eso, en la formulación de nuestros productos deshidratados incluimos proteína animal en presas enteras, porque en ellos se encuentra la proporción ideal de macro y micronutrientes que tu mascota necesita para encontrar balance de cuerpo y mente. ​</p>                        
                  </div>
                </Col>
            </Row>
          </div> 
          </Col>
        </Row>
      </Container>)}
      {product?.idLine == 1 && (<div className='rendimiento back_rendimiento vertical-center'>
        <div className='con-text-rendimiento'>
          <h3 className='title-rendimiento'>Rendimiento</h3>
          <p className='parr-rendimiento'>Nuestro proceso de deshidratado, aparte de ayudarnos a preservar los nutrientes de los insumos crudos, nos permite ofrecerte un alimento altamente concentrado. ¡No te dejes engañar! Basta con agregar un poco de agua para descubrir el contenido real de nuestras cajas. ​</p>                        
          <h5>En nuestra caja de 2.5 Kg hay en realidad 10 Kg de alimento fresco.</h5>
        </div> 
        <div className='cont_img_rendimiento'>
          <img src={process.env.REACT_APP_PUBLIC_URL + "images/tienda/rendimiento.webp"} alt="Rendimiento" />
        </div>
      </div> )}
      <BeforeFooter></BeforeFooter>
    </div>
    </MainLayout>
)};

export default DetalleProducto;