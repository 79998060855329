import { api } from "../api";
import ActionType from "../utils/customTypes";

export class ProductService extends api {

    private static classInstance?: ProductService;
        
    constructor(token: string, type: ActionType) {
        super( process.env.REACT_APP_BASE_URL,token);    
    } 
     
    public static getInstance() {
        let token = localStorage.getItem("token");
        if (!this.classInstance) {
            this.classInstance = new ProductService(token!==undefined && token !== null ? token:'', 'GET');
        }
        
        return this.classInstance;
    }   
     
    public getProducts = (data:any) => this.instance.post('/get-products',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getProduct = (data:any) => this.instance.post('/get-product',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getProductPresentation = (data:any) => this.instance.post('/get-product-presentation',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getProductsPresentations = (data:any) => this.instance.post('/get-products-presentations',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getProductDetails = (data:any) => this.instance.post('/products/get-product-details', data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getProductsList = (data:any) => this.instance.post('/get-products-list',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getProductsBaseList = (data:any) => this.instance.post('/get-products-base-list',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getLinesList = () => this.instance.post('/get-lines-list').then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getCategoryList = () => this.instance.post('/get-category-list').then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getStageList = () => this.instance.post('/get-stage-list').then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getActivityList = () => this.instance.post('/get-activity-list').then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getExperienceList = () => this.instance.post('/get-experience-list').then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public getPresentationList = () => this.instance.post('/get-presentations-list').then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public insertProduct = (data:any) => this.instance.post('/insert-product',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public insertPresentationImage = (data:any) => this.instance.post('/insert-presentation-image',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public insertProductPresentation = (data:any) => this.instance.post('/insert-product-presentation',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public updateProduct = (data:any) => this.instance.post('/update-product',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public updateProductStatus = (data:any) => this.instance.post('/update-product-status',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public updateProductPromo = (data:any) => this.instance.post('/update-product-promo',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public updateProductPresentation = (data:any) => this.instance.post('/update-product-presentation',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public updateProductPresentationStatus = (data:any) => this.instance.post('/update-product-presentation-status',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public deletePresentationImage = (data:any) => this.instance.post('/delete-presentation-image',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public deleteProduct = (data:any) => this.instance.post('/delete-product',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    public deletePresentation = (data:any) => this.instance.post('/delete-presentation',data).then(response => {
        if (response) {
            return response;
        }
    
    }).catch((error: any) => {
        return Promise.reject(error);
    });

    

    

}