import Modal from 'react-bootstrap/Modal';
import "./style.css";
import BoxNumber from '../producto/boxNumber';
import {formatMoney} from '../../utils/formatCurrency';
import { Button, CloseButton, Form
    , Row , Col 
  } from "react-bootstrap"

type ModalPlanProps = {
    handleClose: any
    show?: boolean
    handleSave: any
    id: number
    text: string
    action:string
}

function ModalConfirm(props:ModalPlanProps) {

  return (
    <Modal className='modal_confirm' show={props.show} onHide={props.handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header  className="close-modal">                
            <CloseButton variant="white" onClick={props.handleClose}/>
        </Modal.Header>
        <Modal.Body>                
            <h3 className='title_modal_confirm'>{props.action}</h3>
            <div className='cont_modal_confirm'>               
                <div className="con_btn_confirm">
                    <button className='btn_confirm' onClick={(e) => props.handleSave(e, props.id)}>Sí, Continuar</button> 
                </div>
                <div className="con_btn_confirm">
                    <button className='btn_cancel' onClick={props.handleClose}>Cancelar</button> 
                </div>
            </div>

        </Modal.Body>
    </Modal>
  );
}

export default ModalConfirm