import './style.css';
import { Row , Col} from "react-bootstrap"
  
  type ButonCCProp = {
    text: string
    url: string
}  
function ButonCreateAccount(props: ButonCCProp){
  
    return (
        <Col md={12} className="space-button">
            <a className="btn-quiero-ser" href={props.url}>{props.text}</a>
        </Col>
    );
}
export default ButonCreateAccount;