import React from 'react';
import "../reporteVentas/style.css";
import BannerParallax from '../../../components/bannerParallax/bannerParallax';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';
import { useState, useCallback, useEffect } from 'react';


let suscripcion = [
    {
       id_donaci: 1
      ,suscriptor: "Bazan Mallma Fiorella"
      ,fecha: "2023-05-17 11:16:53"
      ,plan: "Plan S/ 20.00"
      ,monto: 20.00
      ,nro_tarjeta: "41479183****0173"
      ,estado: "Activo"      
    },
    {
        id_donaci: 2
        ,suscriptor: "Jacay Aldui Diego Jair"
        ,fecha: "2023-05-18 15:03:53"
        ,plan: "Plan S/ 50.00"
        ,monto: 50.00
        ,nro_tarjeta: "47728900****9444"
        ,estado: "Anulado"   
    }

]

let estadoSuscripcion = [
    {
        name: "Activo"
        ,value: 1
    },
    {
        name: "Fallo en cargo"
        ,value: 2
    },
    {        
        name: "Anulado"
        ,value: 3
    },
    {        
        name: "Pendiente de pago"
        ,value: 4
    }
]
function MisDonaciones (){

    return (
        <div className='fond_backoffice'>
            <Container>                
            <div className='cont_title'>
                <h1>Mis Donaciones</h1>
            </div>
            <div className='cont_data'>
                <Container className='cont_filters'>
                    <Row className='row_filter'>
                        <Col md={3} className="align-items-center txt_ali dis_fle">
                            <Col md={4}>
                            <label htmlFor="fecha_desde">F. Desde:</label>
                            </Col>
                            <Col md={8}>
                                <input name="fecha_desde"type="date" className='form-control'/>
                            </Col>
                        </Col>
                        <Col md={3} className="align-items-center txt_ali dis_fle">
                            <Col md={4}>
                                <label htmlFor="fecha_hasta">F. Hasta:</label>
                            </Col>
                            <Col md={8}>
                                <input name="fecha_hasta"type="date" className='form-control'/>
                            </Col>
                        </Col>
                        <Col md={3} className="align-items-center txt_ali dis_fle">
                            <Col md={4}>
                            <label htmlFor="estado_suscripcion">Estado:</label>
                            </Col>
                            <Col md={8}>
                                <select name="estado_suscripcion" id="" className='form-control'>
                                    <option>Seleccione</option>
                                    {estadoSuscripcion.map(item => (
                                    <option key={item.value} value={item.value}>{item.name}</option>
                                    ))}                                      
                                </select>
                            </Col>
                        </Col>
                        
                        <Col md={2} className="align-items-center txt_ali dis_fle">
                            <Button className='btn_filter_search'>Buscar</Button>
                        </Col>
                    </Row>
                </Container>
                <Table responsive className='table_backoffice'>
                <thead>
                    <tr>
                        <th>F. Donación</th>
                        <th>Plan</th>  
                        <th>Monto</th>
                        <th>Nro Tarjeta</th>                  
                        <th>Estado</th>
                        <th>Anular Donación</th>
                    </tr>
                </thead>
                <tbody>
                    {suscripcion.map((item) => {
                        return (    
                    <tr>
                        <td>{item.fecha}</td>
                        <td> {item.plan}</td>
                        <td>{item.monto}</td>
                        <td>{item.nro_tarjeta}</td>
                        <td><span className="label label-lg label-light-primary label-inline">{item.estado}</span></td>
                        <td className='text_icon'>
                            <div className='cont_input_switch'>
                                <label className="switch">
                                    <input type="checkbox"/>
                                    <span className="slider round"></span>
                                </label>
                            </div>
                            {/* <a href="#" className="desuscribirse" data-id={item.id_donaci}>
                                <div className='cont_ver_detalle'>                                
                                    <img src={process.env.REACT_APP_PUBLIC_URL + 'images/common/cancel.webp'} alt="Cancelar" />
                                </div>
                            </a> */}
                        </td>
                    </tr>
                    )
                })}
                </tbody>
                </Table>
                <div className='pagination_back'>                    
                <Pagination>
                    <Pagination.Prev />
                    <Pagination.Item active>{1}</Pagination.Item>
                    <Pagination.Item>{2}</Pagination.Item>
                    <Pagination.Item>{3}</Pagination.Item>
                    <Pagination.Ellipsis />
                    <Pagination.Item>{20}</Pagination.Item>
                    <Pagination.Next />
                </Pagination>

                </div>
            </div>
            </Container>
        </div>
)};
export default MisDonaciones;
