export const NETWORK_ERROR = 'Network Error';
export const MESSAGE_CODES = {
    OK: '0',
    SESSION_EXPIRED: '2',
    };

const constantes = {
    NETWORK_ERROR,
    MESSAGE_CODES

}
export const SUBDIRECTORY_PATH = process.env.REACT_APP_PUBLIC_SUBDIRECTORY;