import React from 'react';
import "../reporteVentas/style.css";
import BackLayout from "../../../components/layout/BackLayout/BackLayout";
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useState, useCallback, useEffect } from 'react';
import {AccountService} from '../../../business/AccountService';

let cuenta = {
    usuario: ""
    ,email: ""
    ,total_creditos: 0
    ,telefono_dos: ""
    ,codigo_unico: ""
}

function DashboardUsuario (){
    const [data, setData] = useState(cuenta);
    const [loading, setLoading] = useState(false);
    const [totalSuscripciones, setTotalSuscripciones] = useState(0);
    const [totalDonaciones, setTotalDonaciones] = useState(0);
    const [totalPedidos, setTotalPedidos] = useState(0);

    useEffect(() => {   
        getDashboard();      
      }, []);

    const getDashboard = async () => { 
        const getInstance = AccountService.getInstance();
        
        await getInstance.getDashboard()
            .then((response:any) => {
                    setLoading(false);
                    if(response.success === true)
                    {
                        setData(response.datosresumen);
                        setTotalSuscripciones(response.total_suscripciones);
                        setTotalDonaciones(response.total_donaciones);
                        setTotalPedidos(response.total_pedidos);
                    }
                    
                })
            .catch((err:any) => {
            });

    }

        return (
            <BackLayout>
            <div className='fond_backoffice'>  
                <Container>      
                {/* <div className='cont_title'>
                    <h1>Mi cuenta</h1>
                </div>      */}
                <div className='cont_data'>     
                    <Row>
                        <Col md={6} className="back_detalle_venta txt_h_100 other_h_sty">
                            <div className="">
                                <div className="">
                                    <h2 className="subtitle_detalle_venta">{data.usuario}</h2>
                                    <h3 className="title_detalle_venta">{data.email}</h3>
                                    <h3 className="title_detalle_venta">{data.telefono_dos}</h3>

                                </div>
                            </div>  
                        </Col>
                        <Col md={6}>
                            <div className='back_detalle_venta txt_h_100'>
                                <div className="">
                                    <div className="">
                                        <h3 className="subtitle_detalle_venta">Código único:</h3>
                                        <h3 className="title_detalle_venta">{data.codigo_unico}</h3>
                                        <hr></hr>
                                        <h1 className="subtitle_detalle_venta">Oripoints Disponibles:</h1>
                                        <h1 className="title_detalle_venta">{data.total_creditos}</h1>
                                        
                                    </div>
                                </div>   
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className='cont_data'>     
                    <Row>
                        <Col>
                            <div className="back_detalle_venta txt_h_100">
                                <div className="">
                                    <div className="">
                                        <h3 className="title_detalle_venta">Total compras</h3>
                                        <h4 className="title_detalle_venta">{totalPedidos}</h4>
                                    </div>
                                </div>   
                            </div>
                        </Col>
                        <Col>
                            <div className="back_detalle_venta txt_h_100">
                                <div className="">
                                    <div className="">
                                        <h3 className="title_detalle_venta">Suscripciones</h3>
                                        <h4 className="title_detalle_venta">{totalSuscripciones}</h4>
                                    </div>
                                </div>   
                            </div>
                        </Col>
                        <Col>
                            <div className="back_detalle_venta txt_h_100">
                                <div className="">
                                    <div className="">
                                        <h3 className="title_detalle_venta">Donaciones</h3>
                                        <h4 className="title_detalle_venta">{totalDonaciones}</h4>
                                    </div>
                                </div>   
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
            </div>
        </BackLayout>
    )};
export default DashboardUsuario;
